import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Semantic} from 'packages/components';
import {formProptypes} from 'packages/utils/formikPropTypes';
import {URLS} from 'packages/enum';
import {Button} from 'packages/components/buttons';

import {
    Content,
    Container,
    Header,
    Footer,
    StyledFormInput,
    StyledMessage,
} from '@landing/components/common/nodes';

const ResetPasswordForm = (
    {handleSubmit, errors, status, isSubmitting, loading},
    {intl: {formatMessage}},
) => {
    const statusSuccess = useMemo(() => {
        return status && status.success;
    }, [status]);
    const message = statusSuccess ?
        formatMessage({id: 'landing.resetPassword.success'}) :
        null;
    const error = Object.keys(errors).length || status && !!status.error;
    return (
        <Container>
            <Header>
                {formatMessage({id: 'landing.resetPassword.title'})}
            </Header>
            <Content>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting || loading}
                    onSubmit={handleSubmit}
                >
                    <StyledMessage
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    {!statusSuccess &&
                    <StyledFormInput
                        name="password"
                        label={formatMessage({id: 'landing.resetPassword.label.password'})}
                        placeholder={formatMessage({id: 'landing.resetPassword.placeholder.password'})}
                        width="100%"
                        type="password"
                    />
                    }
                    {
                        message ? <p>{message}</p> : null
                    }
                    {!statusSuccess &&
                    <Button
                        fluid
                        type="submit"
                        disabled={isSubmitting}
                        content={formatMessage({id: 'landing.resetPassword.button.label'})}
                    />
                    }
                </Semantic.Form>
                <Footer>
                    {formatMessage({id: 'landing.resetPassword.label.login'})}
                    {' '}
                    <Link to={URLS.website_login}>
                        {formatMessage({id: 'landing.resetPassword.label.here'})}
                    </Link>
                </Footer>
            </Content>
        </Container>
    );
};

ResetPasswordForm.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    loading: PropTypes.bool.isRequired,
    ...formProptypes,
};

ResetPasswordForm.defaultProps = {};

ResetPasswordForm.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default ResetPasswordForm;

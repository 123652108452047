import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import moment from 'moment';
import {injectIntl, intlShape} from 'react-intl';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';

import {PLAN_TYPE, SUBSCRIPTION_TYPE, URLS} from 'packages/enum';

import {Button} from 'packages/components/buttons';
import {useSubscription} from '@configurator/providers/subscription';

import {
    FreeAccountBanner,
    TrialText,
    TrialContainer,
    FreeAccountContainer,
    Counter,
    Progress,TrialLink,
} from './nodes';


function WebsiteBanner({intl: {formatMessage}, deletePrefilledPhotos, data}) {
    const {
        info: {
            subscriptionType,
            freePlan,
            planType,
            currentPeriodEnd,
            hideTrialEndsBanner,
        },
        loading,
    } = useSubscription();

    const dispatch = useDispatch();

    const goToBilling = useCallback(() => dispatch(push(URLS.website_billing)), [dispatch]);

    if (loading) {
        return null;
    }

    const count = data.count > data.limit ? 100 : ((data.count / data.limit) * 100).toFixed(0);

    return (
        <FreeAccountBanner>
            {
                subscriptionType === SUBSCRIPTION_TYPE.TRIAL && !hideTrialEndsBanner ? (
                    <TrialContainer>
                        <TrialText>
                            <span dangerouslySetInnerHTML={{__html: formatMessage({id: 'banner.trial.expires'},
                                    {date: moment(currentPeriodEnd).diff(moment(), 'days')})}}/>
                            <TrialLink onClick={goToBilling}>
                                <span>{formatMessage({id: 'banner.trial.expires.link'})}</span>
                            </TrialLink>
                        </TrialText>
                        <Button
                            content={formatMessage({id: 'banner.trial.upgrade'})}
                            onClick={goToBilling}
                        />
                    </TrialContainer>
                ) : null
            }
            {
                freePlan || planType === PLAN_TYPE.PROLITE ? (
                    <FreeAccountContainer>
                        <Counter>
                            <div>
                                <b>{data.count}/{data.limit}</b>
                                <span>{formatMessage({id: 'banner.trial.upload.count'})}</span>
                            </div>
                            <div>
                                <Progress count={count}/>
                            </div>
                        </Counter>
                        <Button
                            content={formatMessage({id: 'banner.trial.delete'})}
                            view="secondaryGray"
                            onClick={() => deletePrefilledPhotos()}
                        />
                        <Button
                            content={formatMessage({id: 'banner.trial.upgrade'})}
                            onClick={goToBilling}
                        />
                    </FreeAccountContainer>
                ) : null
            }
        </FreeAccountBanner>
    );
}

WebsiteBanner.propTypes = {
    intl: intlShape.isRequired,
    data: PropTypes.shape({
        count: PropTypes.number,
        limit: PropTypes.number,
    }).isRequired,
    deletePrefilledPhotos: PropTypes.func.isRequired,
};

WebsiteBanner.defaultProps = {
    data: {},
};

export default injectIntl(WebsiteBanner);

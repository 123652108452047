import {connect} from 'react-redux';
import {Map} from 'immutable';
import PageLoader from '../components/pageLoader/PageLoader';

const mapStateToProps = state => {
    let {
        open = false,
        content = '',
    } = (state.get('pageLoader') || new Map())
        .toJS() || {};
    return {
        open,
        content,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLoader);

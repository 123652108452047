import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Container, Header} from './nodes';

const StatisticsHeader = () => {

    return (
        <Container>
            {/*<StyledLink to={URLS.website_config}>*/}
            {/*    <Arrow name="arrow left"/>*/}
            {/*</StyledLink>*/}
            <Header>
                <FormattedMessage id="statistics.newDesign.header"/>
            </Header>
        </Container>
    );
};

export default StatisticsHeader;

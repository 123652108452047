import {PORTFOLIO_TEMPLATES, PORTFOLIO_TEMPLATES_ALIGN, SUBSCRIPTION_TYPE} from "packages/enum";

import barcelona from "packages/theme-ab/resources/assets/configurator/templates/Barcelona.jpg";
import rotterdam from "packages/theme-ab/resources/assets/configurator/templates/Rotterdam.jpg";
import stockholm from "packages/theme-ab/resources/assets/configurator/templates/Stockholm.jpg";
import vien from "packages/theme-ab/resources/assets/configurator/templates/Vien.jpg";
import riga from "packages/theme-ab/resources/assets/configurator/templates/Riga.jpg";
import pavel from "packages/theme-ab/resources/assets/configurator/templates/Pavel.jpg";
import madrid from "packages/theme-ab/resources/assets/configurator/templates/Madrid.jpg";
import rigaSlideshow from "packages/theme-ab/resources/assets/configurator/templates/Riga_Slideshow.jpg";
import antwerp from "packages/theme-ab/resources/assets/configurator/templates/Antwerp.jpg";
import berlin from "packages/theme-ab/resources/assets/configurator/templates/Berlin.jpg";
import brooklyn from "packages/theme-ab/resources/assets/configurator/templates/Brooklyn.jpg";
import sophie from "packages/theme-ab/resources/assets/configurator/templates/Sophie.jpg";
import sydney from "packages/theme-ab/resources/assets/configurator/templates/Sydney.jpg";
import jeremy from "packages/theme-ab/resources/assets/configurator/templates/Jeremy.jpg";
import jeremySlideshow from "packages/theme-ab/resources/assets/configurator/templates/Jeremy_Slideshow.jpg";
import newYork from "packages/theme-ab/resources/assets/configurator/templates/New_York.jpg";
import peggy from "packages/theme-ab/resources/assets/configurator/templates/Peggy.jpg";
import peggySlideshow from "packages/theme-ab/resources/assets/configurator/templates/Peggy_Slideshow.jpg";
import paris from "packages/theme-ab/resources/assets/configurator/templates/Paris.jpg";
import anton from "packages/theme-ab/resources/assets/configurator/templates/Anton.jpg";
import nikolai from "packages/theme-ab/resources/assets/configurator/templates/Nikolai.jpg";
import losAngeles from "packages/theme-ab/resources/assets/configurator/templates/LosAngeles.jpg";
import oslo from "packages/theme-ab/resources/assets/configurator/templates/Oslo.jpg";
import marseille from "packages/theme-ab/resources/assets/configurator/templates/Marseille.jpg";
import sevilla from "packages/theme-ab/resources/assets/configurator/templates/Sevilla.jpg";
import memphis from "packages/theme-ab/resources/assets/configurator/templates/Memphis.jpg";
import sacramento from "packages/theme-ab/resources/assets/configurator/templates/Sacramento.jpg";
// import edmonton from "packages/theme-ab/resources/assets/configurator/templates/Edmonton.png";

export const makeTemplatesArray = ({
                                       updateTemplate,
                                       templateName,
                                       align,
                                       slideshowEnabled,
                                       planType,
                                   }) => [
    // // GOTHENBURG
    // {
    //     imageUrl: edmonton,
    //     previewUrl: "https://vsble.me",
    //     header: "gothenburg",
    //     slideshowEnabled: false,
    //     isFree: false,
    //     selected:
    //         templateName === PORTFOLIO_TEMPLATES.GOTHENBURG &&
    //         (align || "").toLowerCase() ===
    //             PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
    //         slideshowEnabled === false,
    //     updateTemplate: () =>
    //         updateTemplate({
    //             template: PORTFOLIO_TEMPLATES.GOTHENBURG,
    //             align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
    //             inverted: false,
    //             slideshowEnabled: false,
    //         }),
    // },
    // // EDMONTON
    // {
    //     imageUrl: sevilla,
    //     previewUrl: "https://vsble.me",
    //     header: "EDMONTON",
    //     slideshowEnabled: false,
    //     isFree: false,
    //     selected:
    //         templateName === PORTFOLIO_TEMPLATES.EDMONTON &&
    //         (align || "").toLowerCase() ===
    //         PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
    //         slideshowEnabled === false,
    //     updateTemplate: () =>
    //         updateTemplate({
    //             template: PORTFOLIO_TEMPLATES.EDMONTON,
    //             align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
    //             inverted: false,
    //             slideshowEnabled: false,
    //         }),
    // },
    // SACRAMENTO
    {
        imageUrl: sacramento,
        previewUrl: "https://vsble.me",
        header: "SACRAMENTO",
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.SACRAMENTO &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.SACRAMENTO,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // MEMPHIS
    {
        imageUrl: memphis,
        previewUrl: "https://vsble.me",
        header: "MEMPHIS",
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.MEMPHIS &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.MEMPHIS,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // SEVILLA
    {
        imageUrl: sevilla,
        previewUrl: "https://vsble.me",
        header: "SEVILLA",
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.SEVILLA &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.SEVILLA,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // MARSEILLE
    {
        imageUrl: marseille,
        previewUrl: "https://vsble.me",
        header: "MARSEILLE",
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.MARSEILLE &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.MARSEILLE,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // OSLO
    {
        imageUrl: oslo,
        previewUrl: "https://oslo.vsble.me",
        header: "templates.label.oslo",
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.OSLO &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.OSLO,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // LOS_ANGELES
    {
        imageUrl: losAngeles,
        previewUrl: "https://user-ktsco2ue.vsble.me",
        header: "templates.label.losangeles",
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.LOS_ANGELES &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.LOS_ANGELES,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // ROTTERDAM
    {
        imageUrl: rotterdam,
        previewUrl: "https://user-kq7xxfwf.vsble.me",
        header: "templates.label.rotterdam",
        genericName: PORTFOLIO_TEMPLATES.ROTTERDAM,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.ROTTERDAM &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.ROTTERDAM,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // STOCKHOLM
    {
        imageUrl: stockholm,
        previewUrl: "https://user-knq1604k.vsble.me",
        header: "templates.label.stockholm",
        genericName: PORTFOLIO_TEMPLATES.STOCKHOLM,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.STOCKHOLM &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.STOCKHOLM,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // Vienna
    {
        imageUrl: vien,
        previewUrl: "https://user-kmncva5q.vsble.me",
        header: "templates.label.vien",
        genericName: PORTFOLIO_TEMPLATES.VIEN,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.VIEN &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.VIEN,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // RIGA
    {
        imageUrl: riga,
        previewUrl: "https://user-khuz7mpt.vsble.me",
        header: "templates.label.riga",
        genericName: PORTFOLIO_TEMPLATES.RIGA,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.RIGA &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.RIGA,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // PAVEL
    {
        imageUrl: pavel,
        previewUrl: "https://user-khs6nid7.vsble.me",
        header: "templates.label.pavel",
        genericName: PORTFOLIO_TEMPLATES.PAVEL,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.PAVEL &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.PAVEL,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // MADRID
    {
        imageUrl: madrid,
        previewUrl: "https://user-khqjsdcd.vsble.me",
        header: "templates.label.madrid",
        genericName: PORTFOLIO_TEMPLATES.MADRID,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.MADRID &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.MADRID,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // RIGA SLIDESHOW
    {
        imageUrl: rigaSlideshow,
        previewUrl: "https://user-kgc9lq4o.vsble.me",
        header: "templates.label.rigaslideshow",
        genericName: PORTFOLIO_TEMPLATES.RIGA,
        slideshowEnabled: true,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.RIGA &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === true,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.RIGA,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: true,
            }),
    },
    // BARCELONA
    {
        imageUrl: barcelona,
        previewUrl: "https://user-kgas6a8z.vsble.me",
        header: "templates.label.barcelona",
        genericName: PORTFOLIO_TEMPLATES.BARCELONA,
        slideshowEnabled: false,
        isFree: true,
        selected:
            templateName === PORTFOLIO_TEMPLATES.BARCELONA &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.BARCELONA,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // ANTWERP
    {
        imageUrl: antwerp,
        previewUrl: "https://user-kgf8xkba.vsble.me",
        header: "templates.label.antwerp",
        genericName: PORTFOLIO_TEMPLATES.ANTWERPEN,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.ANTWERPEN &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.ANTWERPEN,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // BERLIN
    {
        imageUrl: berlin,
        previewUrl: "https://user-kho1yg48.vsble.me",
        header: "templates.label.berlin",
        genericName: PORTFOLIO_TEMPLATES.BERLIN,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.BERLIN &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.BERLIN,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // BROOKLYN
    {
        imageUrl: brooklyn,
        previewUrl: "https://axljansen.com",
        header: "templates.label.brooklyn",
        genericName: PORTFOLIO_TEMPLATES.BROOKLYN,
        slideshowEnabled: true,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.BROOKLYN &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === true,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.BROOKLYN,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: true,
            }),
    },
    // SOPHIE
    {
        imageUrl: sophie,
        previewUrl: "https://annalenarumler.com",
        header: "templates.label.sophie",
        genericName: PORTFOLIO_TEMPLATES.SOPHIE,
        slideshowEnabled: false,
        isFree: false,
        selected: templateName === PORTFOLIO_TEMPLATES.SOPHIE,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.SOPHIE,
                align: null,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // SYDNEY
    {
        imageUrl: sydney,
        previewUrl: "https://carlfredriksvenstedt.com",
        header: "templates.label.sydney",
        genericName: PORTFOLIO_TEMPLATES.SYDNEY,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.SYDNEY &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.SYDNEY,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // JEREMY
    {
        imageUrl: jeremy,
        previewUrl: "https://albertsanchez.vsble.me",
        header: "templates.label.jeremy",
        genericName: PORTFOLIO_TEMPLATES.CATHERINE,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.CATHERINE &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.CATHERINE,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    {
        imageUrl: jeremySlideshow,
        previewUrl: "https://albertsanchez.vsble.me",
        header: "templates.label.jeremyslideshow",
        genericName: PORTFOLIO_TEMPLATES.CATHERINE,
        slideshowEnabled: true,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.CATHERINE &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === true,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.CATHERINE,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: true,
            }),
    },
    // NEW YOUR
    {
        imageUrl: newYork,
        previewUrl: "https://ldsparis.com",
        header: "templates.label.newyork",
        genericName: PORTFOLIO_TEMPLATES.NEW_YORK,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.NEW_YORK &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.NEW_YORK,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // PEGGY
    {
        imageUrl: peggy,
        previewUrl: "https://www.michelemathijssen.com",
        header: "templates.label.peggy",
        genericName: PORTFOLIO_TEMPLATES.CATHERINE,
        slideshowEnabled: false,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.CATHERINE &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.LEFT.toLowerCase() &&
            slideshowEnabled === false,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.CATHERINE,
                align: PORTFOLIO_TEMPLATES_ALIGN.LEFT,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    {
        imageUrl: peggySlideshow,
        previewUrl: "https://www.manuringo.com",
        header: "templates.label.peggyslideshow",
        genericName: PORTFOLIO_TEMPLATES.CATHERINE,
        slideshowEnabled: true,
        isFree: false,
        selected:
            templateName === PORTFOLIO_TEMPLATES.CATHERINE &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.LEFT.toLowerCase() &&
            slideshowEnabled === true,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.CATHERINE,
                align: PORTFOLIO_TEMPLATES_ALIGN.LEFT,
                inverted: false,
                slideshowEnabled: true,
            }),
    },
    // PARIS
    {
        imageUrl: paris,
        previewUrl: "https://antonioromeromakeup.com",
        header: "templates.label.paris",
        genericName: PORTFOLIO_TEMPLATES.PARIS,
        slideshowEnabled: true,
        isFree: planType !== SUBSCRIPTION_TYPE.UNPAID,
        selected:
            templateName === PORTFOLIO_TEMPLATES.PARIS &&
            (align || "").toLowerCase() ===
            PORTFOLIO_TEMPLATES_ALIGN.CENTER.toLowerCase() &&
            slideshowEnabled === true,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.PARIS,
                align: PORTFOLIO_TEMPLATES_ALIGN.CENTER,
                inverted: false,
                slideshowEnabled: true,
            }),
    },
    // ANTON
    {
        imageUrl: anton,
        previewUrl: "https://www.alexkahan.de",
        header: "templates.label.anton",
        genericName: PORTFOLIO_TEMPLATES.ANTON,
        slideshowEnabled: false,
        isFree: planType !== SUBSCRIPTION_TYPE.UNPAID,
        selected: templateName === PORTFOLIO_TEMPLATES.ANTON,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.ANTON,
                align: null,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
    // NIKOLAI
    {
        imageUrl: nikolai,
        previewUrl: "https://atact.vsble.me",
        header: "templates.label.nikolai",
        genericName: PORTFOLIO_TEMPLATES.NIKOLAI,
        slideshowEnabled: false,
        isFree: planType !== SUBSCRIPTION_TYPE.UNPAID,
        selected: templateName === PORTFOLIO_TEMPLATES.NIKOLAI,
        updateTemplate: () =>
            updateTemplate({
                template: PORTFOLIO_TEMPLATES.NIKOLAI,
                align: null,
                inverted: false,
                slideshowEnabled: false,
            }),
    },
];

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  border: 1px solid #E1E2E6;
  border-radius: 4px;
`;

export const ToggleItem = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 13px;
    color: #666A73;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

export const BlackToggleItem = styled(ToggleItem)`
    ${props => props.isSelected && `
        color: white;
        background-color: black;
    `};
`;

export const WhiteToggleItem = styled(ToggleItem)`
    ${props => props.isSelected && `
        color: black;
        background-color: white;
    `};
`;

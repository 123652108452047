import Accordion from '../components/navigation/accordion';
import {connect} from 'react-redux';
import {pushIdx, remIdx, setIdx} from '../redux/modules/accordion/actions';

export const accordionWrapper = Component => connect(
    state => ({
        activeIndices: state.get('accordion'),
    }),
    dispatch => ({
        pushIdx: (idx) => dispatch(pushIdx(idx)),
        remIdx: (idx) => dispatch(remIdx(idx)),
        // setIdx sets first item in list to be idx
        setIdx: (idx) => dispatch(setIdx(idx)),
    })
)(Component);

export default accordionWrapper(Accordion);

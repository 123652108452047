import styled from 'styled-components';
import {Semantic} from "packages/components";

export const Dropdown = styled(Semantic.Dropdown)`
    height: max-content !important;
    width: max-content !important;

    .dropdown.icon {
        display: none !important;
    }

    .menu.transition.visible {
        bottom: unset;
        border: none;
        border-radius: 2px;
        ${props => props.isLastItemInRow ? '' : 'right: -69px !important;'}
    }

    .ui.disabled.dropdown, .ui.dropdown .menu > .disabled.item {
        opacity: 1 !important;
    }
`;

export const SettingsContent = styled.div`
    width: ${props => !props.wide ? 170 : 215}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const SettingsItemButton = styled.span`
    color: ${props => props.color ? props.color : '#343840'};
    font-size: 13px;
    line-height: 140%;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    opacity: ${props => props.disabled ? 0.5 : 1};
    cursor: ${props => props.header || props.disabled ? 'initial' : 'pointer'};
    font-weight: ${props => props.header ? 'bold' : 'normal'};
    padding-left: ${props => !props.isIconExist ? 22 : 10}px;

    &:first-child {
        border-radius: 2px 2px 0 0;
    }

    &:last-child {
        border-radius: 0 0 2px 2px;
    }

    &:hover {
        ${props => props.header ? '' : 'background-color: #F6F6F6;'}
    }

    > span {
        width: 23px;
    }
`;

import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {intlShape} from 'react-intl';
import {Input} from 'packages/components/inputs';
import {CheckIcon} from 'packages/components/icons/basic/check';
import {VAT_STATES} from 'packages/enum';

import {
    Header,
    Container,
    SubmitButton,
} from './nodes';

const {VALID, INVALID, UNKNOWN} = VAT_STATES;

const VatValidator = ({
    onChange,
    vatValidation,
    onBlur,
    name,
    value,
    countryId,
    options,
}, {intl: {formatMessage}}) => {
    const [vat, setVat] = useState(value);
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(UNKNOWN);
    const [countryCode, setCountryCode] = useState();

    useEffect(() => {
        setVat(value);
    }, [value]);

    useEffect(() => {
        const country = options.find(el => el.id === countryId);
        if (country) {
            if (country.englishName === 'Greece') {
                setCountryCode('EL');
            } else {
                setCountryCode(country.shortName);
            }
        } else {
            setCountryCode(undefined);
        }
    }, [options, countryId]);

    const handleSubmit = async () => {
        if (!vat) {
            return;
        }
        setLoading(true);
        const res = await vatValidation({variables: {vat}});
        if (get(res, 'data.vatValidation.data.valid')) {
            setLoading(false);
            setValid(VALID);
            onChange({target: {name, value: vat}});
            onBlur({target: {name}});
        } else {
            setLoading(false);
            setValid(INVALID);
        }
    };

    return (
        <>
            <Header>{formatMessage({id: 'vatValidator.header'})}</Header>
            <Container iconInput={valid === VALID}>
                <Input
                    name="vat"
                    value={vat}
                    error={valid === INVALID}
                    errorText={valid === INVALID ? formatMessage({id: 'vatValidator.error'}) : undefined}
                    onChange={(e) => {
                        setVat(e.target.value);
                        setValid(UNKNOWN);
                        // Даем возможность убрать Vat
                        if (!e.target.value) {
                            onChange({target: {name, value: e.target.value}});
                            onBlur({target: {name}});
                        }
                    }}
                    disabled={loading}
                    inlineNode={
                        valid === VALID
                            ? <CheckIcon/>
                            : null
                    }
                    placeholder={`${countryCode}xxx`}
                />
                <SubmitButton
                    type="button"
                    view="secondaryBlack"
                    content={formatMessage({id: 'vatValidator.button.apply'})}
                    onClick={handleSubmit}
                    disabled={loading}
                    loading={loading}
                />
            </Container>
        </>
    );
};

VatValidator.propTypes = {
    onChange: PropTypes.func.isRequired,
    vatValidation: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    countryId: PropTypes.number,
    options: PropTypes.array,
};

VatValidator.defaultProps = {
    value: '',
    onBlur: (e) => e,
    countryId: undefined,
    options: [],
};

VatValidator.contextTypes = {
    intl: intlShape,
};

export default VatValidator;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import {Semantic} from 'packages/components';

import {Loader} from '../nodes';
import {
    Container,
    Content,
    Title,
    ExpandLink,
    Item,
    Image,
    ListItem,
} from './nodes';
import {getDomain} from "packages/helpers/Helper";

const MostViewed = ({title, item, loading, image, list}, {intl: {formatMessage}}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <Container>
            <Content>
                <Title>{formatMessage({id: title})}</Title>
                {item && !image && <Item>{item}</Item>}
                {item && image && <Image
                    fluid={{
                        src: getDomain(item),
                        aspectRatio: 1,
                    }}
                    fadeIn
                    imgStyle={{objectFit: 'contain'}}
                    loading="lazy"
                    backgroundColor="white"
                />}
                {!item && <Item>{formatMessage({id: 'mostViewed.noInfo'})}</Item>}
            </Content>
            {list && !isEmpty(list) && isExpanded &&
            orderBy(list, ['value'], ['desc'])
                .slice(0, 9)
                .map(i => (
                    <ListItem>
                        <span>{i.value}</span>
                        {image ? <Image
                            fluid={{
                                src: getDomain(i.name),
                                aspectRatio: 1,
                            }}
                            fadeIn
                            imgStyle={{objectFit: 'contain'}}
                            loading="lazy"
                            backgroundColor="white"
                        /> : <span>{i.name}</span>}
                    </ListItem>
                ))
            }
            {list && !isEmpty(list) &&
            <ExpandLink onClick={() => setIsExpanded(!isExpanded)}>
                {formatMessage({id: isExpanded ? 'mostViewed.link.hide' : 'statistics.newDesign.mostViewed.expand'})}
            </ExpandLink>
            }
            {loading &&
            <Loader>
                <Semantic.Loader active size="small"/>
            </Loader>}
        </Container>
    );
};

MostViewed.propTypes = {
    title: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    image: PropTypes.bool,
    list: PropTypes.array,
};

MostViewed.defaultProps = {
    loading: false,
    image: false,
    list: [],
};

MostViewed.contextTypes = {
    intl: intlShape,
};

export default MostViewed;

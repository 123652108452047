import isEmpty from "lodash-es/isEmpty";

export function filterNavigationPages(pages = [], search = '', types = []) {
    const searchValue = search.toLowerCase().trim();
    return pages.reduce((filteredPages, page) => {
        const {title, blocks: [block]} = page;
        const albums = block?.albums || [];
        const pageType = block?.type;
        const filteredAlbums = albums.filter(({ name }) => name.toLowerCase().includes(searchValue));
        const pageTitleMatched = title.toLowerCase().includes(searchValue);

        if (!isEmpty(types) && !types.some(type => type === pageType)) {
            return filteredPages;
        }

        if (pageTitleMatched || !isEmpty(filteredAlbums)) {
            if (!isEmpty(filteredAlbums)) {
                return [...filteredPages, {
                    ...page,
                    blocks: [{
                        ...page.blocks[0],
                        albums: filteredAlbums,
                    }],
                }];
            }
            return [...filteredPages, page];
        }

        return filteredPages;
    }, []);
};

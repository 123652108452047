import { compose } from "redux";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import adminUsersListGet from "@graphql/gql/admin/adminUsersListGet.gql";
import adminLoginAs from "@graphql/gql/admin/adminLoginAs.gql";
import adminResetPassword from "@graphql/gql/admin/adminResetPassword.gql";
import adminUndeleteUser from "@graphql/gql/admin/adminUndeleteUser.gql";
import adminStartTrial from "@graphql/gql/admin/adminStartTrial.gql";
import adminSetLifetimePro from "@graphql/gql/admin/adminSetLifetimePro.gql";
import adminSetByInvoiceAllowed from "@graphql/gql/admin/adminSetByInvoiceAllowed.gql";

import { open } from "packages/redux/modules/modalYesNo/actions";
import { COOKIE, PERIOD, URLS } from "packages/enum";
import { filterUsersList } from "@admin/redux/modules/admin/actions";

import { PAGE_SIZE } from "../constants";
import UsersList from "../components/usersList";
import { Cookies } from "react-cookie";

export default compose(
    connect(
        (state) => ({
            filter: createSelector(
                (state) => state.getIn(["admin", "filter"]),
                (filter) => filter
            )(state),
        }),
        (dispatch) => ({
            setFilterByEmail: (payload) => {
                dispatch(filterUsersList(payload));
            },
            openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
                dispatch(
                    open({
                        headerMessageId,
                        yesMessageId,
                        subMessageId,
                    })
                ),
        })
    ),
    graphql(adminUsersListGet, {
        options({ filter }) {
            return {
                variables: {
                    page: 1,
                    pageSize: PAGE_SIZE,
                    filter,
                },
            };
        },
        props({ data: { loading, refetch, adminUsersListGet } }) {
            if (loading) {
                return { loading };
            }
            if (isEmpty(adminUsersListGet)) {
                return;
            }
            const users = get(adminUsersListGet, "data.users", []);
            const page = get(adminUsersListGet, "data.page", 0);
            const pageSize = get(adminUsersListGet, "data.pageSize", 0);
            const pagesCount = get(adminUsersListGet, "data.pagesCount", 0);

            return {
                users,
                page: {
                    page,
                    pageSize,
                    pagesCount,
                },
                loading,
                refetch,
                fetchPage: async ({ page, pageSize }) => {
                    await refetch({
                        page,
                        pageSize,
                    });
                },
            };
        },
    }),
    graphql(adminLoginAs, {
        props({ mutate, ownProps }) {
            return {
                ownProps,
                async loginAs(login) {
                    try {
                        const res = await mutate({
                            variables: {
                                login,
                            },
                        });

                        let {
                            data: { adminLoginAs: { success, data } = {} },
                        } = res;
                        if (success && get(data, "token")) {
                            new Cookies().set(
                                COOKIE.token,
                                get(data, "token"),
                                {
                                    expires: PERIOD.neverExpires,
                                    path: "/",
                                }
                            );
                            return (window.location = URLS.website_config);
                        }
                    } catch (err) {}
                },
            };
        },
    }),
    graphql(adminUndeleteUser, {
        props({ mutate, ownProps }) {
            return {
                async undeleteUser(userId) {
                    try {
                        const res = await mutate({
                            variables: {
                                userId,
                            },
                        });
                        if (get(res, "data.adminUndeleteUser.errors.error")) {
                            ownProps.openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.adminUndeleteUser.errors.error"
                                ),
                            });
                        }
                    } catch (err) {}
                },
            };
        },
    }),
    graphql(adminStartTrial, {
        props({ mutate, ownProps }) {
            return {
                async startTrial(userId) {
                    try {
                        const res = await mutate({
                            variables: {
                                userId,
                            },
                        });
                        if (get(res, "data.adminStartTrial.errors.error")) {
                            ownProps.openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.adminStartTrial.errors.error"
                                ),
                            });
                        } else {
                            ownProps.refetch();
                        }
                    } catch (err) {}
                },
            };
        },
    }),
    graphql(adminResetPassword, {
        props({ mutate, ownProps }) {
            return {
                async resetPassword(userId) {
                    try {
                        const res = await mutate({
                            variables: {
                                userId,
                            },
                        });
                        if (get(res, "data.adminResetPassword.errors.error")) {
                            ownProps.openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.adminResetPassword.errors.error"
                                ),
                            });
                        }
                    } catch (err) {}
                },
            };
        },
    }),
    graphql(adminSetLifetimePro, {
        props({ mutate, ownProps }) {
            return {
                async setLifetimePro(userId) {
                    try {
                        const res = await mutate({
                            variables: {
                                userId,
                            },
                        });
                        if (get(res, "data.adminSetLifetimePro.errors.error")) {
                            ownProps.openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.adminSetLifetimePro.errors.error"
                                ),
                            });
                        } else {
                            ownProps.refetch();
                        }
                    } catch (err) {}
                },
            };
        },
    }),
    graphql(adminSetByInvoiceAllowed, {
        props({ mutate, ownProps }) {
            return {
                async setByInvoiceAllowed(userId) {
                    try {
                        const res = await mutate({
                            variables: {
                                userId,
                            },
                        });
                        if (
                            get(
                                res,
                                "data.adminSetByInvoiceAllowed.errors.error"
                            )
                        ) {
                            ownProps.openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.adminSetByInvoiceAllowed.errors.error"
                                ),
                            });
                        } else {
                            ownProps.refetch();
                        }
                    } catch (err) {}
                },
            };
        },
    })
)(UsersList);

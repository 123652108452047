import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {set} from './actions';

const defaultState = fromJS({
    id: null,
    login: null,
    currency: null,
    token: null,
});

export default handleActions({
    [set]: {
        next(state, {payload}) {
            return state.merge(fromJS(payload));
        },
    },
}, defaultState);

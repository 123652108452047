/* eslint-disable max-len */
import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { intlShape, injectIntl, FormattedMessage } from "react-intl";
import chunk from "lodash/chunk";
import { Button } from "packages/components/buttons";
import {
    FEATURE_PROPERTY_KEYS,
    PERMISSIONS_ALL_PORTFOLIO_TEMPLATES,
    PLAN_TYPE,
    PORTFOLIO_TEMPLATES,
    SUBSCRIPTION_TYPE,
} from "packages/enum";
import Image from "packages/components/gatsby-image";
import ChangeTemplateModal from "@configurator/components/modals/changeTemplateModal";

import {
    HeaderBlock,
    Container,
    Row,
    Item,
    ItemHeader,
    ImageWrapper,
    ImageOverlay,
    Content,
    SelectedBlock,
    ProBlock,
    SlideshowNotification,
    FreeBlock,
} from "./nodes";

import { makeTemplatesArray } from "./const";
import { HeaderStyled } from "packages/components/layout/nodes";
import { useSubscription } from "@configurator/providers/subscription";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import {IconWrapper} from "@configurator/components/navigation/pageLinkNode/nodes";
import {SlideshowIcon} from "packages/components/icons";

const aspectRatio = 1.291248207;

const TemplateItem = ({
    genericName,
    src,
    header,
    selected,
    updateTemplate,
    previewUrl,
    slideshowEnabled,
    availableTemplates,
    planType,
    isFree,
    intl: { formatMessage },
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const formattedHeader = formatMessage({ id: header });
    const permissionsRegex = new RegExp(
        `^(${genericName}|${PERMISSIONS_ALL_PORTFOLIO_TEMPLATES})$`,
        "i"
    );
    const restrictionTemplate = planType === PLAN_TYPE.PROLITE ||
                                planType === SUBSCRIPTION_TYPE.UNPAID ||
                                planType === SUBSCRIPTION_TYPE.FREE;

    const defaultTemplate = genericName === PORTFOLIO_TEMPLATES.BARCELONA;
    const available = availableTemplates?.find((el) => permissionsRegex.test(el));

    return (
        <>
            <Item>
                <ImageWrapper selected={selected}>
                    {do {
                        if (selected) {
                            <SelectedBlock>
                                <FormattedMessage id="template.selectedTemplate" />
                            </SelectedBlock>;
                        }
                    }}
                    {do {
                        if (!isFree) {
                            <ProBlock>PRO</ProBlock>;
                        }
                        else if (available && restrictionTemplate || defaultTemplate || !restrictionTemplate) {
                            <FreeBlock>FREE</FreeBlock>;
                        }
                    }}
                    <Image
                        fluid={{
                            src,
                            aspectRatio,
                        }}
                        alt=""
                        fadeIn
                        loading="lazy"
                        backgroundColor="#f6f6f6"
                    />
                    <ImageOverlay selected={selected}>
                        <PermissionsOverlay
                            isAllowed={availableTemplates?.find((el) =>
                                permissionsRegex.test(el)
                            )}
                        >
                            <Button
                                content={formatMessage(
                                    { id: "template.button.select" },
                                    { template: formattedHeader }
                                )}
                                view="primary"
                                onClick={() => setOpenDialog(true)}
                            />
                        </PermissionsOverlay>
                        <Button
                            content={formatMessage(
                                { id: "template.button.preview" },
                                { template: formattedHeader }
                            )}
                            view="secondaryGray"
                            as="a"
                            href={previewUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    </ImageOverlay>
                </ImageWrapper>
                <Content>
                    <ItemHeader>{formattedHeader}</ItemHeader>
                    {slideshowEnabled && (
                        <SlideshowNotification>
                            <IconWrapper>
                                <SlideshowIcon newVariant={true}/>
                            </IconWrapper>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: formatMessage({
                                        id: "template.slideShowEnabled",
                                    }),
                                }}
                            />
                        </SlideshowNotification>
                        )}
                </Content>
            </Item>
            {openDialog && (
                <ChangeTemplateModal
                    open
                    onClickYes={() => {
                        updateTemplate();
                        setOpenDialog(false);
                    }}
                    onClose={() => setOpenDialog(false)}
                />
            )}
        </>
    );
};

TemplateItem.propTypes = {
    src: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    genericName: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    updateTemplate: PropTypes.func.isRequired,
    previewUrl: PropTypes.string.isRequired,
    slideshowEnabled: PropTypes.bool.isRequired,
    availableTemplates: PropTypes.array.isRequired,
    planType: PropTypes.string.isRequired,
    isFree: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
};

TemplateItem.defaultProps = { selected: false };

const Header = () => (
    <HeaderBlock>
        <Row>
            <h1>
                <FormattedMessage id="template.header.main" />
            </h1>
            <FormattedMessage id="template.header.description" />
        </Row>
    </HeaderBlock>
);

const TemplatesComponent = ({
    meta,
    updateTemplate,
    templatesColor,
    arrowBack = true,
    history,
    intl,
}) => {
    const { templateName, features = [] } = meta;
    const { info: { planType }, permissions: { TEMPLATES } } = useSubscription();

    const slideshowEnabled = !!(
        features.find(
            (itm) => itm.featureName === FEATURE_PROPERTY_KEYS.slider
        ) || {}
    ).enabled;
    const align = meta.align ? meta.align.toUpperCase() : "";

    const templatesArray = makeTemplatesArray({
        updateTemplate,
        templateName,
        align,
        slideshowEnabled,
        planType,
    });

    const templatesByColor = useMemo(() => chunk(templatesArray, 2), [templatesArray]);

    return (
        <>
            <HeaderStyled backArrow />
            <Container>
                <Header />
                {templatesByColor.map((pair) => (
                    <Row key={pair[0].header}>
                        {pair.map((item) => {
                            return (
                                <TemplateItem
                                    key={item.header}
                                    src={item.imageUrl}
                                    genericName={item.genericName}
                                    header={item.header}
                                    description={item.description}
                                    selected={item.selected}
                                    updateTemplate={(params) => {
                                        item.updateTemplate(params);
                                    }}
                                    previewUrl={item.previewUrl}
                                    intl={intl}
                                    slideshowEnabled={item.slideshowEnabled}
                                    availableTemplates={TEMPLATES}
                                    planType={planType}
                                    isFree={item.isFree}
                                />
                            )
                        })}
                    </Row>
                ))}
            </Container>
        </>
    );
};

TemplatesComponent.propTypes = {
    updateTemplate: PropTypes.func,
    meta: PropTypes.object,
    arrowBack: PropTypes.bool,
    history: PropTypes.object,
};

TemplatesComponent.defaultProps = {
    updateTemplate: () => {},
    meta: {},
    arrowBack: true,
    history: {},
};

export const Templates = injectIntl(TemplatesComponent);

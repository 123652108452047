import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import Dropzone from "react-dropzone";
import get from "lodash/get";
import { Semantic } from "packages/components";
import Message from "packages/components/message/Message";
import { formProptypes } from "packages/utils/formikPropTypes";
import {
    MAX_IMAGE_SIZE,
    MAX_FAVICON_SIZE,
} from "@configurator/constants/upload";
import ModalConfirmDelete from "packages/components/modalConfirmDelete";
import Image from "packages/components/image/Image";
import { getDomain } from "packages/helpers/Helper";
import { MESSAGE_TYPE } from "packages/enum";
import { TrashButton, Button } from "packages/components/buttons";
import { FormInput, FormSlider } from "packages/components/inputs";

import {
    FormRow,
    ButtonRow,
    UploadLogo,
    TextRow,
    Logo,
    Wrapper,
    ImageComponent,
    LogoDescription,
} from "./nodes";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import { useSubscription } from "@configurator/providers/subscription";

const EditLogo = (
    {
        handleSubmit,
        errors,
        status,
        isSubmitting,
        loading,
        setFieldValue,
        values,
        deleteLogo,
        deleteFavicon,
        refetchMeta,
        openErrorModal,
    },
    { intl: { formatMessage } }
) => {
    const error = Object.keys(errors).length || (status && !!status.error);
    const [isLogoFileReady, setIsLogoFileReady] = useState(false);
    const [isFaviconFileReady, setIsFaviconFileReady] = useState(false);
    const [logoPreview, setLogoPreview] = useState();
    const [faviconPreview, setFaviconPreview] = useState();
    const [showModalDeleteFavicon, setShowModalDeleteFavicon] = useState(false);
    const [showModalDeleteLogo, setShowModalDeleteLogo] = useState(false);
    const [previewLogoData, setPreviewLogoData] = useState();
    const [previewFaviconData, setPreviewFaviconData] = useState();
    const { permissions } = useSubscription();

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result || "");
            };
        });
    };

    useEffect(() => {
        if (!faviconPreview) {
            setPreviewFaviconData(undefined);
        } else {
            getBase64(faviconPreview.file).then((file) => {
                setPreviewFaviconData(file);
            });
        }
    }, [faviconPreview]);

    useEffect(() => {
        if (!logoPreview) {
            setPreviewLogoData(undefined);
        } else {
            getBase64(logoPreview.file).then((file) => {
                setPreviewLogoData(file);
            });
        }
    }, [logoPreview]);

    useEffect(() => {
        window.frames["preview-frame"]?.postMessage(
            JSON.stringify({
                name: MESSAGE_TYPE.SET_LOGO_PREVIEW,
                logoPreview: previewLogoData,
                logoSizePercent: values.logoSizePercent,
            }),
            "*"
        );
    }, [values.logoSizePercent, previewLogoData]);

    useEffect(() => {
        window.frames["preview-frame"]?.postMessage(
            JSON.stringify({
                name: MESSAGE_TYPE.SET_FAVICON_PREVIEW,
                faviconPreview: previewFaviconData,
            }),
            "*"
        );
    }, [previewFaviconData]);

    const memoizedLogo = useMemo(() => get(values, "logo"), [values]);
    useEffect(() => {
        setFieldValue("logoSizePercent", get(memoizedLogo, "logoSizePercent"));
    }, [memoizedLogo, setFieldValue]);

    const handleDropFile = (files, isLogo) => {
        if (files && files.length) {
            const file = files[0];
            const preview = {
                index: 0,
                file,
                name: file.name,
                preview: window.URL.createObjectURL(file),
            };
            if (isLogo) {
                setIsLogoFileReady(true);
                setLogoPreview(preview);
                setFieldValue("newLogo", file);
                setFieldValue("logoSizePercent", 1);
            } else {
                setIsFaviconFileReady(true);
                setFieldValue("newFavicon", file);
                setFaviconPreview(preview);
            }
        }
    };

    const onDropRejected = (files, accept, maxSize) => {
        if (files.find((el) => el.size > maxSize)) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileSize.description",
                subMessageValues: { size: maxSize / 1024 / 1024 },
            });
        }
        const acceptArr = accept.split(", ");
        if (files.find((el) => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileType.description",
                subMessageValues: { types: accept.replace(/image\//g, "") },
            });
        }
    };

    const renderUpload = (isLogo) => {
        const isFileReady = isLogo ? isLogoFileReady : isFaviconFileReady;
        const preview = isLogo ? logoPreview : faviconPreview;

        const accept = isLogo ? "image/jpeg, image/png" : "image/png";
        const maxSize = isLogo ? MAX_IMAGE_SIZE : MAX_FAVICON_SIZE;

        return (
            <UploadLogo isLogo={isLogo}>
                {isFileReady ? (
                    <div className="image-preview">
                        <img src={preview.preview} alt="" />
                        <span>{`/...${preview.file.name.slice(-10)}`}</span>
                        <Dropzone
                            accept={accept}
                            onDrop={(files) => handleDropFile(files, isLogo)}
                            multiple={false}
                            maxSize={maxSize}
                            onDropRejected={(files) =>
                                onDropRejected(files, accept, maxSize)
                            }
                        >
                            {({ getInputProps, getRootProps }) => (
                                <div {...getRootProps()} className="input">
                                    <input {...getInputProps()} />
                                    {formatMessage({
                                        id: "editLogo.button.change",
                                    })}
                                </div>
                            )}
                        </Dropzone>
                        <TrashButton
                            onClick={() => {
                                if (isLogo) {
                                    setLogoPreview(undefined);
                                    setIsLogoFileReady(false);
                                    setFieldValue("newLogo", undefined);
                                    setFieldValue("logoSizePercent", undefined);
                                } else {
                                    setFaviconPreview(undefined);
                                    setIsFaviconFileReady(false);
                                    setFieldValue("newFavicon", undefined);
                                }
                            }}
                        />
                    </div>
                ) : (
                    <Dropzone
                        accept={accept}
                        onDrop={(files) => handleDropFile(files, isLogo)}
                        multiple={false}
                        maxSize={maxSize}
                        onDropRejected={(files) =>
                            onDropRejected(files, accept, maxSize)
                        }
                    >
                        {({ getInputProps, getRootProps }) => (
                            <div {...getRootProps()} className="input">
                                <input {...getInputProps()} />
                                {formatMessage({
                                    id: isLogo
                                        ? "editLogo.upload.customLogo"
                                        : "editLogo.upload.favicon",
                                })}
                            </div>
                        )}
                    </Dropzone>
                )}
            </UploadLogo>
        );
    };

    const renderImage = (isLogo) => {
        const {
            hashPath,
            height = 1,
            width = 1,
        } = get(values, isLogo ? "logo" : "favicon", {});
        const accept = isLogo ? "image/jpeg, image/png" : "image/png";
        const maxSize = isLogo ? MAX_IMAGE_SIZE : MAX_FAVICON_SIZE;
        return (
            <Logo isLogo={isLogo}>
                <ImageComponent isLogo={isLogo}>
                    <Image
                        aspectRatio={width / height}
                        src={getDomain(hashPath)}
                        imgStyle={{ objectFit: "contain" }}
                        alt=""
                    />
                </ImageComponent>
                <Dropzone
                    accept={accept}
                    onDrop={(files) => handleDropFile(files, isLogo)}
                    multiple={false}
                    maxSize={maxSize}
                    onDropRejected={(files) =>
                        onDropRejected(files, accept, maxSize)
                    }
                >
                    {({ getInputProps, getRootProps }) => (
                        <div {...getRootProps()} className="input">
                            <input {...getInputProps()} />
                            {formatMessage({ id: "editLogo.button.change" })}
                        </div>
                    )}
                </Dropzone>
                <TrashButton
                    onClick={() =>
                        isLogo
                            ? setShowModalDeleteLogo(true)
                            : setShowModalDeleteFavicon(true)
                    }
                />
            </Logo>
        );
    };

    return (
        <Wrapper>
            <Semantic.Form
                noValidate
                error={error}
                loading={isSubmitting || loading}
                onSubmit={handleSubmit}
            >
                <Message
                    error={error}
                    errors={errors}
                    content={status && status.error}
                />
                <FormRow>
                    <FormInput
                        name="siteName"
                        label="siteName.update.field.siteName"
                        placeholder="siteName.update.field.siteName"
                        type="text"
                        width="396px"
                    />
                </FormRow>
                <FormRow>
                    <FormInput
                        name="siteDescription"
                        label="siteName.update.field.siteDescription"
                        placeholder="siteName.update.field.siteDescription"
                        type="text"
                        width="396px"
                    />
                </FormRow>
                <TextRow>
                    <hr />
                    <span>{formatMessage({ id: "editLogo.label.or" })}</span>
                    <hr />
                </TextRow>
                <FormRow>
                    <LogoDescription>
                        {formatMessage({ id: "editLogo.label.customLogo" })}
                        <span>
                            {formatMessage({ id: "editLogo.text.customLogo" })}
                        </span>
                    </LogoDescription>
                </FormRow>
                <PermissionsOverlay isAllowed={permissions.UPLOADABLE_LOGO}>
                    <FormRow>
                        {!values.logo || values.newLogo
                            ? renderUpload(true)
                            : renderImage(true)}
                    </FormRow>
                    {(values.logo || values.newLogo) && (
                        <FormRow>
                            <FormSlider
                                name="logoSizePercent"
                                label={formatMessage({
                                    id: "slideshow.label.logoSize",
                                })}
                                min={0.2}
                                max={1}
                                step={0.05}
                                bottomMessages={[
                                    formatMessage({
                                        id: "styles.inputs.fontSize.value.small",
                                    }),
                                    formatMessage({
                                        id: "styles.inputs.fontSize.value.medium",
                                    }),
                                    formatMessage({
                                        id: "styles.inputs.fontSize.value.large",
                                    }),
                                ]}
                            />
                        </FormRow>
                    )}
                </PermissionsOverlay>
                <FormRow>
                    <hr />
                </FormRow>
                <FormRow>
                    <LogoDescription>
                        {formatMessage({ id: "editLogo.label.favicon" })}
                        <span>
                            {formatMessage({ id: "editLogo.text.favicon" })}
                        </span>
                    </LogoDescription>
                </FormRow>
                <PermissionsOverlay isAllowed={permissions.CUSTOM_FAVICON}>
                    {!values.favicon || values.newFavicon
                        ? renderUpload(false)
                        : renderImage(false)}
                </PermissionsOverlay>
                <ButtonRow>
                    <Button
                        type="submit"
                        green
                        fluid
                        disabled={isSubmitting}
                        content={formatMessage({ id: "editLogo.button.save" })}
                    />
                </ButtonRow>
            </Semantic.Form>
            <ModalConfirmDelete
                open={showModalDeleteFavicon || showModalDeleteLogo}
                onClickYes={() => {
                    if (showModalDeleteLogo) {
                        deleteLogo()
                            .then(refetchMeta)
                            .then(() =>
                                window.frames["preview-frame"]?.postMessage(
                                    JSON.stringify({
                                        name: MESSAGE_TYPE.UPDATE_META,
                                    }),
                                    "*"
                                )
                            );
                        setFieldValue("newLogo", undefined);
                        setFieldValue("logoSizePercent", undefined);
                        setLogoPreview(undefined);
                        setIsLogoFileReady(false);
                        setShowModalDeleteLogo(false);
                    } else {
                        deleteFavicon()
                            .then(refetchMeta)
                            .then(() =>
                                window.frames["preview-frame"]?.postMessage(
                                    JSON.stringify({
                                        name: MESSAGE_TYPE.UPDATE_META,
                                    }),
                                    "*"
                                )
                            );
                        setFieldValue("newFavicon", undefined);
                        setFaviconPreview(undefined);
                        setIsFaviconFileReady(false);
                        setShowModalDeleteFavicon(false);
                    }
                }}
                onClickCancel={() =>
                    showModalDeleteLogo
                        ? setShowModalDeleteLogo(false)
                        : setShowModalDeleteFavicon(false)
                }
                onClose={() =>
                    showModalDeleteLogo
                        ? setShowModalDeleteLogo(false)
                        : setShowModalDeleteFavicon(false)
                }
            />
        </Wrapper>
    );
};

EditLogo.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    deleteLogo: PropTypes.func.isRequired,
    deleteFavicon: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    refetchMeta: PropTypes.func,
    openErrorModal: PropTypes.func,
    ...formProptypes,
};

EditLogo.defaultProps = {
    refetchMeta: () => null,
    openErrorModal: () => null,
};

EditLogo.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default EditLogo;

import styled from 'styled-components';

export const Container = styled.div`
    min-width: 1024px;
    overflow-x: scroll;

    .ui.button {
        padding: 5px;
        white-space: normal;
    }
    .rt-resizable-header-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        white-space: normal;
    }
    .rt-td {
        span {
            white-space: normal;
        }
    }
`;

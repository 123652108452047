import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import {useMutation} from 'react-apollo';

import adminSetTrialEnd from '@graphql/gql/admin/adminSetTrialEnd.gql';

import {ErrorModal} from './modal/ErrorModal';
import {Container, Styles, SubmitButton, Input} from './nodes';

const TrialEndDatePicker = (
    {
        id,
        currentTrialEnd,
        closeModal,
        refetch,
    }
) => {
    const [selectedDate, onSelectedDateChange] = useState(moment(currentTrialEnd).format('DDMMYYYY'));
    const [setTrialEnd, {error}] = useMutation(adminSetTrialEnd);
    const handleDateChange = useCallback((day) => {
        onSelectedDateChange(moment(day).format('DDMMYYYY'));
    }, []);

    const handleSubmit = () => {
        setTrialEnd({variables: {userId: id, date: selectedDate}}).then(() => {
            closeModal();
            refetch();
        });
    };

    return (
        <Container>
            {error ? (<ErrorModal open={error} content={error}/>) : null}
            <Styles>
                <h3>Choose new trial end date</h3>
                <DayPickerInput
                    className="Selectable"
                    selectedDays={selectedDate}
                    onDayChange={handleDateChange}
                    dayPickerProps={{disabledDays: {before: new Date()}}}
                    component={props => <Input {...props} />}
                    placeholder={moment(currentTrialEnd).format('YYYY-MM-DD')}
                />
                <SubmitButton color="green" onClick={() => handleSubmit()}>
                    Save
                </SubmitButton>
            </Styles>
        </Container>
    );
};

TrialEndDatePicker.propTypes = {
    id: PropTypes.number.isRequired,
    setTrialEnd: PropTypes.func.isRequired,
    currentTrialEnd: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
};

export default TrialEndDatePicker;

import React from 'react';
import PropTypes from 'prop-types';
import {Formik, Form as FormikForm} from 'formik';
import {FormInput} from 'packages/components/inputs';
import {Button} from 'packages/components/buttons/button';
import * as Yup from 'yup';
import { ModalContainer } from './nodes';


const validationSchema = Yup.object().shape({
    email: Yup.string().required('deleteAccount.modal.error.email.required'),
});

const DeleteAccount = ({
    open,
    error,
    onClose,
    onSubmit,
    onError,
}, {intl: {formatMessage}}) => {
    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            styles={{
                height: 'initial',
                width: '500px',
            }}
        >
            <Formik
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                initialValues={{}}
                onSubmit={onSubmit}
                render={(props) => (
                    <FormikForm>
                        <div className="title">
                            {formatMessage({id: 'deleteAccount.modal.header'})}
                        </div>
                        <div className="body">
                            {formatMessage({id: 'deleteAccount.modal.text'})}
                        </div>
                        <div className="formField">
                            <FormInput
                                name="email"
                                error={props.errors.email || error}
                                errorText={(props.errors.email || error)
                                    && formatMessage({id: props.errors.email || error})
                                }
                                label={formatMessage({id: 'deleteAccount.modal.field.email'})}
                                type="email"
                                onChange={({ target: { name, value } }) => {
                                    props.setFieldValue(name, value);
                                    if (error) {
                                        onError(undefined);
                                    }
                                }}
                            />
                        </div>
                        <div className="footer">
                            <Button
                                type="submit"
                                className="button"
                                view="primaryRed"
                                content={formatMessage({id: 'deleteAccount.modal.button.yes'})}
                            />
                            <Button
                                type="submit"
                                className="button"
                                content={formatMessage({id: 'deleteAccount.modal.button.cancel'})}
                                view="primaryGray"
                                onClick={onClose}
                            />
                        </div>
                    </FormikForm>
                )}
            />
        </ModalContainer>
    );
};

DeleteAccount.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.string,

    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

DeleteAccount.defaultProps = {};

DeleteAccount.contextTypes = {
    intl: PropTypes.object.isRequired,
};

export default DeleteAccount;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "packages/components/buttons";
import { ModalContainer } from "./nodes";

export function PermissionModal({
    open,
    onClose,
    headerMessageId,
    subMessageId,
    buttonMessageId,
    onButtonClick,
}) {
    return (
        <ModalContainer open={open} onClose={onClose} styles={{width: '435px'}}>
            <div className="title">
                <FormattedMessage id={headerMessageId} />
            </div>
            <div className="body">
                <FormattedMessage id={subMessageId} />
            </div>
            <div className="footer">
                <Button
                    className="button"
                    content={<FormattedMessage id={buttonMessageId} />}
                    view="primary"
                    onClick={onButtonClick}
                />
            </div>
        </ModalContainer>
    );
}

PermissionModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    headerMessageId: PropTypes.string,
    subMessageId: PropTypes.string,
    buttonMessageId: PropTypes.string,
};

PermissionModal.defaultProps = {
    open: false,
    headerMessageId: "permission.modal.pro.header",
    subMessageId: "permission.modal.pro.subMessage",
    buttonMessageId: "permission.modal.buttonMessage",
};

import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {open, close} from './actions';


const defaultState = fromJS({
    open: false,
});

export default handleActions({
    [open]: {
        next(state, {payload}) {
            return fromJS(payload);
        },
    },
    [close]: {
        next() {
            return defaultState;
        },
    },
}, defaultState);

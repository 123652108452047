import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {updateIntl} from 'react-intl-redux';
import {Cookies} from 'react-cookie';
import {ThemeProvider} from 'styled-components';

import {addLocaleData, IntlProvider} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import jaLocaleData from 'react-intl/locale-data/ja';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ja';

import {Provider, connect} from 'react-redux';
import {ApolloProvider} from 'react-apollo';
import {ConnectedRouter} from 'connected-react-router/immutable';
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import {intlSelector} from 'packages/intl/redux';
import {defaultLocale, fetchMessages} from 'packages/intl';
import {COOKIE} from 'packages/enum';
import createApolloClient from 'packages/apollo-client';
import mainTheme from 'packages/styledComponentsThemes/main';

import {apolloState} from 'packages/utils/hydrateInitialState';

import store from './redux/createStore';
import {history} from './redux/initialMiddlewares';
import {SubscriptionProvider} from './providers/subscription';
import Layout from './components/Layout';


// Set react-intl locales
addLocaleData([
    ...deLocaleData,
    ...enLocaleData,
    ...frLocaleData,
    ...jaLocaleData,
]);

// Create apollo client. Cookies are needed for authentication
export const apollo = createApolloClient({
    cookies: new Cookies(),
    apolloState,
    dispatch: store.dispatch,
});


function useSetLocale() {
    const dispatch = useDispatch();
    useEffect(() => {
        const userLocale = new Cookies().get(COOKIE.locale);
        if (userLocale && userLocale !== defaultLocale) {
            fetchMessages({locale: userLocale})
                .then(messages => {
                    dispatch(updateIntl(messages));
                })
                .catch(err => console.error(`Невозможно загрузить словари, ${err.message}`));
        }
    }, [dispatch]);
}

function isTouchDevice() {
    return (typeof window !== 'undefined' && typeof navigator !== 'undefined')
        && ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
}

const ConnectedLayout = connect((state) => ({intl: intlSelector(state)}))
    (({intl}) => {
            useSetLocale();

            moment.locale(intl.locale);

            return (
                <IntlProvider
                    defaultLocale={defaultLocale}
                    {...intl}
                >
                    <ConnectedRouter history={history}>
                        <SubscriptionProvider>
                            <Layout store={store}/>
                        </SubscriptionProvider>
                    </ConnectedRouter>
                </IntlProvider>

            );

        },
    )
;

const App = () => (
    <ApolloProvider client={apollo}>
        <ThemeProvider theme={mainTheme}>
            <Provider store={store}>
                <DndProvider
                    backend={isTouchDevice() ? TouchBackend : HTML5Backend}
                    options={{
                        enableMouseEvents: true,
                        enableHoverOutsideTarget: true,
                        ignoreContextMenu: true,
                        delayTouchStart: 100,
                    }}
                >
                    <ConnectedLayout/>
                </DndProvider>
            </Provider>
        </ThemeProvider>
    </ApolloProvider>
);

export default App;

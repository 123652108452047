import styled from 'styled-components';
import Slider from 'rc-slider';

export const Container = styled.div`
  width: 100%;
`;

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
`;

export const BottomMessagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;

  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
`;

export const StyledSlider = styled(Slider)`
  & .rc-slider-rail  {
    background-color: #E1E2E6 !important;
    height: 2px;
    border-radius: 0 !important;
  }
  & .rc-slider-track {
    display: none !important;
  }
  & .rc-slider-handle {
    width: 15px;
    height: 15px;
    background: #F6F6F6;
    border: 3px solid #343840;
    box-sizing: border-box;
    margin-top: -7px !important;
    &:active {
      box-shadow: none !important;
    }
  }
`;

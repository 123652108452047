import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: ${props => props.isOver ? '40px' : '20px'};
    border: ${props => props.isOver ? 'dashed green 0.3px' : ''};
    transition: height 0.2s cubic-bezier(0.2, 0, 0, 1) 0s;

    &:hover {
        background: inherit !important;
    }
`;

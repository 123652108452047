import styled from 'styled-components';

export const Container = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #343840;
`;

export const Description = styled.div`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #343840;
  margin: 20px 0 30px 0;
`;

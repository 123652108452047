import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import './css/style.css';

export default class Checkbox extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string,
        className: PropTypes.string,
        type: PropTypes.string,
        onChange: PropTypes.func,
        checked: PropTypes.bool,
    };

    static defaultProps = {
        name: '',
        id: '',
        title: '',
        className: '',
        type: 'checkbox',
        checked: false,
        onChange: () => {
        },
    };

    // обрабатываем через хендлер div
    emptyHandler = () => {
    };

    render() {
        const {name, type, id, title, className, onChange, checked} = this.props;
        return (
            <div
                className={cs('styled-input-container styled-input--square', className)}
                onClick={onChange}
            >
                <div className="styled-input-single">
                    <input checked={checked} type={type} name={name} id={id} onChange={this.emptyHandler}/>
                    <label htmlFor={id}>{title}</label>
                </div>
            </div>
        );
    }
}

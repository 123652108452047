import styled from 'styled-components';
import {media} from 'packages/components/media';

export const Label = styled.div`
    margin-bottom: ${props => props.compact ? 20 : 10}px;
    font-size: 15px;
    line-height: 21px;
`;

export const Container = styled.div`
    justify-content: center;
    display: flex !important;
    width: 100%;

    > form {
        width: 100%;
    }
`;

export const UploadWrapper = styled.div`
    position: relative;
    overflow-y: auto;
    width: 100%;

    .input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 300px;
        width: 100%;
        cursor: pointer;
        color: #666A73;
        font-size: 13px;
        line-height: 140%;
        padding: 15px;

        background: #F6F6F6;
        border: 1px dashed #E1E2E6;
        border-radius: 4px;

        .dropzone-header {
            color: #343840;
            font-size: 24px;
            font-weight: bold;
            line-height: 150%;
            margin: 18px 0 0;
            text-align: center;

            > span {
                color: #666A73;
                font-size: 13px;
                line-height: 140%;
                display: block;
                font-weight: normal;
            }
        }

        .dropzone-footer {
            text-align: center;
            position: absolute;
            bottom: 15px;
        }

        &:focus {
            outline-color: rgba(0, 0, 0, 0) !important;
        }
    }

    .image-preview {
        display: flex;
        align-items: center;
        overflow-x: hidden;

        > span {
            margin-left: 20px;
            margin-right: auto;
            color: #666A73;

        }

        img {
            border: 1px dashed #E1E2E6;
            box-sizing: border-box;
            border-radius: 4px;
            width: 250px;
            max-height: 100px;
            object-fit: contain;
        }

        .input {
            height: 32px;
            color: #58C367;
            background: none;
            width: max-content;
            border: none;

            &:focus {
                outline-color: rgba(0, 0, 0, 0);
            }
        }

        > svg {
            margin-right: -6px;
            cursor: pointer;
        }
    }

    ${media.max.phone`
        .input {
            height: 240px;
            justify-content: flex-start;
            padding: 10px 0;

            .dropzone-header {
                margin-bottom: 20px;
            }
        }
        .image-preview {
            flex-wrap: wrap;

            > span {
                margin-left: 0;
            }

            > img {
                width: 100%;
                height: 240px;
            }
        }
    `}
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;

    .input {
        margin-left: auto;
        color: #58C367;
        background: none;
        cursor: pointer;
        margin-right: 10px;

        &:focus {
            outline-color: rgba(0, 0, 0, 0);
        }
    }

    > svg {
        margin-right: -6px;
        cursor: pointer;
    }
`;

export const StyledTextareaContainer = styled.div`
    width: 100%;

    > div {
        width: 100%;

        .field {
            width: 100% !important;

            textarea {
                min-width: 416px !important;
                width: 100% !important;
            }
        }
    }
`;

export const ImageComponent = styled.div`
    width: 250px;
    border: 1px dashed #E1E2E6;
    box-sizing: border-box;
    border-radius: 4px;

    > div {
        max-width: 250px;
        max-height: 100px;
        background-color: #FFF;
    }
`;

export const FormRow = styled.div`
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : 30}px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 15px;
    line-height: 21px;
`;

export const SubscriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #343840;
    font-size: 15px;
    line-height: 21px;

    > span {
        margin-bottom: 20px;
    }

    button {
        width: 220px;
        height: 45px;
    }
`;

export const SubscriptionTitle = styled.span`
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
`;

import isEmpty from "lodash/isEmpty";
import { compose } from "redux";
import { graphql } from "react-apollo";

import billingHistoryGet from "@graphql/gql/website/billingHistoryGet.gql";

import BillingHistory from "@configurator/components/billing/history";

export const billingHistoryWrapper = (Component) =>
    compose(
        graphql(billingHistoryGet, {
            options() {
                return {
                    fetchPolicy: "network-only",
                };
            },
            props({ data: { loading, billingHistoryGet } }) {
                if (loading) {
                    return { loading };
                }
                if (isEmpty(billingHistoryGet)) {
                    return { loading };
                }
                const { data } = billingHistoryGet;

                return {
                    data,
                };
            },
        })
    )(Component);

export default billingHistoryWrapper(BillingHistory);

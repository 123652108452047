import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input} from 'packages/components/semantic';


const SearchByEmail = ({filter, setFilter}) => {
    const [search, setSearch] = useState('');

    const onReset = () => setFilter('');
    const onSearch = () => setFilter(search);

    useEffect(() => () => {
        onReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Input
                value={search}
                action={{
                    icon: 'search',
                    color: 'grey',
                    onClick: () => onSearch(),
                }}
                iconPosition="left"
                loading={false}
                onChange={(event) => {
                    filter && filter !== ''
                        ? onReset()
                        : setSearch(event.target.value);
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        onSearch();
                    }
                }}
                placeholder="Search by email"
            />
        </div>
    );
};

SearchByEmail.propTypes = {
    filter: PropTypes.string,
    setFilter: PropTypes.func.isRequired,
};

SearchByEmail.defaultProps = {filter: undefined};

export default SearchByEmail;

import React from 'react';
import {loaderDefaultProps, loaderPropTypes} from '../../const';

import {Container} from './nodes';

export const Ellipsis = ({color, width, height}) => (
    <Container color={color} width={width} height={height}>
        <div/>
        <div/>
        <div/>
        <div/>
    </Container>
);

Ellipsis.propTypes = loaderPropTypes;

Ellipsis.defaultProps = loaderDefaultProps;

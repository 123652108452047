/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const TrendingUpIcon = ({color}) => (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99997 5.414L11.2929 8.70689C11.6834 9.09742 12.3166 9.09742 12.7071 8.70689L17.707 3.707L20 6V0H14L16.293 2.293L12 6.586L8.70708 3.29311C8.31655 2.90258 7.68339 2.90258 7.29286 3.29311L0.292969 10.293L1.70697 11.707L7.99997 5.414Z"
            fill={iconColors[color]}/>
    </svg>
);
TrendingUpIcon.propTypes = {
    ...iconColorPropTypes,
};

TrendingUpIcon.defaultProps = {
    color: 'dark',
};

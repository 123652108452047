import { compose } from "redux";
import { graphql } from "react-apollo";
import isEmpty from "lodash/isEmpty";

import CountryDropdown from "packages/components/countryDropdown";
import countryListGet from "@graphql/gql/common/countryListGet.gql";

export const countryDropdownWrapper = (Component) =>
    compose(
        graphql(countryListGet, {
            props({ data: { loading, countryListGet } }) {
                if (loading) {
                    return {
                        loading,
                        options: [],
                    };
                }
                if (isEmpty(countryListGet)) {
                    return {
                        loading,
                        options: [],
                    };
                }
                const { data } = countryListGet;

                return {
                    options: data,
                };
            },
        })
    )(Component);
export default countryDropdownWrapper(CountryDropdown);

import React, {useState, useEffect, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {ShortLeftIcon} from '../icons';
import {Container, Menu, MenuItem, Pane, ArrowBackContainer} from './nodes';

const Component = ({panes, activeIndex, onTabClick, ...props}) => {
    const [activeIndexState, setActiveIndexState] = useState(activeIndex);
    const [backArrowState, setBackArrowState] = useState(null);

    useEffect(() => {
        setActiveIndexState(activeIndex);
    }, [activeIndex]);

    const onClick = useCallback((index) => {
        onTabClick ? onTabClick(index) : setActiveIndexState(index);
    }, [onTabClick, setActiveIndexState]);

    const tabSetBackArrowState = useCallback((text, onArrowClick, settingsComponent) => {
        setBackArrowState({text, onArrowClick, settingsComponent});
    }, [setBackArrowState]);

    const tabClearBackArrowState = useCallback(() => {
        setBackArrowState(null);
    }, [setBackArrowState]);

    return (
        <Container>
            {!backArrowState ? (
                <Menu>
                    {panes.map(({menuItem, hide}, index) => (
                        <MenuItem
                            key={menuItem}
                            active={index === activeIndexState}
                            onClick={() => !hide && onClick(index)}
                            hide={hide}
                        >
                            <FormattedMessage id={menuItem}/>
                        </MenuItem>
                    ))}
                </Menu>
            ) : (
                <Menu
                    onClick={backArrowState.onArrowClick}
                    enableHover
                    showBackArrow
                >
                    <ArrowBackContainer>
                        <ShortLeftIcon/>
                        <FormattedMessage id={backArrowState.text}/>
                    </ArrowBackContainer>
                    {backArrowState.settingsComponent}
                </Menu>
            )}
            <Pane isPortfolio={activeIndexState === 0}>
                {React.cloneElement(
                    panes[activeIndexState].render({
                        ...props,
                        tab:
                            {
                                setBackArrowState: tabSetBackArrowState,
                                clearBackArrowState: tabClearBackArrowState,
                            },
                    }),
                    {
                        tab:
                            {
                                setBackArrowState: tabSetBackArrowState,
                                clearBackArrowState: tabClearBackArrowState,
                            },
                    },
                )}
            </Pane>
        </Container>
    );
};

Component.propTypes = {
    panes: PropTypes.arrayOf(PropTypes.shape({
        menuItem: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired,
    })).isRequired,
    activeIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onTabClick: PropTypes.func,
};

Component.defaultProps = {
    activeIndex: 0,
    onTabClick: undefined,
};

export const Tabs = Component;

export const TabPropTypes = {
    tab: PropTypes.shape({
        setBackArrowState: PropTypes.func.isRequired,
        clearBackArrowState: PropTypes.func.isRequired,
    }).isRequired,
};

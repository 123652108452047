import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';
import {
    ButtonsContainer,
    ModalContainer,
    StyledButton,
    StyledForm,
} from './nodes';
import get from 'lodash/get';
import {injectStripe} from 'react-stripe-elements';
import {MessageStep, ExtendTrialStep, FeaturesStep} from './steps';
import {PAYMENT_STEPPER_TYPES} from 'packages/enum';

const DowngradeToFree = ({
    open,
    onClose,
    values,
    setFieldValue,
    billingCreateOneTimeCheckoutSession,
    handleSubmit,
    stripe,
    type,
}, {intl: {formatMessage}}) => {
    const [step, setStep] = useState(0);

    const handleExtendClick = async () => {
        const res = await billingCreateOneTimeCheckoutSession();
        const sessionId = get(res, 'data.billingCreateOneTimeCheckoutSession.data.sessionId');
        sessionId && stripe && stripe.redirectToCheckout({sessionId});
    };

    const stepSubmitHandler = () => {
        if( step < (2 - +(type !== PAYMENT_STEPPER_TYPES.CREATE_SUBSCRIPTION)) ) {
            setStep(step + 1);
        }
        else {
            handleSubmit();
        }
    }

    const StepComponent = useMemo(() => {
        switch (step) {
            default:
            case 0:
                return FeaturesStep;
            case 1:
                return MessageStep;
            case 2:
                return ExtendTrialStep;
        }
    }, [step]);

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            styles={{
                height: 'initial',
                width: step === 2 ? '600px' : '708px',
                paddingTop: '35px',
                paddingRight: '35px',
                paddingLeft: '35px',
                paddingBottom: '35px',
            }}
        >
            <StyledForm
                noValidate
                onSubmit={handleSubmit}
            >
                <StepComponent
                    values={values}
                    setFieldValue={setFieldValue}
                />
                <ButtonsContainer>
                    <StyledButton
                        type="button"
                        onClick={step === 2 ? handleExtendClick : stepSubmitHandler}
                        content={formatMessage({id: step === 2 ? 'downgradeToFree.modal.extendTrial.yes' : 'downgradeToFree.modal.button.confirm'})}
                    />
                    <StyledButton
                        type="button"
                        view="secondaryWhite"
                        onClick={step === 2 ? stepSubmitHandler : onClose}
                        content={formatMessage({id: step === 2 ? 'downgradeToFree.modal.extendTrial.no' : 'downgradeToFree.modal.button.changedMind'})}
                    />
                </ButtonsContainer>
            </StyledForm>
        </ModalContainer>
    );
};

DowngradeToFree.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    billingCreateOneTimeCheckoutSession: PropTypes.func.isRequired,
    stripe: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(PAYMENT_STEPPER_TYPES)),
};

DowngradeToFree.defaultProps = {
    type: '',
};

DowngradeToFree.contextTypes = {
    intl: intlShape.isRequired,
};

export default injectStripe(DowngradeToFree);

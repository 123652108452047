/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const ShortLeftIcon = ({ color }) => (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 5L3.83 5L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7L16 7V5Z"
            fill={iconColors[color]}
        />
    </svg>
);

ShortLeftIcon.propTypes = {
    ...iconColorPropTypes,
};

ShortLeftIcon.defaultProps = {
    color: 'dark',
};

import {connect} from 'react-redux';
import {Map} from 'immutable';
import ModalConfirmDelete from 'packages/components/modalConfirmDelete';
import {close} from 'packages/redux/modules/modalConfirmDelete/actions';

const mapStateToProps = state => {
    let {
        open = false,
        onClickYes,
        onClickCancel,
        onClose,
        headerMessageId,
        headerMessageValues,
        subMessageId,
        subMessageValues,
        yesMessageId,
        cancelMessageId,
        hideHeader,
        hideCancelButton,
        styles,
    } = (state.get('modalConfirmDelete') || new Map())
        .toJS() || {};
    return {
        open,
        onClickYes,
        onClickCancel,
        onClose,
        headerMessageId,
        headerMessageValues,
        subMessageId,
        subMessageValues,
        yesMessageId,
        cancelMessageId,
        hideHeader,
        hideCancelButton,
        styles,
    };
};

const mapDispatchToProps = {
    close,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmDelete);

import PropTypes from "prop-types";
import React, { useEffect, useRef} from "react";
import { Semantic } from "packages/components";
import { Modal } from "packages/components/modal";
import {
    FormInput,
    FormToggle,
    Checkbox,
} from "packages/components/inputs";
import { Button } from "packages/components/buttons";
import { NewFeatureBudge } from "packages/components/newFeatureBudge/nodes";
import { formProptypes } from "packages/utils/formikPropTypes";
import Message from "packages/components/message/Message";
import union from "lodash/union";
import isEqual from "lodash/isEqual";
import {
    Header,
    Content,
    StyledInput,
    Title,
    Description,
    DeleteItem,
    MobileSubmitContainer,
} from "@configurator/components/modals/nodes";
import {
    DescriptionLabel, DescriptionWrapper,
    StyledTextareaContainer,
    ToggleContainer,
} from "@configurator/components/modals/albumCreate/nodes";
import { PagesList, CheckboxContainer, StyledSlugPreview, StyledSlug} from "./nodes";

import newFeatureIcon from "src/packages/theme-ab/resources/assets/configurator/new_feature.svg";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import { useSubscription } from "@configurator/providers/subscription";
import TextEditorContainer from "@configurator/containers/TextEditorContainer";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const AlbumUpdate = (
    {
        open,
        onClose,
        handleSubmit,
        errors,
        initErrors,
        status,
        isSubmitting,
        loading,
        pagesList,
        setFieldValue,
        initialValues,
        values,
        onDelete,
        openDiscardChangesModal,
        closeDiscardChangesModal,
        loadingDeleteAlbum,
        premiumDomain,
        domain,
        pageUrl,
    },
    { intl: { formatMessage } }
) => {
    errors= initErrors || errors
    const prevValues = usePrevious(values);
    const { permissions } = useSubscription();
    useEffect(() => {
        if (prevValues && prevValues.visible !== values.visible) {
            setFieldValue("hide", !values.visible);
        }
        if (prevValues && prevValues.hide !== values.hide) {
            setFieldValue("visible", !values.hide);
        }
    }, [prevValues, setFieldValue, values]);

    const error = Object.keys(errors).length || (status && !!status.error);

    const handleChangeText = (text) => setFieldValue("description", text);
    const handleChangePage = (pageId) => {
        const newPageIds = isPageChecked(pageId)
            ? values.pageIds.filter((el) => el !== pageId)
            : union(values.pageIds, [pageId]);

        setFieldValue("pageIds", newPageIds);
    };

    const isPageChecked = (pageId) =>
        !!values.pageIds.find((el) => el === pageId);

        const parentPageUrl = pagesList.find(p => p.id === pageUrl)?.url;

    const renderPagesList = () => {
        return (
            <PagesList>
                <Title>
                    {formatMessage({
                        id: "newDesign.album.update.field.displayCategory",
                    })}
                </Title>
                <div>
                    {(pagesList || []).map((page) => (
                        <CheckboxContainer>
                            <Checkbox
                                onChange={() => handleChangePage(page.id)}
                                checked={isPageChecked(page.id)}
                            />
                            <span>{page.title}</span>
                        </CheckboxContainer>
                    ))}
                </div>
            </PagesList>
        );
    };

    const modalStyle = {
        width: "600px",
        height: "initial",
    };

    const handleClose = () => {
        if (!isEqual(values, initialValues)) {
            openDiscardChangesModal({
                subMessageId: "discardChanges.subMessage",
                yesMessageId: "discardChanges.yesMessage",
                hideHeader: true,
                onClickYes: () => {
                    onClose();
                    closeDiscardChangesModal();
                },
                styles: { width: "450px" },
            });
        } else {
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={handleClose} styles={modalStyle} mobileWide>
            <Content>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting || loading || loadingDeleteAlbum}
                    onSubmit={handleSubmit}
                >
                    <Header>
                        <span>
                            {formatMessage({
                                id: "newDesign.album.update.header",
                            })}
                        </span>
                        <Button
                            type="submit"
                            content={formatMessage({
                                id: "newDesign.album.update.submit",
                            })}
                            disabled={isSubmitting}
                        />
                    </Header>
                    <Message
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <StyledInput>
                        <FormInput
                            name="title"
                            type="text"
                            label="newDesign.album.update.field.name"
                        />
                    </StyledInput>
                    <StyledInput>
                        <FormInput
                            name="albumUrl"
                            type="text"
                            label="newDesign.album.update.field.slug"
                        />
                        <StyledSlugPreview>
                            {domain && `https://${premiumDomain || `${domain}.vsble.me`}/${parentPageUrl + "/" || ""}`}
                            <StyledSlug>{values.albumUrl}</StyledSlug>
                        </StyledSlugPreview>
                    </StyledInput>
                    <StyledTextareaContainer>
                        <DescriptionWrapper>
                            <DescriptionLabel>
                                {formatMessage({
                                    id: "newDesign.album.update.field.desc",
                                })}
                            </DescriptionLabel>
                            <TextEditorContainer
                                text={initialValues.description}
                                handleChangeText={handleChangeText}
                                inline
                            />
                        </DescriptionWrapper>
                    </StyledTextareaContainer>
                    {renderPagesList()}
                    <ToggleContainer>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.album.update.toggle.visibility.title",
                                })}
                            </Title>
                            <Description>
                                {formatMessage({
                                    id: "newDesign.album.update.toggle.visibility.desc",
                                })}
                            </Description>
                        </div>
                        <FormToggle name="hide" />
                    </ToggleContainer>
                    <ToggleContainer>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.album.update.toggle.hideHeader.title",
                                })}
                                <NewFeatureBudge src={newFeatureIcon} alt="" />
                            </Title>
                            {/*<Description>*/}
                            {/*    {formatMessage({*/}
                            {/*        id: "newDesign.album.update.toggle.hideHeader.desc",*/}
                            {/*    })}*/}
                            {/*</Description>*/}
                        </div>
                        <div>
                            <PermissionsOverlay
                                isAllowed={permissions.HIDE_ALBUM_TITLE}
                            >
                                <FormToggle name="hideHeader" />
                            </PermissionsOverlay>
                        </div>
                    </ToggleContainer>
                    <MobileSubmitContainer>
                        <Button
                            type="submit"
                            fluid
                            content={formatMessage({
                                id: "newDesign.album.update.submit",
                            })}
                            disabled={isSubmitting}
                        />
                    </MobileSubmitContainer>
                    <DeleteItem onClick={onDelete}>
                        {formatMessage({
                            id: "newDesign.album.update.button.deleteAlbum",
                        })}
                    </DeleteItem>
                </Semantic.Form>
            </Content>
        </Modal>
    );
};

AlbumUpdate.propTypes = {
    albumId: PropTypes.string.isRequired, // eslint-disable-line
    pageId: PropTypes.string.isRequired, // eslint-disable-line
    pageType: PropTypes.string.isRequired, // eslint-disable-line
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    pagesList: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    loading: PropTypes.bool,
    open: PropTypes.bool,
    loadingDeleteAlbum: PropTypes.bool,
    domain: PropTypes.string,
    premiumDomain: PropTypes.string,
    ...formProptypes,
};

AlbumUpdate.defaultProps = {
    open: false,
    loading: false,
    loadingDeleteAlbum: false,
    pagesList: [],
    onDelete: () => {},
};

AlbumUpdate.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default AlbumUpdate;

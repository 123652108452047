/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {iconColors, iconColorPropTypes} from '../const';

export const ShowIcon = ({ color, showBg }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        {showBg && <circle className="iconBg" cx="16" cy="16" r="16" fill="white"/>}
        <path
            d="M16 13C14.358 13 13 14.359 13 16C13 17.642 14.358 19 16 19C17.641 19 19 17.642 19 16C19 14.359 17.641 13 16 13Z"
            fill={iconColors[color]}
        />
        <path
            d="M16 9C8.408 9 6.12632 15.617 6.10543 15.684L6 16L6.10444 16.316C6.12632 16.383 8.408 23 16 23C23.592 23 25.8737 16.383 25.8946 16.316L26 16L25.8956 15.684C25.8737 15.617 23.592 9 16 9ZM16 21C10.6777 21 8.61587 17.154 8.11657 16C8.61786 14.842 10.6807 11 16 11C21.3223 11 23.3841 14.846 23.8834 16C23.3821 17.158 21.3193 21 16 21Z"
            fill={iconColors[color]}
        />
    </svg>
);

ShowIcon.propTypes = {
    ...iconColorPropTypes,
    showBg: PropTypes.bool,
};

ShowIcon.defaultProps = {
    color: 'dark',
    showBg: false,
};

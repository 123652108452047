import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { BottomMessagesContainer, Container, LabelContainer, StyledSlider } from './nodes';

const findValueMessage = (valueMessages, min, max, value) => {
    const denominator = valueMessages.length;
    const range = max - min;
    const partRange = range / denominator;
    let partsArray = [];
    for (let i = min; i < max; i += partRange) {
        partsArray.push([i, i + partRange]);
    }
    let result;
    for (let i = 0; i <= valueMessages.length; i += 1) {
        if (+value >= get(partsArray, `[${i}][0]`) && +value <= get(partsArray, `[${i}][0]`)) {
            result = valueMessages[i];
            if (result === undefined) {
                result = valueMessages[i - 1];
            }
            break;
        }
    }
    return result;
};

export const Slider = ({
    label, value, min, max, valueMessages, className, showValue, bottomMessages, ...props
}) => (
    <Container className={className}>
        {label && valueMessages && (
            <LabelContainer>
                <div>{label}</div>
                <div>{findValueMessage(valueMessages, min, max, value)}</div>
            </LabelContainer>
        )}
        {label && showValue && (
            <LabelContainer>
                <div>{label}</div>
                <div>{value}</div>
            </LabelContainer>
        )}
        {label && !valueMessages && !showValue && (
            <LabelContainer>
                <div>{label}</div>
            </LabelContainer>
        )}
        <StyledSlider
            value={value}
            min={min}
            max={max}
            {...props}
        />
        {bottomMessages && !isEmpty(bottomMessages) && (
            <BottomMessagesContainer>
                {bottomMessages.map(msg => <div>{msg}</div>)}
            </BottomMessagesContainer>
        )}
    </Container>
);

Slider.propTypes = {
    label: PropTypes.string,
    unitSuffix: PropTypes.string,
    value: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    valueMessages: PropTypes.array,
    className: PropTypes.string,
    showValue: PropTypes.bool,
    bottomMessages: PropTypes.array,
};

Slider.defaultProps = {
    label: undefined,
    unitSuffix: undefined,
    min: undefined,
    max: undefined,
    valueMessages: undefined,
    className: undefined,
    showValue: false,
    bottomMessages: undefined,
};

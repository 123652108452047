import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "packages/components/buttons";
import merge from "lodash/merge";
import { ModalContainer } from "./nodes";

const ModalConfirmDelete = (
    {
        open,
        onClickYes,
        onClickCancel,
        onClose,
        headerMessageId,
        headerMessageValues,
        subMessageId,
        subMessageValues,
        yesMessageId,
        cancelMessageId,
        close,
        hideHeader,
        hideCancelButton,
        styles,
    },
    { intl: { formatMessage } }
) => (
    <ModalContainer
        open={open}
        onClose={onClose || close}
        styles={merge(
            {
                height: "initial",
                width: "initial",
                paddingLeft: hideHeader || hideCancelButton ? "50px" : "65px",
                paddingRight: hideHeader || hideCancelButton ? "50px" : "65px",
                paddingTop: hideHeader || hideCancelButton ? "50px" : "79px",
                paddingBottom: hideHeader || hideCancelButton ? "50px" : "79px",
            },
            styles
        )}
    >
        {!hideHeader && (
            <div className="title">
                <FormattedMessage
                    id={headerMessageId}
                    values={headerMessageValues}
                />
            </div>
        )}
        <div className="body">
            <FormattedMessage id={subMessageId} values={subMessageValues} />
        </div>
        <div className="footer">
            {!hideCancelButton && (
                <Button
                    className="button"
                    content={formatMessage({ id: cancelMessageId })}
                    view="primaryGray"
                    onClick={onClickCancel || close}
                />
            )}
            <Button
                className="button"
                content={formatMessage({ id: yesMessageId })}
                onClick={onClickYes || close}
            />
        </div>
    </ModalContainer>
);

ModalConfirmDelete.propTypes = {
    open: PropTypes.bool,
    onClickYes: PropTypes.func,
    onClickCancel: PropTypes.func,
    onClose: PropTypes.func,
    headerMessageId: PropTypes.string,
    headerMessageValues: PropTypes.object,
    subMessageId: PropTypes.string,
    subMessageValues: PropTypes.object,
    yesMessageId: PropTypes.string,
    cancelMessageId: PropTypes.string,
    close: PropTypes.func,
    hideHeader: PropTypes.bool,
    hideCancelButton: PropTypes.bool,
    styles: PropTypes.object,
};

ModalConfirmDelete.defaultProps = {
    open: false,
    onClose: undefined,
    onClickYes: undefined,
    onClickCancel: undefined,
    headerMessageId: "modalConfirmDelete.header",
    headerMessageValues: {},
    subMessageId: "modalConfirmDelete.subMessage",
    subMessageValues: {},
    yesMessageId: "modalConfirmDelete.yes",
    cancelMessageId: "modalConfirmDelete.cancel",
    close: undefined,
    hideHeader: false,
    hideCancelButton: false,
    styles: {},
};

ModalConfirmDelete.contextTypes = {
    intl: PropTypes.object,
};

export default ModalConfirmDelete;

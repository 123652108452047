import React, {
    useState,
    useRef,
    useMemo,
    useEffect,
    useCallback,
} from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { injectStripe } from "react-stripe-elements";

import moment from "moment";

import get from "lodash/get";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import has from "lodash/has";

import { PLAN_PRICE_INTERVALS } from "packages/constants";
import {
    PAYMENT_STEPPER_TYPES,
    PLAN_TYPE,
    SUBSCRIPTION_TYPE,
    PAYMENT_TYPE,
    EU_COUNTRIES_CODE,
    CURRENCY,
} from "packages/enum";

import { currencyConversion, getPlansByInterval } from "../../helpers";
import { useLazyQuery } from "react-apollo";
import billingExchangeRateGet from "@graphql/gql/website/billingExchangeRateGet.gql";
import AddressContainer from "@configurator/containers/billing/AddressContainer";
import { Radio } from "packages/components/inputs";
import { CheckIcon } from "packages/components/icons";
import {
    ModalContainer,
    Header,
    Body,
    Step,
    StepTitle,
    StepContent,
    PlanSelectors,
    PlanSelector,
    RadioContainer,
    PaymentContainer,
    ButtonsContainer,
    ButtonContainer,
    StyledButton,
    SavedForYear,
    RadioWrapper,
    IntervalSelectors,
    IntervalSelector,
    IntervalRenewsInfo,
    IntervalTitle,
    SelectorLabel,
} from "./nodes";
import { ANALYTICS } from "packages/mixpanel/Mixpanel";
import { useMediaQuery } from "packages/helpers/useMediaQuery";


const PaymentByPlan = ({
                           plans,
                           fromType,
                           toType,
                           open,
                           onClose,
                           type,
                           marketplaceSettingValid,
                           countryId,
                           countriesList,
                           billingCreateCheckoutSession,
                           stripe,
                           paymentByInvoiceAllowed,
                           billingUpgradeTrialByInvoice,
                           billingCreateSubscriptionByInvoice,
                           intl: { formatMessage },
                       }) => {
    const [loadExchangeRates, { data }] = useLazyQuery(billingExchangeRateGet);
    const [selectedPlanId, setSelectedPlanId] = useState();
    const [exchangeRates, setExchangeRates] = useState();
    const [selectedPlanType, setSelectedPlanType] = useState(toType);
    const [selectedPaymentType, setSelectedPaymentType] = useState(
        PAYMENT_TYPE.CREDIT_CARD
    );
    const [isMonthlyPlan, setIsMonthlyPlan] = useState(true);
    const [currency, setCurrency] = useState();
    const addressFormRef = useRef();
    const isMobile = useMediaQuery("(max-width:480px)");

    const currentCountryData = useMemo(() =>
        filter(countriesList, country => country.id === countryId)[0],[countriesList, countryId]);

    const userLanguage = window?.navigator.language.toUpperCase();
    const isEUCountry = has(EU_COUNTRIES_CODE, currentCountryData ? currentCountryData?.shortName : userLanguage);

    const readyToPaymentUSD = exchangeRates && exchangeRates[CURRENCY.USD] && !isEUCountry;

    const withConvertedPrice = useCallback( (plan) => {
        let price = currencyConversion(plan?.price, exchangeRates);
        return {
            ...plan, price,
        };
    },[exchangeRates]);

    const plansByInterval = useMemo(() => {
        if(readyToPaymentUSD) {
            const convertedPlans = map(plans, (plan) => {
                return withConvertedPrice(plan);
            })
            return getPlansByInterval(convertedPlans);
        }
        return getPlansByInterval(plans);
    } , [plans, readyToPaymentUSD, withConvertedPrice]);

    const plan = plansByInterval[selectedPlanType];
    const monthPlan = plan.month;
    const yearPlan = plan.year;

    const periodPlans = useMemo(
        () =>
            filter( orderBy(
                map(plansByInterval, (el) =>
                    {
                        if (isMonthlyPlan) {
                            return el.month;
                        }
                        else {
                            if (!el.hasOwnProperty('year')) {
                                return null;
                            }
                            return {
                                ...el.year,
                            }
                        }
                    }
                ),
                "price"
            ), (plan) => plan !== null),
        [isMonthlyPlan, plansByInterval]
    );

    const savedForYear = useMemo(() => {
        if (!plan.month?.price) return;
        return Math.floor(plan.month.price * 12 - plan.year?.price);
    }, [plan]);

    useEffect(() => {
        if (!isEUCountry) {
            if (!exchangeRates) {
                loadExchangeRates();
            }
        }
    }, [exchangeRates, loadExchangeRates, isEUCountry]);

    useEffect(() => {
        if (readyToPaymentUSD) {
            setCurrency(CURRENCY.USD);
        }
        else {
            setCurrency(CURRENCY.EUR);
        }
    }, [readyToPaymentUSD]);

    useEffect(()=> {
        if (data) {
            const { billingExchangeRateGet: { data : { rates } } } = data;
            setExchangeRates(rates);
        }
    },[data]);

    useEffect(() => {
        setSelectedPlanId(isMonthlyPlan ? monthPlan.id : yearPlan.id);
    }, [isMonthlyPlan, monthPlan.id, monthPlan.price, yearPlan]);

    useEffect(() => {
        const plansById = keyBy(plans, "id");
        const selectedPlan = plansById[selectedPlanId];
        if (selectedPlan) {
            setSelectedPlanType(selectedPlan.planType);
        }
    }, [plans, selectedPlanId]);

    useEffect(() => {
        setSelectedPlanType(toType);
        if(toType === PLAN_TYPE.PROLITE) {
            setIsMonthlyPlan(true);
        }
    }, [toType]);

    const handleRedirectToPayment = async () => {
        ANALYTICS.billingThirdStep();
        const res = await billingCreateCheckoutSession({
            priceId: selectedPlanId,
            clientReferenceId: window.FPROM && window.FPROM.data.tid,
        });
        const sessionId = get(
            res,
            "data.billingCreateCheckoutSession.data.sessionId"
        );
        sessionId && stripe && stripe.redirectToCheckout({ sessionId });
    };

    const handleRedirectToInvoice = async () => {
        ANALYTICS.billingThirdStep();
        if (type === PAYMENT_STEPPER_TYPES.BILLING_UPGRADE) {
            await billingUpgradeTrialByInvoice({
                priceId: selectedPlanId,
            });
        } else {
            await billingCreateSubscriptionByInvoice({
                priceId: selectedPlanId,
            });
        }
        onClose();
    };

    const handlePlanSelect = (planId) => {
        setSelectedPlanId(planId);
    };

    const handlePay = (type) => {
        setSelectedPaymentType(type);
        addressFormRef.current.handleSubmit();
    };

    const getPlanTypeTextName = (planType) => {
        if (planType === PLAN_TYPE.PRO) {
            return formatMessage({ id: "subscription.plan.pro.header" });
        } else if (planType === PLAN_TYPE.PROMAX) {
            return formatMessage({ id: "subscription.plan.promax.header" });
        }
        else if (planType === PLAN_TYPE.PROLITE) {
            return formatMessage({ id: "subscription.plan.light.header" });
        }
        return planType;
    };

    const title =
        type === PAYMENT_STEPPER_TYPES.BILLING_UPGRADE
            ? formatMessage(
                { id: "subscription.modal.title.upgrade" }
            )
            : formatMessage({ id: "subscription.modal.title.trialEnded" });

    return (
<ModalContainer
            open={open}
            onClose={() => onClose()}
            styles={{ width: "580px", height: "initial" }}
            mobileWide
        >
            <Header>{title}</Header>
            <Body>
                <Step>
                    <StepContent>
                        <AddressContainer
                            formRef={addressFormRef}
                            redirectToPayment={handleRedirectToPayment}
                            redirectToInvoice={handleRedirectToInvoice}
                            paymentType={selectedPaymentType}
                            isUpgrade
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepTitle>
                        <span>
                            {formatMessage({
                                id: isMobile
                                    ? "subscription.modal.step.2.title.mobile"
                                    : "subscription.modal.step.2.title",
                            })}
                        </span>
                    </StepTitle>
                    <StepContent>
                        <PlanSelectors>
                            {periodPlans.map(
                                (
                                    {
                                        id,
                                        interval,
                                        price: planPrice,
                                        planType,
                                        savedForYear,
                                    },
                                    index
                                ) => {
                                    const isSelected = id === selectedPlanId;

                                    const isYear =
                                        interval === PLAN_PRICE_INTERVALS.year;

                                    let price = isYear
                                        ? (planPrice / 12).toFixed(2)
                                        : Number(planPrice).toFixed(2);

                                    const selectedLiteInYearly =
                                        !isMonthlyPlan &&
                                        planType === PLAN_TYPE.PROLITE;

                                    return (
                                        <PlanSelector
                                            key={index}
                                            selected={isSelected}
                                            isLight={selectedLiteInYearly}
                                            onClick={() =>
                                                !selectedLiteInYearly &&
                                                handlePlanSelect(id)
                                            }
                                        >
                                            {isMobile ? (
                                                <>
                                                    <SelectorLabel>
                                                        {getPlanTypeTextName(
                                                            planType
                                                        )}
                                                    </SelectorLabel>
                                                    <PaymentContainer>
                                                        <b
                                                            dangerouslySetInnerHTML={{
                                                                __html: formatMessage(
                                                                    {
                                                                        id: "subscription.modal.plan.price.title",
                                                                    },
                                                                    {
                                                                        currency,
                                                                        price,
                                                                    }
                                                                ),
                                                            }}
                                                        />
                                                    </PaymentContainer>
                                                    <RadioContainer>
                                                        <RadioWrapper
                                                            selected={
                                                                isSelected
                                                            }
                                                        >
                                                            <Radio
                                                                checked={
                                                                    isSelected &&
                                                                    !selectedLiteInYearly
                                                                }
                                                                radio
                                                                disabled={
                                                                    selectedLiteInYearly
                                                                }
                                                            />
                                                            <CheckIcon color="white" />
                                                        </RadioWrapper>
                                                    </RadioContainer>
                                                </>
                                            ) : (
                                                <>
                                                    <RadioContainer>
                                                        <SelectorLabel>
                                                            {getPlanTypeTextName(
                                                                planType
                                                            )}
                                                        </SelectorLabel>
                                                        <RadioWrapper
                                                            selected={
                                                                isSelected
                                                            }
                                                        >
                                                            <Radio
                                                                checked={
                                                                    isSelected &&
                                                                    !selectedLiteInYearly
                                                                }
                                                                radio
                                                                disabled={
                                                                    selectedLiteInYearly
                                                                }
                                                            />
                                                            <CheckIcon color="white" />
                                                        </RadioWrapper>
                                                    </RadioContainer>
                                                    <PaymentContainer>
                                                        <b
                                                            dangerouslySetInnerHTML={{
                                                                __html: formatMessage(
                                                                    {
                                                                        id: "subscription.modal.plan.price.title",
                                                                    },
                                                                    {
                                                                        currency,
                                                                        price,
                                                                    }
                                                                ),
                                                            }}
                                                        />
                                                    </PaymentContainer>
                                                </>
                                            )}
                                        </PlanSelector>
                                    );
                                }
                            )}
                        </PlanSelectors>

                        {isMobile && (
                            <StepTitle style={{ marginTop: "25px" }}>
                                <span>
                                    {formatMessage({
                                        id: "subscription.modal.step.3.title.mobile",
                                    })}
                                </span>
                            </StepTitle>
                        )}
                        <IntervalSelectors>
                            <IntervalSelector
                                selected={isMonthlyPlan}
                                onClick={() => setIsMonthlyPlan(true)}
                            >
                                <RadioContainer>
                                    <b>
                                        {formatMessage({
                                            id: "billing.interval.monthly",
                                        })}
                                    </b>
                                    <RadioWrapper selected={isMonthlyPlan}>
                                        <Radio checked={isMonthlyPlan} radio />
                                        <CheckIcon color="white" />
                                    </RadioWrapper>
                                </RadioContainer>
                                <IntervalRenewsInfo>
                                    {formatMessage({
                                        id: "billing.interval.monthly.renews.info",
                                    })}
                                </IntervalRenewsInfo>
                            </IntervalSelector>

                            <IntervalSelector
                                selected={!isMonthlyPlan}
                                onClick={() => setIsMonthlyPlan(false)}
                                disabled={
                                    selectedPlanType === PLAN_TYPE.PROLITE
                                }
                            >
                                <RadioContainer>
                                    <IntervalTitle>
                                        <b>
                                            {formatMessage({
                                                id: "billing.interval.yearly",
                                            })}
                                        </b>
                                        {!!savedForYear && (
                                            <SavedForYear>
                                                {formatMessage(
                                                    {
                                                        id: "subscription.modal.plan.price.savedMoneyInfo",
                                                    },
                                                    {
                                                        currency,
                                                        savedForYear:
                                                            savedForYear,
                                                    }
                                                )}
                                            </SavedForYear>
                                        )}
                                    </IntervalTitle>
                                    <RadioWrapper selected={!isMonthlyPlan}>
                                        <Radio
                                            checked={!isMonthlyPlan}
                                            radio
                                            disabled={
                                                selectedPlanType ===
                                                PLAN_TYPE.PROLITE
                                            }
                                        />
                                        <CheckIcon color="white" />
                                    </RadioWrapper>
                                </RadioContainer>
                                <IntervalRenewsInfo>
                                    {formatMessage({
                                        id: "billing.interval.yearly.renews.info",
                                    })}
                                </IntervalRenewsInfo>
                            </IntervalSelector>
                        </IntervalSelectors>

                        <ButtonsContainer>
                            <StyledButton
                                content={formatMessage({
                                    id: "subscription.modal.plan.button.payByCard.text",
                                })}
                                onClick={() =>
                                    handlePay(PAYMENT_TYPE.CREDIT_CARD)
                                }
                                fullWidth={true}
                            />
                            {paymentByInvoiceAllowed ? (
                                <ButtonContainer>
                                    <p>
                                        {formatMessage({
                                            id: "subscription.modal.plan.button.payByInvoice.desc",
                                        })}
                                    </p>
                                    <StyledButton
                                        view="secondaryBlack"
                                        content={formatMessage({
                                            id: "subscription.modal.plan.button.payByInvoice.text",
                                        })}
                                        onClick={() =>
                                            handlePay(PAYMENT_TYPE.INVOICE)
                                        }
                                    />
                                </ButtonContainer>
                            ) : null}
                        </ButtonsContainer>
                    </StepContent>
                </Step>
            </Body>
        </ModalContainer>
    );
};

PaymentByPlan.propTypes = {
    intl: intlShape.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    marketplaceSettingValid: PropTypes.bool.isRequired,
    billingCreateCheckoutSession: PropTypes.func.isRequired,
    billingUpgradeTrialByInvoice: PropTypes.func.isRequired,
    billingCreateSubscriptionByInvoice: PropTypes.func.isRequired,
    stripe: PropTypes.object.isRequired,
    paymentByInvoiceAllowed: PropTypes.bool.isRequired,
    plans: PropTypes.array,
    fromType: PropTypes.oneOf([
        SUBSCRIPTION_TYPE.FREE,
        SUBSCRIPTION_TYPE.TRIAL,
        ...Object.values(PLAN_TYPE),
    ]),
    toType: PropTypes.oneOf(Object.values(PLAN_TYPE)),
    countryId: PropTypes.number,
    countriesList: PropTypes.array,
};

PaymentByPlan.defaultProps = {
    plans: [],
    toType: PLAN_TYPE.PRO,
};

export default injectIntl(injectStripe(PaymentByPlan));


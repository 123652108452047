import mixpanel from "mixpanel-browser";

class MixpanelService {
    constructor() {
        mixpanel.init(__MIXPANEL_KEY__, { debug: true });
    }

    identify = ({ email, subscriptionType, planType }) => {
        __MIXPANEL_KEY__ && mixpanel.identify(email);
        __MIXPANEL_KEY__ &&
            mixpanel.people.set({
                Plan: subscriptionType,
                PlanType: planType,
                Email: email,
            });
    };

    trackFreeSignUp = () => {
        __MIXPANEL_KEY__ && mixpanel.track("Free SignUp Click");
    };

    trackProSignUp = () => {
        __MIXPANEL_KEY__ && mixpanel.track("PRO SignUp Click");
    };

    trackLogin = () => {
        __MIXPANEL_KEY__ && mixpanel.track("Login Click");
    };

    pageLoad = () => {
        __MIXPANEL_KEY__ && mixpanel.track("Page Load");
    };

    trackIntegration = (integrationName) => {
        __MIXPANEL_KEY__ &&
            mixpanel.track(`Integration Details ${integrationName} Click`);
    };

    switchLang = (lang) => {
        __MIXPANEL_KEY__ && mixpanel.track(`Switch Lang To ${lang} Click`);
    };

    downgradeToFree = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Downgrade To Free Click`);
    };

    deleteAccount = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Delete Account Click`);
    };

    billingFirstStep = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Choose Plan Click`);
    };

    billingSecondStep = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Fill Billing Info Click`);
    };

    billingThirdStep = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Proceed In Stripe Click`);
    };

    startTrial = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Start Trial Click`);
    };

    trialUpgrade = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Trial Upgrade Click`);
    };

    photoUpload = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Photo Upload`);
    };

    videoUpload = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Video Upload`);
    };

    pageCreate = (pageType) => {
        __MIXPANEL_KEY__ && mixpanel.track(`Page Create [${pageType}]`);
    };

    changeTemplate = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Change Template`);
    };

    addPremiumDomain = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Add Premium Domain`);
    };

    addFreeDomain = () => {
        __MIXPANEL_KEY__ && mixpanel.track(`Add Free Domain`);
    };
}

export const ANALYTICS = new MixpanelService();

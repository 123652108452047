import {compose} from 'redux';
import {connect} from 'react-redux';
import {graphql} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import referralsStatisticGet from '@graphql/gql/website/referralsStatisticGet.gql';

import Referrals from '@configurator/components/statistics/referrals/Referrals';

export const referralsWrapper = Component =>  compose(
    connect(
        state => ({ matomoOptions: state.get('periodPicker').toJS() })
    ),
    graphql(referralsStatisticGet, {
        skip: ({ matomoOptions }) => !matomoOptions.idSite,
        options: ({ matomoOptions }) => ({
            variables: matomoOptions,
            ssr: false,
        }),
        props({data: {loading, referralsStatisticGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(referralsStatisticGet)) {
                return {loading};
            }
            const {data} = referralsStatisticGet;

            return {
                items: isArray(data) ? data : undefined,
            };
        },
    })
)(Component);

export default referralsWrapper(Referrals);

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const ExpandIcon = ({ color }) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 14H0L0.007 7H2V12H7V14ZM13.992 7H12V2H7V0H14L13.992 7Z"
            fill={iconColors[color]}
        />
    </svg>
);

ExpandIcon.propTypes = {
    ...iconColorPropTypes,
};

ExpandIcon.defaultProps = {
    color: 'dark',
};

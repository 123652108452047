import { compose } from "redux";
import { graphql } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import { push } from "connected-react-router";
import { withRouter } from 'react-router';

import {
    open as openErrorModal,
    close as closeErrorModal,
} from "packages/redux/modules/modalConfirmDelete/actions";
import { lockModal } from "packages/redux/modules/modalProgress/actions";
import {
    getStateFeatureId,
    getStateFeatureProperty,
} from "packages/helpers/Helper";

import {
    BLOCK_PROPERTY_KEYS,
    FEATURE_PROPERTY_KEYS,
    MESSAGE_TYPE,
    URLS,
    WEBSITE_BLOCK,
} from "packages/enum";

import websitePageUpdate from "@graphql/gql/website/websitePageUpdate.gql";
import websitePagesGet from "@graphql/gql/website/websitePagesGet.gql";
import websitePageGet from "@graphql/gql/website/websitePageGet.gql";
import previewPageGet from "@graphql/gql/website/previewPageGet.gql";
import websitePagePhotoUpdate from "@graphql/gql/website/websitePagePhotoUpdate.gql";
import blockPropertyUpdate from "@graphql/gql/website/blockPropertyUpdate.gql";
import blockPropertyDelete from "@graphql/gql/website/blockPropertyDelete.gql";
import featureUpdate from "@graphql/gql/website/featureUpdate.gql";

import { metaSelector} from "packages/selectors/Website";

import PageUpdate from "@configurator/components/modals/pageUpdate";

const validationSchema = Yup.object().shape({
    title: Yup.string().required("page.field.error.title"),
    url: Yup.string().matches(/^[a-zA-Z]+$/, 'page.field.error.slug')
});

export const pageUpdateWrapper = (Component) =>
    compose(
        withRouter,
        connect(
            // mapStateToProps
            (state) => ({
                uploads: state.getIn(["uploadPhotos", "uploads"]),
                meta: metaSelector(state),
                contactFormFeatureId: getStateFeatureId(
                    state.getIn(["preview", "meta", "features"]) ||
                        state.getIn(["meta", "features"]),
                    FEATURE_PROPERTY_KEYS.contactForm,
                    FEATURE_PROPERTY_KEYS.contactFormLang
                ),
                featureLanguage: getStateFeatureProperty(
                    state.getIn(["preview", "meta", "features"]) ||
                        state.getIn(["meta", "features"]),
                    FEATURE_PROPERTY_KEYS.contactForm,
                    FEATURE_PROPERTY_KEYS.contactFormLang
                ),
                featureAlign: getStateFeatureProperty(
                    state.getIn(["preview", "meta", "features"]) ||
                        state.getIn(["meta", "features"]),
                    FEATURE_PROPERTY_KEYS.contactForm,
                    FEATURE_PROPERTY_KEYS.contactFormAlign
                ),
            }),
            // mapDispatchToProps
            (dispatch) => ({
                lockModalProgress: () => dispatch(lockModal(true)),
                unLockModalProgress: () => dispatch(lockModal(false)),
                redirectToConfig: () => dispatch(push(URLS.website_config)),
                openErrorModal: ({
                    headerMessageId,
                    subMessageValues,
                    hideCancelButton,
                    subMessageId,
                    yesMessageId,
                    hideHeader,
                    onClickYes,
                    styles,
                }) =>
                    dispatch(
                        openErrorModal({
                            headerMessageId,
                            subMessageValues,
                            hideCancelButton,
                            subMessageId,
                            yesMessageId,
                            hideHeader,
                            onClickYes,
                            styles,
                        })
                    ),
                closeErrorModal: () => dispatch(closeErrorModal()),
            })
        ),
        graphql(websitePageUpdate, {
            props({ mutate, ownProps: { match } }) {
                return {
                    async updatePage({
                        title,
                        pageId,
                        visible,
                        startPage,
                        password,
                        url,
                        // fileText,
                        expanded,
                        zoom,
                        showHeader,
                    }) {
                        try {
                            return await mutate({
                                variables: {
                                    title,
                                    pageId,
                                    visible,
                                    startPage,
                                    password,
                                    url,
                                    // fileText,
                                    expanded,
                                    zoom,
                                    showHeader,
                                },
                                refetchQueries: [
                                    {
                                        query: websitePagesGet,
                                    },
                                    {
                                        query: previewPageGet,
                                        variables: {
                                            id: match.params.pageId
                                                ? decodeURIComponent(match.params.pageId)
                                                : undefined,
                                        },
                                    },
                                ],
                            });
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        graphql(websitePagePhotoUpdate, {
            props({ mutate }) {
                return {
                    async updatePhoto({ blockId, file }) {
                        try {
                            return await mutate({
                                variables: {
                                    blockId,
                                    file,
                                },
                            });
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        graphql(blockPropertyUpdate, {
            props({ mutate }) {
                return {
                    async updateBlockProperty({
                        blockId,
                        propertyKey,
                        propertyValue,
                    }) {
                        try {
                            return await mutate({
                                variables: {
                                    blockId,
                                    propertyKey,
                                    propertyValue,
                                },
                            });
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        graphql(featureUpdate, {
            props({ mutate }) {
                return {
                    async featureUpdate({ featureId, properties }) {
                        try {
                            return await mutate({
                                variables: {
                                    featureId,
                                    properties,
                                },
                            });
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        graphql(websitePageGet, {
            options({ pageId }) {
                return {
                    variables: {
                        pageId,
                    },
                    ssr: false,
                    fetchPolicy: "network-only",
                };
            },
            props({
                data: { loading, websitePageGet, refetch },
                ownProps: { featureAlign, featureLanguage },
            }) {
                if (loading) {
                    return { loading };
                }
                if (isEmpty(websitePageGet)) {
                    return;
                }
                const { data } = websitePageGet;

                const contactBlock = (data.blocks || []).find(
                    (el) => el.type === WEBSITE_BLOCK.CONTACTS
                );

                const htmlBlock = (data.blocks || []).find(
                    (el) => el.type === WEBSITE_BLOCK.HTML
                );
                let userPhotoText = data.userPhotoText;
                // совместимость со старой концепцией (align, lang хранились в features)
                let align = featureAlign;
                let language = featureLanguage;
                if (contactBlock) {
                    const userPhotoTextObj = (
                        contactBlock.blockProperties || []
                    ).find(
                        (el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoText
                    );
                    const alignObj = (contactBlock.blockProperties || []).find(
                        (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormAlign
                    );
                    const languageObj = (
                        contactBlock.blockProperties || []
                    ).find(
                        (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormLang
                    );
                    userPhotoText = userPhotoTextObj
                        ? userPhotoTextObj.value
                        : userPhotoText;
                    align = (alignObj && alignObj.value) || align;
                    language = (languageObj && languageObj.value) || language;
                }

                if (htmlBlock) {
                }
                return {
                    initialValues: {
                        ...data,
                        album: !data.zoom,
                        align,
                        language,
                        userPhotoText,
                        footerNavigation: !data.mainNavigation,
                        hideHeader: !data.showHeader,
                        stacked: !data.expanded,
                        content: htmlBlock && htmlBlock.content,
                        blockType: htmlBlock && htmlBlock.blockType,
                    },
                    refetchData: refetch,
                };
            },
        }),
        graphql(blockPropertyDelete, {
            props({ mutate }) {
                return {
                    async deleteBlockProperty({ propertyId }) {
                        try {
                            return await mutate({
                                variables: {
                                    propertyId,
                                },
                            });
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        withFormik({
            mapPropsToValues: ({ initialValues }) => {
                return {
                    ...initialValues,
                };
            },
            enableReinitialize: true,
            validateOnBlur: false,
            validateOnChange: false,
            validationSchema,
            handleSubmit: async (
                {
                    title,
                    visible,
                    startPage,
                    file,
                    userPhotoText,
                    blocks = [],
                    password,
                    url,
                    expanded,
                    zoom,
                    hideHeader,
                    language,
                    align,
                    content,
                },
                {
                    setSubmitting,
                    setStatus,
                    props: {
                        updatePage,
                        onClose,
                        pageId,
                        lockModalProgress,
                        unLockModalProgress,
                        updatePhoto,
                        updateBlockProperty,
                        redirectToConfig,
                        initialValues,
                        deleteBlockProperty,
                    },
                }
            ) => {

                let defaultError = "page.update.error.header";
                const contactBlock = blocks.find(
                    (el) => el.type === WEBSITE_BLOCK.CONTACTS
                );
                const htmlBlock = blocks.find(
                    (el) => el.type === WEBSITE_BLOCK.HTML
                );

                if (contactBlock) {
                    if (file) {
                        lockModalProgress();
                        const userPhotoProperty =
                            (contactBlock.blockProperties || []).find(
                                (el) => el.key === BLOCK_PROPERTY_KEYS.userPhoto
                            ) || undefined;
                        try {
                            // updatePhoto добавляет фото, поэтому удаляем вручную
                            if (userPhotoProperty) {
                                const {
                                    data: {
                                        blockPropertyDelete: {
                                            errors,
                                            success,
                                        } = {},
                                    },
                                } = await deleteBlockProperty({
                                    propertyId: userPhotoProperty.id,
                                });
                                if (!success) {
                                    setSubmitting(false);
                                    return setStatus({
                                        error: errors._error || defaultError,
                                    });
                                }
                            }
                            const res = await updatePhoto({
                                blockId: contactBlock.id,
                                file,
                            });
                            unLockModalProgress();
                            let {
                                data: {
                                    websitePagePhotoUpdate: {
                                        errors,
                                        success,
                                    } = {},
                                },
                            } = res;
                            if (!success) {
                                unLockModalProgress();
                                setSubmitting(false);
                                return setStatus({
                                    error: errors._error || defaultError,
                                });
                            }
                        } catch (err) {
                            unLockModalProgress();
                            setSubmitting(false);
                            return setStatus({
                                error: __PRODUCTION__
                                    ? defaultError
                                    : err.toString(),
                            });
                        }
                    }

                    if (userPhotoText || userPhotoText === "") {
                        try {
                            const res = await updateBlockProperty({
                                blockId: contactBlock.id,
                                propertyKey: BLOCK_PROPERTY_KEYS.userPhotoText,
                                propertyValue: userPhotoText,
                            });
                            let {
                                data: {
                                    blockPropertyUpdate: {
                                        errors,
                                        success,
                                    } = {},
                                },
                            } = res;
                            if (!success) {
                                setSubmitting(false);
                                return setStatus({
                                    error: errors._error || defaultError,
                                });
                            }
                        } catch (err) {
                            setSubmitting(false);
                            return setStatus({
                                error: __PRODUCTION__
                                    ? defaultError
                                    : err.toString(),
                            });
                        }
                    }

                    if (language) {
                        try {
                            const res = await updateBlockProperty({
                                blockId: contactBlock.id,
                                propertyKey:
                                    BLOCK_PROPERTY_KEYS.contactFormLang,
                                propertyValue: language,
                            });
                            let {
                                data: {
                                    blockPropertyUpdate: {
                                        errors,
                                        success,
                                    } = {},
                                },
                            } = res;
                            if (!success) {
                                setSubmitting(false);
                                return setStatus({
                                    error: errors._error || defaultError,
                                });
                            }
                        } catch (err) {
                            setSubmitting(false);
                            return setStatus({
                                error: __PRODUCTION__
                                    ? defaultError
                                    : err.toString(),
                            });
                        }
                    }

                    if (align) {
                        try {
                            const res = await updateBlockProperty({
                                blockId: contactBlock.id,
                                propertyKey:
                                    BLOCK_PROPERTY_KEYS.contactFormAlign,
                                propertyValue: align,
                            });
                            let {
                                data: {
                                    blockPropertyUpdate: {
                                        errors,
                                        success,
                                    } = {},
                                },
                            } = res;
                            if (!success) {
                                setSubmitting(false);
                                return setStatus({
                                    error: errors._error || defaultError,
                                });
                            }
                        } catch (err) {
                            setSubmitting(false);
                            return setStatus({
                                error: __PRODUCTION__
                                    ? defaultError
                                    : err.toString(),
                            });
                        }
                    }
                }

                if (htmlBlock) {
                    try {
                        const res = await updateBlockProperty({
                            blockId: htmlBlock.id,
                            propertyKey: BLOCK_PROPERTY_KEYS.content,
                            propertyValue: content,
                        });
                        let {
                            data: {
                                blockPropertyUpdate: {
                                    errors,
                                    success,
                                } = {},
                            },
                        } = res;
                        if (!success) {
                            setSubmitting(false);
                            return setStatus({
                                error: errors._error || defaultError,
                            });
                        }
                    } catch (err) {
                        setSubmitting(false);
                        return setStatus({
                            error: __PRODUCTION__
                                ? defaultError
                                : err.toString(),
                        });
                    }
                }

                try {
                    const res = await updatePage({
                        title,
                        pageId,
                        visible,
                        startPage,
                        password,
                        url,
                        expanded,
                        zoom,
                        showHeader: !hideHeader,
                    });
                    setSubmitting(false);
                    let {
                        data: { websitePageUpdate: { errors, success } = {} },
                    } = res;
                    if (success) {
                        window.frames["preview-frame"]?.postMessage(
                            JSON.stringify({
                                name: MESSAGE_TYPE.UPDATE_PAGE,
                            }),
                            "*"
                        );
                        window.frames["preview-frame"]?.postMessage(
                            JSON.stringify({
                                name: MESSAGE_TYPE.UPDATE_META,
                            }),
                            "*"
                        );
                        if ((initialValues || {}).title !== title || (initialValues.url || {}).url !== url) {
                            redirectToConfig();
                        }
                        return onClose();
                    }
                    setStatus({ error: errors._error || defaultError });
                } catch (err) {
                    setSubmitting(false);
                    setStatus({
                        error: __PRODUCTION__ ? defaultError : err.toString(),
                    });
                }
            },
        })
    )(Component);

export default pageUpdateWrapper(PageUpdate);

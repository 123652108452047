import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Semantic} from 'packages/components';
import {formProptypes} from 'packages/utils/formikPropTypes';
import {FormInput} from 'packages/components/inputs';
import {Button} from 'packages/components/buttons';
import Message from 'packages/components/message/Message';
import {CircleCheckIcon} from 'packages/components/icons';
import {
    Content,
    Container,
    Header,
    ButtonsContainer,
    RequestSuccessContent,
} from './nodes';

const SendResetLinkForm = ({
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    onClickBack,
}, {intl: {formatMessage}}) => {
    const statusSuccess = useMemo(() => {
        return status && status.success;
    }, [status]);

    const error = Object.keys(errors).length || status && !!status.error;

    const renderRequestSuccess = () => (
        <RequestSuccessContent>
            <CircleCheckIcon color="green"/>
            <h1>{formatMessage({id: 'changePassword.sendResetLink.success.header'})}</h1>
            <span>{formatMessage({id: 'changePassword.sendResetLink.success.text'})}</span>
        </RequestSuccessContent>
    );

    const renderForm = () => (
        <>
            <Header>
                {formatMessage({id: 'changePassword.sendResetLink.header'})}
                <div>
                    {formatMessage({id: 'changePassword.sendResetLink.text'})}
                </div>
            </Header>
            <Content>
                <Semantic.Form
                    error={error}
                    loading={isSubmitting || loading}
                    onSubmit={(e, ...args) => {
                        e.stopPropagation();
                        handleSubmit(e, ...args);
                    }}
                >
                    <Message
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <FormInput
                        name="email"
                        label={formatMessage({id: 'changePassword.sendResetLink.field.email'})}
                        type="email"
                        required
                    />
                    <ButtonsContainer>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            content={formatMessage({id: 'changePassword.sendResetLink.button.submit'})}
                        />
                        <div
                            onClick={onClickBack}
                        >
                            {formatMessage({id: 'changePassword.sendResetLink.button.back'})}
                        </div>
                    </ButtonsContainer>
                </Semantic.Form>
            </Content>
        </>
    );

    return (
        <Container>
            {statusSuccess
                ? renderRequestSuccess()
                : renderForm()
            }
        </Container>
    );
};

SendResetLinkForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onClickBack: PropTypes.func.isRequired,
    onClickForgot: PropTypes.func.isRequired,
    ...formProptypes,
};

SendResetLinkForm.defaultProps = {};

SendResetLinkForm.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default SendResetLinkForm;

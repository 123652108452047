import styled from 'styled-components';
import {Tab} from 'packages/components/semantic';
import {media} from 'packages/components/media';

export const Container = styled.div`
    max-width: 1420px;
    margin: 0 auto 0 auto;
    color: #343840;
    padding: 30px 20px 10px 20px;

    ${media.max.phone`
        padding-left: 16px;
        padding-right: 16px;
    `}
`;

export const Header = styled.div`
    font-size: 36px;
    line-height: 140%;
    font-weight: bold;
    margin-bottom: 30px;

    @media (max-width: 375px) {
        font-size: 30px;
    }
`;

export const SplitContent = styled.div`
    display: flex;
`;

export const LeftPanel = styled.div`
    flex: 1;
    max-width: 500px;
    padding-right: 40px;

    > div {
        margin-bottom: 40px;
    }
`;

export const RightPanel = styled.div`
    flex: 1;
    padding-left: 40px;

    > div {
        margin-bottom: 40px;
    }
`;

export const BottomMenu = styled.div`
    margin-top: 40px;
    > div {
        margin-bottom: 60px;
    }
`;

export const TabPane = styled(Tab.Pane)`
    ${media.max.tablet`
        padding-left: 0 !important;
        padding-right: 0 !important;
    `}
`;

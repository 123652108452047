import styled from 'styled-components';
import {media} from 'packages/components/media';

const TEXT_COLOR = '#343840';
const DESC_TEXT_COLOR = '#666A73';

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > span {
        font-weight: bold;
        font-size: 20px;
        color: ${TEXT_COLOR};
    }

    .ui.button {
       font-weight: bold;
       width: 166px;
       height: 45px;
    }

    ${media.max.phone`
        margin-bottom: 32px;

        .ui.button {
            display: none !important;
        }
    `}
`;

export const Content = styled.div`
    justify-content: center;
    display: flex !important;
    color: ${TEXT_COLOR};

    > form {
        width: 100%;
    }
`;

export const StyledInput = styled.div`
    ${props => props.hide ? 'display: none;' : ''}
    margin-top: 20px;

    .ui.input {
        width: 100% !important;
    }
`;

export const Title = styled.div`
    position: relative;
    font-size: 15px;
    line-height: 21px;
    ${props => props.bold ? 'font-weight: bold;' : ''}
`;

export const Description = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: ${DESC_TEXT_COLOR};
    margin-top: 5px;
`;

export const DeleteItem = styled.div`
    margin-top: 40px;
    font-size: 15px;
    line-height: 21px;
    color: #FF002E;
    cursor: pointer;
`;

export const MobileSubmitContainer = styled.div`
    display: none;
    margin-top: 30px;

    ${media.max.phone`
        display: block;
    `}
`;

import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Semantic} from 'packages/components';
import style from './style.less';


export const Button = ({
    basicGrey,
    centered,
    className,
    content,
    green,
    red,
    greenSolid,
    nowrap,
    upload,
    transparent,
    ...props
}) => (
    <Semantic.Button
        {...props}
        className={cs(
            className,
            style.button,
            {
                [style.basic]: basicGrey,
                [style['basic--grey']]: basicGrey,
                [style.centered]: centered,
                [style.green]: green,
                [style.red]: red,
                [style['green-solid']]: greenSolid,
                [style.nowrap]: nowrap,
                [style.upload]: upload,
                [style.transparent]: transparent,
                [style.fluid]: props.fluid,
            },
        )}
    >
        {content}
    </Semantic.Button>
);

Button.defaultProps = {
    basicGrey: undefined,
    classnames: undefined,
    centered: undefined,
    className: undefined,
    nowrap: undefined,
    green: undefined,
    red: undefined,
    greenSolid: undefined,
    transparent: undefined,
    upload: undefined,
    props: {},
};
Button.propTypes = {
    basicGrey: PropTypes.bool,
    classnames: PropTypes.string,
    centered: PropTypes.bool,
    className: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    green: PropTypes.bool,
    red: PropTypes.bool,
    greenSolid: PropTypes.bool,
    nowrap: PropTypes.bool,
    transparent: PropTypes.bool,
    upload: PropTypes.bool,
    props: PropTypes.object,
};

import { compose } from "redux";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import billingPlansGet from "@graphql/gql/website/billingPlansGet.gql";
import billingSubscriptionsGet from "@graphql/gql/website/billingSubscriptionsGet.gql";
import billingAccountDelete from "@graphql/gql/website/billingAccountDelete.gql";
import billingCreateCustomerPortal from "@graphql/gql/website/billingCreateCustomerPortal.gql";
import billingAccountGet from "@graphql/gql/website/billingAccountGet.gql";
import countryListGet from "@graphql/gql/common/countryListGet.gql";

import { open as openErrorModal } from "packages/redux/modules/modalYesNo/actions";
import { start as loaderStart, stop as loaderStop } from "packages/redux/modules/pageLoader/actions";
import { openModal as openDowngradeModal } from "@configurator/redux/modules/downgrade/actions";

import { URLS } from "packages/enum";
import { getActiveSubscription } from "packages/helpers/Helper";

import Subscriptions from "@configurator/components/billing/subscriptions";

export default compose(
    withRouter,
    connect(
        // mapStateToProps
        (state) => ({}),
        // mapDispatchToProps
        (dispatch) => ({
            openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
                dispatch(
                    openErrorModal({
                        headerMessageId,
                        yesMessageId,
                        subMessageId,
                    })
                ),
            loaderStart: () => dispatch(loaderStart()),
            loaderStop: () => dispatch(loaderStop()),
            openDowngradeModal: () => dispatch(openDowngradeModal()),
        })
    ),
    graphql(billingAccountDelete, {
        props({
            mutate,
            ownProps: { openErrorModal, loaderStart, loaderStop },
        }) {
            return {
                async billingAccountDelete() {
                    try {
                        loaderStart();
                        const res = mutate();
                        loaderStop();
                        if (
                            get(res, "data.billingAccountDelete.errors._error")
                        ) {
                            openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.billingAccountDelete.errors._error"
                                ),
                            });
                        }
                        return (window.location = URLS.root);
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(billingPlansGet, {
        props({ data: { loading, billingPlansGet } }) {
            if (loading) {
                return { loading };
            }
            if (isEmpty(billingPlansGet)) {
                return { loading };
            }
            const { data } = billingPlansGet;

            return {
                plans: data.plans,
            };
        },
    }),
    graphql(billingCreateCustomerPortal, {
        props({
            mutate,
            ownProps: { openErrorModal, loaderStart, loaderStop },
        }) {
            return {
                async billingCreateCustomerPortal() {
                    try {
                        loaderStart();
                        const res = await mutate();
                        loaderStop();
                        if (
                            get(
                                res,
                                "data.billingCreateCustomerPortal.errors._error"
                            )
                        ) {
                            openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.billingCreateCustomerPortal.errors._error"
                                ),
                            });
                        }
                        return res;
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(billingSubscriptionsGet, {
        props({ data: { loading, billingSubscriptionsGet } }) {
            if (loading) {
                return { loading };
            }
            if (isEmpty(billingSubscriptionsGet)) {
                return { loading };
            }
            const { data } = billingSubscriptionsGet;

            const subscription = getActiveSubscription(data);
            return {
                subscriptions: subscription.subscriptions,
            };
        },
    }),
    graphql(billingAccountGet, {
        props({ data: { loading, billingAccountGet } }) {
            if (loading) {
                return { loading };
            }
            if (isEmpty(billingAccountGet)) {
                return { loading };
            }
            const { data } = billingAccountGet;

            return {
                countryId: data.countryId
            };
        },
    }),
    graphql(countryListGet, {
        props({ data: { loading, countryListGet } }) {
            if (loading) {
                return {
                    loading,
                    options: [],
                };
            }
            if (isEmpty(countryListGet)) {
                return {
                    loading,
                    options: [],
                };
            }
            const { data } = countryListGet;

            return {
                countriesList: data,
            };
        },
    }),
)(Subscriptions);

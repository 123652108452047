import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {fieldProptypes} from './formikPropTypes';
import {Slider} from '../slider';

const SliderInput = (
    {
        field, // { name, value, onChange, onBlur }
        form: {setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid,
        ...props
    },
) => {
    const handleChange = (value) => {
        if (!props.disabled) {
            setFieldValue(field.name, value);
        }
    };
    return (
        <Slider
            {...field}
            {...props}
            onChange={handleChange}
            value={field.value}
        />
    );
};

SliderInput.propTypes = {
    ...fieldProptypes,
};

SliderInput.defaultProps = {
};

export const FormSlider = ({name, ...props}) => (
    <Field name={name} {...props} component={SliderInput}/>
);

FormSlider.propTypes = {
    name: PropTypes.string.isRequired,
};

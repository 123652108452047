import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: #343840;
  font-family: Sailec, sans-serif;
`;

export const Label = styled.label`
  display: block;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 17px;
`;

export const RadioLabel = styled.div`
  font-size: 13px;
  line-height: 140%;
`;

export const RadiosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  & ${RadioLabel} {
    margin-left: 10px;
  }
  margin-right: 40px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 30px;
  }
`;

import {createActions} from 'redux-actions';

export const {
    albumView: {
        changeView,
    },
} = createActions({
    ALBUM_VIEW: {
        CHANGE_VIEW: payload => payload
    },
});

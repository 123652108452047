/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const HomeIcon = ({ color }) => (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 10L7 4L4 2L1 4L1 10L2 10L2 6L6 6L6 10L7 10Z"
            fill={iconColors[color]}
        />
        <path
            d="M7 4L8 4C8 3.66565 7.8329 3.35342 7.5547 3.16795L7 4ZM7 10L7 11C7.55228 11 8 10.5523 8 10L7 10ZM4 2L4.5547 1.16795L4 0.798149L3.4453 1.16795L4 2ZM1 4L0.4453 3.16795C0.167101 3.35342 -7.2808e-08 3.66565 -8.74231e-08 4L1 4ZM1 10L-3.49692e-07 10C-3.73833e-07 10.5523 0.447715 11 1 11L1 10ZM6 10L5 10L5 11L6 11L6 10ZM2 10L2 11L3 11L3 10L2 10ZM6 6L7 6L7 5L6 5L6 6ZM2 6L2 5L1 5L1 6L2 6ZM6 4L6 10L8 10L8 4L6 4ZM7.5547 3.16795L4.5547 1.16795L3.4453 2.83205L6.4453 4.83205L7.5547 3.16795ZM3.4453 1.16795L0.4453 3.16795L1.5547 4.83205L4.5547 2.83205L3.4453 1.16795ZM-8.74231e-08 4L-3.49692e-07 10L2 10L2 4L-8.74231e-08 4ZM6 11L7 11L7 9L6 9L6 11ZM1 11L2 11L2 9L1 9L1 11ZM5 6L5 10L7 10L7 6L5 6ZM3 10L3 6L1 6L1 10L3 10ZM2 7L6 7L6 5L2 5L2 7Z"
            fill={iconColors[color]}
        />
    </svg>
);

HomeIcon.propTypes = {
    ...iconColorPropTypes,
};

HomeIcon.defaultProps = {
    color: 'dark',
};

import styled from 'styled-components';
import { Container } from '@landing/components/common/nodes';

export const Notes = styled.div`
    color: #ededed;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    > a {
        color: #436df6;
        cursor: pointer;
    }
`;

export const NotesRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 24px;
`;

export const LinkToFree = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
    > a {
        color: #4d4d52;
        text-decoration: underline;
        font-style: italic;
    }
`;

export const Tab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    text-align: center;
    font-size: 13px;
    border-radius: 2px;
    color: ${(props) => (props.isActive ? '#fff' : '#666a73')};
    background: ${(props) => (props.isActive ? '#343840' : '#fff')};
    cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
`;

export const Tabs = styled.div`
    height: 50px;
    border-radius: 2px;
    border: 1px solid #e1e2e6;
    display: flex;
    align-items: center;
    padding: 0 4px;

    > a {
        width: 50%;

        ${Tab} {
            width: 100%;
        }
    }
`;
export const Description = styled.div`
    color: #ededed;
    font-size: 14px;
    margin-top: 8px;

    > span {
        text-align: center;
        margin: 0 auto;
        display: block;
        line-height: 20px;
        font-weight: 400;
        max-width: 260px;
    }

    > p {
        font-size: 14px;
    }
`;

export const WaitingScreenContainer = styled(Container)`
    text-align: center;
`;

export const WaitingScreenHeader = styled.div`
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #FFFFFF;
`;

export const WaitingScreenDescription = styled.div`
    margin-bottom: 56px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #EDEDED;
`;

export const WaitingScreenContent = styled.div`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
`;

export const WaitingScreenProgress = styled.div`
    max-width: 330px;
    margin: auto;

    .ui.progress {
        border-radius: 6px;
        margin-bottom: 0;
        background-color: #34363B;

        .bar {
            height: 8px;
            border-radius: inherit;
            background-color: #436DF6;
        }
    }
`;
export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
        margin-left: 5px;
    }
`;

export const AboutFormHeader = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #fff;
`;

export const AboutFormSelectHeader = styled(AboutFormHeader)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;
`;

export const AboutFormCheckboxHeader = styled(AboutFormHeader)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 16px;
`;

export const AboutFormLabel = styled.label`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #EDEDED;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;

    > span {
        margin-left: 16px;
    }
`;

export const AboutFormInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    div:nth-child(2) > div > div {
        color: #C1C3C7 !important;
    }
`;

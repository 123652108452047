import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: ${(props) => props.isModalClosed ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  cursor: pointer;

  > div {
    cursor: default;
    max-width: 420px;
    padding: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: content-box !important;
  }

  & button {
    width: 50% !important;
  }
`;

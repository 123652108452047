import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Semantic } from "packages/components";
import { Modal } from "packages/components/modal";
import {
    FormInput,
    FormToggle
} from "packages/components/inputs";
import { Button } from "packages/components/buttons";
import { NewFeatureBudge } from "packages/components/newFeatureBudge/nodes";
import { formProptypes } from "packages/utils/formikPropTypes";
import Message from "packages/components/message/Message";
import {
    Header,
    Content,
    StyledInput,
    Title,
    Description,
    MobileSubmitContainer,
} from "@configurator/components/modals/nodes";
import {DescriptionLabel, DescriptionWrapper, StyledTextareaContainer, ToggleContainer} from "./nodes.js";

import newFeatureIcon from "src/packages/theme-ab/resources/assets/configurator/new_feature.svg";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import { useSubscription } from "@configurator/providers/subscription";
import TextEditorContainer from "@configurator/containers/TextEditorContainer";
import {WEBSITE_BLOCK} from 'packages/enum';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const AlbumCreate = (
    {
        open,
        onClose,
        handleSubmit,
        errors,
        status,
        isSubmitting,
        values,
        setFieldValue,
        initialValues,
        pageId,
        mainNavigation,
        footerNavigation,
        redirectToAlbum,
    },
    { intl: { formatMessage } }
) => {
    const prevValues = usePrevious(values);
    const prevIsSubmitting = usePrevious(isSubmitting);
    const handleChangeDescription = (text) => setFieldValue("description", text);
    const { permissions } = useSubscription();
    useEffect(() => {
        if (prevValues && prevValues.visible !== values.visible) {
            setFieldValue("hide", !values.visible);
        }
        if (prevValues && prevValues.hide !== values.hide) {
            setFieldValue("visible", !values.hide);
        }
    }, [prevValues, setFieldValue, values]);

    const error = Object.keys(errors).length || (status && !!status.error);

    useEffect(() => {
        if (prevIsSubmitting && !isSubmitting && !error) {
            const pageIndexMain = mainNavigation?.findIndex(el => el.id === pageId);
            const pageIndex = pageIndexMain === -1 ? footerNavigation?.findIndex(el => el.id === pageId) : pageIndexMain;
            const navigation = pageIndexMain === -1 ? footerNavigation : mainNavigation;
            if (pageIndex > -1) {
                const albumListIndex = navigation[pageIndex].blocks?.findIndex(el => el.type === WEBSITE_BLOCK.ALBUM_LIST);
                if (albumListIndex > -1 && navigation[pageIndex].blocks[albumListIndex]?.albums?.[0]?.link) {
                    return redirectToAlbum(navigation[pageIndex].blocks[albumListIndex].albums[0].link);
                }
            }
        }
    }, [error, footerNavigation, isSubmitting, mainNavigation, pageId, prevIsSubmitting, redirectToAlbum]);

    const modalStyle = {
        width: "600px",
        height: "initial",
    };

    return (
        <Modal open={open} onClose={onClose} styles={modalStyle} mobileWide>
            <Content>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting}
                    onSubmit={handleSubmit}
                >
                    <Header>
                        <span>
                            {formatMessage({
                                id: "newDesign.album.create.header",
                            })}
                        </span>
                        <Button
                            type="submit"
                            content={formatMessage({
                                id: "newDesign.album.create.submit",
                            })}
                            disabled={isSubmitting}
                        />
                    </Header>
                    <Message
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <StyledInput>
                        <FormInput
                            name="title"
                            type="text"
                            label="newDesign.album.create.field.name"
                        />
                    </StyledInput>
                    <StyledTextareaContainer>
                        <DescriptionWrapper>
                            <DescriptionLabel>
                                {formatMessage({
                                    id: "newDesign.album.update.field.desc",
                                })}
                            </DescriptionLabel>
                            <TextEditorContainer
                                text={initialValues.description}
                                handleChangeText={handleChangeDescription}
                                inline
                            />
                        </DescriptionWrapper>
                    </StyledTextareaContainer>
                    <ToggleContainer>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.album.create.toggle.visibility.title",
                                })}
                            </Title>
                            <Description>
                                {formatMessage({
                                    id: "newDesign.album.create.toggle.visibility.desc",
                                })}
                            </Description>
                        </div>
                        <FormToggle name="hide" />
                    </ToggleContainer>
                    <ToggleContainer>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.album.create.toggle.hideHeader.title",
                                })}
                                <NewFeatureBudge src={newFeatureIcon} alt="" />
                            </Title>
                            {/*<Description>*/}
                            {/*    {formatMessage({id: 'newDesign.album.create.toggle.hideHeader.desc'})}*/}
                            {/*</Description>*/}
                        </div>
                        <div>
                            <PermissionsOverlay
                                isAllowed={permissions.HIDE_ALBUM_TITLE}
                            >
                                <FormToggle name="hideHeader" />
                            </PermissionsOverlay>
                        </div>
                    </ToggleContainer>
                    <MobileSubmitContainer>
                        <Button
                            type="submit"
                            fluid
                            content={formatMessage({
                                id: "newDesign.album.create.submit",
                            })}
                            disabled={isSubmitting}
                        />
                    </MobileSubmitContainer>
                </Semantic.Form>
            </Content>
        </Modal>
    );
};

AlbumCreate.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    pageId: PropTypes.number,
    mainNavigation: PropTypes.array,
    footerNavigation: PropTypes.array,
    redirectToAlbum: PropTypes.func,
    ...formProptypes,
};

AlbumCreate.defaultProps = {
    open: false,
    pageId: null,
    mainNavigation: [],
    footerNavigation: [],
    redirectToAlbum: () => null,
};

AlbumCreate.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default AlbumCreate;

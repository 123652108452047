import styled from 'styled-components';
import { Button } from 'packages/components/buttons';
import {media} from 'packages/components/media';

const TEXT_COLOR = '#343840';

export const SubmitButton = styled(Button)`
    width: 166px;
    height: 45px;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > span {
        font-weight: bold;
        font-size: 20px;
        color: ${TEXT_COLOR};
    }

    ${media.max.phone`
        ${SubmitButton} {
            display: none !important;
        }
    `}
`;

export const SocialInput = styled.div`
    position: relative;
    margin-top: 20px;

    > span {
        display: block;
        margin-bottom: 5px;
    }

    > div > div {
        > label {
            position: absolute;
            z-index: 1;
            padding: 16px 0 0 12px;
            color: #C1C3C7 !important;
        }

        > div {
            > div {
                width: 100%;

                input {
                    padding-left: ${({styles}) => styles?.paddingLeft ?? '138px'} !important;
                }
            }
        }
    }
`;

export const Content = styled.div`
    display: flex !important;
    justify-content: center;
    color: ${TEXT_COLOR};

    > form {
        width: 100%;
    }
`;

export const MobileSubmitContainer = styled.div`
    display: none;

    ${media.max.phone`
        display: block;
        margin-top: 20px;
    `}
`;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import get from 'lodash/get';
import {connect} from 'react-redux';

import {open as openErrorModal} from 'packages/redux/modules/modalYesNo/actions';
import {start as loaderStart, stop as loaderStop} from 'packages/redux/modules/pageLoader/actions';
import {MESSAGE_TYPE} from 'packages/enum';
import {lockModal} from 'packages/redux/modules/modalProgress/actions';

import textBlockUpdate from '@graphql/gql/website/textBlockUpdate.gql';
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql';
import previewPageGet from '@graphql/gql/website/previewPageGet.gql';
import blockUpdateWithPhoto from '@graphql/gql/website/blockUpdateWithPhoto.gql';

import TextEditor from '@configurator/components/textEditor/TextEditor';
import isEmpty from 'lodash/isEmpty';

export default compose(
    connect(
        // mapStateToProps
        state => ({}),
        // mapDispatchToProps
        dispatch => ({
            lockModalProgress: () => dispatch(lockModal(true)),
            unLockModalProgress: () => dispatch(lockModal(false)),
            openErrorModal: ({
                headerMessageId,
                yesMessageId,
                subMessageId,
            }) =>
                dispatch(openErrorModal({
                    headerMessageId,
                    yesMessageId,
                    subMessageId,
                })),
            loaderStart: () => dispatch(loaderStart()),
            loaderStop: () => dispatch(loaderStop()),
        }),
    ),
    graphql(previewPageGet, {
        options({pageUrl}) {
            return {
                variables: {
                    id: decodeURIComponent(pageUrl),
                },
            };
        },
        props({data: {loading, previewPageGet, refetch}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(previewPageGet)) {
                return {loading};
            }
            return {
                refetchPagePreview: refetch,
            };
        },
    }),
    graphql(textBlockUpdate, {
        props({mutate, ownProps: {openErrorModal, loaderStart, loaderStop, pageUrl}}) {
            return {
                async blockUpdate({blockId, text, rawText}) {
                    try {
                        loaderStart();
                        const res = await mutate({
                            variables: {
                                blockId, text, rawText,
                            },
                            refetchQueries: [
                                {
                                    query: websitePagesGet,
                                },
                                {
                                    variables: {
                                        id: decodeURIComponent(pageUrl),
                                    },
                                    query: previewPageGet,
                                },
                            ],
                        });
                        loaderStop();
                        if (!get(res, 'data.textBlockUpdate.success')) {
                            openErrorModal({
                                headerMessageId: 'error.header',
                                yesMessageId: 'OK',
                                subMessageId: get(res, 'data.textBlockUpdate.errors._error'),
                            });
                        } else {
                            window.frames['preview-frame']?.postMessage(
                                JSON.stringify({
                                    name: MESSAGE_TYPE.UPDATE_PAGE,
                                }),
                                '*'
                            );
                        }
                        return res;
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(blockUpdateWithPhoto, {
        props({mutate}) {
            return {
                async updateBlockWithPhoto(data) {
                    try {
                        return await mutate({
                            ...data
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
)(TextEditor);

import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {intlShape} from 'react-intl';
import {fieldProptypes} from './formikPropTypes';
import {ColorPicker} from '../colorPicker';

const ColorPickerComponent = (
    {
        field, // { name, value, onChange, onBlur }
        form: {setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid,
        label,
        ...props
    },
) => {
    const handleChange = (value) => {
        if (!props.disabled) {
            setFieldValue(field.name, value);
        }
    };
    return (
        <ColorPicker
            {...field}
            {...props}
            onColorChange={handleChange}
            value={field.value || props.value || undefined}
        />
    );
};

ColorPickerComponent.propTypes = {
    ...fieldProptypes,
};

ColorPickerComponent.defaultProps = {
};

ColorPickerComponent.contextTypes = {
    intl: intlShape,
};

export const FormColorPicker = ({name, ...props}) => (
    <Field name={name} {...props} component={ColorPickerComponent}/>
);

FormColorPicker.propTypes = {
    name: PropTypes.string.isRequired,
};

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const ShrinkIcon = ({ color }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.992 16H5V11H0V9H7L6.992 16ZM16 7H9L9.007 0H11V5H16V7Z"
            fill={iconColors[color]}
        />
    </svg>

);

ShrinkIcon.propTypes = {
    ...iconColorPropTypes,
};

ShrinkIcon.defaultProps = {
    color: 'dark',
};

import PropTypes from 'prop-types';

export const iconColors = {
    red: '#FF002E',
    green: '#58C367',
    dark: '#343840',
    gray: '#C1C3C7',
    darkGray: '#666A73',
    white: '#FFFFFF',
    default: '#C1C3C7',
    lightGray: '#C4C4C4'
};

export const iconColorPropTypes = {
    color: PropTypes.oneOf(Object.keys(iconColors)),
};

/* eslint-disable no-tabs */

const LIST_BASE_URL = 'https://www.googleapis.com/webfonts/v1/webfonts';

const PREVIEW_ATTRIBUTE_NAME = 'data-is-preview';

const FONT_BASE_URL = 'https://fonts.googleapis.com/css';

const FONT_FACE_REGEX = /@font-face {([\s\S]*?)}/gm;
const FONT_FAMILY_REGEX = /font-family: ['"](.*?)['"]/gm;

if (typeof document === 'undefined') {
    global.document = {
        createElement: () => null,
        head: {
            appendChild: () => null,
        },
    };
}

const previewFontsStylesheet = document.createElement('style');
document.head.appendChild(previewFontsStylesheet);

/**
 * Return the fontId based on the provided font family
 */
export const getFontId = (fontFamily) => {
    return fontFamily.replace(/\s+/g, '-')
        .toLowerCase();
};

const getData = async (url) => {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.overrideMimeType('application/json');
        request.open('GET', url, true);
        request.onreadystatechange = () => {
            // Request has completed
            if (request.readyState === 4) {
                if (request.status !== 200) {
                    // On error
                    reject(new Error(`Response has status code ${request.status}`));
                } else {
                    // On success
                    resolve(request.responseText);
                }
            }
        };
        request.send();
    });
};

export const getFontList = async () => {
    // Request list of all Google Fonts, sorted by popularity
    const url = new URL(LIST_BASE_URL);
    url.searchParams.append('sort', 'popularity');
    url.searchParams.append('key', __GOOGLE_API_KEY__);
    const response = await getData(url.href);

    // Parse font list
    const json = JSON.parse(response);

    // For each font:
    // - Rename "subset" key to "script"
    // - Generate fontId
    // Return the updated list
    const fontsOriginal = json.items;
    return fontsOriginal.map(
        (fontOriginal) => {
            const {family, subsets, ...others} = fontOriginal;
            return {
                ...others,
                family,
                id: getFontId(family),
                scripts: subsets,
            };
        },
    );
};

/**
 * Generate font stylesheet ID from fontId
 */
const getStylesheetId = (fontId) => {
    return `font-${fontId}`;
};

/**
 * Check whether a font stylesheet already exists in the document head
 */
export const stylesheetExists = (fontId, isPreview) => {
    const stylesheetNode = document.getElementById(getStylesheetId(fontId));
    if (isPreview === null || isPreview === undefined) {
        return stylesheetNode !== null;
    }
    return (
        stylesheetNode !== null &&
        stylesheetNode.getAttribute(PREVIEW_ATTRIBUTE_NAME) === isPreview.toString()
    );
};

/**
 * Attach a new font stylesheet to the document head using the provided content
 */
export const createStylesheet = (fontId, isPreview) => {
    const stylesheetNode = document.createElement('style');
    stylesheetNode.id = getStylesheetId(fontId);
    stylesheetNode.setAttribute(PREVIEW_ATTRIBUTE_NAME, isPreview.toString());
    document.head.appendChild(stylesheetNode);
};

export const getStylesheet = async (
    fonts,
    scripts,
    variants,
    previewsOnly,
) => {
    const url = new URL(FONT_BASE_URL);

    // Build query URL for specified font families and variants
    const variantsStr = variants.join(',');
    const familiesStr = fonts.map((font): string => `${font.family}:${variantsStr}`);

    url.searchParams.append('family', familiesStr.join('|'));

    // Query the fonts in the specified scripts
    url.searchParams.append('subset', scripts.join(','));

    // If previewsOnly: Only query the characters contained in the font names
    if (previewsOnly) {
        // Concatenate the family names of all fonts
        const familyNamesConcat = fonts.map((font): string => font.family)
            .join('');
        // Create a string with all characters (listed once) contained in the font family names
        const downloadChars = familyNamesConcat
            .split('')
            .filter((char, pos, self): boolean => self.indexOf(char) === pos)
            .join('');
        // Query only the identified characters
        url.searchParams.append('text', downloadChars);
    }

    // Tell browser to render fallback font immediately and swap in the new font once it's loaded
    url.searchParams.append('font-display', 'swap');

    // Fetch and return stylesheet
    return getData(url.href);
};

/**
 * Execute the provided regex on the string and return all matched groups
 */
export const getMatches = (regex, str) => {
    const matches: string[] = [];
    let match;
    do {
        match = regex.exec(str);
        if (match) {
            matches.push(match[1]);
        }
    } while (match);
    return matches;
};

/**
 * Parse the list of @font-face rules provided by the Google Fonts API. Split up the rules
 * according to the font family and return them in an object
 */
export const extractFontStyles = (allFontStyles) => {
    // Run Regex to separate font-face rules
    const rules = getMatches(FONT_FACE_REGEX, allFontStyles);

    // Assign font-face rules to fontIds
    const fontStyles = {};
    rules.forEach((rule): void => {
        // Run regex to get font family
        const fontFamily = getMatches(FONT_FAMILY_REGEX, rule)[0];
        const fontId = getFontId(fontFamily);

        // Append rule to font font family's other rules
        if (!(fontId in fontStyles)) {
            fontStyles[fontId] = '';
        }
        fontStyles[fontId] += `@font-face {\n${rule}\n}\n\n`;
    });
    return fontStyles;
};

/**
 * Add declaration for applying the specified preview font
 */
export const applyFontPreview = (previewFont, selectorSuffix) => {
    const fontId = getFontId(previewFont.family);
    const style = `
			#font-button-${fontId}${selectorSuffix} {
				font-family: "${previewFont.family}";
			}
		`;
    previewFontsStylesheet.appendChild(document.createTextNode(style));
};

/**
 * Insert the provided styles in the font's <style> element (existing styles are replaced)
 */
export const fillStylesheet = (fontId, styles) => {
    const stylesheetId = getStylesheetId(fontId);
    const stylesheetNode = document.getElementById(stylesheetId);
    if (stylesheetNode) {
        stylesheetNode.textContent = styles;
    } else {
        console.error(`Could not fill stylesheet: Stylesheet with ID "${stylesheetId}" not found`);
    }
};

/**
 * Get the Google Fonts stylesheet for the specified font (in the specified scripts and variants,
 * only the characters needed for creating the font previews), add the necessary CSS declarations to
 * apply them and add the fonts' stylesheets to the document head
 */
export const loadFontPreviews = async (
    fonts,
    scripts,
    variants,
    selectorSuffix,
) => {
    const fontsToFetch = fonts.map((font) => font.id)
        .filter((fontId): boolean => !stylesheetExists(fontId));

    // Create stylesheet elements for all fonts which will be fetched (this prevents other font
    // pickers from loading the fonts as well)
    fontsToFetch.forEach((fontId): void => createStylesheet(fontId, true));

    // Get Google Fonts stylesheet containing all requested styles
    const response = await getStylesheet(fonts, scripts, variants, true);
    // Parse response and assign styles to the corresponding font
    const fontStyles = extractFontStyles(response);

    // Create separate stylesheets for the fonts
    fonts.forEach((font): void => {
        applyFontPreview(font, selectorSuffix);

        // Add stylesheets for fonts which need to be downloaded
        if (fontsToFetch.includes(font.id)) {
            // Make sure response contains styles for the font
            if (font.id in fontStyles) {
                // Insert styles into the stylesheet element which was created earlier
                fillStylesheet(font.id, fontStyles[font.id]);
            }
        }
    });
};

/**
 * Create/find and return the apply-font stylesheet for the provided selectorSuffix
 */
const getActiveFontStylesheet = (selectorSuffix) => {
    const stylesheetId = `active-font-${selectorSuffix}`;
    let activeFontStylesheet = document.getElementById(stylesheetId);
    if (!activeFontStylesheet) {
        activeFontStylesheet = document.createElement('style');
        activeFontStylesheet.id = stylesheetId;
        document.head.appendChild(activeFontStylesheet);
    }
    return activeFontStylesheet;
};

/**
 * Add/update declaration for applying the current active font
 */
export const applyActiveFont = (
    activeFont,
    previousFontFamily,
    selectorSuffix,
) => {
    const style = `
		.apply-font${selectorSuffix} {
			font-family: "${activeFont.family}"${previousFontFamily ? `, "${previousFontFamily}"` : ''};
		}
	`;
    const activeFontStylesheet = getActiveFontStylesheet(selectorSuffix);
    activeFontStylesheet.innerHTML = style;
};

/**
 * Update the value of a stylesheet's "data-is-preview" attribute
 */
export const setStylesheetType = (fontId, isPreview) => {
    const stylesheetId = getStylesheetId(fontId);
    const stylesheetNode = document.getElementById(stylesheetId);
    if (stylesheetNode) {
        stylesheetNode.setAttribute(PREVIEW_ATTRIBUTE_NAME, isPreview.toString());
    } else {
        console.error(
            `Could not change stylesheet type: Stylesheet with ID "${stylesheetId}" not found`,
        );
    }
};

/**
 * Get the Google Fonts stylesheet for the specified font (in the specified scripts and variants),
 * add the necessary CSS declarations to apply it and add the font's stylesheet to the document head
 */
export const loadActiveFont = async (
    font,
    previousFontFamily,
    scripts,
    variants,
    selectorSuffix,
) => {
    // Only load font if it doesn't have a stylesheet yet
    if (stylesheetExists(font.id, false)) {
        // Add CSS declaration to apply the new active font
        applyActiveFont(font, previousFontFamily, selectorSuffix);
    } else {
        if (stylesheetExists(font.id, true)) {
            // Update the stylesheet's "data-is-preview" attribute to "false"
            setStylesheetType(font.id, false);
        } else {
            // Create stylesheet for the font to be fetched (this prevents other font pickers from loading
            // the font as well)
            createStylesheet(font.id, false);
        }

        // Get Google Fonts stylesheet containing all requested styles
        const fontStyle = await getStylesheet([font], scripts, variants, false);

        // Add CSS declaration to apply the new active font
        applyActiveFont(font, previousFontFamily, selectorSuffix);

        // Insert styles into the stylesheet element which was created earlier
        fillStylesheet(font.id, fontStyle);
    }
};

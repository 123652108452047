import styled from "styled-components";

export const Container = styled.div`
    max-width: 1200px;
    margin: 30px auto;
`;

export const Header = styled.div`
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    /* Dark */
    color: #343840;
    margin-bottom: 104px;
`;

export const Stack = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Item = styled.div`
    position: relative;
    height: 250px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: #ffffff;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: 280px;

    padding: 57px 33px 25px 33px;

    button {
        width: 100%;
    }
`;
export const Badge = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;

    > div {
        padding: 6px 20px;
        background: #58c367;

        font-family: Sailec;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 140%;
        /* or 18px */

        text-align: center;

        color: #ffffff;
    }
`;

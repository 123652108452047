import styled from 'styled-components';
import {Dropdown} from 'semantic-ui-react';

export const StyledInputContainer = styled.div`

`;

export const StyledLabel = styled.label`
    font-family: Sailec, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #343840;

    display: block;
    margin-bottom: 5px;
`;

export const StyledDropdown = styled(Dropdown)`

  height: 45px !important;
  background: #FFFFFF !important;
  border-radius: 2px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #EDEDED !important;
  box-sizing: border-box !important;
  padding: 12px 14px !important;
  box-shadow: none !important;

  color: #343840 !important;
  font-size: 13px !important;
  font-family: Sailec, sans-serif !important;
  line-height: 140% !important;

  & > input {
    line-height: 140% !important;
    color: #343840 !important;
    font-size: 13px !important;
    font-family: Sailec, sans-serif !important;
    height: 100% !important;
  }

  & > .text {
    line-height: 140% !important;
    color: #343840 !important;
    font-size: 13px !important;
    font-family: Sailec, sans-serif !important;
    height: 100% !important;
  }

  &:focus {
    & i {
      opacity: 1 !important;
    }
  }

  & i {
    font-family: 'Dropdown' !important;
    font-size: 12px !important;
    right: 13px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0.5 !important;
  }

  &.ui.upward.dropdown > div.menu {
    top: auto !important;
    bottom: 110% !important;
  }

  &.ui.dropdown > div.menu {
    top: 110% !important;
    border: none !important;
    border-radius: 2px !important;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1) !important;
    & > div.item {
      color: #343840 !important;
      font-size: 13px !important;
      font-family: Sailec, sans-serif !important;
      line-height: 140%;
      padding: 12px 14px !important;
      border: none !important;
      &.active {
        font-weight: normal !important;
      }
      &.active, &:hover {
        background: #F6F6F6 !important;
      }
    }
  }
`;

import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Semantic } from "packages/components";
import { Modal } from "packages/components/modal";
import {
    FormInput,
    FormRadio,
    FormToggle,
    FormDropdown,
    FormTextArea,
    FormInputPassword,
} from "packages/components/inputs";
import { Button } from "packages/components/buttons";
import { ImageIcon, TrashIcon } from "packages/components/icons/basic";
import { formProptypes } from "packages/utils/formikPropTypes";
import Message from "packages/components/message/Message";
import Dropzone from "react-dropzone";
import { MAX_IMAGE_SIZE } from "@configurator/constants/upload";
import { FileUploadIcon } from "packages/components/icons/file";
import ModalConfirmDelete from "packages/components/modalConfirmDelete";
import AlignCheckbox from "packages/components/alignCheckbox/alignCheckbox";
import { getDomain } from "packages/helpers/Helper";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import {
    WEBSITE_BLOCK,
    BLOCK_PROPERTY_KEYS,
    WEBSITE_PAGE_TYPES,
    CONTACT_FORM_ALIGN,
    CONTACT_FORM_LOCALE,
    WEBSITE_PAGE_BLOCK_TYPES,
    URLS,
} from "packages/enum";
import { NewFeatureBudge } from "packages/components/newFeatureBudge/nodes";
import {
    Header,
    Content,
    StyledInput,
    Title,
    Description,
    DeleteItem,
    MobileSubmitContainer,
} from "@configurator/components/modals/nodes";
import {
    StyledRadio,
    EnablePage,
    Divider,
    DisplayMode,
    DisplayModeOptions,
    DisplayModeOptionTab,
    EntireZoomOption,
    ExpandedPreview,
    StackedPreview,
    LandingPage,
    HidePage,
    Password,
    StyledInputCode,
    LinkComponent,
} from "@configurator/components/modals/pageCreate/nodes";
import {
    StyledDropdown,
    CenteredDropModal,
    UploadPicture,
    PreviewBlock,
    PreviewContainer,
    PreviewWrapper,
    StyledTextareaContainer,
    AlignContainer,
    UploadWrapper,
    StyledSlugPreview,
    StyledSlug
} from "./nodes";

import newFeatureIcon from "src/packages/theme-ab/resources/assets/configurator/new_feature.svg";
import albumExpanded1 from "src/packages/theme-ab/resources/assets/configurator/album_expanded_1.svg";
import albumExpanded2 from "src/packages/theme-ab/resources/assets/configurator/album_expanded_2.svg";
import albumExpanded3 from "src/packages/theme-ab/resources/assets/configurator/album_expanded_3.svg";
import albumExpanded4 from "src/packages/theme-ab/resources/assets/configurator/album_expanded_4.svg";
import albumStacked from "src/packages/theme-ab/resources/assets/configurator/album_stacked.svg";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import { useSubscription } from "@configurator/providers/subscription";
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

const languageDropdownOptions = [
    {
        key: CONTACT_FORM_LOCALE.de,
        value: CONTACT_FORM_LOCALE.de,
        text: "Deutsch",
    },
    {
        key: CONTACT_FORM_LOCALE.en,
        value: CONTACT_FORM_LOCALE.en,
        text: "English",
    },
    {
        key: CONTACT_FORM_LOCALE.fr,
        value: CONTACT_FORM_LOCALE.fr,
        text: "French",
    },
    {
        key: CONTACT_FORM_LOCALE.ja,
        value: CONTACT_FORM_LOCALE.ja,
        text: "Japanese",
    },
    {
        key: CONTACT_FORM_LOCALE.ru,
        value: CONTACT_FORM_LOCALE.ru,
        text: "Russian",
    },
    {
        key: CONTACT_FORM_LOCALE.sp,
        value: CONTACT_FORM_LOCALE.sp,
        text: "Spanish",
    },
];

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const PageUpdate = (
    {
        open,
        onClose,
        handleSubmit,
        errors,
        status,
        isSubmitting,
        loading,
        values,
        setFieldValue,
        deleteBlockProperty,
        refetchData,
        refetchPagePreview,
        meta,
        onDelete,
        initialValues,
        openErrorModal,
        closeErrorModal,
        slideshowEnabled,
        url,
    },
    { intl: { formatMessage } }
) => {
    const prevValues = usePrevious(values);
    const [showModalDeleteImage, setShowModalDeleteImage] = useState(false);
    const [isFileReady, setIsFileReady] = useState(false);
    const [preview, setPreview] = useState();
    const [isLinkPage, setIsLinkPage] = useState(false);
    const [isHTMLPage, setIsHTMLPage] = useState(false);
    const [dropModalOpen, setDropModalOpen] = useState(false);
    const { permissions } = useSubscription();

    useEffect(() => {
        if (
            values &&
            get(values, "blocks[0].type") === WEBSITE_PAGE_TYPES.EXTERNAL_LINK
        ) {
            setIsLinkPage(true);
        }
        if (
            values &&
            get(values, "blocks[0].type") === WEBSITE_PAGE_TYPES.HTML
        ) {
            setIsHTMLPage(true);
        }
    }, [values]);

    const error = Object.keys(errors).length || (status && !!status.error);

    const contactBlock = useMemo(() => {
        return (values.blocks || []).find(
            (el) => el.type === WEBSITE_BLOCK.CONTACTS
        );
    }, [values.blocks]);

    useEffect(() => {
        if (prevValues && prevValues.id !== values.id) {
            if (contactBlock) {
                const userPhotoText = (contactBlock.blockProperties || []).find(
                    (el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoText
                );
                if (userPhotoText && !values.userPhotoText) {
                    setFieldValue("userPhotoText", userPhotoText.value);
                }
                const align = (contactBlock.blockProperties || []).find(
                    (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormAlign
                );
                if (align && align.value) {
                    setFieldValue("align", align.value);
                }
                const language = (contactBlock.blockProperties || []).find(
                    (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormLang
                );
                if (language && language.value) {
                    setFieldValue("language", language.value);
                }
            }
            setFieldValue("stacked", !values.expanded);
            setFieldValue("album", !values.zoom);
            setFieldValue("hideHeader", !values.showHeader);
        }
        if (prevValues && prevValues.mainNavigation !== values.mainNavigation) {
            setFieldValue("footerNavigation", !values.mainNavigation);
        }
        if (
            prevValues &&
            prevValues.footerNavigation !== values.footerNavigation
        ) {
            setFieldValue("mainNavigation", !values.footerNavigation);
        }
        if (prevValues && prevValues.stacked !== values.stacked) {
            setFieldValue("expanded", !values.stacked);
            if (values.stacked) {
                setFieldValue("zoom", false);
            }
        }
        if (prevValues && prevValues.expanded !== values.expanded) {
            setFieldValue("stacked", !values.expanded);
            if (!values.expanded) {
                setFieldValue("zoom", false);
            }
        }
        if (prevValues && prevValues.album !== values.album) {
            setFieldValue("zoom", !values.album);
        }
        if (prevValues && prevValues.zoom !== values.zoom) {
            setFieldValue("album", !values.zoom);
        }
    }, [contactBlock, prevValues, setFieldValue, values]);

    const showExpandedOptions = useMemo(() => {
        return (
            (values.blocks || []).find(
                (el) => el.type === WEBSITE_BLOCK.ALBUM_LIST
            ) && get(meta, "canExpand")
        );
    }, [values.blocks, meta]);

    const handleDropFile = (files) => {
        if (files && files.length) {
            setIsFileReady(true);
            const file = files[0];
            setPreview({
                index: 0,
                file,
                name: file.name,
                preview: window.URL.createObjectURL(file),
            });
            setFieldValue("file", file);
            setDropModalOpen(false);
        }
    };

    const onDropRejected = (files, accept) => {
        if (files.find((el) => el.size > MAX_IMAGE_SIZE)) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileSize.description",
                subMessageValues: { size: MAX_IMAGE_SIZE / 1024 / 1024 },
                hideCancelButton: true,
            });
        }
        const acceptArr = accept.split(", ");
        if (files.find((el) => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileType.description",
                subMessageValues: { types: accept.replace(/image\//g, "") },
                hideCancelButton: true,
            });
        }
    };

    const handleDeletePreview = () => {
        setFieldValue("file", undefined);
        setPreview();
        setIsFileReady(false);
    };

    const dropModalStyle = {
        height: "540px",
        width: "670px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
    };

    const renderDropModal = () => {
        const accept = "image/jpeg, image/gif, image/png";
        return (
            <CenteredDropModal
                open={dropModalOpen}
                onClose={() => setDropModalOpen(false)}
                styles={dropModalStyle}
                centered
            >
                <UploadWrapper>
                    <Dropzone
                        accept={accept}
                        onDrop={handleDropFile}
                        multiple={false}
                        maxSize={MAX_IMAGE_SIZE}
                        onDropRejected={(files) =>
                            onDropRejected(files, accept)
                        }
                    >
                        {({ getInputProps, getRootProps }) => (
                            <div {...getRootProps()} className="input">
                                <input {...getInputProps()} />
                                <FileUploadIcon color="lightGray" />
                                <span className="dropzone-header">
                                    {formatMessage({
                                        id: "upload.video.text.title",
                                    })}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: formatMessage({
                                                id: "upload.video.text.browse",
                                            }),
                                        }}
                                    />
                                </span>
                                <span
                                    className="dropzone-footer"
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({
                                            id: "upload.video.text.info",
                                        }),
                                    }}
                                />
                            </div>
                        )}
                    </Dropzone>
                </UploadWrapper>
            </CenteredDropModal>
        );
    };

    const renderContactOptionsBlock = () => {
        if (contactBlock) {
            const photoBlockProperty = (
                contactBlock.blockProperties || []
            ).find((el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoMediaUrl);
            return (
                <>
                    <PermissionsOverlay
                        isAllowed={permissions.MULTILANG_CONTACT}
                    >
                        <StyledDropdown>
                            <FormDropdown
                                name="language"
                                label="newDesign.page.update.field.language"
                                options={languageDropdownOptions}
                            />
                        </StyledDropdown>
                    </PermissionsOverlay>
                    <StyledTextareaContainer>
                        <AlignContainer>
                            {formatMessage({
                                id: "page.update.field.textAlign",
                            })}
                            <AlignCheckbox
                                name="left"
                                alignment="left"
                                active={
                                    values.align === CONTACT_FORM_ALIGN.LEFT
                                }
                            />
                            <AlignCheckbox
                                name="center"
                                alignment="center"
                                active={
                                    values.align === CONTACT_FORM_ALIGN.CENTER
                                }
                            />
                            <AlignCheckbox
                                name="right"
                                alignment="right"
                                active={
                                    values.align === CONTACT_FORM_ALIGN.RIGHT
                                }
                            />
                        </AlignContainer>
                        <FormTextArea
                            label="newDesign.page.update.field.additionalText"
                            name="userPhotoText"
                        />
                    </StyledTextareaContainer>
                    <UploadPicture
                        hideButton={isFileReady || photoBlockProperty}
                    >
                        <Title>
                            {formatMessage({
                                id: "newDesign.page.update.field.selectPhoto",
                            })}
                        </Title>
                        <Button
                            type="button"
                            disabled={isSubmitting}
                            view="secondaryBlack"
                            icon={<ImageIcon />}
                            onClick={() => setDropModalOpen(true)}
                        >
                            {formatMessage({
                                id: "newDesign.page.update.button.uploadImage",
                            })}
                        </Button>
                    </UploadPicture>
                    {(isFileReady || photoBlockProperty) &&
                        renderPreview(
                            photoBlockProperty
                                ? photoBlockProperty.value
                                : undefined
                        )}
                    <Divider />
                </>
            );
        }
        return null;
    };

    const renderPreview = (src) => {
        const fileSrc = isFileReady ? get(preview, "preview") : getDomain(src);
        return (
            <PreviewBlock>
                <PreviewContainer>
                    <PreviewWrapper>
                        <img alt="" src={fileSrc} />
                    </PreviewWrapper>
                </PreviewContainer>
                <div>
                    <span>
                        {isFileReady ? `/..${get(preview, "file.name")}` : ""}
                    </span>
                    <div>
                        <span onClick={() => setDropModalOpen(true)}>
                            {formatMessage({
                                id: "newDesign.page.update.button.change",
                            })}
                        </span>
                        <span
                            onClick={() =>
                                isFileReady
                                    ? handleDeletePreview()
                                    : setShowModalDeleteImage(true)
                            }
                        >
                            <TrashIcon />
                        </span>
                    </div>
                </div>
            </PreviewBlock>
        );
    };

    const getHeaderTitle = useMemo(() => {
        switch (get(values, "blocks[0].type")) {
            case WEBSITE_PAGE_TYPES.EXTERNAL_LINK:
                return "newDesign.page.update.header.url";
            case WEBSITE_PAGE_TYPES.CONTACTS:
                return "newDesign.page.update.header.contact";
            case WEBSITE_PAGE_TYPES.TEXT:
                return "newDesign.page.update.header.text";
            case WEBSITE_PAGE_TYPES.HTML:
                return "newDesign.page.update.header.html";
            default:
                return "newDesign.page.update.header.photo";
        }
    }, [values]);

    const modalStyle = {
        width: "580px",
        height: "initial",
        paddingTop: "24px",
    };

    const handleClose = () => {
        if (!isEqual(values, initialValues)) {
            openErrorModal({
                subMessageId: "discardChanges.subMessage",
                yesMessageId: "discardChanges.yesMessage",
                hideHeader: true,
                onClickYes: () => {
                    onClose();
                    closeErrorModal();
                },
                styles: { width: "450px" },
            });
        } else {
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={handleClose} styles={modalStyle} mobileWide>
            <Content>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting || loading}
                    onSubmit={handleSubmit}
                >
                    <Header>
                        <span>{formatMessage({ id: getHeaderTitle })}</span>
                        <Button
                            type="submit"
                            content={formatMessage({
                                id: "newDesign.page.update.submit",
                            })}
                            fluid
                            green
                            disabled={isSubmitting}
                        />
                    </Header>
                    <Message
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <StyledInput>
                        <FormInput
                            name="title"
                            type="text"
                            label="newDesign.page.update.field.title"
                        />
                    </StyledInput>
                    <StyledInput>
                        <FormInput
                            name="url"
                            type="text"
                            label="newDesign.page.update.field.slug"
                        />
                        <StyledSlugPreview>
                            {meta.domain && `https://${meta.premiumDomain || `${meta.domain}.vsble.me`}/`}
                            <StyledSlug>{values.url}</StyledSlug>
                        </StyledSlugPreview>
                    </StyledInput>
                    <StyledInput hide={!isLinkPage}>
                        <FormInput
                            validate={(value) =>
                                isLinkPage && !value && "page.field.error.url"
                            }
                            name="url"
                            type="text"
                            label="newDesign.page.update.field.linkUrl"
                        />
                    </StyledInput>
                    {values.blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID ? (
                        <LinkComponent
                            target={"_blank"}
                            href={`${URLS.website_integrations}/ecwid`}
                        >
                            {formatMessage({
                                id: "newDesign.page.create.ecwid.link",
                            })}
                        </LinkComponent>
                    ) : null}
                    <StyledInputCode hide={!isHTMLPage}>
                        <FormTextArea
                            name="content"
                            type="text"
                            label={
                                values.blockType ===
                                WEBSITE_PAGE_BLOCK_TYPES.ECWID
                                    ? formatMessage({
                                          id: "newDesign.page.create.ecwid.page.code",
                                      })
                                    : formatMessage({
                                          id: "newDesign.page.create.page.code",
                                      })
                            }
                        />
                    </StyledInputCode>
                    {renderContactOptionsBlock()}
                    {/*<PagePosition>*/}
                    {/*    <Title>*/}
                    {/*        {formatMessage({id: 'newDesign.page.update.field.pagePosition'})}*/}
                    {/*    </Title>*/}
                    {/*    <StyledRadio>*/}
                    {/*        <FormRadio*/}
                    {/*            name="mainNavigation"*/}
                    {/*            value*/}
                    {/*        />*/}
                    {/*        <span>*/}
                    {/*            {formatMessage({id: 'newDesign.page.update.field.mainNavigation'})}*/}
                    {/*        </span>*/}
                    {/*    </StyledRadio>*/}
                    {/*    <StyledRadio>*/}
                    {/*        <FormRadio*/}
                    {/*            name="footerNavigation"*/}
                    {/*            value*/}
                    {/*        />*/}
                    {/*        <span>*/}
                    {/*            {formatMessage({id: 'newDesign.page.update.field.footerNavigation'})}*/}
                    {/*        </span>*/}
                    {/*    </StyledRadio>*/}
                    {/*</PagePosition>*/}
                    <EnablePage hide={isLinkPage}>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.page.update.field.enablePage.title",
                                })}
                            </Title>
                            <Description>
                                {formatMessage({
                                    id: "newDesign.page.update.field.enablePage.desc",
                                })}
                            </Description>
                        </div>
                        <FormToggle name="visible" />
                    </EnablePage>
                    <DisplayMode hide={!showExpandedOptions}>
                        <Divider />
                        <Title bold>
                            {formatMessage({
                                id: "newDesign.page.update.field.displayMode.title",
                            })}
                        </Title>
                        <DisplayModeOptions>
                            <DisplayModeOptionTab
                                active={values.stacked}
                                onClick={() => {
                                    USERLIST_SERVICE.trackExpandedModeUsed()
                                    return setFieldValue("stacked", true)
                                }}
                            >
                                {formatMessage({
                                    id: "newDesign.page.update.field.displayMode.stacked",
                                })}
                            </DisplayModeOptionTab>
                            <DisplayModeOptionTab
                                active={values.expanded}
                                onClick={() => {
                                    USERLIST_SERVICE.trackExpandedModeUsed()
                                    return setFieldValue("expanded", true)
                                }}
                            >
                                {formatMessage({
                                    id: "newDesign.page.update.field.displayMode.expanded",
                                })}
                            </DisplayModeOptionTab>
                        </DisplayModeOptions>
                        <StackedPreview hide={!values.stacked}>
                            <Description>
                                {formatMessage({
                                    id: "newDesign.page.update.field.displayMode.desc",
                                })}
                            </Description>
                            <div>
                                <img src={albumStacked} alt="" />
                                <span>
                                    {formatMessage({
                                        id: "newDesign.page.update.field.displayMode.albumTitle",
                                    })}
                                </span>
                            </div>
                        </StackedPreview>
                        {values.expanded && (
                            <>
                                <EntireZoomOption>
                                    <StyledRadio>
                                        <FormRadio name="album" value />
                                        <span>
                                            {formatMessage({
                                                id: "newDesign.page.update.field.displayMode.album",
                                            })}
                                        </span>
                                    </StyledRadio>
                                    <StyledRadio>
                                        <FormRadio name="zoom" value />
                                        <span>
                                            {formatMessage({
                                                id: "newDesign.page.update.field.displayMode.zoom",
                                            })}
                                        </span>
                                    </StyledRadio>
                                </EntireZoomOption>
                                <ExpandedPreview>
                                    <img src={albumExpanded1} alt="" />
                                    <img src={albumExpanded2} alt="" />
                                    <img src={albumExpanded3} alt="" />
                                    <img src={albumExpanded4} alt="" />
                                    <span>
                                        {formatMessage({
                                            id: "newDesign.page.update.field.displayMode.albumTitle",
                                        })}
                                    </span>
                                </ExpandedPreview>
                            </>
                        )}
                        <Divider />
                    </DisplayMode>
                    <LandingPage hide={slideshowEnabled}>
                        <Title>
                            {formatMessage({
                                id: "newDesign.page.update.field.landingPage",
                            })}
                        </Title>
                        <FormToggle name="startPage" />
                    </LandingPage>
                    <HidePage>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.page.update.field.hidePage.title",
                                })}
                                <NewFeatureBudge src={newFeatureIcon} alt="" />
                            </Title>
                            <Description>
                                {formatMessage({
                                    id: "newDesign.page.update.field.hidePage.desc",
                                })}
                            </Description>
                        </div>
                        <div>
                            <PermissionsOverlay
                                isAllowed={permissions.HIDE_PAGE_TITLE}
                            >
                                <FormToggle name="hideHeader" />
                            </PermissionsOverlay>
                        </div>
                    </HidePage>
                    <Password hide={isLinkPage}>
                        <div>
                            <Title>
                                {formatMessage({
                                    id: "newDesign.page.update.field.password.title",
                                })}
                                <NewFeatureBudge src={newFeatureIcon} alt="" />
                            </Title>
                            <Description>
                                {formatMessage({
                                    id: "newDesign.page.update.field.password.desc",
                                })}
                            </Description>
                        </div>
                        <PermissionsOverlay
                            isAllowed={permissions.PASSWORD_PROTECTION}
                        >
                            <FormInputPassword name="password" type="text" />
                        </PermissionsOverlay>
                    </Password>
                    <MobileSubmitContainer>
                        <Button
                            type="submit"
                            content={formatMessage({
                                id: "newDesign.page.update.submit",
                            })}
                            fluid
                            green
                            disabled={isSubmitting}
                        />
                    </MobileSubmitContainer>
                    <DeleteItem onClick={onDelete}>
                        {formatMessage({
                            id: "newDesign.page.update.button.deletePage",
                        })}
                    </DeleteItem>
                </Semantic.Form>
            </Content>
            {renderDropModal()}
            <ModalConfirmDelete
                open={showModalDeleteImage}
                onClickYes={() => {
                    const userPhotoProperty =
                        (contactBlock.blockProperties || []).find(
                            (el) => el.key === BLOCK_PROPERTY_KEYS.userPhoto
                        ) || {};
                    deleteBlockProperty({ propertyId: userPhotoProperty.id })
                        .then(refetchData)
                        .then(refetchPagePreview);
                    setShowModalDeleteImage(false);
                }}
                onClickCancel={() => setShowModalDeleteImage(false)}
                onClose={() => setShowModalDeleteImage(false)}
            />
        </Modal>
    );
};

PageUpdate.propTypes = {
    url: PropTypes.string,
    pageId: PropTypes.string.isRequired, // eslint-disable-line
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    deleteBlockProperty: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    refetchPagePreview: PropTypes.func,
    openErrorModal: PropTypes.func,
    closeErrorModal: PropTypes.func,
    meta: PropTypes.shape({
        canExpand: PropTypes.bool,
    }),
    slideshowEnabled: PropTypes.bool,
    ...formProptypes,
};

PageUpdate.defaultProps = {
    onDelete: () => {},
    open: false,
    refetchPagePreview: () => null,
    openErrorModal: () => null,
    closeErrorModal: () => null,
    meta: {
        canExpand: undefined,
    },
    slideshowEnabled: false,
};

PageUpdate.contextTypes = {
    intl: PropTypes.object.isRequired,
};

export default PageUpdate;

import React from 'react';
import {loaderDefaultProps, loaderPropTypes} from '../../const';

import {Container} from './nodes';

export const RingDual = ({color, width, height}) => (
    <Container color={color} width={width} height={height} />
);

RingDual.propTypes = loaderPropTypes;

RingDual.defaultProps = loaderDefaultProps;

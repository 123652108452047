import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

import {formProptypes} from 'packages/utils/formikPropTypes';
import {usePrevious} from 'packages/helpers/usePrevious';

import {getFontList, getFontId, loadFontPreviews, loadActiveFont} from './utils';
import {Select, Pagination, PaginationItem} from './nodes';

export const FormFontPicker = ({
    activeFontFamily: defaultActiveFontFamily,
    onChange,
    pickerId,
    families,
    categories,
    scripts,
    variants,
    filter,
    limit,
    sort,
    placeholder,
    type,
    name,
    intl: {formatMessage},
}) => {

    const [loading, setLoading] = useState(false);
    const [googleFonts, setGoogleFonts] = useState([]);
    const [loadedFonts, setLoadedFonts] = useState([]);
    const [visibleFonts, setVisibleFonts] = useState({
        page: 1,
        pageCount: 1,
        visible: [],
        all: [],
    });
    const [activeFontFamily, setActiveFontFamily] = useState(defaultActiveFontFamily);
    const prevActiveFontFamily = usePrevious(activeFontFamily);

    const selectorSuffix = pickerId ? `-${pickerId}` : '';


    const loadFonts = useCallback((fonts) => {
        const fontsToLoad = fonts.filter(({family}) => family &&
            !loadedFonts.find(el => el.family === family));
        if (fontsToLoad.length) {
            loadFontPreviews(fontsToLoad, scripts, variants, selectorSuffix)
                .then(() => setLoadedFonts(loadedFonts.concat(fontsToLoad)));
        }
    }, [loadedFonts, scripts, selectorSuffix, variants]);

    const setFonts = useCallback((fonts, page = 1) => {
        const visible = fonts.slice((page - 1) * limit, page * limit);
        setVisibleFonts({
            all: fonts,
            page: 1,
            pageCount: Math.floor(fonts.length / limit) + (fonts.length % limit > 0 ? 1 : 0),
            visible,
        });
        loadFonts(visible);
    }, [limit, loadFonts]);

    const filterFonts = useCallback((fonts) => fonts.filter(font =>
        // `families` parameter: Only keep fonts whose names are included in the provided array
        (families.length === 0 || families.includes(font.family)) &&
        // `categories` parameter: only keep fonts in categories from the provided array
        (categories.length === 0 || categories.includes(font.category)) &&
        // `scripts` parameter: Only keep fonts which are available in all specified scripts
        scripts.every((script) => font.scripts.includes(script)) &&
        // `variants` parameter: Only keep fonts which contain all specified variants
        variants.every((variant) => font.variants.includes(variant)) &&
        // `filter` parameter: Only keep fonts for which the `filter` function evaluates to `true`
        filter(font)), [categories, families, filter, scripts, variants]);

    useEffect(() => {
        setLoading(true);
        getFontList()
            .then(fonts => {
                let filteredFonts = filterFonts(fonts);
                if (sort === 'alphabet') {
                    filteredFonts = fonts.sort((font1, font2) => font1.family.localeCompare(font2.family));
                }
                setGoogleFonts(filteredFonts);
                setFonts(fonts);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error('Error trying to fetch the list of available fonts');
                console.error(err);
            });
    }, [filterFonts, setFonts, sort]);

    const setActiveFont = useCallback((fontFamily) => {
        const activeFont = googleFonts.find(({family}) => fontFamily === family);

        if (activeFont) {
            loadActiveFont(
                activeFont,
                prevActiveFontFamily,
                scripts,
                variants,
                selectorSuffix,
            )
                .then(() => onChange(activeFont));
        }
    }, [googleFonts, onChange, prevActiveFontFamily, scripts, selectorSuffix, variants]);

    useEffect(() => {
        setActiveFontFamily(defaultActiveFontFamily);
    }, [defaultActiveFontFamily]);

    const getActiveFont = useCallback(() => googleFonts.find(({family}) => activeFontFamily === family), [activeFontFamily, googleFonts]);

    const changeVisiblePage = useCallback((page) => {
        const visible = visibleFonts.all.slice((page - 1) * limit, page * limit);

        setVisibleFonts({
            ...visibleFonts,
            page,
            visible,
        });
        loadFonts(visible);
    }, [limit, loadFonts, visibleFonts]);

    const onSelection = useCallback(({target}) => {
        const fontFamily = target.textContent;
        if (!fontFamily) {
            throw Error('Missing font family in clicked font button');
        }
        setActiveFontFamily(fontFamily);
        setActiveFont(fontFamily);
    }, [setActiveFont]);

    const generateFontList = useCallback(() => {
        const {page, pageCount, visible} = visibleFonts;
        const activeFont = getActiveFont() || {};
        let options = [{
            key: `${activeFont.family}-default`,
            value: activeFont.family,
            text: activeFont.family,
        }];
        const pagination = (
            <Pagination show={pageCount > 1}>
                {page > 1 &&
                <PaginationItem onClick={() => changeVisiblePage(page - 1)}>
                    {formatMessage({id: 'fonts.update.page.previous'})}
                </PaginationItem>}
                {page < pageCount &&
                <PaginationItem onClick={() => changeVisiblePage(page + 1)}>
                    {formatMessage({id: 'fonts.update.page.next'})}
                </PaginationItem>}
            </Pagination>
        );
        options = options.concat(visible.map(font => {
            const fontId = getFontId(font.family);
            return {
                content: (
                    <div onClick={onSelection}>
                        <span
                            id={`font-button-${fontId}${selectorSuffix}`}
                            key={fontId}
                        >
                            {font.family}
                        </span>
                    </div>
                ),
                value: font.family,
                text: font.family,
                selected: font.family === activeFontFamily,
            };
        }));
        return options.concat({
            content: pagination,
            value: '',
            text: '',
            onClick: () => null,
        });
    }, [activeFontFamily, changeVisiblePage, formatMessage, getActiveFont, onSelection, selectorSuffix, visibleFonts]);

    const onSearchChange = useCallback((event, {searchQuery}) => {
        let newFonts = [];
        if (searchQuery && searchQuery.length) {
            newFonts = googleFonts.filter((font) =>
                font.family.toLowerCase()
                    .indexOf(searchQuery.toLowerCase()) !== -1);
        } else {
            newFonts = googleFonts;
        }
        setFonts(newFonts);
    }, [googleFonts, setFonts]);

    return (
        <Select
            loading={loading}
            name={name}
            options={generateFontList()}
            onChange={onSelection}
            placeholder={placeholder}
            type={type}
            value={activeFontFamily}
            search={generateFontList}
            selection
            onSearchChange={onSearchChange}
            onClose={() => setFonts(googleFonts)}
        />
    );
};

FormFontPicker.propTypes = {
    activeFontFamily: PropTypes.string,
    onChange: PropTypes.func,
    pickerId: PropTypes.string,
    categories: PropTypes.array,
    scripts: PropTypes.array,
    families: PropTypes.array,
    variants: PropTypes.array,
    filter: PropTypes.func,
    limit: PropTypes.number,
    sort: PropTypes.string,
    ...formProptypes,
};

FormFontPicker.defaultProps = {
    activeFontFamily: 'Open Sans',
    onChange: () => {
    },
    pickerId: '',
    families: [],
    categories: [],
    scripts: ['latin'],
    variants: ['regular'],
    filter: () => true,
    limit: 20,
    sort: 'popularity',
};

import PropTypes from 'prop-types';
import React, {useEffect, useState, useCallback} from 'react';
import queryString from 'query-string';
import debounce from 'lodash.debounce';
import {Semantic} from 'packages/components';
import {Button} from 'packages/components/buttons';
import {formProptypes} from 'packages/utils/formikPropTypes';
import Message from 'packages/components/message/Message';
import {FormInput, FormRadio} from 'packages/components/inputs';
import {HOSTING_URLS, VIDEO_HOSTING} from 'packages/enum';
import {MAX_PHOTO_SIZE} from '@configurator/constants/upload';
import Dropzone from 'react-dropzone';
import {FileUploadIcon, TrashIcon} from 'packages/components/icons';
import {Modal} from 'packages/components/modal';

import AutomaticVideoPreview from './AutomaticVideoPreview';
import {
    Header,
    VideoInput,
    Container,
    UploadWrapper,
    RadioGroup,
    RadioField,
    DeleteButton,
} from './nodes';
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay';
import {useSubscription} from '@configurator/providers/subscription';

const updateVideoDebounce = debounce((func = () => null) => {
    func();
}, 1000);

const UploadVideo = (
    {
        handleSubmit,
        errors,
        status,
        isSubmitting,
        values,
        setValues,
        setStatus,
        setFieldValue,
        onClose,
        open,
        openErrorModal,
    },
    {intl: {formatMessage}},
) => {
    const [isFileReady, setIsFileReady] = useState(false);
    const [preview, setPreview] = useState();
    const {permissions} = useSubscription();

    const error = Object.keys(errors).length || status && !!status.error;

    const changeValues = useCallback(() => {
        if (values.videoUrl) {
            const {url, query} = queryString.parseUrl(values.videoUrl || '');

            if (url.indexOf('www.youtube.com') !== -1) {
                const videoId = query.v;
                if (query.v) {
                    setValues({
                        ...values,
                        hosting: VIDEO_HOSTING.YOUTUBE,
                        videoId,
                        videoUrl: HOSTING_URLS.YOUTUBE + videoId,
                    });
                    setStatus({});
                } else {
                    setStatus({error: 'upload.modal.error.invalid'});
                }

            } else if (url.indexOf('youtu.be') !== -1) {
                const videoId = url.split('/')
                    .pop();
                if (videoId) {
                    setValues({
                        ...values,
                        hosting: VIDEO_HOSTING.YOUTUBE,
                        videoId,
                        videoUrl: HOSTING_URLS.YOUTUBE + videoId,
                    });
                    setStatus({});
                } else {
                    setStatus({error: 'upload.modal.error.invalid'});
                }

            } else if (url.indexOf('vimeo.com') !== -1) {
                const videoId = url.split('/')
                    .pop();
                if (videoId) {
                    setValues({
                        ...values,
                        hosting: VIDEO_HOSTING.VIMEO,
                        videoId,
                        videoUrl: HOSTING_URLS.VIMEO + videoId,
                    });
                    setStatus({});
                } else {
                    setStatus({error: 'upload.modal.error.invalid'});
                }

            } else if (!values.hosting) {

                setStatus({error: 'upload.modal.error.invalid'});
            }
        }

    }, [setStatus, setValues, values]);

    useEffect(() => {
        if (values.videoUrl) {
            updateVideoDebounce(changeValues);
        } else if (values.videoId || values.hosting) {
            setValues({
                ...values,
                videoId: undefined,
                hosting: undefined,
            });
        }
        /* eslint-disable-next-line */
    }, [changeValues, setValues, values.videoUrl]);

    const handleDropFile = (files) => {
        if (files && files.length) {
            setIsFileReady(true);
            const file = files[0];
            setPreview({
                index: 0,
                file,
                name: file.name,
                preview: window.URL.createObjectURL(file),
            });
            setFieldValue('file', file);
        }
    };

    const onDropRejected = (files, accept) => {
        if (files.find(el => el.size > MAX_PHOTO_SIZE)) {
            return openErrorModal({
                headerMessageId: 'uploadFile.error.header',
                yesMessageId: 'uploadFile.error.ok',
                subMessageId: 'uploadFile.error.fileSize.description',
                subMessageValues: {size: MAX_PHOTO_SIZE / 1024 / 1024},
            });
        }
        const acceptArr = accept.split(', ');
        if (files.find(el => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: 'uploadFile.error.header',
                yesMessageId: 'uploadFile.error.ok',
                subMessageId: 'uploadFile.error.fileType.description',
                subMessageValues: {types: accept.replace(/image\//g, '')},
            });
        }
    };

    const handleDeletePreview = () => {
        setFieldValue('file', undefined);
        setPreview();
        setIsFileReady(false);
    };

    const accept = 'image/jpeg, image/png, image/gif';
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Container>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting && (preview && preview.progress === 100)}
                    onSubmit={handleSubmit}
                >
                    <Message
                        error={error}
                        errors={errors}
                        content={status && status.error}
                    />
                    <Header>
                        {formatMessage({id: 'upload.video.title'})}
                    </Header>
                    <VideoInput>
                        <FormInput
                            name="videoUrl"
                            label={formatMessage({id: 'upload.video.label'})}
                        />
                    </VideoInput>
                    {values.videoId &&
                    <RadioGroup>
                        <RadioField>
                            <FormRadio
                                name="customPreview"
                                value={false}
                            />
                            <span>{formatMessage({id: 'upload.video.automaticPreview'})}</span>
                        </RadioField>
                        <RadioField>
                            <PermissionsOverlay isAllowed={permissions.CUSTOM_VIDEO_PLACEHOLDER}>
                                <FormRadio
                                    name="customPreview"
                                    value
                                />
                            </PermissionsOverlay>
                            <span>{formatMessage({id: 'upload.video.customPreview'})}</span>
                        </RadioField>
                    </RadioGroup>
                    }
                    {values.videoId && values.customPreview &&
                    <UploadWrapper>
                        {isFileReady ?
                            <div className="image-preview">
                                <img src={preview.preview} alt=""/>
                                <DeleteButton onClick={handleDeletePreview}>
                                    <TrashIcon/>
                                </DeleteButton>
                            </div> : (
                                <Dropzone
                                    accept={accept}
                                    onDrop={handleDropFile}
                                    multiple={false}
                                    maxSize={MAX_PHOTO_SIZE}
                                    onDropRejected={(files) => onDropRejected(files, accept)}
                                >
                                    {({getInputProps, getRootProps}) => (
                                        <div
                                            {...getRootProps()}
                                            className="input"
                                        >
                                            <input {...getInputProps()} />
                                            <FileUploadIcon color="lightGray"/>
                                            <span className="dropzone-header">
                                                {formatMessage({id: 'upload.video.text.title'})}
                                                <span
                                                    dangerouslySetInnerHTML={{__html: formatMessage({id: 'upload.video.text.browse'})}}
                                                />
                                            </span>
                                            <span
                                                className="dropzone-footer"
                                                dangerouslySetInnerHTML={{__html: formatMessage({id: 'upload.video.text.info'})}}
                                            />
                                        </div>
                                    )}
                                </Dropzone>
                            )}
                    </UploadWrapper>}
                    {
                        values.videoId && !values.customPreview && (
                            <AutomaticVideoPreview
                                videoId={values.videoId}
                                hosting={values.hosting}
                            />
                        )
                    }
                    {values.videoId &&
                    <Button
                        type="submit"
                        disabled={isSubmitting || error || !values.videoId || values.customPreview && !preview}
                        content={formatMessage({id: 'upload.video.button.save'})}
                    />}
                </Semantic.Form>
            </Container>
        </Modal>
    );
};

UploadVideo.propTypes = {
    onClose: PropTypes.func.isRequired, // eslint-disable-line
    refetchPagePreview: PropTypes.func, // eslint-disable-line
    open: PropTypes.bool,
    openErrorModal: PropTypes.func,
    ...formProptypes,
};

UploadVideo.defaultProps = {
    open: false,
    refetchPagePreview: () => null,
    openErrorModal: () => null,
};

UploadVideo.contextTypes = {
    intl: PropTypes.object.isRequired,
};

export default UploadVideo;

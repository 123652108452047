import styled from 'styled-components';
import {Input} from 'semantic-ui-react';

export const StyledInputContainer = styled.div`

`;

export const StyledInputWithIcon = styled.div`
  position: relative;
  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
`;

export const StyledLabel = styled.label`
    font-family: Sailec, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #343840;

    display: block;
    margin-bottom: 5px;
`;

export const StyledInput = styled(Input)`
  & > input {
    height: 45px !important;
    background: #FFFFFF !important;
    border-radius: 2px !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: ${props => props.error ? '#FF0000' : '#EDEDED'} !important;
    box-sizing: border-box !important;
    padding: 12px 14px !important;

    color: #343840 !important;
    font-size: 13px !important;
    font-family: Sailec, sans-serif !important;
    line-height: 140% !important;

    &:focus {
      border-color: #58C367 !important;
    }
  }

  &.ui.input.error > input {
     background: #FFFFFF !important;
     &::placeholder {
       color: rgba(191, 191, 191, 0.87); !important;
     }
  }
`;

export const StyledInputError = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  margin-top: 10px;
  /* Red */
  color: #FF002E;
`;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {formProptypes} from 'packages/utils/formikPropTypes';
import get from 'lodash/get';
import DeleteAccountModal from '../modals/deleteAccount';
import {
    Container,
    Header,
    Content,
} from './nodes';

const DeleteAccount = ({email, billingAccountDelete}, {intl: {formatMessage}}) => {
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState();

    const handleSubmit = async (values) => {
        if (email !== get(values, 'email')) {
            return setError('deleteAccount.modal.error.email');
        }
        await billingAccountDelete();
        setOpenModal(false);
    };

    return (
        <Container>
            <Header>{formatMessage({id: 'deleteAccount.header'})}</Header>
            <Content>
                <span>{formatMessage({id: 'deleteAccount.text'})}</span>
                <button
                    onClick={() => {
                        setError(undefined);
                        setOpenModal(true);
                    }}
                >
                    {formatMessage({id: 'deleteAccount.button.text'})}
                </button>
            </Content>
            {openModal && (
                <DeleteAccountModal
                    open
                    error={error}
                    onClose={() => setOpenModal(false)}
                    onError={(value) => setError(value)}
                    onSubmit={handleSubmit}
                />
            )}
        </Container>
    );
};

DeleteAccount.propTypes = {
    email: PropTypes.string.isRequired,
    billingAccountDelete: PropTypes.func.isRequired,
    ...formProptypes,
};

DeleteAccount.defaultProps = {};

DeleteAccount.contextTypes = {
    intl: PropTypes.object.isRequired,
};

export default DeleteAccount;

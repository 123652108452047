import styled from 'styled-components';
import {media} from 'packages/components/media';

export const Header = styled.div`
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
`;

export const Container = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center;
    form {
        width: 100% !important;

        > button {
           position: absolute;
           height: 45px !important;
           width: 165px !important;
           top: 0;
           right: 0;
        }
    }
`;

export const VideoInput = styled.div`

  .ui.input {
    width: 100% !important;
  }

`;

export const UploadWrapper = styled.div`
    .input {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
        cursor: pointer;
        color: #666A73;
        font-size: 13px;
        line-height: 140%;
        padding: 70px 0 15px;

        background: #F6F6F6;
        border: 1px dashed #E1E2E6;
        border-radius: 4px;

         .dropzone-header {
             color: #343840;
             font-size: 24px;
             font-weight: bold;
             line-height: 150%;
             margin: 18px 0 55px;
             text-align: center;

             > span {
               color: #666A73;
               font-size: 13px;
               line-height: 140%;
               display: block;
               font-weight: normal;
             }
          }
         .dropzone-footer {
            text-align: center;
         }

         &:focus {
            outline-color: rgba(0, 0, 0, 0) !important;
         }
    }

    .image-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: #EDEDED;
        width: 100%;
        > img {
          height: 300px;
          width: 100%;
          object-fit: contain;
        }
    }

    ${media.max.phone`
        .input {
            height: 240px;
            padding: 10px 0;

            .dropzone-header {
                margin-bottom: 20px;
            }
        }
        .image-preview > img {
            height: 240px;
        }
    `}
`;

export const RadioGroup = styled.div`
  display: flex;
  margin: 20px 0;

  ${media.max.phone`
    flex-direction: column;
  `}
`;

export const RadioField = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  > span {
    margin-left: 10px;
    font-size: 13px;
    line-height: 1em;
    color: #343840;
  }
  &+& {
    margin-left: 50px;
  }

  ${media.max.phone`
    & + & {
      margin-top: 15px;
      margin-left: 0;
    }
  `}
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
  background: #FFF;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoPreview = styled.div`

`;

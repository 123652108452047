/* eslint-disable max-len */
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { intlShape, injectIntl } from "react-intl";
import get from "lodash/get";
import { useDispatch } from "react-redux";

import { useApolloClient, useMutation } from "react-apollo";

import { useInterval } from "packages/helpers/useInterval";
import { DOMAIN_TYPES } from "packages/constants";
import { open as openErrorModal } from "packages/redux/modules/modalYesNo/actions";
import { useSubscription } from "@configurator/providers/subscription";
import { PREMIUM_STATUSES } from "packages/enum";

import domainGet from "@graphql/gql/website/domainGet.gql";
import domainDeleteMutation from "@graphql/gql/website/domainDelete.gql";
import domainSetMutation from "@graphql/gql/website/domainSet.gql";
import domainRetryMutation from "@graphql/gql/website/domainRetry.gql";

import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import DomainForm from "../domain/DomainForm";

import {
    Paragraph,
    StepWrapper,
    StepContent,
    Step,
    StepNumber,
    PremiumHeaderContainer,
    StepTable,
    CustomDomain,
    CustomDomainPreview,
    CustomDomainPreviewContainer,
    CustomDomainPreviewWrapper,
    ChangeIconWrapper,
} from "../nodes";
import { LockIcon, RightIcon } from "packages/components/icons";
import { ChangeIcon } from "packages/components/icons/arrows/change";
import {useWindowSize} from 'packages/helpers/useWindowSize';
import { Divider } from "packages/components/divider";

const PremiumSection = ({ websiteId, freeDomain, intl: { formatMessage } }) => {
    const client = useApolloClient();
    const size = useWindowSize();

    const dispatch = useDispatch();
    const { permissions } = useSubscription();

    const [startPrimiumDomainRequest, setStartPrimiumDomainRequest] =
        useState(false);
    const [premiumDomainInfo, setPremiumDomainInfo] = useState({});

    const [domainDelete] = useMutation(domainDeleteMutation);

    const [domainSet] = useMutation(domainSetMutation);

    const [domainRetry] = useMutation(domainRetryMutation);

    const deletePremiumDomain = () =>
        domainDelete({
            variables: {
                requestId: premiumDomainInfo.id,
            },
        }).then(
            ({
                data: {
                    domainDelete: { success },
                },
            }) => {
                if (success) {
                    setPremiumDomainInfo({ isRequestExist: false, domain: "" });
                    setStartPrimiumDomainRequest(false);
                }
            }
        );

    // toggle change domain
    const changePremiumDomain = async ({ domain }) => {
        const data = await domainSet({
            variables: {
                websiteId,
                domain,
            },
        });
        if (get(data, "data.domainSet.errors._error")) {
            dispatch(
                openErrorModal({
                    headerMessageId: "error.header",
                    yesMessageId: "OK",
                    subMessageId: get(data, "data.domainSet.errors._error"),
                })
            );
        } else {
            setStartPrimiumDomainRequest(true);
        }
    };

    // toggle change domain
    const retryDomainCheck = async () => {
        await domainRetry();
        setStartPrimiumDomainRequest(true);
    };

    const updateRequestStatus = useCallback(() => {
        client
            .query({
                query: domainGet,
                variables: { websiteId },
                fetchPolicy: "network-only",
            })
            .then(
                ({
                    data: {
                        domainGet: { data, success },
                    },
                }) => {
                    if (success) {
                        setPremiumDomainInfo(data);
                    }
                }
            );
    }, [client, websiteId]);

    useEffect(() => {
        if (websiteId && client) {
            updateRequestStatus(true);
        }
    }, [startPrimiumDomainRequest, websiteId, client, updateRequestStatus]);

    useInterval(() => {
        if (
            startPrimiumDomainRequest &&
            premiumDomainInfo.isRequestExist &&
            premiumDomainInfo.status !== PREMIUM_STATUSES.ACTIVE
        ) {
            updateRequestStatus();
        }
    }, 10000);

    return (
        <div>
            <PremiumHeaderContainer>
                <Paragraph type="h1" mb={10}>
                    {formatMessage({
                        id: "website.settings.premium.title",
                    })}
                </Paragraph>
                <Paragraph
                    type="h2"
                    mb={10}
                    mw={550}
                    dangerouslySetInnerHTML={{
                        __html: formatMessage({
                            id: "website.settings.premium.text1",
                        }),
                    }}
                />
                <CustomDomainPreviewWrapper width={size.clientWidth}>
                    <CustomDomainPreviewContainer>
                        <CustomDomainPreview>
                            <div>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div>
                                <div>LA</div>
                                <div>
                                    <div>
                                        <LockIcon />
                                    </div>
                                    <div>
                                        https://<span>{freeDomain}</span>
                                        .vsble.me
                                    </div>
                                    <ChangeIconWrapper>
                                        <ChangeIcon />
                                    </ChangeIconWrapper>
                                </div>
                            </div>
                        </CustomDomainPreview>
                        <div></div>
                    </CustomDomainPreviewContainer>
                    <CustomDomainPreviewContainer>
                        <CustomDomainPreview>
                            <div>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div>
                                <div>LA</div>
                                <div>
                                    <div>
                                        <LockIcon />
                                    </div>
                                    <div>https://yourdomain.com</div>
                                </div>
                            </div>
                        </CustomDomainPreview>
                    </CustomDomainPreviewContainer>
                    <div></div>
                </CustomDomainPreviewWrapper>
                <CustomDomain>
                    https://<span>{freeDomain}</span>.vsble.me <RightIcon />{" "}
                    https://<span>yourdomain</span>.com
                </CustomDomain>
            </PremiumHeaderContainer>
            <StepWrapper>
                <Step>
                    <StepNumber>
                        <div>
                            <span>Step 1</span>
                        </div>
                    </StepNumber>
                    <StepContent>
                        <Paragraph type="h2" mt={10} mb={14}>
                            {formatMessage({
                                id: "website.settings.premium.text4",
                            })}
                        </Paragraph>
                        <StepTable>
                            <div>
                                <div>
                                    <span>
                                        {formatMessage({
                                            id: "website.settings.premium.text11",
                                        })}
                                        <b>A</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Host: <b>@</b>
                                    </span>
                                </div>
                                <div>
                                    <Paragraph type="h2" mb={10}>
                                        <span>
                                            Value:{" "}
                                            <b>
                                                {formatMessage({
                                                    id: "website.settings.premium.text5",
                                                })}
                                            </b>{" "}
                                        </span>
                                    </Paragraph>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>
                                        {formatMessage({
                                            id: "website.settings.premium.text11",
                                        })}
                                        <b>A</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Host: <b>www</b>
                                    </span>
                                </div>
                                <div>
                                    <Paragraph type="h2" mb={10}>
                                        <span>
                                            Value:{" "}
                                            <b>
                                                {formatMessage({
                                                    id: "website.settings.premium.text5",
                                                })}
                                            </b>{" "}
                                        </span>
                                    </Paragraph>
                                </div>
                            </div>
                        </StepTable>
                        <Paragraph
                            type="h2"
                            mb={20}
                            mt={5}
                            dangerouslySetInnerHTML={{
                                __html: formatMessage(
                                    { id: "website.settings.premium.text7" },
                                    {
                                        videoLink:
                                            "https://learn.vsble.me/mypaths/9/items/33/advanced",
                                    }
                                ),
                            }}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepNumber>
                        <div>
                            <span>Step 2</span>
                        </div>
                    </StepNumber>
                    <StepContent>
                        <Paragraph
                            type="h2"
                            mt={10}
                            mb={20}
                            dangerouslySetInnerHTML={{
                                __html: formatMessage({
                                    id: "website.settings.premium.text3",
                                }),
                            }}
                        />
                        <PermissionsOverlay
                            isAllowed={permissions.CUSTOM_DOMAIN}
                        >
                            <DomainForm
                                type={DOMAIN_TYPES.PREMIUM_DOMAIN}
                                domain={premiumDomainInfo.domain}
                                changeDomain={changePremiumDomain}
                                deleteDomain={deletePremiumDomain}
                                domainRetry={retryDomainCheck}
                                premiumStatus={premiumDomainInfo.status}
                                isRequestExist={
                                    premiumDomainInfo.isRequestExist
                                }
                            />
                        </PermissionsOverlay>
                        <Paragraph
                            type="h2"
                            mt={20}
                            mb={10}
                            dangerouslySetInnerHTML={{
                                __html: formatMessage(
                                    { id: "website.settings.premium.text10" },
                                    {
                                        guideLink:
                                            "https://vsble.freshdesk.com/en/support/solutions/articles/63000263676-custom-domain-is-not-connecting",
                                    }
                                ),
                            }}
                        />
                    </StepContent>
                </Step>
            </StepWrapper>
            <Divider/>
        </div>
    );
};

PremiumSection.propTypes = {
    websiteId: PropTypes.string.isRequired,
    freeDomain: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

PremiumSection.defaultProps = {};

export default injectIntl(PremiumSection);

import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";

import { Semantic } from "packages/components";
import { formProptypes } from "packages/utils/formikPropTypes";
import { Button } from "packages/components/buttons";
import {
    Content,
    Container,
    Stack,
    StyledFormCheckbox,
    StyledMessage,
    StyledFormSelect,
} from '@landing/components/common/nodes';
import {
    AboutFormHeader,
    AboutFormLabel,
    AboutFormInputWrapper,
    CheckboxContainer,
    AboutFormSelectHeader,
    AboutFormCheckboxHeader,
} from "./nodes";
import Loader from "packages/components/pageLoader/PageLoader";
import {getMessagesList} from "packages/helpers/Helper";

const AboutForm = (
    {
        handleSubmit,
        errors,
        status,
        isSubmitting,
        loading,
        values,
        location,
        setFieldValue,
    },
    { intl: { formatMessage } }
) => {
    const error = Object.keys(errors).length || (status && !!status.error);
    const interestList = useMemo(
        () => getMessagesList(formatMessage({id: 'landing.account.about.interest'})),
        [formatMessage]);

    const optionList = useMemo(
        () => getMessagesList(formatMessage({id: 'landing.account.about.select.options'})),
        [formatMessage]);

    useEffect(() => {
        interestList.forEach((item) => setFieldValue(item, false))
    },[interestList, setFieldValue]);

    const renderAboutForm = () => (
        <Container verticalAlign="initial">
            <AboutFormHeader>
                {formatMessage({ id: "landing.account.about.header" })}
            </AboutFormHeader>
            <Content>
                <Semantic.Form noValidate error={error} onSubmit={handleSubmit}>
                    <StyledMessage
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <AboutFormInputWrapper>
                        <AboutFormSelectHeader>
                            {formatMessage({ id: "landing.account.about.select.header" })}
                        </AboutFormSelectHeader>
                        <StyledFormSelect
                            name={formatMessage({id: "landing.account.about.select.name"})}
                            type="text"
                            width="100%"
                            options={optionList.map(item => ({occupation: item}))}
                            selection
                            placeholder={formatMessage({ id: "landing.account.about.select.placeholder" })}
                        />
                        <AboutFormCheckboxHeader>
                            {formatMessage({ id: "landing.account.about.checkbox.header" })}
                        </AboutFormCheckboxHeader>
                        <Stack $spacing={8}>
                            {interestList.map((item, index) => (
                                <CheckboxContainer key={index.toString()}>
                                    <AboutFormLabel>
                                        <StyledFormCheckbox name={item} type='checkbox' value={values[item]} />
                                        <span>{item}</span>
                                    </AboutFormLabel>
                                </CheckboxContainer>
                            ))}
                        </Stack>
                    </AboutFormInputWrapper>
                    <Button
                        fluid
                        type="submit"
                        disabled={isSubmitting}
                        content={formatMessage({
                            id: "landing.account.about.button",
                        })}
                    />
                </Semantic.Form>
            </Content>
            {isSubmitting || loading ? (
                <Loader
                    open
                    inverted
                    content={formatMessage({
                        id: "landing.account.create.loader",
                    })}
                />
            ) : null}
        </Container>
    );
    return renderAboutForm();
};

AboutForm.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    loading: PropTypes.bool.isRequired,
    ...formProptypes,
};
AboutForm.defaultProps = {
    loading: false,
};

AboutForm.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default AboutForm;

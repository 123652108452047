import { graphql } from "react-apollo";
import { compose } from "redux";
import isEmpty from "lodash/isEmpty";
import { getActiveSubscription } from "packages/helpers/Helper";

import billingSubscriptionsGet from "@graphql/gql/website/billingSubscriptionsGet.gql";
import billingStripeTokenGet from "@graphql/gql/website/billingStripeTokenGet.gql";

import Billing from "@configurator/components/billing/Billing";

export const billingWrapper = (Component) =>
    compose(
        graphql(billingStripeTokenGet, {
            props({ data: { loading, billingStripeTokenGet } }) {
                if (loading) {
                    return { loading };
                }
                if (isEmpty(billingStripeTokenGet)) {
                    return { loading };
                }
                const { data } = billingStripeTokenGet;

                return {
                    config: data,
                };
            },
        }),
        graphql(billingSubscriptionsGet, {
            props({ data: { loading, billingSubscriptionsGet } }) {
                if (loading) {
                    return { loading };
                }
                if (isEmpty(billingSubscriptionsGet)) {
                    return { loading };
                }
                const { data } = billingSubscriptionsGet;

                const subscription = getActiveSubscription(data);
                return {
                    subscriptionType: subscription.subscriptionType,
                };
            },
        })
    )(Component);

export default billingWrapper(Billing);

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const FolderIcon = ({ color }) => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 2H10.414L8.707 0.293C8.52 0.105 8.265 0 8 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H18C19.103 18 20 17.103 20 16V4C20 2.897 19.103 2 18 2ZM2 16V4H18L18.002 16H2Z"
            fill={iconColors[color]}
        />
    </svg>
);

FolderIcon.propTypes = {
    ...iconColorPropTypes,
};

FolderIcon.defaultProps = {
    color: 'dark',
};

import styled from 'styled-components';
import Img from 'packages/components/gatsby-image';
import {media} from 'packages/components/media';

export const Container = styled.div`
    position: relative;
    font-size: 13px;
    font-weight: normal;
    line-height: 140%;
    color: #666A73;
    padding: 10px 0;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.span`
    color: #858686;
    font-weight: normal;
    width: 50%;
    line-height: 32px;
    align-self: flex-start;
`;

export const ExpandLink = styled.span`
    color: #58C367;
    cursor: pointer;
    font-size: 13px;
    line-height: 140%;
`;

export const Item = styled.span`
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    color: #343840;
`;

export const Image = styled(Img)`
    height: 125px;
    width: 125px;
`;

export const ListItem = styled.div`
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    span {
        width: 50%;
        display: inline-block;
    }

    span:first-child {
        width: 50%;
    }

    img {
        width: 50%;
        display: inline-block;
    }
`;

export const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px;

    ${media.max.phone`
        padding: 20px 16px;
    `}
`;

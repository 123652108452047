import { formatMessagesList } from "packages/helpers/Helper";

const shared = {
    currency: "{currency, select, EUR {€} USD {$} other {€}}",
};

const messages = {
    "format.currency": shared.currency,
    "page.update.error.header": "Error",
    "album.delete.header": "Please confirm action",
    "album.delete.subHeader": "The album will be deleted.",
    "siteName.update.field.siteName": "Logo Title",
    "siteName.update.field.siteDescription": "Logo Subtitle",
    "siteName.update.error.header": "Error",
    "page.create.error.header": "Error",
    "socialLinks.update.error.header": "Error",
    "domain.update.error.header": "Error",
    "preview.banner.websiteOffline.title": `
        This website is set <b>Offline</b> and will not display to your visitors.
        Its only visible to you, so you can edit it.
        You can set it life in website settings
    `,
    "preview.banner.websiteOffline.unpaidPlan.title": `
        Your billing plan is <b>Unpaid</b>.
        This website is set <b>Offline</b> and will not display to your visitors.
        Its only visible to you, so you can edit it.
        You can set it life in website settings
    `,
    "preview.banner.pageOffline.title": `
        This page is set <b>Offline</b> and will not display to your visitors.
        Its only visible to you, so you can edit it.
        You can set it life in page settings
    `,
    "preview.banner.pageProtected.title":
        "This page is currently protected by a password and not visible to public.",
    "preview.banner.pageProtected.subTitle": `Remove password in <b>Page Settings</b> to unlock the page.`,
    "preview.banner.pageProtected.iconDesc":
        "The lock next to the page name indicates it.",
    "album.label.cover": "Album Cover",
    "album.label.delete": "Delete",
    "album.notification.movedToAlbum": "Moved to",
    "upload.modal.error.header": "Error",
    "upload.modal.error.invalid": "Invalid url",
    "upload.error.header": "Upload error",
    "upload.error.ok": "Ok",
    "upload.error.description": "You can try it later",
    "upload.error.description.short": "Internal error",
    "header.preview": "Preview website",
    "navigation.label.main": "Main navigation",
    "navigation.label.footer": "Footer navigation",
    "navigation.search.placeholder": "Search Category, Album",
    "progressBar.fileUploading": "Please wait. File is uploading.",
    "progressBar.processing": "Please wait. Processing...",
    "statistics.total.pageViews": "TOTAL PAGE VIEWS",
    "statistics.total.visitors": "TOTAL VISITORS (UNIQUE)",
    "lineChart.label.pageViews": "Visits",
    "lineChart.label.visitors": "Unique visitors",
    "mapChart.title": "Territory",
    "mostViewed.page.title": "Most viewed Page",
    "mostViewed.album.title": "Most viewed Album",
    "mostViewed.image.title": "Most viewed Image",
    "referrals.title": "Referrals",
    "referrals.column.header.url": "URL",
    "bootstrap.your.free": "Your FREE vsble.me domain",
    "bootstrap.domain": "Domain",
    "bootstrap.confirm.change": "Confirm change",
    "page.field.error.title": "Page Name cannot be empty",
    "page.field.error.slug": "For URL slugs use only latin characters",
    "page.field.error.pageType": "Page Type cannot be empty",
    "album.field.error.name": "Album Name cannot be empty",
    "billing.plan.month": "per month billed monthly",
    "billing.plan.year": "per month billed yearly",
    "siteName.delete.header": "Are you sure?",
    "siteName.delete.subHeader": "Do you really want to delete?",
    "siteName.delete.button.cancel": "Cancel",
    "siteName.delete.button.delete": "Delete",
    "album.label.makeCover": "Make cover",
    "pdfTool.modal.header": "Upload a logo for your PDF header",
    "pdfTool.modal.footer":
        "Footer text: this will be displayed on each page of the downloaded PDF",
    "slideshow.modal.error.default": "Something went wrong.",
    "slideshow.modal.upload.label":
        "You can upload a single static image or several images. Images will appear in the order they are sorted. You can set slideshow transition speed as well as how images are scaled in the slideshow area.",
    "slideshow.modal.speed.label": "Transition speed",
    "slideshow.modal.speed.slow": "slow",
    "slideshow.modal.speed.fast": "fast",
    "slideshow.modal.type.photo": "Photo",
    "slideshow.modal.type.video": "Video",
    "upload.error.subscription.text":
        "Your upload limit has been reached. Delete default system albums to free up the space or subscribe to Vsble PRO for unlimited uploads!",
    "upload.error.subscription.button": "Subscribe",
    "upload.error.subscription.short": "Upload limit reached",
    "website.settings.visibility.title": "Website visibility",
    "website.settings.visibility.text1": "General website visibilty.",
    "website.settings.visibility.text2":
        "By switching the website to “Offline,” it will not be visible publicly. Instead, the\n message “Website coming soon...” will be displayed.",
    "website.settings.visibility.text3":
        "Switch off the default cookie consent solution",
    "website.settings.visibility.text4": "Remove branding",
    "website.settings.visibility.text5":
        "Remove Vsble logo from website footer",
    "website.settings.premium.title":
        "Connect a custom domain from any provider",
    "website.settings.premium.text1":
        "You can connect any custom top-level domain from any provider with your Vsble website. In addition, we provide a free SSL for better Google ranking, security, and GDPR compliance. You don’t have a domain yet? You can buy one e.g. from Namecheap <a href='https://namecheap.pxf.io/c/3214164/386170/5618' target='_blank'>here.</a>",
    "website.settings.premium.text2": "{domain}.vsble → www.{domain}.com",
    "website.settings.premium.text3":
        "Enter your domain name in the below field <b>without „www“</b> but with its extension (.com , .fr , .uk.co …). Depending how fast your domain provider can update the DNS settings, your domain will need from \n" +
        "5min. - 72h. to connect with your Vsble website.",
    "website.settings.premium.text4":
        "Login to your domain provider dashboard. In the DNS settings of your domain add two records.",
    "website.settings.premium.text5": "94.130.249.72",
    "website.settings.premium.text6":
        "A www 94.130.249.72 (Add “www” subdomain first, if you don’t have one)",
    "website.settings.premium.text7":
        "Make sure no other A records for these hosts exist. Remove them if necessary. Watch this <a href='{videoLink}' target='_blank' rel='noopener noreferrer'>video</a> how to connect your domain e.g. with GoDaddy here.",
    "website.settings.premium.text10":
        'For trouble shooting refer to this  <a target="_blank" href="{guideLink}">guide</a>.</br></br>' +
        'If you prefer us to connect your domain, you can book connection service for a one time fee of 39€ — <a href="mailto:support@vsble.me?subject=Domain%20Connect%20Request">contact us</a>.',
    "website.settings.premium.text11": "1. Record Type: ",
    "website.settings.premium.text12": "",
    "website.settings.privacy.title": "Privacy & GDPR",
    "website.settings.privacy.text1": "Cookie bar",
    "website.settings.privacy.text2":
        "Cookie bar is by default enabled and blocks any cookies before the visitor gives his consent.",
    "website.settings.privacy.text3": "Block third party fonts by default",
    "website.settings.privacy.text4":
        "Third-party fonts like Google Fonts, Type font, or Adobe fonts are not blocked by default. However, depending on your country, you might need to prevent using third-party fonts before your website visitor consents explicitly. Enable this function to comply with the GDPR regulations of your region.",
    "website.domain.status.dnsCheckingWrongIPV4":
        "IPV4 is configured incorrectly",
    "website.domain.status.dnsCheckingConstantIPV6": "Сontains IPV6",
    "website.domain.status.wrongIPV4AndConstantIPV6":
        "Invalid IPV4 and IPV6 present",
    "website.domain.status.unregistredDomain":
        "Error at the DNS ckecking stage",
    "website.domain.status.genericError": "Unexpected error",
    "website.domain.status.dnsChecking":
        "Awaiting DNS validation from provider...",
    "website.domain.status.dnsCheckingFailed": "DNS checking failed",
    "website.domain.status.dnsValid": "Setting SSL certificate...",
    "website.domain.status.sertFailed": "SSL certificate setting failed",
    "website.domain.status.failed": "Connection failed",
    "website.domain.status.connected": "Connected!",
    "website.domain.status.ssl": "Awaiting SSL certificate",
    "website.domain.status.configuration": "Applying configuration",
    "album.item.label.show": "Show",
    "bootstrap.domain.subheader.text2":
        "You can customize your vsble.me subdomain provided the name is not taken by another user.",
    "fonts.update.placeholder.primary": "Primary font",
    "fonts.update.error.text": "Something went wrong.",
    "album.field.error.pageIds":
        "You have to choose at least one category page",
    "fonts.update.page.previous": "Previous",
    "fonts.update.page.next": "Next",
    "album.name.noTitle": "No Title",
    "templates.label.anton": "ANTON",
    "templates.label.anton.black": "ANTON BLACK",
    "templates.label.sophie": "SOPHIE",
    "templates.label.sophie.black": "SOPHIE BLACK",
    "templates.label.nikolai": "NIKOLAI",
    "templates.label.nikolai.black": "NIKOLAI BLACK",
    "templates.label.peggy": "PEGGY",
    "templates.label.peggy.black": "PEGGY BLACK",
    "templates.label.peggyslideshow": "PEGGY SLIDESHOW",
    "templates.label.peggyslideshow.black": "PEGGY SLIDESHOW BLACK",
    "templates.label.jeremy": "JEREMY",
    "templates.label.jeremy.black": "JEREMY BLACK",
    "templates.label.jeremyslideshow": "JEREMY SLIDESHOW",
    "templates.label.jeremyslideshow.black": "JEREMY SLIDESHOW BLACK",
    "templates.label.paris": "PARIS",
    "templates.label.paris.black": "PARIS BLACK",
    "templates.label.brooklyn": "BROOKLYN",
    "templates.label.brooklyn.black": "BROOKLYN BLACK",
    "templates.label.newyork": "NEW YORK",
    "templates.label.newyork.black": "NEW YORK BLACK",
    "templates.label.sydney": "SYDNEY",
    "templates.label.sydney.black": "SYDNEY BLACK",
    "templates.label.berlin": "BERLIN",
    "templates.label.berlin.black": "BERLIN BLACK",
    "templates.label.antwerp": "ANTWERP",
    "templates.label.antwerp.black": "ANTWERP BLACK",
    "templates.label.barcelona": "BARCELONA",
    "templates.label.barcelona.black": "BARCELONA BLACK",
    "templates.label.riga": "RIGA",
    "templates.label.riga.black": "RIGA BLACK",
    "templates.label.rigaslideshow": "RIGA SLIDESHOW",
    "templates.label.rigaslideshow.black": "RIGA SLIDESHOW BLACK",
    "templates.label.madrid": "MADRID",
    "templates.label.madrid.black": "MADRID BLACK",
    "templates.label.pavel": "PAVEL",
    "templates.label.pavel.black": "PAVEL BLACK",
    "templates.colorToggle.black": "Black",
    "templates.colorToggle.white": "White",
    "slideshow.label.logoSize": "Logo size",
    "statistics.total.averageTime": "AVERAGE TIME SPENT ON YOUR SITE",
    "statistics.periodPicker.options.day": "Day",
    "statistics.periodPicker.options.week": "Week",
    "statistics.periodPicker.options.month": "Month",
    "statistics.periodPicker.options.year": "Year",
    "statistics.periodPicker.options.range": "Range...",
    "statistics.periodPicker.date": "Date",
    "statistics.periodPicker.period": "Period",
    "statistics.periodPicker.useDemo": "Use Demo",
    "statistics.periodPicker.apply": "Apply",
    "statistics.line.header": "Visits over time",
    "statistics.online": "Online",
    "mostViewed.link.hide": "hide list",
    "mostViewed.noInfo": "No Info",
    "statistics.subscription.insights": "Get insights on",
    "statistics.subscription.pageviews": "Pageviews & visitors",
    "statistics.subscription.visitors": "Realtime visitors",
    "statistics.subscription.referralPages": "Referral pages and links",
    "statistics.subscription.countries": "Visitors countries",
    "statistics.subscription.mvPage": "Most viewed page",
    "statistics.subscription.mvAlbum": "Most viewed album",
    "statistics.subscription.mvImage": "Most viewed image",
    "statistics.subscription.button": "Upgrade now",
    "upload.error.fileSize.header": "Upload error",
    "upload.error.fileSize.ok": "Ok",
    "upload.error.fileSize.description":
        "Your file exceeds the upload limit, please upload files smaller than 20mb each.",
    "upload.error.fileSize.short": "File size exceeds 20MB",
    "album.items.loading": "Album data is saving...",
    "album.items.info.success":
        "Album data is saved successfully! You can close the window.",
    "album.items.info.error": "Album data updating completed with error.",
    "page.albums.loading": "Page data is saving...",
    "page.albums.info.success":
        "Page data is saved successfully! You can close the window.",
    "page.albums.info.error": "Page data updating completed with error.",
    "items.info.yes": "OK",
    "page.update.field.textAlign": "Text align:",
    "page.update.field.language": "Contact form language",
    "styles.title.header": "Logo",
    "styles.title.header.sublogo": "Sublogo",
    "styles.title.mainNavigation.navigation": "Navigation Elements",
    "styles.title.footer": "Footer",
    "styles.title.contacts": "Contact Page",
    "styles.form.button.save": "Save",
    "styles.reset.header": "Are you sure?",
    "styles.header.scrollable": "Scrollable Header",
    "styles.reset.subHeader": "Do you really want to reset settings?",
    "styles.subscription.header": "Fonts & Colors",
    "styles.subscription.subheader":
        "Create a unique look for your portfolio website",
    "styles.subscription.description":
        "Get control over font type, size, and color for each element of your website, as well as background and navigation elements color.",
    "styles.subscription.upgrade": "Upgrade",
    "template.change.header": "Design Change",
    "template.change.message":
        "Your custom font & colors for the currently active design will reset to default. You can make screenshots of custom font & color settings if you want to use them later. All other settings and contents are automatically saved and adjusted to any selected design.",
    "template.change.question": "Proceed with template change?",
    "template.change.button.ok": "Yes",
    "template.change.button.cancel": "Cancel",
    "template.button.select": "Select {template}",
    "template.slideShowEnabled": "Landing page<br/>photo & video slideshow",
    "template.button.preview": "Preview {template}",
    "tabs.tab.fontsAndColors": "Fonts & Colors",
    "styles.inputs.fontSize.label": "Size",
    "styles.inputs.fontSize.value.small": "Small",
    "styles.inputs.fontSize.value.medium": "Medium",
    "styles.inputs.fontSize.value.large": "Large",
    "styles.title.navigation": "Main Navigation",
    "styles.title.navigation.pageTitle": "Page Title",
    "styles.footer.defaultFooterLinks": "Default Footer Links",
    "styles.footer.defaultFooterLinks.allCaps": "ALL CAPS",
    "styles.footer.defaultFooterLinks.firstCaps": "First Caps",
    "styles.footer.defaultFooterLinks.lowerCase": "lower case",
    "styles.footer.toggle.showYear": "Show Year",
    "styles.footer.toggle.showDomainTitle": "Show Domain Title",
    "styles.logo.marginBottom": "Margin bottom",
    "styles.logo.marginTop": "Margin top",
    "styles.footer.toggle.overSlideshow": "Over Slideshow",
    "styles.title.albumPage": "Album Page",
    "styles.title.albumPage.titles": "Album Titles",
    "styles.title.albumPage.description": "Description",
    "styles.title.albumPage.imgDescription": "Image Description",
    "styles.title.textPage": "Text Page",
    "styles.title.textPage.text": "Text",
    "styles.title.burger": "Burger Color",
    "styles.title.hover": "Hover Color",
    "styles.title.backgroundColor": "Background Color",
    "styles.button.resetAllSettingsToDefault": "Reset All Settings to Default",
    "styles.form.button.resetToDefault": "Reset to Default",
    "slideshow.fit.label": "Image Fit",
    "slideshow.fit.fullHeight": "Full height",
    "slideshow.fit.fullHeight.desc":
        "Shows the image entirely in the slideshow",
    "slideshow.fit.scaleToFix": "Scale to fit",
    "slideshow.fit.scaleToFix.desc": "Scales image to entire viewport",
    "slideshow.button.addPhotos": "Add Photos",
    "slideshow.button.removeAll": "Remove All",
    "slideshow.button.save": "Save",
    "slideshow.removeAll.text": "Are you sure you want to delete all photos?",
    "slideshow.removeAll.error.header": "Error",
    "slideshow.removeAll.error.ok": "Ok",
    "album.item.label.addText": "Add Text",
    "album.label.addPhotos": "Add Photos",
    "album.label.addVideo": "Add Video",
    "album.label.addText": "Add Text",
    "album.label.move": "Move",
    "album.item.field.text": "Text",
    "album.item.placeholder.text": "Max 500 characters",
    "album.button.save": "Save",
    "page.create.menu.title": "Create New Page",
    "page.create.menu.type.photo": "Photo or Video Page",
    "page.create.menu.type.text": "Text Page",
    "page.create.menu.type.contact": "Contact Page",
    "page.create.menu.type.link": "External Link",
    "page.confirmDelete.header": "Delete {name}",
    "page.confirmDelete.subMessage": "Are you sure you want to delete {name}?",
    "page.confirmDelete.yesMessage": "Yes, Delete Page",
    "template.header.main": "Choose your template",
    "template.header.description":
        "Choose one of our best-in-class website templates and customize it to fit your needs. Making a beautiful website has never been faster.",
    "template.selectedTemplate": "Selected Template",
    "header.arrowBack.text": "Return to Dashboard",
    "header.arrowBackToIntegrations.text": "Back to Integrations",
    "user.menu.accountAndBilling": "Account & Billing",
    "user.menu.domainAndWebsite": "Domain & Website",
    "user.menu.googleSeo": "Google(SEO)",
    "user.menu.language": "Language",
    "user.menu.logout": "Log Out",
    "upload.video.text":
        "You can embed a background video from Vimeo in your slideshow. Youtube is currently not supported. Background videos play on a loop without sound.",
    "upload.video.label.paste": "Paste Vimeo Link",
    "upload.video.label.remove": "Remove Video",
    "upload.video.label.add": "Add Video",
    "upload.video.title": "Embed video",
    "upload.video.label": "Paste Vimeo or Youtube Link",
    "upload.video.automaticPreview": "Automatic Preview",
    "upload.video.customPreview": "Use Custom Preview Image",
    "upload.video.button.save": "Save",
    "upload.video.text.title": "Drag and drop photo",
    "upload.video.text.browse":
        'or <a style="color: #58C367;">browse</a> to select from a folder',
    "upload.video.text.info":
        "For optimal quality upload images with at least 2200 px.<br/>JPEG, JPG, PNG and GIF are supported, up to 10MB.",
    "upload.photos.title": "{count} files selected",
    "upload.photos.button.removeAll": "Remove all",
    "upload.photos.button.upload": "Upload",
    "upload.photos.button.viewFiles": "View files",
    "upload.photos.beforeUnloadMessage": "You'll lose your data!",
    "newDesign.page.create.header.photo": "Photo/Video Page create new",
    "newDesign.page.create.header.text": "Text Page create new",
    "newDesign.page.create.header.url": "URL Page create new",
    "newDesign.page.create.header.contact": "Contact Page create new",
    "newDesign.page.create.submit": "Save",
    "newDesign.page.create.field.title": "Page Title",
    "newDesign.page.create.field.linkUrl": "Link url",
    "newDesign.page.create.field.pagePosition": "Page position",
    "newDesign.page.create.field.mainNavigation": "Header",
    "newDesign.page.create.field.footerNavigation": "Footer",
    "newDesign.page.create.field.enablePage.title": "Enable page",
    "newDesign.page.create.field.enablePage.desc":
        "Disabled pages can’t be accessed by your site visitors",
    "newDesign.page.create.field.displayMode.title": "Display mode",
    "newDesign.page.create.field.displayMode.desc":
        'In the "expanded" view mode, all images of the album are shown at once, one after another. The viewer can see all photos of the collection at a glance as miniatures. That is helpful if you have a smaller portfolio or don\t want one image to represent the entire album.',
    "newDesign.page.create.field.displayMode.stacked": "Stacked",
    "newDesign.page.create.field.displayMode.expanded": "Expanded",
    "newDesign.page.create.field.displayMode.album": "Entire Album on click",
    "newDesign.page.create.field.displayMode.zoom": "Zoom image on click",
    "newDesign.page.create.field.displayMode.albumTitle": "Album Title",
    "newDesign.page.create.field.landingPage": "Set as landing page",
    "newDesign.page.create.field.hidePage.title":
        "Hide page title in the navigation",
    "newDesign.page.create.field.hidePage.desc":
        "(But show contents. This is useful if you like to promote albums from a different categories on the start page)",
    "newDesign.page.create.field.password.title": "Password",
    "newDesign.page.create.field.password.desc":
        "Enter password to make category protected",
    "newDesign.page.update.header.photo": "Photo/Video Page settings",
    "newDesign.page.update.header.text": "Text Page settings",
    "newDesign.page.update.header.url": "URL Page settings",
    "newDesign.page.update.header.contact": "Contact Page settings",
    "newDesign.page.update.submit": "Save",
    "newDesign.page.update.field.title": "Page Title",
    "newDesign.page.update.field.slug": "Slug",
    "newDesign.page.update.field.linkUrl": "Link url",
    "newDesign.page.update.field.language": "Contact form language",
    "newDesign.page.update.button.uploadImage": "Upload Image",
    "newDesign.page.update.field.selectPhoto": "Profile Picture",
    "newDesign.page.update.button.change": "Change",
    "newDesign.page.update.field.additionalText": "Additional Text",
    "newDesign.page.update.field.pagePosition": "Page position",
    "newDesign.page.update.field.mainNavigation": "Header",
    "newDesign.page.update.field.footerNavigation": "Footer",
    "newDesign.page.update.field.enablePage.title": "Enable page",
    "newDesign.page.update.field.enablePage.desc":
        "Disabled pages can’t be accessed by your site visitors",
    "newDesign.page.update.field.displayMode.title": "Display mode",
    "newDesign.page.update.field.displayMode.desc":
        'In the "expanded" view mode, all images of the album are shown at once, one after another. The viewer can see all photos of the collection at a glance as miniatures. That is helpful if you have a smaller portfolio or don\t want one image to represent the entire album.',
    "newDesign.page.update.field.displayMode.stacked": "Stacked",
    "newDesign.page.update.field.displayMode.expanded": "Expanded",
    "newDesign.page.update.field.displayMode.album": "Entire Album on click",
    "newDesign.page.update.field.displayMode.zoom": "Zoom image on click",
    "newDesign.page.update.field.displayMode.albumTitle": "Album Title",
    "newDesign.page.update.field.landingPage": "Set as landing page",
    "newDesign.page.update.field.hidePage.title":
        "Hide page title in the navigation",
    "newDesign.page.update.field.hidePage.desc":
        "(But show contents. This is useful if you like to promote albums from a different categories on the start page)",
    "newDesign.page.update.field.password.title": "Password",
    "newDesign.page.update.field.password.desc":
        "Enter password to make category protected",
    "newDesign.page.update.button.deletePage": "Delete Page",
    "modalConfirmDelete.header": "Confirm delete",
    "modalConfirmDelete.subMessage":
        "Are you sure you want to delete this element?",
    "modalConfirmDelete.cancel": "Cancel",
    "modalConfirmDelete.yes": "Yes, Delete",
    "banner.trial.expires":
        "Your Vsble PRO MAX trial ends in <span>{date} days.<span/> ",
    "banner.trial.expires.link":
        "Upgrade now to keep all advanced professional features.",
    "banner.trial.upgrade": "Choose plan",
    "banner.trial.delete": "Delete demo photos",
    "banner.trial.upload.count": "Uploads used, Upgrade for unlimited uploads",
    "newDesign.socialLinks.update.header": "Add social links",
    "newDesign.socialLinks.update.label.facebook": "Facebook",
    "newDesign.socialLinks.update.label.instagram": "Instagram",
    "newDesign.socialLinks.update.placeholder.facebook": "www.facebook.com/",
    "newDesign.socialLinks.update.placeholder.instagram": "www.instagram.com/",
    "newDesign.socialLinks.update.button.save": "Save",
    "newDesign.album.create.header": "Create new album",
    "newDesign.album.create.submit": "Save",
    "newDesign.album.create.field.name": "Album Title",
    "newDesign.album.create.field.desc": "Album Description",
    "newDesign.album.create.toggle.visibility.title": "Hide Album",
    "newDesign.album.create.toggle.visibility.desc":
        "Hidden album can’t be accessed by your site visitors",
    "newDesign.album.create.toggle.hideHeader.title":
        "Hide album title in the navigation",
    "newDesign.album.create.toggle.hideHeader.desc":
        "(But show contents. This is useful if you like to promote albums from a different categories on the start page)",
    "newDesign.album.update.header": "Album settings",
    "newDesign.album.customSlug.error": "For URL slugs use only latin characters",
    "newDesign.album.update.submit": "Save",
    "newDesign.album.update.field.name": "Album Title",
    "newDesign.album.update.field.slug": 'Slug',
    "newDesign.album.update.field.desc": "Album Description",
    "newDesign.album.update.field.displayCategory":
        "Display Album on Category Pages",
    "newDesign.album.update.toggle.visibility.title": "Hide Album",
    "newDesign.album.update.toggle.visibility.desc":
        "Hidden album can’t be accessed by your site visitors",
    "newDesign.album.update.toggle.hideHeader.title":
        "Hide album title in the navigation",
    "newDesign.album.update.toggle.hideHeader.desc":
        "(But show contents. This is useful if you like to promote albums from a different categories on the start page)",
    "newDesign.album.update.button.deleteAlbum": "Delete Album",
    "discardChanges.subMessage":
        "You have made changes. Are you sure you want to discard them?",
    "discardChanges.yesMessage": "Discard",
    "pdfTool.header": "PDF tool",
    "pdfTool.button.save": "Save",
    "pdfTool.enable": "Enable PDF tool",
    "pdfTool.upload.logo": "Drag and drop your logo",
    "inputs.slider.value.original": "Original",
    "pdf.freeUser.title": "PDF Tool available only<br/>with PRO plan",
    "pdf.freeUser.text":
        "Create PDF with your images and send <br/>or download it",
    "pdf.freeUser.upgrade": "Upgrade Now",
    "statistics.newDesign.header": "Statistics dashboard",
    "statistics.newDesign.total.visitors": "visitors on your site now",
    "statistics.newDesign.mostViewed.expand": "Expand list",
    "statistics.newDesign.referrals.visits": "Visits",
    "statistics.newDesign.periodPicker.date": "Date: {date}",
    "statistics.newDesign.subscription.title":
        "Statistics available only<br/>with PRO plan",
    "accountBilling.header": "Account & Billing",
    "accountBilling.login.header": "Login",
    "accountBilling.login.field.email": "E-mail",
    "accountBilling.login.field.changePassword": "Password",
    "accountBilling.login.button.update": "Update",
    "accountBilling.login.button.changePassword": "Change",
    "accountBilling.address.header": "Billing address",
    "accountBilling.address.field.name": "Name",
    "accountBilling.address.field.address1": "Address Line 1",
    "accountBilling.address.field.address2": "Address Line 2",
    "accountBilling.address.field.city": "City",
    "accountBilling.address.field.zipCode": "ZIP Code",
    "accountBilling.address.field.country": "Country",
    "accountBilling.address.button.update": "Update billing info",
    "accountBilling.error.requiredFields":
        "First Name, Last Name, Address Line 1 and Country are required",
    "accountBilling.tabs.account": "Account",
    "accountBilling.tabs.address": "Billing address",
    "accountBilling.tabs.history": "Billing history",
    "vatValidator.header": "Have a VAT ID? If not, leave empty",
    "vatValidator.error": "Code is not valid",
    "vatValidator.button.apply": "Apply",
    "deleteAccount.header": "Delete account",
    "deleteAccount.text": "Permanently delete this account?",
    "deleteAccount.button.text": "Proceed",
    "deleteAccount.modal.header": "Delete account",
    "deleteAccount.modal.text":
        "Once you confirm, all of your account data and your website will be permanently deleted",
    "deleteAccount.modal.field.email": "Enter your account e-mail",
    "deleteAccount.modal.button.yes": "Yes, Delete Account",
    "deleteAccount.modal.button.cancel": "Cancel",
    "deleteAccount.modal.error.email": "Incorrect e-mail",
    "deleteAccount.modal.error.email.required": "E-mail is required",
    "billingHistory.header": "Billing History",
    "billingHistory.noItems.message": "You haven\t made any purchases yet",
    "billingHistory.column.date": "Date",
    "billingHistory.column.type": "Type",
    "billingHistory.column.link": "Invoice",
    "billingHistory.column.status": "Status",
    "billingHistory.status.paid": "Paid",
    "billingHistory.status.notpaid": "Not paid",
    "billingHistory.column.type.pro":
        "VSBLE Pro, {currency, select, eur {€} usd {$} other {€}}{amount}",
    "billingHistory.column.type.prolite":
        "VSBLE Light, {currency, select, eur {€} usd {$} other {€}}{amount}",
    "billingHistory.column.link.pay.now": "Pay now",
    "billingHistory.column.link.download": "Download",
    "subscription.capabilities.lifetime": formatMessagesList([
        "All from PRO",
        "and",
        "App Extensions",
        "Marketing",
    ]),
    "subscription.capabilities.free": formatMessagesList([
        "30 images upload limit",
        "4 design templates",
        "Free vsble.me domain",
        "Free SSL",
        "Own logo",
        "Mobile optmized",
        "Basic SEO",
    ]),
    "subscription.capabilities.light": formatMessagesList([
        "150 images upload limit",
        "4 design templates",
        "Free vsble.me domain",
        "Free SSL",
        "Own logo",
        "Mobile optmized",
        "Basic SEO",
        "PDF Selections Tool",
        "Visitor Statistics",
    ]),
    "subscription.capabilities.pro": formatMessagesList([
        "Unlimited hosting",
        "All design templates",
        "Custom fonts & colors",
        "Custom favicon",
        "Custom domain connect",
        "SSL for connected domain",
        "Custom video placeholers",
        "Custom open graph image",
        "VIP Password",
        "PDF Tool",
    ]),
    "subscription.capabilities.promax": formatMessagesList([
        "<span style='font-weight: bold'>All from PRO &</span>",
        "Shop integration",
        "Expanded edit mode",
        "Extended SEO",
        "App extensions:",
        "Website translations",
        "Chat, Blog, Marketing",
        "Focus points",
        "Reframe tool",
        "Branding removal",
    ]),
    "subscription.modal.title.upgrade": "Upgrade",
    "subscription.modal.title.trialEnded":
        "Your trial <span style='color:#58C367'>Vsble PRO MAX</span> has ended",
    "subscription.modal.step.1.title": "1. Your Billing Address",
    "subscription.modal.step.2.title": "Select Plan & Billing Cycle",
    "subscription.modal.step.2.title.mobile": "Select Plan",
    "subscription.modal.step.3.title.mobile": "Billing Cycle",
    "subscription.modal.plan.price.label.month": "Monthly Payment",
    "subscription.modal.plan.price.label.year": "Yearly Payment",
    "subscription.modal.plan.price.title": `<span>${shared.currency}{price}/</span> mo`,
    "subscription.modal.plan.price.subTitle.month": "billed monthly",
    "subscription.modal.plan.price.subTitle.year": "billed yearly",
    "subscription.modal.plan.price.savedMoneyInfo": `Save ${shared.currency}{savedForYear}`,
    "subscription.modal.plan.total.title": "Total",
    "subscription.modal.plan.total.price": `${shared.currency}{totalPrice}`,
    "subscription.modal.plan.endDate": "{planType} until {date}",
    "subscription.modal.plan.autoRenew": "Will auto-renew until cancelled",
    "subscription.modal.plan.button.payByCard.desc":
        "The amount will be automatically charged until you cancel",
    "subscription.modal.plan.button.payByCard.text": "Continue to payment",
    "subscription.modal.plan.button.payByInvoice.desc":
        "Pay on each renewal periods manually with various payment methods.",
    "subscription.modal.plan.button.payByInvoice.text": "Pay by Invoice",
    "subscription.plan.price.title": `${shared.currency}{price} <span style='font-size:17px'>/mo.</span>`,
    "subscription.plan.price.subtitle": `${shared.currency}{price} <span style='font-size:12px'>/mo.</span>*`,
    "subscription.plan.others.price.subTitle": `or ${shared.currency}{price} / mo. <br/> with yearly payment`,
    "subscription.plan.button.manageSub": "Manage Subscription",
    "subscription.plan.free.price.subTitle": "Forever <br/> FREE",
    "subscription.plan.free.header": "FREE",
    "subscription.plan.free.limit": "Max. 30 images",
    "subscription.plan.free.domain": "Free Vsble.me domain",
    "subscription.plan.trial.header": "Free trial",
    "subscription.plan.trial.daysLeft": "Your free trial ends on",
    "subscription.plan.light.header": "Starter",
    "subscription.plan.light.domain": "",
    "subscription.plan.light.button.upgrade": "Select Starter",
    "subscription.plan.pro.header": "PRO",
    "subscription.plan.pro.limit": "Unlimited everything",
    "subscription.plan.pro.domain": "Connect your domain",
    "subscription.plan.pro.statistics": "Website Statistics",
    "subscription.plan.pro.button.upgrade": "Select PRO",
    "subscription.plan.promax.header": "PRO MAX",
    "subscription.plan.promax.button.upgrade": "Select PRO MAX",
    "subscription.plan.billingOption.header": "Your billing option",
    "subscription.plan.billingOption.pay":
        "Pay {interval, select, year {Annually} month {Monthly} other {Daily}}",
    "subscription.plan.billingOption.interval":
        "{interval, select, year {year} month {month} other {day}} billed {interval, select, year {yearly} month {monthly} other {daily}}",
    "subscription.plan.billingOption.renew":
        "Auto-renew is {renew, select, on {on} other {off}}",
    "subscription.plan.billingOption.renewDate": "Subscription renews on",
    "subscription.plan.billingOption.cancelDate": "Current plan ends on",
    "trialUpgrade.header": "Upgrade to VSBLE Pro or VSBLE PRO MAX",
    "trialUpgrade.button": "Upgrade",
    "startTrial.header": "Try VSBLE Pro MAX for FREE for 14 Days",
    "startTrial.text": "No credit card needed. You can revert to free anytime.",
    "startTrial.button": "Upgrade",
    "startTrial.modal.header": "Try VSBLE Pro MAX for FREE for 14 Days",
    "startTrial.modal.subMessage":
        "Start your 14 days Pro MAX trial. No credit card needed.",
    "startTrial.modal.button.start": "Try VSBLE Pro MAX for FREE",
    "startTrial.modal.button.skip": "Skip Trial, upgrade to PRO MAX",
    "startTrial.modal.welcome.header": "Welcome to 14 days Vsble PRO MAX trial",
    "startTrial.modal.welcome.text":
        "You can use Vsble PRO MAX for 14 days for free — no need to enter a credit card!",
    "startTrial.modal.welcome.button": "OK",
    "removeBranding.header": "Remove branding",
    "removeBranding.text": "Remove Vsble logo from website footer",
    "downgradeToFree.header": "Downgrade to FREE",
    "downgradeToFree.modal.button.confirm": "Confirm downgrade",
    "cards.pay": "Pay with card",
    "cards.info": "{brand} ending in {lastNums} expiring {expMonth}/{expYear}",
    "cards.manage.button": "Manage Card",
    "proUpgrade.header": "VSBLE Pro/Pro MAX upgrade",
    "proUpgrade.step": "Step {step}",
    "proUpgrade.plans.header": "Please select your billing cycle",
    "proUpgrade.plans.desc":
        "You can cancel anytime. If you cancel, your plan will expire at the end of the billing cycle, and not auto-renew.",
    "proUpgrade.plan.price":
        "{currency, select, eur {€} usd {$} other {€}}{price}",
    "proUpgrade.plan.mo": "/mo.",
    "proUpgrade.plan.billed":
        "{price}{currency, select, eur {€} usd {$} other {€}} billed {interval, select, year {yearly} month {monthly} other {daily}}",
    "proUpgrade.plan.button.continue": "Continue to billing info",
    "proUpgrade.billingDetails.header": "Check or update your billing details",
    "proUpgrade.billingDetails.desc":
        "Check your billing information. This information will appear on your invoice.",
    "proUpgrade.billingDetails.button.continue": "Continue to payment",
    "proUpgrade.billingDetails.button.back": "Back",
    "trialEnded.header": "Your Trial VSBLE Pro MAX has ended",
    "trialEnded.header.prices":
        "Upgrade to VSBLE Pro MAX, {currency, select, eur {€} usd {$} other {€}}{monthPrice}/month or {currency, select, eur {€} usd {$} other {€}}{yearPrice}/year",
    "trialEnded.header.features":
        "Unlock unlimited image uploads, video embeds and custom domain",
    "trialEnded.plans.desc":
        "Cancel anytime and your plan will automatically end on the date of the next due payment.",
    "trialEnded.divider.text": "Or",
    "trialEnded.invoice.header": "Pay By Invoice",
    "trialEnded.invoice.text":
        "Pay on each renewal periods manually with various payment methods.",
    "trialEnded.invoice.button": "Pay By Invoice",
    "trialEnded.downgrade.header":
        "Downgrade to Free, {currency, select, eur {€} usd {$} other {€}}0",
    "trialEnded.downgrade.images": "Max. 30 images",
    "trialEnded.downgrade.domain": "Free Vsble.me domain",
    "trialEnded.downgrade.button": "Downgrade",
    "changePassword.header": "Change Password",
    "changePassword.field.oldPassword": "Old Password",
    "changePassword.field.newPassword": "New Password",
    "changePassword.field.confirmNewPassword": "Confirm New Password",
    "changePassword.button.forgotPassword": "Forgot Password?",
    "changePassword.button.submit": "Change Password",
    "changePassword.button.cancel": "Cancel",
    "changePassword.error.required": "All fields are required",
    "changePassword.error.password.match": "Confirmed password does not match",
    "changePassword.status.success":
        "Your password has been successfully changed",
    "changePassword.sendResetLink.header": "Forgot your password?",
    "changePassword.sendResetLink.text":
        "Enter your account's e-mail and we'll send you a magic link to reset the password",
    "changePassword.sendResetLink.field.email": "E-mail",
    "changePassword.sendResetLink.button.submit": "Send Magic Link",
    "changePassword.sendResetLink.button.back": "Back",
    "changePassword.sendResetLink.success.header":
        "E-mail with the reset link has been sent",
    "changePassword.sendResetLink.success.text":
        "Check your inbox (or spam folder)",
    "textEditor.button.save": "Save changes",
    "textEditor.button.cancel": "Cancel",
    "seo.uploadImage": "Open Graph preview image",
    "seo.button.uploadImage": "Upload Image",
    "seo.button.deleteImage": "Delete Image",
    "seo.generateFiles.title": "Generate Sitemap and Robots.txt Links",
    "seo.googleVerification.title": "Google Site Verification",
    "seo.googleVerification.desc": `Verifying your site with Google will give you acces to your website’s private Google Search data.
        You will also be able to affect how Google Search crawled your site.`,
    "seo.googleVerification.input.label": "Google Site Verification Tag",
    "copyToClipboard.title": "Copy to clipboard",
    "navigation.label.socialLinks": "Social Links",
    "templates.label.vien": "VIENNA",
    "templates.label.vien.black": "VIENNA BLACK",
    "templates.label.stockholm": "STOCKHOLM",
    "templates.label.stockholm.black": "STOCKHOLM BLACK",
    "tabs.tab.slideshow": "Slideshow",
    "tabs.tab.logo": "Logo",
    "tabs.tab.portfolio": "Portfolio",
    "editLogo.label.or": "Or Upload Custom Logo",
    "editLogo.label.customLogo": "Custom Logo",
    "editLogo.text.customLogo":
        "Jpg or png files are accepted with max size of 10mb.",
    "editLogo.upload.customLogo": "Upload Custom Logo",
    "editLogo.label.favicon": "Browser Icon (Favicon)",
    "editLogo.text.favicon":
        "Png files are accepted. The favicon will appear in the browser next to your domain name.",
    "editLogo.upload.favicon": "Upload Favicon",
    "editLogo.button.save": "Save",
    "editLogo.button.change": "Change",
    "website.settings.less": "Must be less than 100 characters",
    "website.settings.validation.free":
        "Domain must contain only latin characters and 0-9 numbers in lowercase without dots(.)",
    "website.settings.validation.premium":
        "Domain must contain only latin characters and 0-9 numbers in lowercase without dots(.)",
    "website.settings.validation.premium.vsbleForbidden":
        "Vsble.me is forbidden.",
    "website.settings.validation.common": "Enter valid domain",
    "website.settings.dots": 'Enter a name without "www" or dots.',
    "website.settings.retry": "Retry",
    "website.settings.remove": "Remove",
    "website.settings": "Domain & Website Settings",
    "seo.google": "Google (SEO)",
    "seo.title.label": "Title (browser tab title)",
    "seo.title.placeholder": "VSBLE",
    "seo.pagesDropdown.defaultValue": "All pages",
    "seo.pagesDropdown.label": "Select page",
    "seo.description.label": "Description",
    "seo.description.placeholder": "Porfolio websites for visual artists.",
    "seo.keywords.label": "Keywords",
    "seo.keywords.placeholder":
        "photography, websites, portfolios, website builder, vsble",
    "seo.save": "Save",
    "seo.search": "Search Engines",
    "seo.tab": "Browser Tab",
    "seo.social": "Social Sites",
    "uploadFile.error.fileSize.description":
        "Your file exceeds the upload limit, please upload file smaller than {size}mb.",
    "uploadFile.error.fileType.description": "Accepted {types} files only.",
    "pdfTool.upload.text.info":
        "For optimal quality upload images with at least 2200 px.<br/>JPEG, JPG, and PNG are supported, up to 10MB.",
    "uploadFile.error.header": "Upload error",
    "uploadFile.error.ok": "Ok",
    "current.theme": "Current Theme",
    "templates.label.oslo": "OSLO",
    "templates.label.oslo.black": "OSLO BLACK",
    "templates.label.rotterdam": "ROTTERDAM",
    "templates.label.rotterdam.black": "ROTTERDAM BLACK",
    "templates.label.losangeles": "LOS ANGELES",
    "templates.label.losangeles.black": "LOS ANGELES BLACK",
    "styles.title.albumPage.note": "Image description",
    "page.field.error.url": "Link url cannot be empty",
    "album.label.view.expand": "Expand view",
    "album.label.view.shrink": "Shrink view",
    "page.create.menu.type.html": "HTML",
    "page.create.menu.type.ecwid": "Ecwid shop page",
    "user.menu.integrations": "Integrations",
    "integration.name": "Integrations",
    "integration.soon": "Soon",
    "integration.edit.settings": "Edit settings",
    "integration.ecwid.name": "Ecwid Integration",
    "integration.ecwid.slogan": "Everything you need to sell online",
    "integration.start.free": "Start for free today",
    "integration.footer.code": "Footer Page code",
    "integration.ecwid.code.here": "$> Ecwid code here",
    "integration.save": "Save changes",
    "integration.ecwid.join":
        "Join hundreds of thousands of small businesses who trust Ecwid E-commerce to sell online.",
    "integration.ecwid.setup":
        "Set up your Ecwid store once to easily sync and sell across a website, social media, marketplaces like Amazon, and live in-person.",
    "integration.ecwid.forever.free":
        "With Ecwid, you get free FOREVER. Set up your free account once, and keep it as long as you like. Seriously.",
    "integration.ecwid.add": "Add the Ecwid integration code to VSBLE",
    "integration.ecwid.note.1":
        "Log into your Ecwid account. If you don’t have one yet, create a free account <a href='http://open.ecwid.com/3rq3FR' target='_blank'>here.</a>",
    "integration.ecwid.note.2":
        "In your Ecwid admin, click on “website” in the left panel.",
    "integration.ecwid.note.3":
        'Scroll down to the "Click “Add your store to your website.” on the presented screen, scroll down to Squarespace and select it.',
    "integration.ecwid.note.4":
        "Copy the long code under 2nd point and paste it in your Vsble. Ecwid integration in footer page code area (on the left of this screen).",
    "integration.ecwid.note.5":
        "Create a shop page on your Vsble website and copy the short code from the 1st point into the code area of that page.",
    "integration.ecwid.video": "Watch video tutorial",
    "integration.ecwid.tips": "Tips:",
    "integration.ecwid.help.1": "Selling digital products",
    "integration.ecwid.help.2": "Selling services",
    "integration.ecwid.help.3": "Selling print on-demand",
    "integration.ecwid.help.4": "Selling physical products",
    "integration.ecwid.help.5": "Ecwid help center",
    "integration.watch.tutorial": "Watch video tutorial",
    "integration.calendly.header": "Calendly Integration",
    "integrations.calendly.sample.header": "https://calendly.com/youurl",
    "integration.calendly.teaser":
        "Calendly is your hub for scheduling meetings professionally and efficiently.",
    "integration.calendly.note.header": "Add Calendly to Vsble.",
    "integration.calendly.step.1": "1",
    "integration.calendly.step.1.text": "Create a new  HTML page.",
    "integration.calendly.step.2": "2",
    "integration.calendly.step.2.text":
        "Paste following code and replace the red portion between “ ” with your Calendly calendar page URL.",
    "integration.calendly.step.3": "3",
    "integration.calendly.step.3.text":
        "Name your page (Ex. Booking) and save.",
    "integration.typeform.header": "Typeform Integration",
    "integrations.typeform.sample.header": "REPLACE THIS TEXT WITH YOUR LINK",
    "integration.typeform.teaser":
        "Create forms, surveys, and quizzes that people enjoy answering.",
    "integration.typeform.note.header": "Add Typeform to Vsble.",
    "integration.typeform.step.1": "1",
    "integration.typeform.step.1.text": "Create a new HTML page.",
    "integration.typeform.step.2": "2",
    "integration.typeform.step.2.text":
        "Copy and paste the following code int the HTML Page Code window.",
    "integration.typeform.step.3": "3",
    "integration.typeform.step.3.text":
        "Replace the red text with your Jotform form URL.",
    "integration.jotform.header": "Jotform Integration",
    "integration.jotform.note.header":
        "Add the Blogger integration code to Vsble.",
    "integration.jotform.teaser":
        "Powerful forms that use conditional logic, accept payments, generate reports, and automate workflows.",
    "integration.jotform.step.1": "1",
    "integration.jotform.step.1.text": "Create a new HTML page.",
    "integration.jotform.step.2": "2",
    "integration.jotform.step.2.text":
        "Copy and paste the following code int the HTML Page Code window.",
    "integrations.jotform.sample.header": "REPLACE THIS TEXT WITH YOUR LINK",
    "integration.jotform.step.3": "3",
    "integration.jotform.step.3.text":
        "Replace the red text with your Jotform form URL.",
    "newDesign.page.create.header.html": "Create new html page",
    "newDesign.page.create.ecwid.link": "Ecwid Settings",
    "newDesign.page.create.ecwid.page.code": "Ecwid Page Code",
    "newDesign.page.create.page.code": "Page Code",
    "newDesign.page.update.header.html": "HTML Page Settings",
    "billing.interval.monthly": "Monthly",
    "billing.interval.monthly.renews.info": "Renews monthly until cancelled",
    "billing.interval.yearly.renews.info": "Renews yearly until cancelled",
    "billing.interval.yearly": "Yearly",
    "billing.free.upgrade.features":
        "Unlock all professional features, such as all templates, unlimited uploads, custom domain connection, shop and extension integrations.",
    "billing.trial.upgrade.features":
        "Unlock professional features & unlimited uploads starting at 6€/month",
    "billings.plans.label.saveMoneyInfo":
        "Save {savedForYear}€ ( {savedForYearPercent}% off ). It's like getting {savedMonths} months free!",
    "billingHistory.column.type.promax":
        "VSBLE Pro MAX, {currency, select, eur {€} usd {$} other {€}}{amount}",
    "permission.modal.pro.header": "Unlock PRO features",
    "permission.modal.pro.subMessage":
        "Unlock this and many other features as well as templates, customization of template font & colors, all templates, PDF Tool, favicon customization, custom open graph image, statistics, password protection, single image descriptions and multilingual contact pages with professional plan",
    "permission.modal.pro.max.header": "Unlock PRO MAX features",
    "permission.modal.pro.max.subMessage":
        "Unlock this and many other features as well as shop & integrations, branding removal, expanded album edit mode, focus point (soon), SEO for any page (soon), template customization settings with professional plan",
    "permission.modal.buttonMessage": "Upgrade",
    "focusPoint.modal.message":
        "Move the focal point to determine which part of the image stays focused in the frame.",
    "focusPoint.modal.button.ok": "Save",
    "addInfoAndAlt.modal.button.save": "Save",
    "addInfoAndAlt.modal.button.descriptive": "Descriptive",
    "addInfoAndAlt.modal.button.decorative": "Decorative",
    "addInfoAndAlt.modal.textarea.placeholder": "Sunset over New York",
    "addInfoAndAlt.modal.title": "Info & Alt Text",
    "addInfoAndAlt.modal.alt.subtitle": "ALT-text",
    "addInfoAndAlt.modal.decorative.text":
        "Decorative images don’t convey meaning and don’t require alt text",
    "addInfoAndAlt.modal.descriptive.text":
        "Wrire a description of this image. Alt text is used by screen readers and/or if an image doesn’t load",
    "album.label.focusPoint": "Focus point",
    "album.label.cropper": "Crop and Resize",
    "album.label.info": "Info & Alt Text",
    "album.label.replace": "Replace",
    "cropper.settings.free": "Free",
    "cropper.settings.fixed": "Fixed",
    "cropper.button.save": "Save Changes",
    "cropper.button.cancel": "Cancel",
    "integrations.tawk.header": "Tawk.to Integration",
    "integrations.tawk.step.1": "1",
    "integrations.tawk.step.2": "2",
    "integrations.tawk.step.3": "3",
    "integrations.tawk.teaser":
        "Monitor and chat with the visitors on your website, respond to support tickets and create a help center to empower customers to help themselves – all 100% Free",
    "integrations.tawk.code.here": "Tawk.to code here",
    "integrations.tawk.note.header":
        "Add the Tawk.to integration code to VSBLE",
    "integrations.tawk.note.1":
        "Log into your Tawk.to account. If you don’t have one yet, create a free account",
    "integrations.tawk.note.2":
        "In your Tawk.to dashboard, click on “Administration” icon in the left panel.",
    "integrations.tawk.note.3":
        "Click to “Chat widget“ section, and copy the long code “Widget Code“ and paste it in your Vsble",
    "integrations.livechat.header": "Livechat Integration",
    "integrations.livechat.teaser":
        "A complete customer service platform that delights your customers",
    "integrations.livechat.code.here": "Livechat code here",
    "integrations.livechat.note.header":
        "Add the Livechat integration code to VSBLE",
    "integrations.livechat.note.1":
        "Log into your Livechat account. If you don’t have one yet, create a free account",
    "integrations.livechat.note.2":
        "In your Livechat account, click on “Settings” icon in the left panel.",
    "integrations.livechat.note.3":
        "Click to “Website“ section, and copy the long code “Install LiveChat code manually“ and paste it in your Vsble",
    "integrations.chatra.header": "Chatra Integration",
    "integrations.chatra.teaser":
        "Talk with visitors on your website: live chat, chat bots, email & social messaging for business",
    "integrations.chatra.code.here": "Chatra code here",
    "integrations.chatra.note.header":
        "Add the Chatra integration code to VSBLE",
    "integrations.chatra.step.1": "1",
    "integrations.chatra.step.2": "2",
    "integrations.chatra.step.3": "3",
    "integrations.chatra.note.1":
        "Log into your Chatra account. If you don’t have one yet, create a free account",
    "integrations.chatra.note.2":
        "In your Chatra dashboard, click on “Settings” icon in the left panel.",
    "integrations.chatra.note.3":
        "Click to “Chat widget“ section, and copy the long code and paste it in your Vsble",
    "integrations.helpspace.header": "Helpspace Integration",
    "integrations.helpspace.teaser":
        "A help desk with team inbox, intuitive interface, self-service sites, and multiple inbound channels to get a competitive edge in your market.",
    "integrations.helpspace.code.here": "Helpspace code here",
    "integrations.helpspace.note.header":
        "Add the Helpspace integration code to VSBLE",
    "integrations.helpspace.note.1":
        "Log into your Helpspace account. If you don’t have one yet, create a free account",
    "integrations.helpspace.note.2":
        "In your Helpspace dashboard, click on “Settings” icon in the left panel.",
    "integrations.helpspace.note.3":
        "Click to “Widgets“ section, and copy the long code from “Embed Options“ and paste it in your Vsble",
    "integrations.tidio.header": "Tidio Integration",
    "integrations.tidio.step.1": "1",
    "integrations.tidio.step.2": "2",
    "integrations.tidio.step.3": "3",
    "integrations.tidio.teaser":
        "Chat with your customers, boost their engagement, and watch your sales go through the roof.",
    "integrations.tidio.code.here": "Tidio code here",
    "integrations.tidio.note.header": "Add the Tidio integration code to VSBLE",
    "integrations.tidio.note.1":
        "Log into your Tidio account. If you don’t have one yet, create a free account",
    "integrations.tidio.note.2":
        "In your Tidio dashboard, click on “Settings” icon in the left panel.",
    "integrations.tidio.note.3":
        "Click to “Live chat -> Installation -> Javascript“ section, and copy the long code and paste it in your Vsble",
    "integrations.optinly.header": "Optinly Integration",
    "integrations.optinly.teaser": "Build Smart Email Pop-ups",
    "integrations.optinly.code.here": "Optinly code here",
    "integrations.optinly.note.header":
        "Add the Optinly integration code to VSBLE",
    "integrations.optinly.step.1": "1",
    "integrations.optinly.step.2": "2",
    "integrations.optinly.note.1":
        "Log into your Optinly account. If you don’t have one yet, create a free account",
    "integrations.optinly.note.2":
        "In you Optinly dashboard click on “Installation instructions” on top of the screen.",
    "integrations.optinly.note.3":
        "Select “JavaScript”, copy the provided code snippet and paste it in the code window on this page.",
    "integrations.blogger.header": "Blogger Integration",
    "integrations.blogger.teaser":
        "Create a unique and beautiful blog. It's easy and free.",
    "integrations.blogger.code.here": "Blogger code here",
    "integrations.blogger.note.header":
        "Add the Blogger integration code to VSBLE",
    "integrations.blogger.step.1": "1",
    "integrations.blogger.step.2": "2",
    "integrations.blogger.note.1": "Create a new HTML page",
    "integrations.blogger.note.2":
        "Copy and paste the following code int the HTML Page Code window.",
    "integrations.blogger.sample.header":
        "URL of the website you want to embed",
    "integrations.blogger.sample.text":
        "Your browser does not support iFrames.",
    "integrations.blogger.note.3": "Replace the red text with your blog URL",
    Color: "Color",
    Font: "Font",
    "integrations.weglot.header": "Weglot Integration",
    "integrations.weglot.step.1": "1",
    "integrations.weglot.step.2": "2",
    "integrations.weglot.step.3": "3",
    "integrations.weglot.step.4": "4",
    "integrations.weglot.teaser":
        "Weglot allows you to make your website multilingual in minutes and to manage all your translations effortlessly.",
    "integrations.weglot.code.here": "Weglot code here",
    "integrations.weglot.note.header":
        "Add the Weglot integration code to VSBLE",
    "integrations.weglot.note.1":
        "Login to your Weglot account and click on setting, then on setup.",
    "integrations.weglot.note.2": "Copy the JS code.",
    "integrations.weglot.note.3": 'Add "body" class to dynamic elements.',
    "integrations.weglot.note.4":
        "Paste the code in the code section on this page, click save.",
    "header.whats.new": "What's new",
    "styles.title.visiblepro": "VSBLE Pro",
    "website.settings.visibility.live": "Website is live",
    "website.settings.visibility.offline": "Website is offline",
    "subscription.plan.trial.upgrade.now": "Upgrade now",
    "subscription.plan.trial.title":
        "You have {days} days left in your free trial. Upgrade now to keep using professional features and to optimize your website performance.",
    "subscription.plan.lifetimepro.header": "Lifetime PRO",
    "subscription.plan.pro.cardError":
        "We could not charge your credit card.<br/>Please pay due invoice and update your credit card.",
    "subscription.plan.pro.notice":
        "*Current equivalent in USD, updated daily. Prices will be charged in $ and deducted in the euqivalent of your country currency. The final amount may\n" +
        "vary depending on your bank conversion and applied fees.",
    "subscription.plan.pro.cardError.button": "Pay & update card",
    "downgradeToFree.modal.button.changedMind": "I changed my mind",
    "downgradeToFree.modal.features.header":
        "Your account will downgrade to VSBLE FREE when you hit confirm",
    "downgradeToFree.modal.features.subheader":
        "Your account will be reset to default VSBLE FREE functions. The current template will be reset to one of the default FREE templates Barcelona. Your first uploaded images will remain. Below is a list of key features that you loose and keep with the downgrade.",
    "downgradeToFree.modal.features.loose.header": "Features you loose",
    "downgradeToFree.modal.features.loose": formatMessagesList([
        "— Unlimited image uploads",
        "— All templates included",
        "— Temlate customization",
        "— Custom favicon",
        "— Extended SEO & pen Graph",
        "— Custom domain connection",
        "— PDF Tool",
        "— Password protection for pages",
        "— Integrations & Shop",
        "— Visitor statistics",
        "— Multilingual contact page",
    ]),
    "downgradeToFree.modal.features.keep.header": "Features you keep",
    "downgradeToFree.modal.features.keep": formatMessagesList([
        "— 30 images",
        "— 4 templates",
        "— Black or white template mode",
        "— Basic SEO",
        "— Free custom subdomain",
        "— Video & GIF support",
        "— Mobile optimized version",
        "— FREE forever",
    ]),
    "downgradeToFree.modal.reasons.header":
        "What made you decide to cancel your subscription?",
    "downgradeToFree.modal.reasons": formatMessagesList([
        "I couldn’t use it on my mobile device",
        "It’s missing key features",
        "It’s too expensive",
        "I will upgrade later eventually",
        "I just wasn’t using it enough",
        "It’s too confusing",
        "It’s something else",
    ]),
    "downgradeToFree.modal.message.header":
        "Please describe what’s missing or can be improved in your own words",
    "downgradeToFree.modal.extendTrial.header":
        "Extend Pro MAX trial for 10 days for 10€ ?",
    "downgradeToFree.modal.extendTrial.subHeader":
        "One-time payment, no subscription",
    "downgradeToFree.modal.extendTrial.description":
        "Keep the progress and continue exploring all the features of Vsble PRO MAX for another 10 days before downgrading to a lifetime FREE account.",
    "downgradeToFree.modal.extendTrial.yes": "Yes, extend Pro MAX trial",
    "downgradeToFree.modal.extendTrial.no": "No, downgrade to Free",
    "styles.title.loaderAnimation": "Loader Animation",
    "styles.loaderAnimation.changeButton": "Change Loader",
    "styles.loaderAnimation.modal.header": "Choose your loader",
    "styles.loaderAnimation.modal.saveButton": "Save",
    "styles.loaderAnimation.modal.separator": "Or Upload Custom Loader",
    "styles.loaderAnimation.upload.button": "Upload Custom Loader",
    "styles.loaderAnimation.upload.change": "Change",
    "styles.loaderAnimation.upload.header": "Custom Loader",
    "styles.loaderAnimation.upload.description":
        "SVG or GIF files are accepted with max size of 5mb",
    "seo.toast.success": "Success",
    "integration.support.article": "Support article",

    "integrations.dropinblog.header": "DropInBlog Integration",
    "integrations.dropinblog.teaser":
        "Embed a blog into your site in only 3 minutes. No WordPress needed.",
    "integrations.dropinblog.code.here": "DropInBlog code here",
    "integrations.dropinblog.note.header":
        "Add the DropInBlog integration code to VSBLE",
    "integrations.dropinblog.step.1": "1",
    "integrations.dropinblog.step.2": "2",
    "integrations.dropinblog.note.1":
        "Create a HTML page on your website for the blog.",
    "integrations.dropinblog.note.2":
        "Copy both codes from Dropinblog into the code window of the page.",
};

export default messages;

import styled from 'styled-components';

const HEADER_HEIGHT = 54;
const EDITOR_HEADER_HEIGHT = 59;
const PADDING_TOP = 60;

export const PreviewWrapper = styled.div`
    font-size: 1em !important;
    ${props => props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''};
    padding: 20px;
`;

export const ModalWrapper = styled.div`
    display: ${(props) => props.open ? 'block' : 'none'};
    position: fixed;
    top: ${props => props.fullscreen ? 0 : HEADER_HEIGHT}px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1001;
    padding-top: ${PADDING_TOP}px;
    overflow-y: auto;
    font-size: 14px !important;

    ${props => props.fontFamily ? `font-family: ${props.fontFamily}, sans-serif !important;` : ''}
    ${props => props.textColor ? `color: ${props.textColor};` : ''}
    > div {
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
        font-size: ${props => props.fontSize ? props.fontSize : 1}em !important;

        > div > div {
            max-height: calc(100vh - ${HEADER_HEIGHT + PADDING_TOP + EDITOR_HEADER_HEIGHT}px);
        }

        #fr-logo {
            display: none !important;
        }
        .fr-autoplay-margin {
            display: none !important;
        }
    }

    .fr-wrapper {
        overflow: auto !important;
    }

    .fr-popup_offsreenX {
        left: auto;
        right: 1px;
    }

    .fr-view {
        overflow: visible !important;
        ${props => !props.isPreview ?
            `max-height: calc(100vh - 215px);`
            : ''}
        ${props => props.textColor ? `color: ${props.textColor};` : ''};
        ${props => props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''};
        ${props => props.fontFamily ? `font-family: ${props.fontFamily}, sans-serif !important;` : ''}
        font-size: inherit !important;

        div, h1, h2, h3, h4, h5,
        h6, a, ul, ol, p, span, li .fr-box {
            ${props => props.textColor ? `color: ${props.textColor};` : ''};

            ${props => props.fontFamily ? `font-family: ${props.fontFamily}, sans-serif !important;` : ''}
            div, span {
                font-size: 1em !important;
            }
        }

        img {
            box-sizing: border-box;
        }

        h1 {
            font-size: ${props => props.fontSize ? props.fontSize * 2 : 2}em !important;
        }

        h2 {
            font-size: ${props => props.fontSize ? props.fontSize * 1.5 : 1.5}em !important;
        }

        h3 {
            font-size: ${props => props.fontSize ? props.fontSize * 1.17 : 1.17}em !important;
        }

        h5 {
            font-size: ${props => props.fontSize ? props.fontSize * 0.83 : 0.83}em !important;
        }

        h6 {
            font-size: ${props => props.fontSize ? props.fontSize * 0.67 : 0.67}em !important;
        }

        a, ul, ol, p, h4, span, .fr-box {
            font-size: ${props => props.fontSize ? props.fontSize : 1}em !important;
        }

        a {
            border-bottom: 1px solid ${props => props.textColor ? props.textColor : '#000'};
        }

        ul, ol {
            padding-inline-start: 0 !important;
            line-height: 1.5 !important;
        }

        p {
            margin: 0 !important;
        }
    }

    .custom-layer > div {
        width: 770px;
        margin: 0 20px;

        > .fr-input-line {
            width: 200px;
            margin: auto;
        }

        .middle-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

export const CloseIcon = styled.a`
    position: absolute;
    right: 25px;
    top: 20px;
    width: 32px;
    height: 32px;
    cursor: pointer;


    &:hover {
        opacity: 0.5;
    }

    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #000;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    > button {
        height: 45px !important;

        &:active {
            transform: none !important;
        }
    }

    button + button {
        margin-left: 10px !important;
    }
`;

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const FileUploadIcon = ({ color }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill={iconColors[color]} xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1" fill="white">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.5252 20.1156C37.7896 13.1561 31.4969 8 24 8C18.0129 8 12.7939 11.2884 10.0506 16.1575C4.35028 17.0888 0 22.0364 0 28C0 34.6274 5.37258 40 12 40C12.1752 40 12.3495 39.9962 12.5228 39.9888C12.6809 39.9962 12.84 40 13 40H24H38C43.5228 40 48 35.5228 48 30C48 24.9957 44.3241 20.85 39.5252 20.1156Z"
            />
        </mask>
        <path
            d="M39.5252 20.1156L37.5847 20.5995L37.9068 21.8912L39.2227 22.0926L39.5252 20.1156ZM10.0506 16.1575L10.373 18.1314L11.3214 17.9765L11.793 17.1393L10.0506 16.1575ZM12.5228 39.9888L12.6167 37.991L12.5269 37.9868L12.4372 37.9907L12.5228 39.9888ZM41.4658 19.6316C39.5132 11.8024 32.4367 6 24 6V10C30.5571 10 36.0659 14.5098 37.5847 20.5995L41.4658 19.6316ZM24 6C17.262 6 11.3914 9.7032 8.3081 15.1758L11.793 17.1393C14.1964 12.8736 18.7639 10 24 10V6ZM9.72811 14.1837C3.07618 15.2704 -2 21.0405 -2 28H2C2 23.0322 5.62437 18.9072 10.373 18.1314L9.72811 14.1837ZM-2 28C-2 35.732 4.26801 42 12 42V38C6.47715 38 2 33.5228 2 28H-2ZM12 42C12.2037 42 12.4066 41.9956 12.6085 41.987L12.4372 37.9907C12.2923 37.9969 12.1466 38 12 38V42ZM13 38C12.8713 38 12.7436 37.997 12.6167 37.991L12.429 41.9866C12.6183 41.9955 12.8087 42 13 42V38ZM24 38H13V42H24V38ZM38 38H24V42H38V38ZM46 30C46 34.4183 42.4183 38 38 38V42C44.6274 42 50 36.6274 50 30H46ZM39.2227 22.0926C43.0601 22.6798 46 25.9984 46 30H50C50 23.993 45.5882 19.0202 39.8278 18.1386L39.2227 22.0926Z"
            fill={iconColors[color]}
            mask="url(#path-1-inside-1)"
        />
        <path
            d="M21.825 25.7143H18L24 20L30 25.7143H26.175V30H21.825V25.7143Z"
            fill={iconColors[color]}
        />
    </svg>
);

FileUploadIcon.propTypes = {
    ...iconColorPropTypes,
};

FileUploadIcon.defaultProps = {
    color: 'dark',
};

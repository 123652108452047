import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const Rotate45Icon = ({ color }) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99473 7.62609e-06C4.83596 -0.00568472 1.3664 3.17599 1.01274 7.3197C0.659089 11.4634 3.5393 15.187 7.63889 15.8862C11.7385 16.5853 15.69 14.0267 16.7297 10H14.6487C13.6315 12.8771 10.6168 14.5324 7.6432 13.8465C4.66964 13.1605 2.68464 10.3519 3.03055 7.3199C3.37646 4.28792 5.94308 1.99856 8.99473 2.00001C10.5843 2.00234 12.1061 2.64379 13.2177 3.78002L9.99973 7.00001H16.9997V7.62609e-06L14.6487 2.35002C13.1525 0.844637 11.1172 -0.0012743 8.99473 7.62609e-06Z"
            fill={iconColors[color]}
        />
    </svg>
);

Rotate45Icon.propTypes = {
    ...iconColorPropTypes,
};

Rotate45Icon.defaultProps = {
    color: 'default',
};

import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;

    @media (max-width: 1024px) {
        margin-bottom: 30px;
    }
`;

export const CreateCardContainer = styled.div`
    text-align: center;
    > span {
        cursor: pointer;
        color: #58C367;
        font-size: 13px;
        line-height: 150%;
    }
`;

export const UpdateCardContainer = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    > span {
        font-size: 13px;
        line-height: 150%;

        &:first-child {
            ${props => !props.main ? 'visibility: hidden; height: 0;' : ''}
        }

        &:last-child {
            cursor: pointer;
            color: #58C367;
            ${props => !props.main ? 'visibility: hidden; height: 0;' : ''}
        }
    }

    @media (max-width: 480px) {
        flex-wrap: wrap;

        & > span:first-child {
            width: 100%;
            ${props => props.main ? 'margin-bottom: 10px;' : ''}
        }
    }
`;

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    background: #FDE5E9;
    padding: 13px;
    margin-top: 20px;
    border-radius: 2px;

    ${({invoiceUrl}) => invoiceUrl && 'justify-content: space-between;'}

    &:first-child {
        margin-top: 0;
    }

    & > svg {
        min-width: 20px;
    }

    & > span, & > a {
        color: #FF002E;
        font-size: 11px;
        line-height: 140%;
        margin-left: 12px;
    }

    & > a {
        padding: 5px;
        color: white;
        background-color: #f95758;
        white-space: nowrap;
    }
`;

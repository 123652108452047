import React, {useEffect, useState, useMemo} from 'react';
import {FocusedImage} from 'image-focus';
import {FocusedImageNode, FocusedImagesContainer, GridImages, CloseIconNode} from './nodes';
import {CloseIcon} from 'packages/components/icons';

const imgs = Array.from('123456789');

export function FocusPreview({
    src,
    xFocalPoint,
    yFocalPoint,
}) {
    const refs = useMemo(() => imgs.map(() => React.createRef()), []);

    const [focusedImages, setFocusedImages] = useState([]);

    useEffect(() => {
        const fInstances = [];
        const focus = {x: 0, y: 0};
        refs.forEach(ref => fInstances.push(new FocusedImage(ref.current, {
            focus,
            debounceTime: 17,
            updateOnWindowResize: true,
        })));
        setFocusedImages(fInstances);
    }, [refs]);

    useEffect(() => {
        if (!focusedImages.length) {
            return;
        }
        focusedImages.forEach(focusedImage => focusedImage.setFocus({x: xFocalPoint, y: yFocalPoint}));
    }, [focusedImages, xFocalPoint, yFocalPoint]);

    return (
        <FocusedImagesContainer>
            <CloseIconNode>
                <CloseIcon color={'white'}/>
            </CloseIconNode>
            <GridImages>
                {imgs.map((el, i) => (
                    <div key={i}>
                        <FocusedImageNode
                            ref={refs[i]}
                            src={src}
                            alt=""
                            data-focus-x={xFocalPoint}
                            data-focus-y={yFocalPoint}
                        />
                    </div>
                ))}
            </GridImages>
        </FocusedImagesContainer>
    );
}

import styled from "styled-components";
import { Modal } from "packages/components/modal";

export const ModalContainer = styled(Modal)`
    position: initial !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    text-align: center;
    color: #343840;

    .title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        padding: 0;
        border: none;
    }

    .body {
        margin-top: 10px;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
    }

    .footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .button {
            font-size: 13px;
            font-weight: 400;
            width: 100%;
            max-height: 45px;
            margin: 0 5px !important;
        }
    }
`;

export const PermissionsOverlayContainer = styled.div`
    display: inherit;
    ${(props) =>
        props.isAllowed
            ? ""
            : "& input,textarea,.rc-slider {pointer-events: none}"}
`;

import {compose} from 'redux';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import {graphql} from 'react-apollo';

import {open as openErrorModal} from 'packages/redux/modules/modalConfirmDelete/actions';
import {lockModal} from 'packages/redux/modules/modalProgress/actions';
import websitePhotoListDelete from '@graphql/gql/website/websitePhotoListDelete.gql';
import get from 'lodash/get';

import PhotosTab from '@configurator/components/slideshow/PhotosTab';

export const slideshowPhotosWrapper = (Component) => compose(
    connect(
        // mapStateToProps
        state => ({
            uploads: state.getIn(['uploadPhotos', 'uploads']),
        }),
        // mapDispatchToProps
        dispatch => ({
            lockModalProgress: () => dispatch(lockModal(true)),
            unLockModalProgress: () => dispatch(lockModal(false)),
            openErrorModal: ({
                headerMessageId,
                yesMessageId,
                subMessageId,
                hideCancelButton,
            }) =>
                dispatch(openErrorModal({
                    headerMessageId,
                    yesMessageId,
                    subMessageId,
                    hideCancelButton,
                })),
        }),
    ),
    graphql(websitePhotoListDelete, {
        props({mutate, ownProps: {openErrorModal}}) {
            return {
                async removeAllPhotos({
                    photoIds,
                }) {
                    try {
                        const res =  await mutate({
                            variables: {
                                photoIds,
                            },
                        });
                        if (get(res, 'data.websitePhotoListDelete.errors._error')) {
                            openErrorModal({
                                headerMessageId: 'slideshow.removeAll.error.header',
                                yesMessageId: 'slideshow.removeAll.error.ok',
                                subMessageId: get(res, 'data.websitePhotoListDelete.errors._error'),
                                hideCancelButton: true,
                            });
                        }
                        return res;
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => {
            return {
                ...initialValues,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
    }),
)(Component);

export default slideshowPhotosWrapper(PhotosTab);

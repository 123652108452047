import styled from "styled-components";
import { Container as CardContainer } from "../subscriptionCard/nodes";

export const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0 auto;

    & > ${CardContainer} {
        margin: 4px 0 0 4px;
    }

    @media (max-width: 550px) {
        flex-direction: column;

        & > ${CardContainer} {
            max-width: 100%;
            margin: 2px 0;
        }
    }
`;

export const TrialTitle = styled.span`
    display: block;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
`;

export const Notice = styled.div`
    padding: 10px 12px 0 12px;
    font-size: 12px;
`

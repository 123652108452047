import React from "react";
import PropTypes from "prop-types";
import { renderRoutes } from "react-router-config";
import Layout from "packages/containers/LayoutContainer";

export const Configurator = ({ route }) => {
    return <Layout leftPanel={renderRoutes(route.routes)} />;
};

Configurator.propTypes = {
    route: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

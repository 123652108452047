import _ from 'lodash';

import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

import PropTypes from 'prop-types';
import React, {Children} from 'react';

import isBrowser from 'packages/utils/isBrowser';
import {userAgentAction} from 'packages/redux';
import {mobileBreakpoint} from 'packages/enum';

const checkMobile = () => isBrowser
    ? window.innerWidth < mobileBreakpoint.thin
    : undefined;

export default class Layout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        // For storybook usage
        isMobile: PropTypes.bool,
    };
    static defaultProps = {
        isMobile: undefined,
    };
    static contextTypes = {
        store: PropTypes.object,
        intl: PropTypes.object,
    };
    static childContextTypes = {
        userAgent: PropTypes.object,
    };

    /**
     * Puts userAgent in context.
     */
    getChildContext() {
        const {store} = this.context;
        if (store) {
            // normal usage
            return {
                userAgent: store.getState()
                    .getIn(['userAgent'])
                    .toJS(),
            };
        } else {
            // storybook usage
            let {isMobile} = this.props;
            return {
                userAgent: {
                    isMobile: _.isUndefined(isMobile) ? checkMobile() : isMobile,
                },
            };
        }
    }

    constructor(...args) {
        super(...args);
        this.onResize = _.debounce(this.onResize, 200);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize, {passive: true});
        Sentry.init({
            dsn: "https://d8ab3e49ae0d40aaa27fada0895321fc@o122070.ingest.sentry.io/268707",
            integrations: [new Integrations.BrowserTracing()],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    /**
     * Update `isMobile` in redux after resize
     */
    onResize = () => {
        const {store} = this.context;
        if (store) {
            store.dispatch(userAgentAction({
                isMobile: checkMobile(),
            }));
            // перенес внутрь, перерендривало все приложение
            this.forceUpdate(); // under storybook
        }
    };

    redirectToHome = () => window.location.href = '/';

    render() {
        return (
            <Sentry.ErrorBoundary fallback={"Oops. Something went wrong"} showDialog>
                {Children.only(this.props.children)}
            </Sentry.ErrorBoundary>
        )
    }
}

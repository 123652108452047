import React from 'react';
import PropTypes from 'prop-types';

export const BarChartIcon = ({className, onClick}) => (
    <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle className="iconBg" cx="20" cy="20" r="20" fill="white" onClick={onClick}/>
        <path
            className="main"
            d="M19 13H16V29H19V13Z"
            onClick={onClick}
        />
        <path
            className="main"
            d="M14 21H11V29H14V21Z"
            onClick={onClick}
        />
        <path
            className="main"
            d="M21 16H24V29H21V16Z"
            onClick={onClick}
        />
        <path
            className="main"
            d="M29 19H26V29H29V19Z"
            onClick={onClick}
        />
    </svg>
);

BarChartIcon.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

BarChartIcon.defaultProps = {
    className: '',
    onClick: () => {
    },
};

import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {setImages, deleteAll, setOpenDownload} from './actions';


const defaultState = fromJS({
    images: [],
    openDownload: false,
});

export default handleActions({
    [setImages]: {
        next(state, {payload}) {
            return state.set('images', fromJS(payload));
        },
    },
    [deleteAll]: {
        next() {
            return defaultState;
        },
    },
    [setOpenDownload]: {
        next(state, {payload}) {
            return state.set('openDownload', payload);
        },
    },
}, defaultState);

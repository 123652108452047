import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Layout from "packages/components/layout/Layout";
import { graphql } from "react-apollo";
import userlistTokenGet from "@graphql/gql/common/userlistTokenGet.gql";
import isEmpty from "lodash/isEmpty";

export default compose(
    withRouter,
    connect(
        // mapStateToProps
        (state) => ({
            name: state.getIn(["user", "name"]),
            email: state.getIn(["user", "email"]),
            expandedView: state.getIn(["albumView", "expandedView"])
        }),
        (dispatch) => ({})
    ),
    graphql(userlistTokenGet, {
        options() {
            return {
                variables: {},
                ssr: false,
            };
        },
        props({ data: { loading, userlistTokenGet, refetch } }) {
            if (loading) {
                return { loading };
            }
            if (isEmpty(userlistTokenGet)) {
                return;
            }
            const { data } = userlistTokenGet;

            return { token: data };
        }
    })
)(Layout);

import styled, { css } from "styled-components";

export const Container = styled.div`
    max-width: 1220px;
    margin: 30px auto;
`;

export const Header = styled.div`
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    /* Dark */
    color: #343840;
    margin-bottom: 30px;
`;
export const Teaser = styled.div`
    height: 100px;
    background: #ffffff;
    border-radius: 6px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;

    margin-bottom: 40px;
`;
export const TeaserText = styled.div`
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => (props.small ? "15" : "20")}px;
    max-width: 600px;
    line-height: 150%;
    color: #343840;
`;

export const Logo = styled.div`
    width: 190px;
`;

export const FormWrapper = styled.div`
    margin-bottom: 40px;
`;

export const Grid = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
        width: 600px;
    }
`;

export const Notes = styled.div`
    background: #ffffff;
    border-radius: 6px;
    padding: 30px;
`;
export const NoteHeader = styled.div`
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    /* Dark */

    color: #343840;

    margin-bottom: 44px;
`;
export const FooterText = styled.div`
    margin-bottom: 10px;
    font-family: Sailec;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    /* or 22px */

    /* Dark */

    color: #343840;
`;

export const StyledTextareaContainerLabel = styled.div`
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    /* or 18px */
    /* Dark */
    color: #343840;

    margin-bottom: 10px;
`;

export const StyledTextareaContainer = styled.div`
    margin-bottom: 10px;

    textarea {
        min-height: 230px !important;
        background: #343840 !important;
        color: white !important;
        border-color: #343840 !important;

        &:focus {
            border-color: #343840 !important;
        }

        &::placeholder {
            color: white !important;
        }
    }
`;

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StepNumber = styled.div`
    min-width: 44px;

    > div {
        background: #343840;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;

        > span {
            line-height: 27px;
            font-weight: bold;
            font-size: 13px;
            color: #ffffff;
        }
    }
`;

export const StepContent = styled.div`
    flex-grow: 1;
`;
export const Step = styled.div`
    display: flex;
`;

export const Paragraph = styled.div`
    ${(props) =>
        props.type === "h1" &&
        css`
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 150%;
        `}
    ${(props) =>
        props.type === "h2" &&
        css`
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
        `}
    > span > a {
        color: #34cf49;
        font-weight: bold;
    }

    margin-top: ${(props) => props.mt || 0}px;
    margin-bottom: ${(props) => props.mb || 0}px;

    color: #343840;
`;

export const TipsHeader = styled.div`
    margin-left: 28px;
    margin-top: 20px;
    margin-bottom: 14px;
    display: block;
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
`;

export const TipsContent = styled.a`
    margin-left: 28px;
    display: block;
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    /* or 18px */
    /* Dark */
    color: #343840;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 10px;
    &:hover {
        text-decoration: underline;
        color: #34cf49;
    }
`;
export const VideoLink = styled.a`
    margin-top: 16px;
    display: block;
    font-family: Sailec;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    /* or 18px */
    /* Dark */
    color: #343840;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
        color: #34cf49;
    }
`;

export const CodeSample = styled.span`
    margin-top: 16px;
    margin-bottom: ${props => `${props.mb}px` || 0};
    display: block;
    font-family: Sailec;
    font-weight: ${props => props.normal ? 'normal' : 'bold'};
    font-size: 14px;
    line-height: 140%;
    /* or 18px */
    /* Dark */
    color: #343840;
    font-style: ${props => props.normal ? 'normal' : 'italic'};

    > b {
      color: red;
    }
`;

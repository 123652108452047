import {connect} from 'react-redux';
import {graphql} from 'react-apollo';
import {compose} from 'redux';
import {withFormik} from 'formik';
import isEmpty from 'lodash/isEmpty';

import websiteMetaUpdate from '@graphql/gql/website/websiteMetaUpdate.gql';
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql';

import OnlineToggle from '@configurator/components/statistics/statisticsHeader/OnlineToggle';
import { applyFilter } from '@configurator/redux/modules/periodPicker/actions';
import {open as openErrorModal} from 'packages/redux/modules/modalYesNo/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    openErrorModal: ({
        subMessageId,
    }) =>
        dispatch(openErrorModal({
            subMessageId,
        })),
    applyFilter: (payload) => dispatch(applyFilter(payload)),
});

export const onlineToggleWrapper = Component =>  compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(websiteMetaGet, {
        options() {
            return {
                variables: {},
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websiteMetaGet, refetch}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(websiteMetaGet)) {
                return;
            }
            const {data} = websiteMetaGet;

            return {initialValues: {...data }, refetchMeta: refetch};
        },
    }),
    graphql(websiteMetaUpdate, {
        props({mutate}) {
            return {
                async updateMeta({
                    matomoOn,
                    id,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                matomoOn,
                                websiteId: id,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({ initialValues }) => {
            return {
                ...initialValues,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        handleSubmit: () => null,
    })
)(Component);

export default onlineToggleWrapper(OnlineToggle);

import styled, {css} from 'styled-components';

export const Container = styled.div`
    ${({width, height, color}) => css`
        &,
        & div {
            box-sizing: border-box;
        }
        & {
            position: relative;
            width: 80px;
            height: 80px;
            transform: scaleX(${width / 80}) scaleY(${height / 80});
            transform-origin: left top;
            color: ${color};
        }
        & div {
            position: absolute;
            top: 33.33333px;
            width: 13.33333px;
            height: 13.33333px;
            border-radius: 50%;
            background: currentColor;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }
        & div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
        }
        & div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
        }
        & div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
        }
        & div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
        }
        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(24px, 0);
            }
        }
    `}
`;

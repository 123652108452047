import React from "react";
import PropTypes from "prop-types";
import { intlShape } from "react-intl";
import Loader from "@configurator/components/loader/Loader";
import { Header, NoItemsMsg, Content, ContentHeader, Item } from "./nodes";
import { PLAN_TYPE } from "packages/enum";

const BillingHistory = (
    { data, loading },
    { intl: { formatDate, formatMessage } }
) => {
    if (loading) {
        return <Loader />;
    }
    const hasData = data && data.length > 0;
    return (
        <Content>
            <Header>{formatMessage({ id: "billingHistory.header" })}</Header>
            <ContentHeader hide={!hasData}>
                <span>
                    {formatMessage({ id: "billingHistory.column.date" })}
                </span>
                <span>
                    {formatMessage({ id: "billingHistory.column.type" })}
                </span>
                <span>
                    {formatMessage({ id: "billingHistory.column.status" })}
                </span>
                <span>
                    {formatMessage({ id: "billingHistory.column.link" })}
                </span>
            </ContentHeader>
            {hasData ? (
                data.map((item) => {
                    let billingHistoryText;
                    switch (item.planType) {
                        case PLAN_TYPE.PROLITE:
                            billingHistoryText = "billingHistory.column.type.prolite";
                            break;
                        case PLAN_TYPE.PRO:
                            billingHistoryText = "billingHistory.column.type.pro";
                            break;
                        default:
                            billingHistoryText = "billingHistory.column.type.promax";
                    }
                    return (
                        <Item isNotPaid={item.status !== "paid" && item.status !== "open"}>
                        <span>
                            {formatDate(new Date(item.paymentDate), {
                                year: "numeric",
                                month: "long",
                                day: "2-digit"
                            })}
                        </span>
                            <span>
                            {formatMessage(
                                {
                                    id: billingHistoryText
                                },
                                {
                                    amount: item.amount,
                                    currency: item.currency
                                }
                            )}
                        </span>
                            <span>
                            {item.status === "paid"
                                ? formatMessage({
                                    id: "billingHistory.status.paid"
                                }) : null
                            }
                                {item.status === "unpaid"
                                    ? formatMessage({
                                        id: "billingHistory.status.notpaid"
                                    }) : null
                                }
                                {item.status === "open"
                                    ? formatMessage({
                                        id: "Processing"
                                    }) : null
                                }
                        </span>
                            <a
                                href={item.invoiceUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {
                                    item.status === "paid"
                                        ? formatMessage({
                                            id: "billingHistory.column.link.download"
                                        }) : null
                                }
                                {
                                    item.status === "open" ? formatMessage({
                                        id: "Invoice info"
                                    }) : null
                                }
                                {
                                    item.status === "unpaid" ? formatMessage({
                                        id: "billingHistory.column.link.pay.now"
                                    }) : null
                                }
                            </a>
                        </Item>
                    );
                })
            ) : (
                <NoItemsMsg>
                    {formatMessage({ id: "billingHistory.noItems.message" })}
                </NoItemsMsg>
            )}
        </Content>
    );
};

BillingHistory.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};

BillingHistory.defaultProps = {
    data: [],
    loading: false
};

BillingHistory.contextTypes = {
    intl: intlShape.isRequired
};

export default BillingHistory;

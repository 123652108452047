import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';
import Loader from '@configurator/components/loader/Loader';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import {
    Container,
    UpdateCardContainer,
    ErrorContainer,
} from './nodes';
import {SUBSCRIPTION_TYPE} from 'packages/enum';
import {AttentionIcon} from 'packages/components/icons';

const Cards = ({
    config,
    loading,
    cards,
    billingCreateCustomerPortal,
    subscriptions,
    billingHistory,
}, {intl: {formatMessage}}) => {
    const invoiceUrl = useMemo(() => billingHistory?.find(el => (el.status !== 'paid' && el.status !== 'open'))?.invoiceUrl, [billingHistory]);

    const isCardHasError = useMemo(() => {
        if (invoiceUrl) {
            return true;
        }
        if (subscriptions?.length) {
            const [{subscriptionType, status}] = subscriptions;
            return subscriptionType === SUBSCRIPTION_TYPE.PAID && status !== 'active' && status !== 'trialing';
        }
    }, [invoiceUrl, subscriptions]);

    if (!config || (!cards.length && !isCardHasError)) {
        return null;
    }
    if (loading) {
        return (<Loader/>);
    }

    return (
        <Container>
            {cards.length > 0 && orderBy(cards, ['email'])
                .map(item => (
                    <UpdateCardContainer
                        key={get(item, 'id')}
                        main={get(item, 'email')}
                    >
                        <span>{formatMessage({id: 'cards.pay'})}</span>
                        <span>
                        {formatMessage({id: 'cards.info'}, {
                            brand: upperFirst(get(item, 'brand')),
                            lastNums: get(item, 'last4'),
                            expMonth: get(item, 'expMonth'),
                            expYear: get(item, 'expYear'),
                        })}
                            </span>
                        <span
                            onClick={async () => {
                                const res = await billingCreateCustomerPortal();
                                if (get(res, 'data.billingCreateCustomerPortal.data.sessionId')) {
                                    window.location.href = get(res,
                                        'data.billingCreateCustomerPortal.data.sessionId');
                                }
                            }}
                        >
                                {formatMessage({id: 'cards.manage.button'})}
                            </span>
                    </UpdateCardContainer>
                ))}
            {isCardHasError && (
                <ErrorContainer invoiceUrl={invoiceUrl}>
                    <AttentionIcon color="red"/>
                    <span dangerouslySetInnerHTML={{__html: formatMessage({id: 'subscription.plan.pro.cardError'})}}/>
                    {invoiceUrl && (
                        <a
                            href={invoiceUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {formatMessage({ id: 'subscription.plan.pro.cardError.button' })}
                        </a>
                    )}
                </ErrorContainer>
            )}
        </Container>
    );
};

Cards.propTypes = {
    config: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    billingCreateCustomerPortal: PropTypes.func.isRequired,
    cards: PropTypes.array,
    subscriptions: PropTypes.array,
    billingHistory: PropTypes.array,
};

Cards.defaultProps = {
    subscriptions: [],
    cards: [],
    billingHistory: [],
};

Cards.contextTypes = {
    intl: intlShape.isRequired,
};

export default Cards;

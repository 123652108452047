import React, { useEffect } from 'react';
import {injectIntl, intlShape} from 'react-intl';
import {useQuery, useMutation} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';

// graphql
import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql';
import websiteMetaUpdateMutation from '@graphql/gql/website/websiteMetaUpdate.gql';

import {HeaderStyled} from 'packages/components/layout/nodes';

import VisibilitySection from './sections/VisibilitySection';
import FreeSection from './sections/FreeSection';
import PremiumSection from './sections/PremiumSection';
import Loader from '../loader/Loader';
import PrivacySection from './sections/PrivacySection';


import {
    Container,
    Header,
} from './nodes';
import {useSubscription} from "@configurator/providers/subscription";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import { USERLIST_SERVICE } from "packages/userlist/Userlist";


const WebsiteSettings = ({
                             intl: {formatMessage},
                         }) => {
    const {loading, data: {websiteMetaGet} = {}} = useQuery(websiteMetaGetQuery, {
        fetchPolicy: 'network-only',
    });
    const [websiteMetaUpdate, { error: metaUpdateError }] = useMutation(websiteMetaUpdateMutation);

    useEffect(() => {
        USERLIST_SERVICE.trackWebsiteSettingsPageVisited();
    }, []);

    if (loading) {
        return <Loader/>;
    }
    if (
        isEmpty(websiteMetaGet)
        || !isEmpty(websiteMetaGet.errors)
        || !isEmpty(metaUpdateError)) {
        return <div>Error</div>;
    }
    const { data } = websiteMetaGet;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { permissions } = useSubscription();

    // toggle visibility
    const toggleVisibility = () => {
        // add loader
        websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                visible: !data.visible,
            },
            refetchQueries: [{
                query: websiteMetaGetQuery,
                variables: {},
            }],
        });
    };

    // toggle cookie consent solution
    const toggleCookieSolution = () => {
        // add loader
        websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                cookie: !data.cookie,
            },
            refetchQueries: [{
                query: websiteMetaGetQuery,
                variables: {},
            }],
        });
    };

    // toggle remove branding
    const toggleRemoveBranding = () => {
        // add loader
        websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                branding: !data.branding,
            },
            refetchQueries: [{
                query: websiteMetaGetQuery,
                variables: {},
            }],
        });
    };


    // toggle change domain
    const changeFreeDomain = ({domain}) => {
        // add loader
        return websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                domain,
            },
            refetchQueries: [{
                query: websiteMetaGetQuery,
                variables: {},
            }],
        }).then(mutationResult => {
            const mutationError = mutationResult?.data?.websiteMetaUpdate?.errors?._error;
            if (mutationError) {
                throw new Error(mutationError);
            }
        });
    };

    const toggleCookieBar = () => {
        // add loader
        websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                displayCookieBanner: !data.displayCookieBanner,
            },
            refetchQueries: [{
                query: websiteMetaGetQuery,
                variables: {},
            }],
        });
    };

    const toggleBlockFonts = () => {
        // add loader
        websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                blockFonts: !data.blockFonts,
            },
            refetchQueries: [{
                query: websiteMetaGetQuery,
                variables: {},
            }],
        });
    };

    return (
        <React.Fragment>
            <HeaderStyled backArrow/>
            <Header>
                {formatMessage({id: 'website.settings'})}
            </Header>
            <Container>
                <div>
                    <div>
                        <PermissionsOverlay isAllowed={permissions.WEBSITE_VISIBILITY}>
                            <VisibilitySection
                                values={{
                                    visible: data.visible,
                                    cookie: data.cookie,
                                    branding: data.branding
                                }}
                                onChange={{toggleVisibility, toggleCookieSolution, toggleRemoveBranding}}
                            />
                        </PermissionsOverlay>
                        <FreeSection
                            domain={data.domain}
                            changeDomain={changeFreeDomain}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <PremiumSection
                            freeDomain={data.domain}
                            websiteId={data.id}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <PrivacySection
                            onChange={{toggleCookieBar,toggleBlockFonts}}
                            values={{
                                cookies: data.displayCookieBanner,
                                fonts: data.blockFonts,
                            }}
                        />
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
};

WebsiteSettings.propTypes = {
    intl: intlShape.isRequired,
};

WebsiteSettings.defaultProps = {};

export default injectIntl(WebsiteSettings);

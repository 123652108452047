import React from "react";
import { injectIntl, intlShape } from "react-intl";

import { HeaderStyled } from "packages/components/layout/nodes";
import {
    Container,
    Header,
    Teaser,
    TeaserText,
    Logo,
    FormWrapper,
    Grid,
    Notes,
    NoteHeader,
    Step,
    StepContent,
    StepNumber,
    StepWrapper,
    Paragraph,
    VideoLink,
    CodeSample,
} from "../nodes";
import Image from "packages/components/gatsby-image";
import typeformImg from "../../assets/typeform.png";

import { Button } from "packages/components/buttons";
import { wrapper } from "../../../../containers/integrations/IntegrationsDetails";
import typeform_instructions from "../../assets/typeform_instructions.png";

const Typeform = ({intl: { formatMessage }}) => {
    return (
        <>
            <HeaderStyled backArrow />
            <Container>
                <Header>
                    {formatMessage({
                        id: "integration.typeform.header",
                    })}
                </Header>
                <Teaser>
                    <Logo>
                        <Image
                            fluid={{
                                src: typeformImg,
                                aspectRatio: 1,
                            }}
                            alt=""
                            fadeIn
                            imgStyle={{ objectFit: "contain" }}
                            loading="lazy"
                            backgroundColor="white"
                        />
                    </Logo>
                    <TeaserText small>
                        {formatMessage({
                            id: "integration.typeform.teaser",
                        })}
                    </TeaserText>
                    <div>
                        <Button
                            as="a"
                            href={"https://www.typeform.com/signup/"}
                            target="_blank"
                            content={formatMessage({
                                id: "integration.start.free",
                            })}
                        />
                    </div>
                </Teaser>
                <Grid>
                    <div>
                        <FormWrapper>
                            <Image
                                fluid={{
                                    src: typeform_instructions,
                                    aspectRatio: 3 / 2.1,
                                }}
                                alt=""
                                fadeIn
                                loading="lazy"
                                backgroundColor="white"
                            />
                        </FormWrapper>
                    </div>
                    <div>
                        <Notes>
                            <NoteHeader>
                                {formatMessage({
                                    id: "integration.typeform.note.header",
                                })}
                            </NoteHeader>
                            <StepWrapper>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>
                                                {formatMessage({
                                                    id: "integration.typeform.step.1",
                                                })}
                                            </span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={25}>
                                            <b>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: formatMessage({
                                                            id: "integration.typeform.step.1.text",
                                                        }),
                                                    }}
                                                />
                                            </b>
                                        </Paragraph>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>
                                                {formatMessage({
                                                    id: "integration.typeform.step.2",
                                                })}
                                            </span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            <b>
                                                {formatMessage({
                                                    id: "integration.typeform.step.2.text",
                                                })}
                                            </b>
                                        </Paragraph>
                                        <CodeSample>
                                            {`<iframe src="`}
                                            <b>
                                                {formatMessage({
                                                    id: "integrations.typeform.sample.header",
                                                })}
                                            </b>
                                            {`" width="100%" height="800" frameBorder="0">`}
                                            <br />
                                            <p>
                                                {formatMessage({
                                                    id: "integrations.blogger.sample.text",
                                                })}
                                            </p>
                                            <br />
                                            {`</iframe>`}
                                        </CodeSample>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>
                                                {formatMessage({
                                                    id: "integration.typeform.step.3",
                                                })}
                                            </span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            <b>
                                                {formatMessage({
                                                    id: "integration.typeform.step.3.text",
                                                })}
                                            </b>
                                        </Paragraph>

                                        <VideoLink
                                            href={"https://support.vsble.me/article/114/typeform-forms"}
                                            target={"_blank"}
                                        >
                                            {formatMessage({
                                                id: "integration.support.article",
                                            })}
                                        </VideoLink>
                                    </StepContent>
                                </Step>
                            </StepWrapper>
                        </Notes>
                    </div>
                </Grid>
            </Container>
        </>
    );
};

Typeform.propTypes = {
    intl: intlShape.isRequired,
};

Typeform.defaultProps = {};

export default wrapper(injectIntl(Typeform), "jotform");

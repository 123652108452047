import {compose} from 'redux';
import {graphql} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';

import {open as openErrorModal} from 'packages/redux/modules/modalConfirmDelete/actions';
import {lockModal} from 'packages/redux/modules/modalProgress/actions';
import {MESSAGE_TYPE} from 'packages/enum';

import websiteMetaUpdate from '@graphql/gql/website/websiteMetaUpdate.gql';
import websiteLogoUpdate from '@graphql/gql/website/websiteLogoUpdate.gql';
import websiteFaviconUpdate from '@graphql/gql/website/websiteFaviconUpdate.gql';
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql';
import websiteFaviconDelete from '@graphql/gql/website/websiteFaviconDelete.gql';
import websiteLogoDelete from '@graphql/gql/website/websiteLogoDelete.gql';

import EditLogo from '@configurator/components/editLogo/EditLogo';
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

const validationSchema = Yup.object()
    .shape({
        siteName: Yup.string().nullable(),
    });

export default compose(
    connect(
        // mapStateToProps
        () => ({}),
        // mapDispatchToProps
        dispatch => ({
            lockModalProgress: () => dispatch(lockModal(true)),
            unLockModalProgress: () => dispatch(lockModal(false)),
            openErrorModal: ({
                headerMessageId,
                yesMessageId,
                subMessageId,
                subMessageValues,
            }) =>
            dispatch(openErrorModal({
                headerMessageId,
                yesMessageId,
                subMessageId,
                subMessageValues,
                hideCancelButton: true,
            })),
        }),
    ),
    graphql(websiteMetaUpdate, {
        props({mutate}) {
            return {
                async updateMeta({siteName, siteDescription, id, logo}) {
                    try {
                        return await mutate({
                            variables: {
                                siteName,
                                siteDescription,
                                websiteId: id,
                                logo,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteLogoUpdate, {
        props({mutate}) {
            return {
                async updateLogo({
                    file,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                file,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteFaviconUpdate, {
        props({mutate}) {
            return {
                async updateFavicon({
                    file,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                file,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteLogoDelete, {
        props({mutate}) {
            return {
                async deleteLogo() {
                    try {
                        return await mutate({
                            variables: {},
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteFaviconDelete, {
        props({mutate}) {
            return {
                async deleteFavicon() {
                    try {
                        return await mutate({
                            variables: {},
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteMetaGet, {
        options() {
            return {
                variables: {},
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websiteMetaGet, refetch}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(websiteMetaGet)) {
                return;
            }
            const {data} = websiteMetaGet;

            return {initialValues: {...data}, refetchMeta: refetch};
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => {
            return {
                ...initialValues,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
        handleSubmit: async (
            {
                siteName,
                siteDescription,
                id,
                newLogo,
                newFavicon,
                logoSizePercent,
            }, {
                setSubmitting,
                setStatus,
                setFieldValue,
                props: {
                    updateMeta,
                    updateLogo,
                    updateFavicon,
                    lockModalProgress,
                    unLockModalProgress,
                    refetchMeta,
                },
            }) => {

            let defaultError = 'siteName.update.error.header';

            if (newLogo) {
                lockModalProgress();
                try {
                    const res = await updateLogo({
                        file: newLogo,
                    });
                    unLockModalProgress();
                    let {
                        data: {
                            websiteLogoUpdate: {errors, success} = {},
                        },
                    } = res;
                    if (!success) {
                        unLockModalProgress();
                        setSubmitting(false);
                        setStatus({error: errors._error || defaultError});
                    } else {
                        USERLIST_SERVICE.trackCustomLogoUploaded();
                        setFieldValue('newLogo', undefined);
                    }
                } catch (err) {
                    unLockModalProgress();
                    setSubmitting(false);
                    setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
                }
            }

            if (newFavicon) {
                lockModalProgress();
                try {
                    const res = await updateFavicon({
                        file: newFavicon,
                    });
                    unLockModalProgress();
                    let {
                        data: {
                            websiteFaviconUpdate: {errors, success} = {},
                        },
                    } = res;
                    if (!success) {
                        unLockModalProgress();
                        setSubmitting(false);
                        setStatus({error: errors._error || defaultError});
                    } else {
                        USERLIST_SERVICE.trackFaviconUploaded()
                        setFieldValue('newFavicon', undefined);
                    }
                } catch (err) {
                    unLockModalProgress();
                    setSubmitting(false);
                    setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
                }
            }

            try {
                const res = await updateMeta({
                    id,
                    siteName,
                    siteDescription,
                    logo: {
                        logoSizePercent,
                    },
                });
                setSubmitting(false);
                let {
                    data: {
                        websiteMetaUpdate: {errors, success} = {},
                    },
                } = res;
                if (success) {
                    refetchMeta();
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_META,
                        }),
                        '*'
                    );
                    USERLIST_SERVICE.trackCustomLogoUploaded();
                    USERLIST_SERVICE.trackFaviconUploaded()
                } else {
                    setStatus({error: errors._error || defaultError});
                }
            } catch (err) {
                setSubmitting(false);
                setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
            }
        },
    }),
)(EditLogo);

import PropTypes from 'prop-types';
import {iconColorPropTypes, TrashIcon} from '../../icons';
import {StyledSVG} from '../svgStyles';

export const TrashButton = StyledSVG(TrashIcon);

TrashButton.propTypes = {
    ...iconColorPropTypes,
    className: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};

TrashButton.defaultProps = {
    color: 'darkGray',
    active: true,
    className: '',
    onClick: () => {
    },
};

import React/*, {useEffect, useCallback} */ from 'react';
import {useDragLayer} from 'react-dnd';
// import {useDispatch} from 'react-redux';
// import throttle from 'lodash/throttle';
import {WEBSITE_ALBUM_ITEM_TYPE} from 'packages/enum';
import ImageDragPreview from './ImageDragPreview';

import {LayerContainer} from './nodes';
import {getDomain} from "packages/helpers/Helper";

// import {setDragCurrentOffset} from '../../../navigation/action';

const getItemStyles = (initialOffset, currentOffset) => {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let {x, y} = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};

const CustomDragLayer = () => {
    const {
        itemType,
        isDragging,
        item,
        initialOffset,
        currentOffset,
    } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    // const dispatch = useDispatch();
    //
    // const dispatchChangeOffset = useCallback(
    //     throttle((currentOffset) => dispatch(setDragCurrentOffset({
    //         ...currentOffset,
    //         item,
    //     })), 250/* 4fps достаточно, т.к. мы только хотим убрать драгнутый айтем в сторону */),
    //     [],
    // );
    //
    // // Видимо либа блокирует подписку на событие движения курсора, придется так
    // useEffect(() => {
    //     dispatchChangeOffset(currentOffset);
    // }, [currentOffset]);


    const renderItem = () => {
        if ([WEBSITE_ALBUM_ITEM_TYPE.PHOTO, WEBSITE_ALBUM_ITEM_TYPE.VIDEO].includes(itemType)) {
            return (
                <ImageDragPreview
                    aspectRatio={item.aspectRatio}
                    src={getDomain(item.src)}
                    srcSetWebpMap={item.srcSetWebpMap}
                />);
        } else {
            return null;
        }
    };

    if (!isDragging) {
        return null;
    }
    return (
        <LayerContainer>
            <div
                style={getItemStyles(initialOffset, currentOffset)}
            >
                {renderItem()}
            </div>
        </LayerContainer>
    );
};

export default CustomDragLayer;

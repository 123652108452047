import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import {
    MESSAGE_TYPE,
    URLS,
    WEBSITE_BLOCK,
    WEBSITE_PAGE_TYPES,
} from "packages/enum";
import NavigationContainer from "@configurator/containers/NavigationContainer";
import Loader from "@configurator/components/loader/Loader";
import { SettingsButton } from "packages/components/buttons";
import { TabPropTypes } from "packages/components/tabs";
import WebsiteAlbum from "@configurator/containers/AlbumContainer";
import AlbumUpdateModal from "@configurator/containers/AlbumUpdateModalContainer";
import ModalConfirmDelete from "packages/components/modalConfirmDelete";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import {
    Container,
    AlbumTitle,
    Preview,
    ChooseViewContainer,
    AlbumTitleContainer,
} from "./nodes";
import { ExpandIcon, ShrinkIcon } from "packages/components/icons";
import { intlShape } from "react-intl";
import { useSubscription } from "@configurator/providers/subscription";

const Page = ({
    blocks,
    loading,
    refetchPagePreview,
    tab: { setBackArrowState, clearBackArrowState },
    match: {
        params: { pageId },
    },
    history,
    deleteAlbum,
    expandedView,
    changeAlbumView,
    intl: { formatMessage },
}) => {
    const { permissions } = useSubscription();

    const [loadingDeleteAlbum, setLoadingDeleteAlbum] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [updateAlbumModalOpen, setUpdateAlbumModalOpen] = useState(false);
    // слева отображаем только блок ALBUM_DETAIL, во всех других случаях отображается дефолтная навигация
    const displayBlocks = useMemo(() => {
        return ((blocks || {}).blocks || []).filter(
            (item) => item.type === WEBSITE_BLOCK.ALBUM_DETAIL
        );
    }, [blocks]);

    useEffect(() => {
        return () => changeAlbumView({ expandedView: false });
    }, [changeAlbumView]);

    const handleDeleteAlbum = (albumId) => {
        setDeleteDialogVisible(false);
        setLoadingDeleteAlbum(true);
        deleteAlbum({ albumId }).then(() => {
            window.frames["preview-frame"]?.postMessage(
                JSON.stringify({
                    name: MESSAGE_TYPE.UPDATE_PAGE,
                }),
                "*"
            );
            window.frames["preview-frame"]?.postMessage(
                JSON.stringify({
                    name: MESSAGE_TYPE.UPDATE_META,
                }),
                "*"
            );
            setLoadingDeleteAlbum(false);
            setUpdateAlbumModalOpen(false);
            history.push(URLS.website_config);
            clearBackArrowState();
        });
    };

    useEffect(() => {
        if (displayBlocks.length > 0) {
            setBackArrowState(
                "tabs.tab.portfolio",
                () => {
                    history.push(URLS.website_config);
                    clearBackArrowState();
                },
                <SettingsButton
                    color="dark"
                    onClick={(e) => {
                        e.stopPropagation();
                        setUpdateAlbumModalOpen(true);
                    }}
                />
            );
        }
    }, [displayBlocks, clearBackArrowState, history, setBackArrowState]);

    if (loading) {
        return <Loader />;
    }

    const renderBlock = (block) => {
        return (
            <div>
                <AlbumTitleContainer>
                    <AlbumTitle>{block.title}</AlbumTitle>
                    <PermissionsOverlay
                        isAllowed={permissions.ALBUM_EXTENDED_VIEW}
                        proMax={true}
                    >
                        <ChooseViewContainer onClick={changeAlbumView}>
                            {formatMessage({
                                id: expandedView
                                    ? "album.label.view.shrink"
                                    : "album.label.view.expand",
                            })}
                            {expandedView ? <ShrinkIcon /> : <ExpandIcon />}
                        </ChooseViewContainer>
                    </PermissionsOverlay>
                </AlbumTitleContainer>
                <WebsiteAlbum
                    albumId={block.albumId}
                    pageId={block.pageId}
                    pageUrl={pageId}
                    photos={block.photos || []}
                    refetchPagePreview={refetchPagePreview}
                />
                {deleteDialogVisible && (
                    <ModalConfirmDelete
                        open={deleteDialogVisible}
                        headerMessageId="album.delete.header"
                        subMessageId="album.delete.subHeader"
                        onClickYes={() => handleDeleteAlbum(block.albumId)}
                        onClickCancel={() => setDeleteDialogVisible(false)}
                        onClose={() => setDeleteDialogVisible(false)}
                    />
                )}
                {updateAlbumModalOpen && (
                    <AlbumUpdateModal
                        open
                        pageUrl={pageId}
                        albumId={block.albumId}
                        pageType={WEBSITE_PAGE_TYPES.PHOTO}
                        initialValues={{
                            title: block.title,
                            description: block.description,
                        }}
                        onClose={() => setUpdateAlbumModalOpen(false)}
                        onDelete={() => setDeleteDialogVisible(true)}
                        loadingDeleteAlbum={loadingDeleteAlbum}
                    />
                )}
            </div>
        );
    };
    return displayBlocks.length > 0 ? (
        <Container>
            {displayBlocks.map((item) => (
                <Preview key={`${item.type}${item.pageId}`}>
                    {renderBlock(item)}
                </Preview>
            ))}
        </Container>
    ) : (
        <NavigationContainer disablePageFetch tab={{ setBackArrowState:()=>{}, clearBackArrowState:()=>{} }}/>
    );
};

Page.propTypes = {
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    refetchPagePreview: PropTypes.func,
    history: PropTypes.object,
    deleteAlbum: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    expandedView: PropTypes.bool,
    changeAlbumView: PropTypes.func,
    ...TabPropTypes,
};

Page.defaultProps = {
    loading: false,
    history: {},
    refetchPagePreview: () => null,
    expandedView: false,
    changeAlbumView: () => null,
};

export default Page;

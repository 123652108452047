import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';
import UpdatePasswordForm from '@configurator/containers/billing/UpdatePasswordFormContainer';
import SendResetLinkForm from '@configurator/containers/billing/SendResetLinkFormContainer';
import {
    ModalContainer,
} from './nodes';

const FORM_TYPES = {
    updatePassword: 'UPDATE_PASSWORD',
    sendResetLink: 'SEND_RESET_LINK',
};

const ChangePassword = ({
    open,
    onClose,
}) => {
    const [type, setType] = useState(FORM_TYPES.updatePassword);
    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            styles={{
                height: 'initial',
                width: '435px',
            }}
        >
            {type === FORM_TYPES.updatePassword &&
                <UpdatePasswordForm
                    onCancel={onClose}
                    onClickForgot={() => setType(FORM_TYPES.sendResetLink)}
                />
            }
            {type === FORM_TYPES.sendResetLink &&
                <SendResetLinkForm
                    onClickBack={() => setType(FORM_TYPES.updatePassword)}
                />
            }
        </ModalContainer>
    );
};

ChangePassword.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

ChangePassword.defaultProps = {
    open: false,
};

ChangePassword.contextTypes = {
    intl: intlShape.isRequired,
};

export default ChangePassword;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import {URLS} from 'packages/enum';

import sendRecoveryEmail from '@graphql/gql/common/sendRecoveryEmail.gql';
import SendEmailForm from '@landing/components/resetPassword/SendEmailForm';

const validationSchema = Yup.object()
    .shape({
        email: Yup.string()
            .required('landing.email.error'),
    });

export default compose(
    graphql(sendRecoveryEmail, {
        props({mutate}) {
            return {
                async sendRecoveryEmailMutation({
                    email,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                email,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => {
            return {
                ...initialValues,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
        handleSubmit: async (
            {
                email,
            }, {
                setSubmitting,
                setStatus,
                props: {
                    sendRecoveryEmailMutation,
                },
            }) => {

            let defaultError = 'error.desc';
            try {
                const res = await sendRecoveryEmailMutation({
                    email,
                });
                setSubmitting(false);
                let {
                    data: {
                        sendRecoveryEmail: {errors, success} = {},
                    },
                } = res;
                if (success) {
                    return window.location = `${URLS.website_login}?resetPassword=true`;
                }
                setSubmitting(false);
                setStatus({error: get(errors, '_error', defaultError)});

            } catch (err) {
                setSubmitting(false);
                setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
            }
        },
    }),
)(SendEmailForm);

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

// в макете называется edit2
export const AttentionIcon = ({color}) => (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0005 8.00012H12.0005V13.0001H10.0005V8.00012Z" fill={iconColors[color]}/>
        <path
            d="M11.0005 14.0001C10.4482 14.0001 10.0005 14.4478 10.0005 15.0001C10.0005 15.5524 10.4482 16.0001 11.0005 16.0001C11.5527 16.0001 12.0005 15.5524 12.0005 15.0001C12.0005 14.4478 11.5527 14.0001 11.0005 14.0001Z"
            fill={iconColors[color]}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.7349 19.0022C0.964563 19.0022 0.483503 18.1678 0.869468 17.5012L10.135 1.49701C10.5202 0.831727 11.4807 0.831724 11.8659 1.49701L21.1314 17.5011C21.5174 18.1678 21.0363 19.0022 20.266 19.0022H1.7349ZM18.5316 17.0022L11.0004 3.99391L3.46934 17.0022H18.5316Z"
              fill={iconColors[color]}/>
        <path d="M10.0005 14.0001H12.0005V16.0001H10.0005V14.0001Z" fill={iconColors[color]}/>
    </svg>

);

AttentionIcon.propTypes = {
    ...iconColorPropTypes,
};

AttentionIcon.defaultProps = {
    color: 'dark',
};

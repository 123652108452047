import {graphql} from 'react-apollo';
import {compose} from 'redux';
import {connect} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { emailSelector, nameSelector } from "packages/selectors/User";
import {getActiveSubscription} from 'packages/helpers/Helper';
import {open as openErrorModal} from 'packages/redux/modules/modalYesNo/actions';
import {start as loaderStart, stop as loaderStop} from 'packages/redux/modules/pageLoader/actions';

import billingCardsGet from '@graphql/gql/website/billingCardsGet.gql';
import billingStripeTokenGet from '@graphql/gql/website/billingStripeTokenGet.gql';
import billingSubscriptionsGet from '@graphql/gql/website/billingSubscriptionsGet.gql';
import billingCreateCustomerPortal from '@graphql/gql/website/billingCreateCustomerPortal.gql';
import billingHistoryGet from '@graphql/gql/website/billingHistoryGet.gql';

import Cards from '@configurator/components/billing/cards';

export const cardFormWrapper = Component => compose(
    connect(
        state => ({
            email: emailSelector(state),
            name: nameSelector(state),
        }),
        dispatch => ({
            openErrorModal: ({
                headerMessageId,
                yesMessageId,
                subMessageId,
            }) =>
                dispatch(openErrorModal({
                    headerMessageId,
                    yesMessageId,
                    subMessageId,
                })),
            loaderStart: () => dispatch(loaderStart()),
            loaderStop: () => dispatch(loaderStop()),
        }),
    ),
    graphql(billingStripeTokenGet, {
        options() {
            return {
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, billingStripeTokenGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(billingStripeTokenGet)) {
                return {loading};
            }
            const {data} = billingStripeTokenGet;

            return {
                config: data,
            };
        },
    }),
    graphql(billingCreateCustomerPortal, {
        props({mutate, ownProps: {openErrorModal, loaderStart, loaderStop}}) {
            return {
                async billingCreateCustomerPortal() {
                    try {
                        loaderStart();
                        const res = await mutate();
                        loaderStop();
                        if (get(res, 'data.billingCreateCustomerPortal.errors._error')) {
                            openErrorModal({
                                headerMessageId: 'error.header',
                                yesMessageId: 'OK',
                                subMessageId: get(res, 'data.billingCreateCustomerPortal.errors._error'),
                            });
                        }
                        return res;
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(billingCardsGet, {
        options() {
            return {
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, billingCardsGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(billingCardsGet)) {
                return {loading};
            }
            const {data} = billingCardsGet;

            return {
                cards: data,
            };
        },
    }),
    graphql(billingSubscriptionsGet, {
        props({data: {loading, billingSubscriptionsGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(billingSubscriptionsGet)) {
                return {loading};
            }
            const {data} = billingSubscriptionsGet;

            const subscription = getActiveSubscription(data);
            return {
                subscriptions: subscription.subscriptions,
            };
        },
    }),
    graphql(billingHistoryGet, {
        options() {
            return {
                fetchPolicy: "network-only",
            };
        },
        props({ data: { loading, billingHistoryGet } }) {
            if (loading) {
                return { loading };
            }
            if (isEmpty(billingHistoryGet)) {
                return { loading };
            }
            const { data } = billingHistoryGet;

            return {
                billingHistory: data,
            };
        },
    }),
)(Component);

export default cardFormWrapper(Cards);

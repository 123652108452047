import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";

import get from "lodash/get";

import DowngradeToFreeModal from "@configurator/containers/billing/DowngradeToFreeModalContainer";
import PaymentModal from "@configurator/containers/billing/PaymentContainer";
import { CheckIcon } from "packages/components/icons/basic";
import {
    PAYMENT_STEPPER_TYPES,
    SUBSCRIPTION_TYPE,
    SUBSCRIPTION_CARD_TYPE,
    PLAN_TYPE,
} from "packages/enum";
import moment from "moment";
import {
    Container,
    CheckIconContainer,
    TextContainer,
    TrialTitle,
    Title,
    StyledButton,
    BottomContainer,
    PaidPlanBottomContainer,
    ManageSubButton,
    Paragraph,
} from "./nodes";
import {useIsMobile} from 'packages/helpers/useWindowSize';

const SubscriptionCard = ({
    activeType,
    type,
    title,
    isActive,
    monthPlan,
    yearPlan,
    bodyItems,
    cardsData: {
        currency,
        subscriptionType,
        currentPeriodEnd,
        cancelAtPeriodEnd,
        billingCreateCustomerPortal,
    },
    intl: { formatMessage },
}) => {
    const isMobile = useIsMobile();
    const [downgradeModalOpen, setDowngradeModalOpen] = useState(false);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [paymentByPlanData, setPaymentByPlanData] = useState({});

    useEffect(() => {
        if (paymentModalOpen) {
            setPaymentByPlanData({
                fromType: activeType,
                toType: type,
            });
        }
    }, [paymentModalOpen, activeType, type]);

    const isFree = type === SUBSCRIPTION_CARD_TYPE.FREE;
    const isUnpaid = activeType === SUBSCRIPTION_TYPE.UNPAID;
    const isLite = type === SUBSCRIPTION_CARD_TYPE.PROLITE;
    const isPro = type === SUBSCRIPTION_CARD_TYPE.PRO;
    const isProMax = type === SUBSCRIPTION_CARD_TYPE.PROMAX;
    const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;
    const isPaid =
        isActive && !isFree && subscriptionType === SUBSCRIPTION_TYPE.PAID;

    const monthBasePriceProps = {
        currency: monthPlan?.currency,
        price: monthPlan?.basePrice || 0,
    };
    const yearPricePerMonthProps = {
        currency,
        price: (yearPlan?.price / 12).toFixed(2),
    };

    const handleSubscriptionManage = async () => {
        const res = await billingCreateCustomerPortal();
        const sessionId = get(
            res,
            "data.billingCreateCustomerPortal.data.sessionId"
        );
        if (sessionId) {
            window.location.href = sessionId;
        }
    };

    return (
        <Container isActive={isActive}>
            {isActive && (
                <CheckIconContainer>
                    <CheckIcon color="white" />
                </CheckIconContainer>
            )}
            <TextContainer>
                {isProMax && !isPaid && (
                    <TrialTitle>
                        {formatMessage({
                            id: "subscription.plan.trial.header",
                        })}
                    </TrialTitle>
                )}
                <Title isFree={isFree}>{title}</Title>
            </TextContainer>
            {(activeType !== SUBSCRIPTION_CARD_TYPE.LIFETIME_PRO && activeType !== PLAN_TYPE.LIFETIMEPRO) && (
                <>
                    <TextContainer
                        css={`
                            margin: 8px 0;
                        `}
                    >
                        <span
                            css={`
                                font-size: 24px;
                                font-weight: bold;
                            `}
                            dangerouslySetInnerHTML={{
                                __html: formatMessage(
                                    { id: "subscription.plan.price.title" },
                                    monthBasePriceProps
                                ),
                            }}
                        />
                        {monthPlan?.price && (
                            <span
                                css={`
                                font-size: 16px;
                                font-weight: bold;
                            `}
                                dangerouslySetInnerHTML={{
                                    __html: formatMessage(
                                        { id: "subscription.plan.price.subtitle" },
                                        {
                                            currency,
                                            price: monthPlan?.price || 0,
                                        }
                                    ),
                                }}
                            />
                        )}
                    </TextContainer>
                    {!isLite && (
                        <TextContainer>
                        <span
                            css={`
                                color: #666a73;
                                font-weight: bold;
                            `}
                            dangerouslySetInnerHTML={{
                                __html: isFree
                                    ? formatMessage({
                                        id: "subscription.plan.free.price.subTitle",
                                    })
                                    : formatMessage(
                                        {
                                            id: "subscription.plan.others.price.subTitle",
                                        },
                                        yearPricePerMonthProps
                                    ),
                            }}
                        />
                        </TextContainer>
                    )}
                </>
            )}
            <TextContainer
                css={`
                    margin: ${isLite ? `64px 0;` : `24px 0;`}
                `}
            >
                <Paragraph
                    textCenter={
                        activeType === SUBSCRIPTION_CARD_TYPE.LIFETIME_PRO || isMobile
                    }
                >
                    {bodyItems.map((item, id) => (
                        <React.Fragment key={id}>
                            <span dangerouslySetInnerHTML={{ __html: item }} />
                            <br />
                        </React.Fragment>
                    ))}
                </Paragraph>
            </TextContainer>
            <BottomContainer>
                {do {
                    if (isFree && activeType !== SUBSCRIPTION_CARD_TYPE.FREE) {
                        <StyledButton
                            disabled={isActive}
                            view="primaryGray"
                            content={formatMessage({
                                id: "downgradeToFree.header",
                            })}
                            onClick={
                                !isActive && isTrial || !isActive && isUnpaid
                                    ? () => setDowngradeModalOpen(true)
                                    : undefined
                            }
                        />;
                    } else if (!isActive) {
                        if(isLite) {
                            <StyledButton
                                content={formatMessage({
                                    id: "subscription.plan.light.button.upgrade",
                                })}
                                onClick={() =>
                                    activeType === SUBSCRIPTION_CARD_TYPE.PROMAX ||
                                    activeType === SUBSCRIPTION_CARD_TYPE.PRO
                                        ? handleSubscriptionManage()
                                        : setPaymentModalOpen(true)
                                }
                            />;
                        } else if (isPro) {
                            <StyledButton
                                content={formatMessage({
                                    id: "subscription.plan.pro.button.upgrade",
                                })}
                                onClick={() =>
                                    activeType === SUBSCRIPTION_CARD_TYPE.PROMAX ||
                                    activeType === SUBSCRIPTION_CARD_TYPE.PROLITE
                                        ? handleSubscriptionManage()
                                        : setPaymentModalOpen(true)
                                }
                            />;
                        } else if (isProMax) {
                            <StyledButton
                                content={formatMessage({
                                    id: "subscription.plan.promax.button.upgrade",
                                })}
                                onClick={() =>
                                    activeType === SUBSCRIPTION_CARD_TYPE.PRO ||
                                    activeType === SUBSCRIPTION_CARD_TYPE.PROLITE
                                        ? handleSubscriptionManage()
                                        : setPaymentModalOpen(true)
                                }
                            />;
                        }
                    } else {
                        if (isTrial) {
                            <StyledButton
                                content={formatMessage({
                                    id: "subscription.plan.promax.button.upgrade",
                                })}
                                onClick={() => setPaymentModalOpen(true)}
                            />;
                        } else if (isPaid) {
                            <PaidPlanBottomContainer>
                                <ManageSubButton
                                    onClick={handleSubscriptionManage}
                                >
                                    {formatMessage({
                                        id: "subscription.plan.button.manageSub",
                                    })}
                                </ManageSubButton>
                                <TextContainer
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <span
                                        css={`
                                            color: #666a73;
                                        `}
                                    >
                                        {formatMessage({
                                            id: cancelAtPeriodEnd
                                                ? "subscription.plan.billingOption.cancelDate"
                                                : "subscription.plan.billingOption.renewDate",
                                        })}
                                        <br />
                                        {moment(currentPeriodEnd).format("ll")}
                                    </span>
                                </TextContainer>
                            </PaidPlanBottomContainer>;
                        }
                    }
                }}
            </BottomContainer>
            {downgradeModalOpen && (
                <DowngradeToFreeModal
                    open
                    onClose={() => setDowngradeModalOpen(false)}
                />
            )}
            {paymentModalOpen && (
                <PaymentModal
                    open
                    onClose={() => setPaymentModalOpen(false)}
                    type={PAYMENT_STEPPER_TYPES.BILLING_UPGRADE}
                    {...paymentByPlanData}
                />
            )}
        </Container>
    );
};

SubscriptionCard.propTypes = {
    intl: intlShape.isRequired,
    activeType: PropTypes.oneOf([
        SUBSCRIPTION_TYPE.FREE,
        SUBSCRIPTION_TYPE.TRIAL,
        ...Object.values(PLAN_TYPE),
    ]).isRequired,
    type: PropTypes.oneOf(Object.values(SUBSCRIPTION_CARD_TYPE)).isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    monthPlan: PropTypes.object,
    yearPlan: PropTypes.object,
    bodyItems: PropTypes.arrayOf(PropTypes.string),
    cardsData: PropTypes.shape({
        status: PropTypes.string,
        currency: PropTypes.string,
        subscriptionType: PropTypes.string,
        currentPeriodEnd: PropTypes.number,
        cancelAtPeriodEnd: PropTypes.number,
        billingCreateCustomerPortal: PropTypes.func,
    }).isRequired,
};

SubscriptionCard.defaultProps = {
    bodyItems: [],
};

export default injectIntl(SubscriptionCard);

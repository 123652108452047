import styled, {css} from 'styled-components';

export const Container = styled.div`
    ${({width, height, color}) => css`
        &,
        & div {
            box-sizing: border-box;
        }
        & {
            position: relative;
            width: 80px;
            height: 80px;
            transform: scaleX(${width / 80}) scaleY(${height / 80});
            transform-origin: left top;
            color: ${color};
        }
        & div {
            position: absolute;
            border: 4px solid currentColor;
            opacity: 1;
            border-radius: 50%;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        & div:nth-child(2) {
            animation-delay: -0.5s;
        }
        @keyframes lds-ripple {
            0% {
                top: 36px;
                left: 36px;
                width: 8px;
                height: 8px;
                opacity: 1;
            }
            100% {
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;
                opacity: 0;
            }
        }
    `}
`;

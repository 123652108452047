import styled from 'styled-components';

export const Container = styled.div`
    max-height: 50vh;
    margin: 0 auto;
    img + img {
        width: 24px;
        height: 24px;
    }
`;

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
// import {Button/*, Modal*/} from 'packages/elements';
import {Modal} from 'packages/components/modal';
import {Button} from 'packages/components/buttons';
import style from './style.less';

class ModalYesNo extends Component {
    static propTypes = {
        open: PropTypes.bool,
        buttons: PropTypes.array,
        onClickYes: PropTypes.func,
        onClickCancel: PropTypes.func,
        yesMessageId: PropTypes.string,
        cancelMessageId: PropTypes.string,
        headerMessageId: PropTypes.string,
        subMessageId: PropTypes.string,
        subMessageValues: PropTypes.object,
        close: PropTypes.func,
        size: PropTypes.string,
        hideHeader: PropTypes.bool,
        hideSubHeader: PropTypes.bool,

    };
    static defaultProps = {
        open: false,
        close: () => {
        },
        onClickYes: undefined,
        onClickCancel: () => {
        },
        buttons: ['yes', 'cancel'],
        yesMessageId: 'modalYesNo.yes',
        cancelMessageId: 'modalYesNo.cancel',
        headerMessageId: 'modalYesNo.header',
        subMessageId: 'modalYesNo.subHeader',
        subMessageValues: {},
        size: undefined,
        hideHeader: false,
        hideSubHeader: false,
    };

    static contextTypes = {
        intl: PropTypes.object,
    };

    render() {
        const {
            open, onClickYes, close, onClickCancel,
            yesMessageId, cancelMessageId, headerMessageId,
            subMessageId, subMessageValues, buttons, size, hideHeader, hideSubHeader,
        } = this.props;
        const {intl: {formatMessage}} = this.context;
        return (
            <Modal
                open={open}
                onClose={close}
                size={size}
            >
                {!hideHeader &&
                <div className={style.popup__header}>
                    <div className={style.popup__title}>
                        {formatMessage({id: headerMessageId})}
                    </div>
                </div>}
                {!hideSubHeader &&
                <div className={style.popup__body}>
                    <FormattedMessage
                        id={subMessageId}
                        values={subMessageValues}
                    />
                </div>}
                <div className={style.popup__footer}>
                    {buttons.includes('yes') && (
                        <Button
                            className={style.popup__button}
                            content={formatMessage({id: yesMessageId})}
                            onClick={onClickYes || close}
                            size="large"
                        />
                    )}
                    {buttons.includes('cancel') && (
                        <Button
                            className={style.popup__button}
                            content={formatMessage({id: cancelMessageId})}
                            view="primaryRed"

                            onClick={onClickCancel}
                            size="large"
                        />
                    )}
                </div>
            </Modal>
        );
    }
}

export default ModalYesNo;

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

import { Semantic } from "packages/components";
import { formProptypes } from "packages/utils/formikPropTypes";
import { URLS } from "packages/enum";
import { Button } from "packages/components/buttons";
import {
    Content,
    Container,
    Header,
    Footer,
    Stack,
    StyledFormInput,
    StyledFormInputPassword,
    StyledMessage,
} from '@landing/components/common/nodes';
import { Helmet } from "react-helmet";
import { seoTags } from "packages/intl/messages/seo";

const LoginForm = (
    { handleSubmit, errors, status, isSubmitting, loading, location },
    { intl: { formatMessage } }
) => {
    const error = Object.keys(errors).length || (status && !!status.error);
    const showSuccessMessage = queryString.parse(location.search).resetPassword;

    return (
        <Container>
            <Helmet>
                <title>{seoTags.login.title}</title>
                <meta name="title" content={seoTags.login.title} />
                <meta name="description" content={seoTags.login.description} />
                <meta property="og:title" content={seoTags.login.title} />
                <meta
                    property="og:description"
                    content={seoTags.login.description}
                />
                <meta property="twitter:title" content={seoTags.login.title} />
                <meta
                    property="twitter:description"
                    content={seoTags.login.description}
                />
            </Helmet>
            <Header>{formatMessage({ id: "landing.login.title" })}</Header>
            <Content>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting || loading}
                    onSubmit={handleSubmit}
                >
                    <StyledMessage
                        error={error}
                        messages={errors}
                        content={
                            (status && status.error) ||
                            (showSuccessMessage && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({
                                            id: "landing.resetPassword.success.message",
                                        }),
                                    }}
                                />
                            ))
                        }
                    />
                    <Stack>
                        <StyledFormInput
                            name="login"
                            label={formatMessage({
                                id: "landing.login.label.login",
                            })}
                            width="100%"
                        />
                        <StyledFormInputPassword
                            name="password"
                            label={formatMessage({
                                id: "landing.login.label.password",
                            })}
                            type="password"
                            width="100%"
                        />
                    </Stack>
                    <Button
                        fluid
                        type="submit"
                        disabled={isSubmitting}
                        content={formatMessage({
                            id: "landing.login.button.label",
                        })}
                    />
                </Semantic.Form>
                <Footer>
                    {formatMessage({ id: "landing.login.forgot" })}{" "}
                    <Link to={URLS.website_reset_password}>
                        {formatMessage({ id: "landing.login.here" })}
                    </Link>
                </Footer>
            </Content>
        </Container>
    );
};

LoginForm.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    loading: PropTypes.bool,
    values: formProptypes.values,
};

LoginForm.defaultProps = {
    loading: false,
};

LoginForm.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default LoginForm;

import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {AttentionIcon, CircleCheckIcon} from 'packages/components/icons';
import {MessageContainer} from './nodes';

const Message = ({messages, error, className, content, intl: {formatMessage}}) => Object.keys(messages).length > 0 || content ? (
    <MessageContainer error={error} className={className}>
        <div>
            {error && <AttentionIcon color="red"/>}
            {!error && <CircleCheckIcon color="green"/>}
            {Object.keys(messages)
                .map((key) => {
                    return (
                        <span key={messages[key]}>
                            {formatMessage({id: messages[key]})}
                        </span>);
                })}
            {typeof content !== 'string' ? content : formatMessage({id: content})}
        </div>
    </MessageContainer>
) : null;

Message.propTypes = {
    messages: PropTypes.object,
    error: PropTypes.bool,
    content: PropTypes.element || PropTypes.string,
    className: PropTypes.string
};

Message.defaultProps = {
    messages: {},
    error: false,
    content: undefined,
    className: undefined
};

Message.contextTypes = {
    intl: PropTypes.object,
};

export default injectIntl(Message);

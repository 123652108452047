import PropTypes from 'prop-types';
import React from 'react';
import {intlShape, injectIntl} from 'react-intl';

import {DOMAIN_TYPES} from 'packages/constants';

import DomainForm from '../domain/DomainForm';

import {
    DomainContainer,
    Paragraph,
} from '../nodes';
import {Divider} from 'packages/components/divider';

const FreeSection = ({
    domain,
    changeDomain,
    intl: {formatMessage},
}
) => (
    <div>
        <Paragraph type="h1" mt={20} mb={10}>
            {formatMessage({id: 'bootstrap.your.free'})}
        </Paragraph>
        <DomainContainer>
            <Paragraph type="h2" mb={30}>
                {formatMessage({id: 'bootstrap.domain.subheader.text2'})}
            </Paragraph>
            <DomainForm
                type={DOMAIN_TYPES.CUSTOM_DOMAIN}
                domain={domain}
                changeDomain={changeDomain}
            />
        </DomainContainer>
        <Divider />
    </div>
);

FreeSection.propTypes = {
    domain: PropTypes.string,
    changeDomain: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
};

FreeSection.defaultProps = {
    domain: '',
};

export default injectIntl(FreeSection);

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import { FormTextArea } from 'packages/components/inputs';
import { formProptypes } from 'packages/utils/formikPropTypes';
import {Modal} from 'packages/components/modal';

import {MobileSubmitContainer, ModalContent, ModalHeader, SubmitButton} from './nodes';

const validationSchema = Yup.object()
    .shape({
        text: Yup.string(),
    });


export const AlbumItemUpdateModal = ({ open, onAlbumItemUpdate, onClose, initialValues }, { intl: { formatMessage } }) => {
    const modalStyle = {
        width: '580px',
        height: 'initial',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            styles={modalStyle}
        >
            <ModalContent>
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        onAlbumItemUpdate({ description: values.description });
                        onClose();
                    }}
                    render={() => {
                        return (
                            <FormikForm>
                                <ModalHeader>
                                    <span>{formatMessage({ id: 'album.label.addText' })}</span>
                                    <SubmitButton
                                        type='submit'
                                        content={formatMessage({ id: 'album.button.save' })}
                                    />
                                </ModalHeader>
                                <FormTextArea
                                    placeholder="album.item.placeholder.text"
                                    name="description"
                                    maxLength={500}
                                />
                                <MobileSubmitContainer>
                                    <SubmitButton
                                        type='submit'
                                        content={formatMessage({ id: 'album.button.save' })}
                                    />
                                </MobileSubmitContainer>
                            </FormikForm>)
                    }}
                />
            </ModalContent>
        </Modal>
    )
};

AlbumItemUpdateModal.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    onClose: PropTypes.func.isRequired,
    onAlbumItemUpdate: PropTypes.func.isRequired,
    open: PropTypes.bool,
    ...formProptypes,
};

AlbumItemUpdateModal.defaultProps = {
    open: false,
};

AlbumItemUpdateModal.contextTypes = {
    intl: PropTypes.object.isRequired,
};

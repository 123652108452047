import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Semantic } from "packages/components";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";
import { getInitials } from "packages/utils/getInitial";
import { COOKIE, URLS } from "packages/enum";
import { FormattedMessage } from "react-intl";
import {
    nameSelector,
    emailSelector,
} from "packages/selectors/User";
import LocalSwitcher from "./LocalSwitcher";
import { EllipseWrapper, UserDropdownHeader } from "../layout/nodes";
import { UserDropdownContainer, Initials } from "./nodes";

const cookies = new Cookies();

export const UserDropdown = () => {
    const name = useSelector(nameSelector);
    const email = useSelector(emailSelector);
    const initials = useMemo(() => getInitials(name), [name]);
    const trigger = (
        <>
            <EllipseWrapper>
                <div>{initials}</div>
            </EllipseWrapper>
            <Initials>{name}</Initials>
        </>
    );

    const handleClickLogout = () => {
        cookies.remove(COOKIE.token, { path: "/" });
        window.location = URLS.website_login;
    };

    return (
        <UserDropdownContainer trigger={trigger}>
            <Semantic.Dropdown.Menu>
                <Semantic.Dropdown.Header>
                    <UserDropdownHeader>
                        <EllipseWrapper inverted>
                            <div>{initials}</div>
                        </EllipseWrapper>
                        <div className="dropdown-header-text">
                            <div>{name}</div>
                            <div>{email}</div>
                        </div>
                    </UserDropdownHeader>
                </Semantic.Dropdown.Header>
                <Semantic.Dropdown.Item
                    text={<FormattedMessage id="user.menu.accountAndBilling" />}
                    as={Link}
                    to={URLS.website_billing}
                />
                <Semantic.Dropdown.Item
                    text={<FormattedMessage id="user.menu.domainAndWebsite" />}
                    as={Link}
                    to={URLS.website_settings}
                />
                <Semantic.Dropdown.Item
                    text={<FormattedMessage id="user.menu.googleSeo" />}
                    as={Link}
                    to={URLS.website_seo}
                />
                <Semantic.Dropdown.Item
                    text={<FormattedMessage id="user.menu.integrations" />}
                    as={Link}
                    to={URLS.website_integrations}
                />
                <Semantic.Dropdown.Item as={LocalSwitcher} />
                <Semantic.Dropdown.Divider />
                <Semantic.Dropdown.Item
                    text={<FormattedMessage id="user.menu.logout" />}
                    onClick={handleClickLogout}
                />
            </Semantic.Dropdown.Menu>
        </UserDropdownContainer>
    );
};

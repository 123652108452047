import styled from 'styled-components';
import {Modal} from 'packages/components/modal';
import {Button} from 'packages/components/buttons';
import {Semantic} from 'packages/components';

export const ModalContainer = styled(Modal)`
    position: initial !important;

    .closeIcon {
        z-index: 1;
    }

    @media (max-width: 480px) {
        min-width: 100% !important;
        height: 100% !important;
        border-radius: 0 !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }
`;

export const StyledForm = styled(Semantic.Form)`
    margin: auto 0;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    height: 45px;

    ${({view}) => view === 'secondaryWhite' && 'color: #666A73 !important;'}
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 312px;
    margin: 40px auto 0;

    ${StyledButton}:not(:last-child) {
        margin-bottom: 10px !important;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

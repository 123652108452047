import {compose} from 'redux';
import {graphql} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import {withFormik} from 'formik';
import {connect} from 'react-redux';

import {MESSAGE_TYPE} from 'packages/enum';
import featureGet from '@graphql/gql/website/featureGet.gql';
import websiteAlbumItemsGet from '@graphql/gql/website/websiteAlbumItemsGet.gql';
import featureUpdate from '@graphql/gql/website/featureUpdate.gql';

import SlideshowModal from '@configurator/components/slideshow/Slideshow';
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

export default compose(
    connect(
        // mapStateToProps
        state => ({
            websiteId: state.getIn(['preview', 'meta', 'id']),
        }),
    ),
    graphql(featureGet, {
        options({websiteId}) {
            return {
                variables: {
                    websiteId,
                    name: 'slider',
                },
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, featureGet, refetch}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(featureGet)) {
                return;
            }
            const {data} = featureGet;

            return {featureData: {...data}, refetchData: refetch};
        },
    }),
    graphql(websiteAlbumItemsGet, {
        options({albumId}) {
            return {
                variables: {
                    albumId,
                },
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websiteAlbumItemsGet, refetch}}) {
            if (loading) {
                return {loading, refetchItems: refetch};
            }
            if (isEmpty(websiteAlbumItemsGet)) {
                return {refetchItems: refetch};
            }

            USERLIST_SERVICE.trackSlideshowSettingsOpened();
            const {data} = websiteAlbumItemsGet;

            return {items: data, refetchItems: refetch};
        },
    }),
    graphql(featureUpdate, {
        props({mutate}) {
            return {
                async featureUpdate({
                    featureId,
                    enabled,
                    properties,
                }) {
                    try {
                        const res = await mutate({
                            variables: {
                                featureId,
                                enabled,
                                properties,
                            },
                        });
                        window.frames['preview-frame']?.postMessage(
                            JSON.stringify({
                                name: MESSAGE_TYPE.UPDATE_PAGE,
                            }),
                            '*'
                        );
                        window.frames['preview-frame']?.postMessage(
                            JSON.stringify({
                                name: MESSAGE_TYPE.UPDATE_META,
                            }),
                            '*'
                        );
                        return res;
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => {
            return {
                ...initialValues,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
    }),
)(SlideshowModal);

import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';
import {Elements, StripeProvider} from 'react-stripe-elements';

import {useMediaBreakpoints} from 'packages/helpers/useMediaQuery';

import {Tab} from 'packages/components/semantic';
import {HeaderStyled} from 'packages/components/layout/nodes';
import LoginInfoContainer from '@configurator/containers/billing/LoginInfoContainer';
import AddressContainer from '@configurator/containers/billing/AddressContainer';
import DeleteAccount from '@configurator/containers/billing/DeleteAccountContainer';
/*import RemoveBranding from '@configurator/components/billing/removeBranding';*/
import SubscriptionContainer from '@configurator/containers/billing/SubscriptionsContainer';
import BillingHistoryContainer from '@configurator/containers/billing/BillingHistoryContainer';

import {
    Container,
    Header,
    SplitContent,
    LeftPanel,
    RightPanel,
    BottomMenu,
    TabPane,
} from './nodes';

const Billing = ({
    subscriptionType,
    config,
    intl: {formatMessage},
}) => {
    const breakpoints = useMediaBreakpoints();

    const tabStyles = {
        background: 'transparent',
        boxShadow: 'none',
    };

    const tabsMenuProps = {
        secondary: true,
        pointing: true,
    };

    const panes = [
        {
          menuItem: formatMessage({id: 'accountBilling.tabs.account'}),
          render: () => (
            <TabPane
                style={tabStyles}
                attached={false}
            >
                <LoginInfoContainer/>
                <SubscriptionContainer/>
                <BottomMenu>
                    <DeleteAccount/>
                    {/*<RemoveBranding/>*/}
                </BottomMenu>
            </TabPane>
          ),
        },
        {
          menuItem: formatMessage({id: 'accountBilling.tabs.address'}),
          render: () => (
            <TabPane
                style={tabStyles}
                attached={false}
            >
                <AddressContainer/>
            </TabPane>
          ),
        },
        {
          menuItem: formatMessage({id: 'accountBilling.tabs.history'}),
          render: () => (
            <TabPane
                style={tabStyles}
                attached={false}
            >
                <BillingHistoryContainer/>
            </TabPane>
          ),
        },
    ];

    return (
        <>
            <HeaderStyled backArrow/>
            {config &&
            <StripeProvider apiKey={config || null}>
                <Elements>
                    <Container>
                        <Header>
                            {formatMessage({id: 'accountBilling.header'})}
                        </Header>
                        {breakpoints.lg ? (
                            <Tab
                                panes={panes}
                                menu={tabsMenuProps}
                            />
                        ) : (
                            <SplitContent>
                                <LeftPanel>
                                    <LoginInfoContainer/>
                                    <AddressContainer/>
                                    <DeleteAccount/>
                                    {/*<RemoveBranding/>*/}
                                </LeftPanel>
                                <RightPanel>
                                    <SubscriptionContainer/>
                                    <BillingHistoryContainer/>
                                </RightPanel>
                            </SplitContent>
                        )}
                    </Container>
                </Elements>
            </StripeProvider>
            }
        </>
    );
};

Billing.propTypes = {
    intl: intlShape.isRequired,
    subscriptionType: PropTypes.string,
    config: PropTypes.object.isRequired,
};

Billing.defaultProps = {
    subscriptionType: '',
};

export default injectIntl(Billing);

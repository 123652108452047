/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const BlankIcon = ({ color }) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.937 6.68C15.926 6.648 15.917 6.617 15.904 6.586C15.855 6.48 15.794 6.379 15.708 6.293L9.708 0.293C9.622 0.207 9.521 0.146 9.415 0.0969999C9.385 0.0829999 9.353 0.0749999 9.321 0.0639999C9.237 0.0359999 9.151 0.018 9.062 0.013C9.04 0.011 9.021 0 9 0H2C0.897 0 0 0.897 0 2V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V7C16 6.979 15.989 6.96 15.987 6.938C15.982 6.85 15.965 6.764 15.937 6.68ZM12.586 6H10V3.414L12.586 6ZM2 18V2H8V7C8 7.553 8.447 8 9 8H14L14.002 18H2Z"
            fill={iconColors[color]}
        />
    </svg>
);

BlankIcon.propTypes = {
    ...iconColorPropTypes,
};

BlankIcon.defaultProps = {
    color: 'dark',
};

import styled from 'styled-components';
import {media} from 'packages/components/media';

export const Container = styled.div`
`;

export const Content = styled.div`
    > form {
        align-items: center;
        display: flex !important;
        flex-direction: column;
        width: 100%;
        color: #343840;

        > div {
          width: 100%;
          margin-top: 20px;
        }
        .ui.input {
           width: 100% !important;
        }
    }
`;

export const Header = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: #343840;
    text-align: center;

    > div {
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        margin-top: 10px;
    }
`;

export const Footer = styled.div`
    display: flex;
    margin-top: 20px;

    .ui.button {
        height: 45px;
        &:first-child {
            width: 200px;
            margin-right: 10px !important;
        }
        &:last-child {
            flex: 1;
        }
    }

    ${media.max.phone`
        flex-direction: column;

        .ui.button:first-child {
            width: 100%;
        }

        .ui.button + .ui.button {
            margin-top: 10px !important;
        }
    `}
`;

export const ButtonsContainer = styled.div`
    margin-top: 20px;

    .ui.button {
        height: 45px;
        width: 100%;
    }

    > div {
        margin-top: 20px;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        color: #58C367;
        cursor: pointer;
    }
`;

export const ForgotPasswordContainer = styled.div`
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 13px;
    line-height: 140%;
    color: #58C367;
    cursor: pointer;
`;

export const RequestSuccessContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 40px 20px 40px;

    > svg {
        height: 40px;
        width: 40px;
    }

    > h1 {
        margin-top: 20px;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
    }

    > span {
        margin-top: 10px;
        font-size: 13px;
        line-height: 140%;
    }
`;

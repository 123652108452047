import styled, {css} from 'styled-components';

const views = {
    error: css`
        color: white;
        background: #FF002E;
    `,
    success: css`
        color: white;
        background: #58C367;
    `,
    default: css`
        background: white;
    `,
}

export const Container = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    ${({view}) => views[view]};
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 6px;
    right: 6px;
    border: none;
    background: transparent;
    cursor: pointer;

    & svg {
        width: 10px;
        height: 10px;
    }
`;

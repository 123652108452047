import styled from 'styled-components';
import {Semantic} from 'packages/components';
import get from 'lodash/get';
import {media} from 'packages/components/media';

export const Modal = styled(Semantic.Modal)`
    will-change: initial !important;
    border-radius: 6px !important;
    width: ${props => get(props, 'styles.width') ? `${props.styles.width}` : '580px'} !important;
    height: ${props => get(props, 'styles.height') ? `${props.styles.height}` : 'max-content'} !important;
    padding-left: ${props => get(props, 'styles.paddingLeft') ? `${props.styles.paddingLeft}` : '40px'} !important;
    padding-right: ${props => get(props, 'styles.paddingRight') ? `${props.styles.paddingRight}` : '40px'} !important;
    padding-top: ${props => get(props, 'styles.paddingTop') ? `${props.styles.paddingTop}` : '40px'} !important;
    padding-bottom: ${props => get(props, 'styles.paddingBottom')
    ? `${props.styles.paddingBottom}` : '40px'} !important;
    max-width: 92vw !important;
    margin: 1em !important;
    color:  #343840;
    display: flex !important;
    flex-direction: column;

    &.ui.active.modal.visible.transition {
        display: flex !important;
        flex-direction: column;
    }

    > .close {
        display: none;
    }

    > .closeIcon {
        cursor: pointer;
        position: fixed;
        top: 25px;
        right: 25px;
        height: 24px;
        padding: 5px;
        z-index: 1;

        > svg path {
            fill: #EDEDED;
        }
    }

    ${media.max.phone`
        padding: ${props => props.mobileWide ? '50px 16px' : '30px 20px'} !important;
        min-width: ${props => props.mobileWide ? '100vw' : 'initial'} !important;
        min-height: ${props => props.mobileWide ? '100%' : 'initial'} !important;
        max-width: ${props => props.mobileWide ? '100vw' : '92vw'} !important;
        border-radius: ${props => props.mobileWide ? 0 : 6}px !important;
        height: ${props => get(props, 'styles.height') ? `${props.styles.height}` : 'auto'} !important;
        margin: ${props => props.mobileWide ? '0' : '0 auto'} !important;
        .closeIcon {
            position: ${props => props.mobileWide ? 'absolute' : 'fixed'};
            top: 21px;
            right: 21px;
            > svg path {
                fill: ${props => props.mobileWide ? '#666A73' : '#EDEDED'};
            }
        }
    `}
`;

export const ButtonRow = styled.div`
    flex-wrap: wrap;
    display: none;

    > button {
        min-width: 165px;
        width: max-content;
        height: 40px !important;
        &:active {
          transform: none !important;
        }
    }

    button + button {
        margin-left: 8px !important;
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;

        > button {
            width: 100% !important;
            margin-top: 20px !important;
        }

        button + button {
            margin-left: 0 !important;
        }
    }
`;

export const ModalHeader = styled.div`
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: #343840;
    ${ButtonRow} {
        display: flex;
    }

    @media (max-width: 480px) {
        ${ButtonRow} {
            display: none;
        }
    }
`;


import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {updateStatus, closeBar, lockModal} from './actions';


const defaultState = fromJS({
    open: false,
    lock: false,
});

export default handleActions({

    [updateStatus]: {
        next(state, {payload}) {
            return state.merge({...payload, open: !state.get('lock')});
        },
    },

    [lockModal]: {
        next(state, {payload: lock}) {
            return state.merge(fromJS({lock, open: lock ? false : undefined}));
        },
    },

    [closeBar]: {
        next(state) {
            return state.set('open', false);
        },
    },

}, defaultState);

export const seoTags = {
    login: {
        title: "Vsble - Login",
        description:
            "Login to your Vsble account. Vsble is a free website builder for creative people with a free domain and SSL.",
    },
    resetPassword: {
        title: "Vsble - Reset Password",
        description:
            "Reset your Vsble password. Vsble is a free website builder for creative people with a free domain and SSL",
    },
    createAccountPro: {
        title: "Vsble PRO - 14-day Trial",
        description:
            "Try Vsble PRO website builder 14 days for free.  Connect any domain and sell across multiple channels, products, or services with Vsble Integrations.",
    },
    createAccountFree: {
        title: "Vsble FREE - Create Account",
        description:
            "Create a free lifetime website with Vsble website builder easily. Showcase your work within minutes with a free domain and SSL certificate.",
    },
};

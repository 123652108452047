import React, {useState, useMemo, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Grid, Loader} from 'semantic-ui-react';
import {useDrop, useDrag} from 'react-dnd';
import Checkbox from 'packages/components/checkbox/Checkbox.js';
import {TrashIcon, PlusIcon} from 'packages/components/icons';
import placeholder from './placeholder.png';
import {
    PreviewContainer,
    StyledGrid,
    PreviewWrapper,
    PlaceholderOverlay,
    Placeholder,
    ProgressContainer,
    CheckboxContainer,
    DeleteButton,
    AddButton,
    ArrowContainer,
    ArrowImg,
    CellContainer,
} from './nodes';

import ArrowRightPinkIcon from 'src/packages/theme-ab/resources/assets/arrowRightPink.svg';

const ImagePreview = forwardRef((props, ref) => {
    const {loading, preview, isPlaceholderShow, hidePlaceholder} = props;
    return (
        <PreviewWrapper
            loading={loading}
            ref={ref}
        >
            <img alt="" src={preview} onLoad={hidePlaceholder} style={{pointerEvents: 'none'}}/>
            {
                isPlaceholderShow ?
                    <PlaceholderOverlay>
                        <Loader active/>
                        <Placeholder
                            style={{
                                background: `url(${placeholder}) no-repeat center`,
                                backgroundSize: '200px',
                            }}
                        />
                    </PlaceholderOverlay> : null
            }
        </PreviewWrapper>
    );
});

ImagePreview.propTypes = {
    preview: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    isPlaceholderShow: PropTypes.bool,
    hidePlaceholder: PropTypes.func,
};

ImagePreview.defaultProps = {
    isPlaceholderShow: false,
    loading: false,
    hidePlaceholder: () => {
    },
};

export const Preview = ({
    preview,
    loading,
    checked,
    progress,
    error,
    onChange,
    index,
    hideCheckbox,
    showDelete,
    onDelete,
    onDrop,
}) => {
    const [isPlaceholderShow, setIsPlaceHolderShow] = useState(true);

    const [{isDragging}, drag] = useDrag({
        item: {type: 'photo_preview'},
        begin: () => ({index}),
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: !loading,
    });

    const [{isOver}, drop] = useDrop({
        accept: ['photo_preview'],
        drop: item => onDrop({
            index: item.index,
            sourceIndex: index,
        }),
        collect: monitor => ({
            isOver: !!monitor.isOver() && !!monitor.canDrop(),
        }),
        canDrop: item => {
            return item.index !== index && !loading
        },
    });

    const renderPreviewImage = useMemo(() => (
        <ImagePreview
            ref={drag}
            preview={preview}
            loading={loading}
            isPlaceholderShow={isPlaceholderShow}
            hidePlaceholder={() => setIsPlaceHolderShow(false)}
        />), [drag, preview, loading, isPlaceholderShow]);

    const renderProgress = useMemo(() => (
        <ProgressContainer progress={progress || 0}/>
    ), [progress]);

    const renderCheckbox = useMemo(() => (
        <CheckboxContainer>
            <Checkbox checked={checked} onChange={onChange.bind(this, index)}/>
        </CheckboxContainer>
    ), [checked, index, onChange]);

    const renderDeleteButton = useMemo(() => (
        <DeleteButton
            className="delete-button"
            onClick={() => onDelete(index)}
        >
            <TrashIcon/>
        </DeleteButton>
    ), [index, onDelete]);

    return (
        <CellContainer>
            <PreviewContainer
                error={error}
                loading={loading}
                ref={drop}
                isDragging={isDragging}
                isOver={isOver}
            >
                {isOver &&
                <ArrowContainer>
                    <ArrowImg>
                        <img height="12px" width="12px" alt="" src={ArrowRightPinkIcon}/>
                    </ArrowImg>
                </ArrowContainer>
                }
                {!loading && !error && !hideCheckbox ? renderCheckbox : null}
                {!loading && showDelete ? renderDeleteButton : null}
                {renderPreviewImage}
                {loading ? renderProgress : null}
            </PreviewContainer>
        </CellContainer>
    );
};

Preview.propTypes = {
    preview: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    checked: PropTypes.bool,
    showDelete: PropTypes.bool,
    hideCheckbox: PropTypes.bool,
    progress: PropTypes.number,
    error: PropTypes.number,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onDrop: PropTypes.func,
    index: PropTypes.number,
};

Preview.defaultProps = {
    loading: false,
    checked: false,
    error: false,
    hideCheckbox: false,
    showDelete: false,
    progress: 100,
    index: 0,
    onChange: () => {
    },
    onDelete: () => {
    },
    onDrop: () => {
    },
};

const PreviewList = ({
    loading = false,
    photos,
    onChange,
    hideCheckbox,
    wide,
    showDelete,
    onDelete,
    onClickAdd,
    onDropItem,
}) => {
    return (
        <StyledGrid loading={loading}>
            {photos.map(photo => (
                <Grid.Column
                    key={`photoPreview_${photo.index}`}
                    mobile={5}
                    tablet={wide ? 16 : 4}
                    computer={wide ? 16 : 4}
                    largeScreen={wide ? 16 : 4}
                >
                    <Preview
                        {...photo}
                        onChange={onChange}
                        onDelete={onDelete}
                        hideCheckbox={hideCheckbox}
                        showDelete={showDelete}
                        onDrop={onDropItem}
                    />
                </Grid.Column>
            ))}
            <Grid.Column
                mobile={5}
                tablet={wide ? 16 : 4}
                computer={wide ? 16 : 4}
                largeScreen={wide ? 16 : 4}
            >
                <CellContainer>
                    <AddButton onClick={onClickAdd}>
                        <PlusIcon color="gray"/>
                    </AddButton>
                </CellContainer>
            </Grid.Column>
        </StyledGrid>
    );
};

PreviewList.propTypes = {
    photos: PropTypes.arrayOf(Preview.propTypes),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onClickAdd: PropTypes.func,
    hideCheckbox: PropTypes.bool,
    showDelete: PropTypes.bool,
    wide: PropTypes.bool,
};

PreviewList.defaultProps = {
    photos: [],
    loading: false,
    showOptions: false,
    hideCheckbox: false,
    showDelete: false,
    wide: false,
    onClickAdd: () => {
    },
    onChange: () => {
    },
    onDelete: () => {
    },
};

export default PreviewList;

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const VideoIcon = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V5C21 3.897 20.103 3 19 3ZM5 19V5H19L19.002 19H5Z"
            fill={iconColors[color]}
        />
        <path
            d="M15 12L9.75 15.4641L9.75 8.5359L15 12Z"
            fill={iconColors[color]}
        />
    </svg>
);

VideoIcon.propTypes = {
    ...iconColorPropTypes,
};

VideoIcon.defaultProps = {
    color: 'white',
};

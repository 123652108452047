/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const PlusIcon = ({ color }) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 7H7V12H5V7H0V5H5V0H7V5H12V7Z"
            fill={iconColors[color]}
        />
    </svg>
);

PlusIcon.propTypes = {
    ...iconColorPropTypes,
};

PlusIcon.defaultProps = {
    color: 'dark',
};

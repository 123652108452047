/* eslint-disable */
import React from 'react';

export const ChangeIcon = () => (
    <svg width="69" height="84" viewBox="0 0 69 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.6567 83.3762C24.9856 79.3172 21.8864 78.3397 19.2056 77.1032C16.5076 76.1424 13.9537 74.8228 11.6132 73.1801L19 73.0004C29.2515 72.4734 43.3614 69.7939 52.2006 64.2656C73.861 50.6538 73.268 18.6792 50.7841 6.8125C36.7838 -0.599032 21.8808 -0.374015 6.90033 4.10527C6.27312 4.32996 5.71111 4.7059 5.26469 5.19937C4.81828 5.69285 4.5014 6.28843 4.34249 6.93273C5.86604 6.71062 7.42584 6.54609 8.95079 6.25208C20.1072 4.1394 31.1981 4.1212 42.0996 7.57192C49.0082 9.53074 54.9506 13.9369 58.8103 19.9625C67.4958 33.7897 62.1909 54.7467 47.5 62.1418C37.1956 67.3277 23.277 69.4786 11.6132 69.5004C14.4164 64.9383 23.4749 61.6646 24.745 54.7305C20.321 57.5963 16.8281 59.9655 13.2045 62.1418C10.0698 64.018 6.78073 65.639 3.6051 67.4452C0.213128 69.3621 -0.473797 71.5214 2.29314 73.9669C5.07229 76.2615 8.19609 78.1084 11.5516 79.4408C15.2346 81.01 19.1844 81.9213 23.6567 83.3762Z" fill="#58C367"/>
    </svg>
);

ChangeIcon.propTypes = {};

ChangeIcon.defaultProps = {};

import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {Button} from 'packages/components/buttons';
import {URLS} from 'packages/enum';

import {Wrapper} from './nodes';

import backgroundImg from 'src/packages/theme-ab/resources/assets/configurator/statistic-subscription-new.png';

const PageStyleSubscription = ({history, intl: { formatMessage }}) => {

    return (
        <Wrapper>
            <img alt="" src={backgroundImg} />
            <div>
                <span className="header">{formatMessage({id: 'styles.subscription.header'})}</span>
                <span>{formatMessage({id: 'styles.subscription.subheader'})}</span>
                <div>
                    <span>{formatMessage({id: 'styles.subscription.description'})}</span>
                </div>
                <Button
                    centered
                    color="green"
                    content={formatMessage({id: 'styles.subscription.upgrade'})}
                    onClick={() => history.push(URLS.website_billing)}
                />
            </div>
        </Wrapper>
    );
};

PageStyleSubscription.propTypes = {
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(PageStyleSubscription));

import styled, {css} from 'styled-components';
import BannerComp from "../banner/Banner";
import {media} from 'packages/components/media';

const COLOR = '#666A73';
const LINK_COLOR = '#58C367';
const VIEW_PADDING = 15;

const getViewWidth = view => {
    switch (view) {
        case 'tablet':
            return '768px';
        case 'mobile':
            return '375px';
        default:
            return '100%';
    }
};

export const Wrapper = styled.div`
    height: calc( 100vh - 100px );
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    ${media.max.tablet`
        overflow: hidden;
        box-shadow: none;
        height: calc( 100vh - 130px );
    `}
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`;

export const Banner = styled(BannerComp)`
    & {
        top: 48px;
        z-index: 5;
    }
`;

export const BannerIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 50%;
`;

export const BannerContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    & > div {
        & > div {
            display: flex;
            align-items: center;
        }
        & > div:not(:first-child) {
            margin-top: 5px;
        }
    }
    ${BannerIconContainer} + span {
        margin-left: 10px;
    }
`;

export const Circles = styled.div`
    margin-left: 20px;

    ${media.max.tablet`
        display: none;
    `}
`;

export const Domain = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    background: #fff;
    margin: 0 16px;
    width: fill-available;
    border-radius: 2px;
    color: ${COLOR};

    > img {
        margin: 8px;
        width: 16px;
        height: 16px;
    }

    > a {
        cursor: pointer;
        color: ${LINK_COLOR};
        margin: 0 16px;
        white-space: nowrap;
    }

    > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    transform-origin: top left;
    // ratio = 1.550053821
    width: ${props => props.width};
    height: ${props => props.height};
    overflow: hidden;

    ${props => props.view !== 'desktop' ? `padding: ${VIEW_PADDING}px 0;` : ''}
    > iframe {
        margin: 0 auto;
        height: 100%;
        width: ${props => getViewWidth(props.view)};
        ${props => props.view === 'desktop' ? 'border-radius: 0 0 6px 6px;' : ''}
        ${props => props.view !== 'desktop' ? 'box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.1);' : ''}
    }

    ${media.max.tablet`
        padding: 0;

        ${({clientWidth, view}) => clientWidth && view === 'desktop' && css`
            transform: scale(${clientWidth / 1460});

            @media(orientation: landscape) {
                height: calc(calc(100% - 48px) * ${1460 / clientWidth});
            }
        `}
    `}
`;

export const Loader = styled.div`
    width: 100%;
    height: 100%;
    z-index: 100;

    transition: visibility 0.1s linear, opacity 0.1s linear;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
`;


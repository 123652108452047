import PropTypes from 'prop-types';
import React, {useEffect, useState, useMemo, useCallback} from 'react';
import debounce from 'lodash/debounce';
import {formProptypes} from 'packages/utils/formikPropTypes';
import {
    Description,
    VideoInput,
} from './nodes';
import {Semantic} from 'packages/components';
import Message from 'packages/components/message/Message';
import {FormInput} from 'packages/components/inputs';
import {Button} from 'packages/components/buttons';
import {
    FEATURE_PROPERTY_KEYS,
    HOSTING_URLS,
    MESSAGE_TYPE,
    VIDEO_HOSTING,
} from 'packages/enum';
import queryString from 'query-string';
import {VideoIcon} from 'packages/components/icons';

const getHosting = (videoId) => `https://player.vimeo.com/video/${videoId}?muted=1&loop=1&autoplay=1&controls=0`;

const updateVideoDebounce = debounce((func = () => null) => {
    func();
}, 1000);

const VideoComponent = (
    {
        handleSubmit,
        errors,
        status,
        isSubmitting,
        loading,
        values,
        refetchPreviewMetaData,
        setStatus,
        setSubmitting,
        setValues,
        featureUpdate,
        initialValues,
        refetchData,
    },
    {intl: {formatMessage}},
) => {
    const defaultError = 'upload.modal.error.header';
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const error = Object.keys(errors).length || status && !!status.error;

    const isNewVideoUrl = useMemo(() => values.videoId !== initialValues.videoId, [values, initialValues]);

    const changeValues = useCallback(() => {
        const {url} = queryString.parseUrl(values.videoUrl || '');
        if (url.indexOf('vimeo.com') !== -1) {
            const videoId = url.split('/')
                .pop();
            if (videoId) {
                setValues({
                    ...values,
                    hosting: VIDEO_HOSTING.VIMEO,
                    videoId,
                    videoUrl: HOSTING_URLS.VIMEO + videoId,
                });
                setStatus({});
            } else {
                setStatus({error: 'upload.modal.error.invalid'});
            }
        } else if (!values.hosting) {
            setStatus({error: 'upload.modal.error.invalid'});
        }
        setIsButtonDisabled(false);
    }, [setStatus, setValues, values]);

    useEffect(() => {
        if (values.videoUrl) {
            setIsButtonDisabled(true);
            updateVideoDebounce(changeValues);
        } else if (values.videoId || values.hosting) {
            setValues({
                ...values,
                videoId: undefined,
                hosting: undefined,
            });
        }
        /* eslint-disable-next-line */
    }, [changeValues, setValues, values.videoUrl]);

    const updateVideo = () => {
        setSubmitting(true);
        featureUpdate({
            featureId: values.id,
            properties: {
                [FEATURE_PROPERTY_KEYS.sliderVideoUrl]: isNewVideoUrl ? values.videoId : null,
            },
        })
            .then(res => {
                let {
                    data: {
                        featureUpdate: {errors, success} = {},
                    },
                } = res;
                if (success) {
                    refetchData();
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_PAGE,
                        }),
                        '*',
                    );
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_META,
                        }),
                        '*',
                    );

                    return refetchPreviewMetaData();
                }
                return setStatus({error: errors._error || defaultError});
            })
            .catch(err => {
                return setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
            });
    };

    return (
        <Semantic.Form
            noValidate
            error={error}
            loading={isSubmitting}
            onSubmit={handleSubmit}
        >
            <Message
                error={error}
                errors={errors}
                content={status && status.error}
            />
            <Description>
                {formatMessage({id: 'upload.video.text'})}
            </Description>
            <VideoInput>
                <FormInput
                    name="videoUrl"
                    label={formatMessage({id: 'upload.video.label.paste'})}
                />
            </VideoInput>
            {values.videoId && values.hosting &&
            <iframe
                title={values.videoId}
                src={getHosting(values.videoId)}
                frameBorder="0"
                width="100%"
                height="200"
            />}
            <Button
                type="button"
                onClick={updateVideo}
                fluid
                view={isNewVideoUrl ? 'secondaryBlack' : 'secondaryGray'}
                content={formatMessage({id: isNewVideoUrl ? 'upload.video.label.add' : 'upload.video.label.remove'})}
                icon={isNewVideoUrl ? <VideoIcon/> : null}
                disabled={isButtonDisabled || !values.videoId}
            />
        </Semantic.Form>
    );
};

VideoComponent.propTypes = {
    initialValues: PropTypes.object,
    loading: PropTypes.bool,
    featureUpdate: PropTypes.func,
    refetchPreviewMetaData: PropTypes.func,
    refetchData: PropTypes.func,
    ...formProptypes,
};

VideoComponent.defaultProps = {
    initialValues: {},
    loading: false,
    refetchPreviewMetaData: () => null,
    featureUpdate: () => null,
    refetchData: () => null,
};

VideoComponent.contextTypes = {
    intl: PropTypes.object.isRequired,
};

export default VideoComponent;

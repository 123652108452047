/* eslint-disable */
import React from 'react';

export const RightIcon = () => (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.186748 4.21669L9.46895 4.21668L6.73844 1.49427L7.81387 0.425018L12.3901 4.97502L7.81387 9.52502L6.73844 8.45577L9.46895 5.73335L0.186748 5.73335V4.21669Z"
            fill="#666A73"/>
    </svg>
);

RightIcon.propTypes = {};

RightIcon.defaultProps = {};

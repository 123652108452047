/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {iconColors, iconColorPropTypes} from '../const';

export const EditIcon = ({ color, showBg }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        {showBg && <circle className="iconBg" cx="16" cy="16" r="16" fill="white"/>}
        <path
            d="M8.41999 24.5787C8.13948 24.5782 7.87206 24.4599 7.68299 24.2527C7.49044 24.0471 7.39476 23.7692 7.41999 23.4887L7.66499 20.7947L18.983 9.48066L22.52 13.0167L11.205 24.3297L8.51099 24.5747C8.47999 24.5777 8.44899 24.5787 8.41999 24.5787ZM23.226 12.3097L19.69 8.77366L21.811 6.65266C21.9986 6.46488 22.2531 6.35938 22.5185 6.35938C22.7839 6.35938 23.0384 6.46488 23.226 6.65266L25.347 8.77366C25.5348 8.96123 25.6403 9.21575 25.6403 9.48116C25.6403 9.74657 25.5348 10.0011 25.347 10.1887L23.227 12.3087L23.226 12.3097Z"
            fill={iconColors[color]}
        />
    </svg>

);

EditIcon.propTypes = {
    ...iconColorPropTypes,
    showBg: PropTypes.bool,
};

EditIcon.defaultProps = {
    color: 'dark',
    showBg: true,
};

import styled from "styled-components";

export const Header = styled.div`
    color: #343840;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 20px;
`;

export const Content = styled.div`
    color: #343840;
    font-size: 13px;
    line-height: 140%;
`;

export const ContentHeader = styled.div`
    ${(props) => (props.hide ? "display: none;" : "display: flex;")}
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;

    > span:first-child {
        width: 28%;
    }
    > span:nth-child(2) {
        width: 35%;
    }
    > span:nth-child(3) {
        width: 20%;
    }
    > span:last-child {
        width: 17%;
    }
`;

export const Item = styled.div`
    display: flex;
    padding: 20px 0;

    > a {
        color: ${({isNotPaid}) => isNotPaid ? '#ff002e' : '#58c367'};
    }

    > span:first-child {
        width: 28%;
    }
    > span:nth-child(2) {
        width: 35%;
    }
    > span:nth-child(3) {
        width: 20%;
    }
    > span:last-child {
        width: 17%;
    }
`;

export const NoItemsMsg = styled.div`
    color: #343840;
    font-size: 15px;
    line-height: 21px;
`;

function getFileNames(options) {
    // Safari on iOS не поддерживает get https://developer.mozilla.org/en-US/docs/Web/API/FormData/getAll
    if (!options.body || !options.body.get) {
        return [];
    }
    let filesJSON = options.body.get('map');
    if (!filesJSON) {
        return [];
    }
    try {
        let indexes = Object.keys(JSON.parse(filesJSON));
        let names = [];
        for (let i of indexes) {
            let file = options.body.get(i);
            if (file.name) {
                names.push(file.name);
            }
        }
        return names;
    } catch (e) {
        return [];
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (url, options = {}) => new Promise((resolve, reject) => {
    let fileNames = getFileNames(options);
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const opts = {
            status: xhr.status,
            statusText: xhr.statusText,
        };
        opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
        const body = 'response' in xhr ? xhr.response : xhr.responseText;
        options.onRequestFinish ? options.onRequestFinish(fileNames) : '';
        resolve(new Response(body, opts));
    };

    xhr.onerror = () => {
        reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
        reject(new TypeError('Network request failed'));
    };

    xhr.open(options.method, url, true);

    Object.keys(options.headers)
        .forEach((key) => {
            xhr.setRequestHeader(key, options.headers[key]);
        });

    if (xhr.upload) {
        xhr.upload.onprogress = options.onProgress ? e => options.onProgress(e, fileNames) : undefined;
        xhr.upload.onload = options.onLoad ? e => options.onLoad(e, fileNames) : undefined;
        xhr.upload.onloadstart = options.onLoadstart ? e => options.onLoadstart(e, fileNames) : undefined;
        xhr.upload.onloadend = options.onLoadend ? e => options.onLoadend(e, fileNames) : undefined;
    }

    xhr.send(options.body);
});

import { Toggle } from 'packages/components/inputs';
import PropTypes from 'prop-types';
import React from 'react';
import {intlShape, injectIntl} from 'react-intl';

import {
    Paragraph,
    PrivacyContainer,
    PrivacyColumn,
    PrivacyItem,
    ItemContent,
    Toggler,
    ItemTitle,
    ItemDescription
} from '../nodes';

const PrivacySection =({
    onChange: {
        toggleCookieBar,
        toggleBlockFonts
    },

    values: {
        cookies,
        fonts
    },

    intl: {
        formatMessage
    }
}) => {
    return (
        <div>
            <Paragraph type="h1" mb={30}>
                {formatMessage({id: 'website.settings.privacy.title'})}
            </Paragraph>
            <PrivacyContainer>
                <PrivacyColumn>
                    <PrivacyItem>
                        <ItemTitle type='h1' mb={5}>
                            {formatMessage({id: 'website.settings.privacy.text1'})}
                        </ItemTitle>
                        <ItemContent>
                            <ItemDescription>
                                {formatMessage({id: 'website.settings.privacy.text2'})}
                            </ItemDescription>
                            <Toggler>
                                <Toggle checked={cookies} onChange={toggleCookieBar} />
                            </Toggler>
                        </ItemContent>
                    </PrivacyItem>
                    <PrivacyItem>
                        <ItemTitle type='h1' mb={5}>
                            {formatMessage({id: 'website.settings.privacy.text3'})}
                        </ItemTitle>
                        <ItemContent>
                            <ItemDescription>
                                {formatMessage({id: 'website.settings.privacy.text4'})}
                            </ItemDescription>
                            <Toggler>
                                <Toggle checked={fonts} onChange={toggleBlockFonts}/>
                            </Toggler>
                        </ItemContent>
                    </PrivacyItem>
                </PrivacyColumn>
            </PrivacyContainer>
        </div>
    )
}

export default injectIntl(PrivacySection)

PrivacySection.propTypes = {
    intl: intlShape.isRequired,

    values:  PropTypes.shape({
        cookies: PropTypes.bool.isRequired,
        fonts: PropTypes.bool.isRequired
    }).isRequired,

    onChange: PropTypes.shape({
        toggleCookieBar: PropTypes.func.isRequired,
        toggleBlockFonts: PropTypes.func.isRequired
    }).isRequired
}

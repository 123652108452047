import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import Dropzone from "react-dropzone";
import { useBeforeunload } from "react-beforeunload";

import isEmpty from "lodash/isEmpty";

import { MAX_PHOTO_SIZE } from "@configurator/constants/upload";
import { FileUploadIcon } from "packages/components/icons/file";
import { Modal, Container, UploadWrapper } from "./nodes";

const UploadPhoto = ({
    open,
    maxSize,
    acceptedFiles,
    onClose,
    onUpload,
    openErrorModal,
    lockModalProgress,
    unLockModalProgress,
    intl: { formatMessage },
}) => {
    useBeforeunload(() => "You'll lose your data!");

    useEffect(() => {
        lockModalProgress();
        return () => unLockModalProgress();
    }, [lockModalProgress, unLockModalProgress]);

    const onDropRejected = (files) => {
        if (files.find((el) => el.size > maxSize)) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileSize.description",
                subMessageValues: { size: maxSize / 1024 / 1024 },
            });
        }
        const acceptArr = acceptedFiles.split(", ");
        if (files.find((el) => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileType.description",
                subMessageValues: { types: acceptedFiles.replace(/image\//g, "") },
            });
        }
    };

    const handlePhotoUpload = (files) => !isEmpty(files) && onUpload(files[0]);

    return (
        <Modal open={open} onClose={onClose} mobileWide>
            <Container>
                <UploadWrapper>
                    <Dropzone
                        accept={acceptedFiles}
                        onDrop={handlePhotoUpload}
                        maxSize={maxSize}
                        onDropRejected={onDropRejected}
                    >
                        {({ getInputProps, getRootProps }) => (
                            <div {...getRootProps()} className="input">
                                <input {...getInputProps()} />
                                <FileUploadIcon color="lightGray" />
                                <span className="dropzone-header">
                                    {formatMessage({
                                        id: "upload.video.text.title",
                                    })}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: formatMessage({
                                                id: "upload.video.text.browse",
                                            }),
                                        }}
                                    />
                                </span>
                                <span
                                    className="dropzone-footer"
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({
                                            id: "upload.video.text.info",
                                        }),
                                    }}
                                />
                            </div>
                        )}
                    </Dropzone>
                </UploadWrapper>
            </Container>
        </Modal>
    );
};

UploadPhoto.propTypes = {
    intl: intlShape.isRequired,
    open: PropTypes.bool,
    maxSize: PropTypes.number,
    acceptedFiles: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    lockModalProgress: PropTypes.func.isRequired,
    unLockModalProgress: PropTypes.func.isRequired,
    openErrorModal: PropTypes.func.isRequired,
};

UploadPhoto.defaultProps = {
    open: false,
    maxSize: MAX_PHOTO_SIZE,
    acceptedFiles: "image/jpeg, image/png, image/gif",
};

export default injectIntl(UploadPhoto);

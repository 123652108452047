import styled from 'styled-components';
import {Modal} from 'packages/components/modal';

export const StyledModal = styled(Modal)`
    > div {
        &:last-child {
            width: auto;
        }
    }
`;

export const Container = styled.div`
    width: 300px;
    height: 200px;
    background: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    > span {
       font-weight: bold;
    }
`;

export const TariffName = styled.span`
   text-transform: uppercase;
`;

export const Header = styled.p`
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const Content = styled.p`
    margin-bottom: 40px;
    font-size: 8px;
`;

export const Actions = styled.div`

`;

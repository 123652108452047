import PropTypes from 'prop-types';
import React, {Component} from 'react';
import UserTable from '../userTable';
import Search from '../searchByEmail';
import style from './style.less';

export default class UsersList extends Component {
    static propTypes = {
        users: PropTypes.array.isRequired,
        page: PropTypes.object.isRequired,
        fetchPage: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        setFilterByEmail: PropTypes.func.isRequired,
        filter: PropTypes.string.isRequired,
    };

    render() {
        return (
            <React.Fragment>
                <div className={style['search-bar__box']}>
                    <Search
                        filter={this.props.filter}
                        setFilter={this.props.setFilterByEmail}
                    />
                </div>
                <UserTable
                    {...this.props} // eslint-disable-line react/jsx-props-no-spreading
                />
            </React.Fragment>
        );
    }
}

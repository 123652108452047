import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import {FormSlider} from 'packages/components/inputs';

const FontSizeSliderComponent = ({intl: {formatMessage}, ...props}) => {
    return (
        <FormSlider
            label={formatMessage({ id: 'styles.inputs.fontSize.label' })}
            valueMessages={[
                formatMessage({ id: 'styles.inputs.fontSize.value.small' }),
                formatMessage({ id: 'styles.inputs.fontSize.value.medium' }),
                formatMessage({ id: 'styles.inputs.fontSize.value.large' }),
            ]}
            min={0.2}
            max={3}
            step={0.05}
            {...props}
        />
    );
};

FontSizeSliderComponent.propTypes = {
    intl: intlShape.isRequired,
};

export const StylesFontSizeSlider = injectIntl(FontSizeSliderComponent);

import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import DayPicker, {DateUtils} from 'react-day-picker';
import {injectIntl} from 'react-intl';
import {PERIOD_OPTIONS} from '@configurator/constants/statistics';
import {CaretDownIcon} from 'packages/components/icons';
import {Semantic} from 'packages/components';
import OnlineToggle from '@configurator/containers/statistics/OnlineToggleContainer';

import {
    Container,
    Form,
    FormRow,
    Styles,
    SubmitButton,
    FormColumn,
    UseDemoCheckbox,
    FilterButton,
    FilterPanel,
} from './nodes';

const periodOptions = [
    {
        label: 'statistics.periodPicker.options.day',
        value: PERIOD_OPTIONS.day,
    },
    {
        label: 'statistics.periodPicker.options.week',
        value: PERIOD_OPTIONS.week,
    },
    {
        label: 'statistics.periodPicker.options.month',
        value: PERIOD_OPTIONS.month,
    },
    {
        label: 'statistics.periodPicker.options.year',
        value: PERIOD_OPTIONS.year,
    },
    {
        label: 'statistics.periodPicker.options.range',
        value: PERIOD_OPTIONS.range,
    },
];

const PeriodPicker = ({handleSubmit, setFieldValue, values, matomoOptions, intl: {locale, formatMessage}}) => {
    const makeRadios = (name, options) => options.map(opt => (
        <Semantic.Form.Checkbox
            key={opt.value}
            radio
            name={name}
            checked={values[name] === opt.value}
            onChange={() => setFieldValue(name, opt.value)}
            label={formatMessage({id: opt.label})}
        />
    ));

    const [isFormOpen, setFormOpen] = useState(false);

    const handleDateChange = useCallback((day) => {
        if (values.period === PERIOD_OPTIONS.range) {
            const range = DateUtils.addDayToRange(day, values.date);
            setFieldValue('date', range);
        } else {
            setFieldValue('date', {from: day, to: day});
        }
    }, [values, setFieldValue]);
    const modifiers = {start: values.date.from, end: values.date.to};

    useEffect(() => {
        if (values.period !== PERIOD_OPTIONS.range && values.date.to !== values.date.from) {
            setFieldValue('date', {from: values.date.from, to: values.date.from});
        }
    }, [values, setFieldValue]);
    return (
        <Container>
            <Styles isFormOpen={isFormOpen}>
                <FilterPanel>
                    <OnlineToggle/>
                    <FilterButton onClick={() => setFormOpen(!isFormOpen)}>
                        <div>{formatMessage(
                            {id: 'statistics.newDesign.periodPicker.date'},
                            {date: matomoOptions.date},
                        )}</div>
                        <div>
                            {matomoOptions.period}
                            <CaretDownIcon/>
                        </div>
                    </FilterButton>
                </FilterPanel>
                <Form open={isFormOpen} onSubmit={handleSubmit}>
                    <FormRow>
                        <FormColumn>
                            <h3>{formatMessage({id: 'statistics.periodPicker.date'})}</h3>
                            <DayPicker
                                className="Selectable"
                                locale={locale}
                                selectedDays={[values.date.to, {from: values.date.to, to: values.date.from}]}
                                onDayClick={handleDateChange}
                                modifiers={modifiers}
                                disabledDays={{after: new Date()}}
                            />
                        </FormColumn>
                        <FormColumn>
                            <h3>{formatMessage({id: 'statistics.periodPicker.period'})}</h3>
                            <div>
                                {makeRadios('period', periodOptions)}
                            </div>
                            {__DEVELOPMENT__ && (<UseDemoCheckbox
                                name="useDemo"
                                checked={values.useDemo}
                                onChange={() => setFieldValue('useDemo', !values.useDemo)}
                                label={formatMessage({id: 'statistics.periodPicker.useDemo'})}
                            />)}

                            <SubmitButton type="submit">
                                {formatMessage({id: 'statistics.periodPicker.apply'})}
                            </SubmitButton>
                        </FormColumn>
                    </FormRow>
                </Form>
            </Styles>
        </Container>
    );
};

PeriodPicker.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    matomoOptions: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(PeriodPicker);

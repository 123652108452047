import styled from 'styled-components';
import {media} from 'packages/components/media';

export const StyledTab = styled.div`
    height: calc(100vh - 155px);

    ${media.laptop`
        overflow: auto;
    `}
`;

export const StyledConfigPanel = styled.div`
    position: relative;
`;

export const TemplatesPanelPosition = styled.div`
    margin-left: 30px;
    margin-bottom: 8px;

    ${media.max.tablet`
        margin-left: 15px;
        margin-bottom: 10px;
    `}
`;

export const TemplatesPanel = styled.div`
    cursor: pointer;
    background-color: white;
    border-radius: 2px;
    display: inline-block;
    width: 340px;
    height: 60px;
    padding: 5px;

    img {
        display: inline-block;
        width: 76px;
        height: 50px;
        margin-right: 20px;
    }

    div:not(:last-child) {
        width: 210px;
        vertical-align: top;
        font-family: Sailec, sans-serif;
        font-size: 13px;
        font-weight: 400;
    }

    div:last-child {
        line-height: 50px;

        svg {
            fill: #666A73 !important;
            vertical-align: top;
            transform: rotate(-90deg);
        }
    }

    > div {
        display: inline-block;

        span {
            :first-child {
                color: #666A73;
            }

            margin-bottom: 5px;
            display: block;
        }

        > span > b {
            text-transform: capitalize;
            font-weight: 700;
        }
    }

    ${media.max.tablet`
        width: 100%;

        div:not(:last-child) {
            width: auto;
        }

        div:last-child {
            display: flex;
            align-items: center;
            height: 100%;
            float: right;
        }
    `}
`;

export const FreeAccountBanner = styled.div`
    position: fixed;
    left: 400px;
    right: 10px;
    bottom: 20px;
    z-index: 1;
    width: calc(100vw - 583px);

    > div {
        border-radius: 2px;
        height: 62px;
        background-color: #fff;
        padding: 10px 10px 10px 20px;
        display: flex;
        align-items: center;

        & > button:nth-child(3) {
            max-height: 100%;
            background-color: #343840 !important;
            border: none !important;
            width: 160px;
            
            &:hover {
                background: #34CF49 !important;
            }
        }
    }

    ${media.max.tablet`
        width: 100vw;
        padding: 0 16px;
        left: 0;

        > div {
            display: flex;
            align-items: stretch;
            height: auto;
            padding: 10px;

            > button {
                width: min-content;
                padding: 6px 10px !important;
                margin-left: 5px !important;
                white-space: nowrap;
            }
        }
    `}

    ${media.max.phone`
        > div > button {
            white-space: normal;
        }
    `}
`;

export const TrialText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #666A73;

    > span > span {
          color: #343840;
          font-weight: bold;
      }
`;

export const TrialLink = styled.a`
    cursor: pointer;
    color: #343840;
`;

export const TrialContainer = styled.div`
    justify-content: space-between;

    > button {
        max-height: 100%;
        background-color: #343840 !important;
        border: none !important;
        width: 160px;
    }
`;

export const FreeAccountContainer = styled.div`

    > button {
        max-height: 100%;
        margin-left: 10px !important;
    }
`;

export const Counter = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    > div {

        &:first-child {
            display: flex;
            align-items: center;

            b {
                font-style: normal;
                font-weight: bold;
                font-size: 17px;
                /* Dark */
                color: #343840;
            }

            span {
                display: block;
                margin-left: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                color: #666A73;
            }
        }

        &:last-child {
            width: 100%;
            background: #EDEDED;
            border-radius: 10px;
            position: relative;
            height: 5px;
        }
    }
`;

export const Progress = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
    background: #C1C3C7;
    border-radius: 10px;
    width: ${props => props.count}%;
`;

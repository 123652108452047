import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const MoveVerticalIcon = ({ color }) => (
    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 20L10 15H6V5H10L5 0L0 5H4V15H0L5 20Z"
            fill={iconColors[color]}
        />
    </svg>
);

MoveVerticalIcon.propTypes = {
    ...iconColorPropTypes,
};

MoveVerticalIcon.defaultProps = {
    color: 'default',
};

import React from 'react';
import PropTypes from 'prop-types';

import {Semantic} from 'packages/components';

import {Loader} from '../nodes';
import {
    Container,
    Icon,
    Info,
    Count,
    Text,
} from './nodes';

const TotalInfo = ({icon, count, text, loading}) => (
    <Container>
        {icon && <Icon>{icon}</Icon>}
        <Info>
            <Count>{count}</Count>
            <Text>{text}</Text>
        </Info>
        {loading &&
        <Loader>
            <Semantic.Loader active size="small"/>
        </Loader>}
    </Container>
);

TotalInfo.propTypes = {
    icon: PropTypes.node,
    count: PropTypes.string,
    text: PropTypes.string,
    loading: PropTypes.bool,
};

TotalInfo.defaultProps = {
    icon: null,
    count: 0,
    text: '',
    loading: false,
};

export default TotalInfo;

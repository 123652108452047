/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const LockIcon = ({ color }) => (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 9L7 6L1 6L1 9L2 9L6 9L7 9Z"
            fill={iconColors[color]}
        />
        <path
            d="M7 6L8 6C8 5.44772 7.55228 5 7 5L7 6ZM7 9L7 10C7.55228 10 8 9.55228 8 9L7 9ZM1 6L1 5C0.447715 5 2.41412e-08 5.44771 0 6L1 6ZM1 9L-1.31135e-07 9C-1.55276e-07 9.55228 0.447715 10 1 10L1 9ZM6 6L6 9L8 9L8 6L6 6ZM0 6L-1.31135e-07 9L2 9L2 6L0 6ZM6 10L7 10L7 8L6 8L6 10ZM1 10L2 10L2 8L1 8L1 10ZM2 10L6 10L6 8L2 8L2 10ZM7 5L1 5L1 7L7 7L7 5Z"
            fill={iconColors[color]}
        />
        <path
            d="M2 5V3C2 1.89543 2.89543 1 4 1V1C5.10457 1 6 1.89543 6 3V5"
            stroke={iconColors[color]}
        />
    </svg>
);

LockIcon.propTypes = {
    ...iconColorPropTypes,
};

LockIcon.defaultProps = {
    color: 'dark',
};

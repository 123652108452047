import styled from "styled-components";
import { media } from "packages/components/media";
import { Semantic } from "packages/components";

const HEADER_HEIGHT = 80;
const LIFT_SIDE_MIN_WIDTH = 400;
const RIGHT_SIDE_MIN_WIDTH = 650;

export const Header = styled.div`
    height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LeftBlock = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    > a {
        &:first-child {
            padding: 20px;
            margin: 0 0 0 -2px;
        }
    }

    ${media.tablet`
       flex-grow: initial;
       width: ${LIFT_SIDE_MIN_WIDTH}px;
       min-width: ${LIFT_SIDE_MIN_WIDTH}px;
       > a {
            &:first-child {
                margin: 0 40px 0 10px;
            }
        }
    `}
`;

export const ReturnArrowContainer = styled.div`
    font-size: 13px;
    line-height: 140%;
    padding: 3px;
    cursor: pointer;
    color: #343840;

    > svg {
        margin-right: 10px;
    }
`;

export const RightBlock = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;

    ${media.tablet`
       flex-grow: initial;
       flex: auto;
       min-width: ${RIGHT_SIDE_MIN_WIDTH}px;
    `}
`;

export const DevicesBlock = styled.div`
    display: flex;
    align-items: center;

    > a {
        display: flex;
    }

    ${media.tablet`
        > svg {
            margin: 0 7px;
        }
    `}
    ${media.desktop`
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    `}
`;

export const UserBlock = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin: 0;
    }

    > .ui.dropdown {
        display: flex;
        align-items: center;
    }

    .menu.transition.visible {
        border-radius: 6px !important;
    }
`;

export const LocalSwitcherBlock = styled.div`
    display: flex !important;
    justify-content: space-between !important;

    > .ui.dropdown {
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        width: calc(100% - 10px);
        justify-content: flex-end;
        padding-right: 17px;
        color: #666a73;

        > .icon {
            color: #c1c3c7;
            transform: rotate(90deg);
        }

        &.active.visible {
            > .icon {
                transform: rotate(-90deg);
            }
        }
    }

    .visible.menu.transition {
        width: 135px !important;
        left: -148px !important;
        top: -25px !important;
        box-shadow: 0 4px 24px rgb(0 0 0 / 10%) !important;
    }
`;

export const UserDropdownContainer = styled(Semantic.Dropdown)`
    &.active {
        > .icon {
            transform: rotate(180deg);
        }
    }

    .menu.transition.visible {
        position: absolute;
        width: 288px;
        top: 50px;
        left: -239px;
        border: none;
        box-shadow: 0 24px 60px rgb(0 0 0 / 15%);
        padding: 10px 0;
        z-index: 101;

        .header {
            margin: 10px 0 20px 0;
            padding: 0 20px;
        }

        > div {
            .item {
                height: 40px;
                display: flex;
                align-items: center;
                margin: 0 10px !important;
                padding: 0 10px !important;
                border-radius: 2px;
            }

            > a {
                cursor: pointer;
                display: block;
                border: none;
                height: auto;
                text-align: left;
                border-top: none;
                line-height: 1em;
                color: rgba(0, 0, 0, 0.87);
                font-size: 1rem;
                text-transform: none;
                font-weight: normal;
                box-shadow: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                    color: rgba(0, 0, 0, 0.95);
                }
            }
        }

        .item {
            height: 40px;
            display: flex;
            align-items: center;
            margin: 0 10px !important;
            padding: 0 10px !important;
            border-radius: 2px;
        }

        .divider {
            margin: 12px 20px;
        }
    }

    &.ui.dropdown > .dropdown.icon {
        display: none;
    }

    ${media.phone`
        &.ui.dropdown >.dropdown.icon {
            display: initial;
        }
    `}
    ${media.tablet`
         .menu.transition.visible {
            left: -118px;
         }
    `}
`;

export const Initials = styled.div`
    display: none;

    ${media.phone`
        text-overflow: ellipsis;
        width: 85px;
        white-space: nowrap;
        overflow: hidden;
        display: initial;
    `}

    ${media.tablet`
         overflow: auto;
         width: auto;
         white-space: wrap;
         text-overflow: initial;
   `}
`;

export const WhatsNewBlock = styled.div`
    font-size: 13px;
    padding: 8px;
    cursor: pointer;

    ${media.max.tablet`
        display: none !important;
    `}
`;

import React from "react";

// в макете называется colors
export const ColorsIcon = ({ color }) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7 12.0833C7.6369 12.6534 8.47797 13 9.4 13C11.3882 13 13 11.3882 13 9.4C13 7.73984 11.8762 6.34215 10.3479 5.9261M3.65211 5.92609C2.12376 6.34214 1 7.73983 1 9.4C1 11.3882 2.61177 13 4.6 13C6.58823 13 8.2 11.3882 8.2 9.4C8.2 8.9317 8.11058 8.48429 7.94789 8.0739M10.6 4.6C10.6 6.58822 8.98823 8.2 7 8.2C5.01178 8.2 3.4 6.58822 3.4 4.6C3.4 2.61177 5.01178 1 7 1C8.98823 1 10.6 2.61177 10.6 4.6Z"
            stroke="#242426"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

ColorsIcon.propTypes = {};

ColorsIcon.defaultProps = {};

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {injectIntl, intlShape} from "react-intl";
import debounce from "lodash/debounce";
import { Semantic } from "packages/components";
import Message from "packages/components/message/Message";
import { FormInput } from "packages/components/inputs";
import { Button } from "packages/components/buttons";
import CountryDropdown from "packages/containers/CountryDropdownContainer";
import CardsContainer from "@configurator/containers/billing/CardsContainer";
import VatValidator from "packages/containers/vatValidatorContainer";
import { formProptypes } from "packages/utils/formikPropTypes";
import { usePrevious } from "packages/helpers/usePrevious";
import {
    Content,
    Header,
    StyledButton,
    StyledDropdown,
    StyledGroup,
    StyledGroupInput,
    VatWrapper,
} from "./nodes";

const Address = ({
    data,
    errors,
    values,
    status,
    formRef,
    isUpgrade,
    isSubmitting,
    loading,
    handleSubmit,
    setFieldValue,
    billingAccountUpdateMutation,
    intl: {formatMessage},
}) => {
    const prevValues = usePrevious(values);
    const refetchItemsAfterChangeCountryId = debounce(async () => {
        billingAccountUpdateMutation(values);
    }, 500);

    useEffect(() => {
        if (prevValues && values.countryId !== prevValues.countryId) {
            refetchItemsAfterChangeCountryId();
        }
    }, [values.countryId, prevValues, refetchItemsAfterChangeCountryId]);

    const CountrySelector = () => (
        <StyledDropdown>
            <CountryDropdown
                name="countryId"
                type="text"
                label={formatMessage({id: 'accountBilling.address.field.country'})}
                search
                selection
                required
            />
        </StyledDropdown>
    );

    const error = Object.keys(errors).length || (status && !!status.error);
    return (
        <Content isUpgrade={isUpgrade}>
            <Semantic.Form
                ref={formRef}
                error={error}
                loading={isSubmitting || loading}
                onSubmit={handleSubmit}
            >
                <Header hide={isUpgrade}>
                    {formatMessage({ id: "accountBilling.address.header" })}
                </Header>
                <Message
                    error={error}
                    messages={errors}
                    content={status && status.error}
                />
                <StyledGroup>
                    <StyledGroupInput>
                        <FormInput
                            name="name"
                            label={formatMessage({
                                id: "accountBilling.address.field.name",
                            })}
                            type="text"
                            required
                        />
                    </StyledGroupInput>
                </StyledGroup>
                <StyledGroup flexDirection={!isUpgrade && 'column'}>
                    <StyledGroupInput>
                        <FormInput
                            name="address1"
                            label={formatMessage({id: 'accountBilling.address.field.address1'})}
                            type="text"
                            required
                        />
                    </StyledGroupInput>
                    <StyledGroupInput>
                        <FormInput
                            name="address2"
                            label={formatMessage({id: 'accountBilling.address.field.address2'})}
                            type="text"
                        />
                    </StyledGroupInput>
                </StyledGroup>
                <StyledGroup>
                    <StyledGroupInput flex={3}>
                        <FormInput
                            name="city"
                            label={formatMessage({
                                id: "accountBilling.address.field.city",
                            })}
                            type="text"
                        />
                    </StyledGroupInput>
                    <StyledGroupInput flex={isUpgrade ? 2 : 1}>
                        <FormInput
                            name="zipCode"
                            label={formatMessage({
                                id: "accountBilling.address.field.zipCode",
                            })}
                            type="text"
                        />
                    </StyledGroupInput>
                    {isUpgrade && (
                        <StyledGroupInput flex={2}>
                            <CountrySelector />
                        </StyledGroupInput>
                    )}
                </StyledGroup>
                {!isUpgrade && (
                    <StyledGroup>
                        <CountrySelector />
                    </StyledGroup>
                )}
                {data && data.canAddVat ? (
                    <VatWrapper>
                        <VatValidator
                            name="vatId"
                            value={values.vatId}
                            onChange={({ target: { name, value } }) =>
                                setFieldValue(name, value)
                            }
                            countryId={values.countryId}
                        />
                    </VatWrapper>
                ) : null}
                {!isUpgrade && (
                    <>
                        <StyledGroup>
                            <StyledButton flex="3">
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    content={formatMessage({id: 'accountBilling.address.button.update'})}
                                />
                            </StyledButton>
                        </StyledGroup>
                        <CardsContainer/>
                    </>
                )}
            </Semantic.Form>
        </Content>
    );
};

Address.propTypes = {
    intl: intlShape.isRequired,
    formRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.elementType })
    ]),
    initialValues: PropTypes.object.isRequired,
    billingAccountUpdateMutation: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isUpgrade: PropTypes.bool,
    redirectToPayment: PropTypes.func,
    ...formProptypes,
};

Address.defaultProps = {
    isUpgrade: false,
    redirectToPayment: () => {},
};

export default injectIntl(Address);

import styled from 'styled-components';
import {Checkbox} from 'semantic-ui-react';
import checkSvg from './check.svg';

export const StyledCheckbox = styled(Checkbox)`
  width: 20px !important;
  height: 20px !important;

  // box
  & > label:before {
      width: 20px !important;
      height: 20px !important;
      box-sizing: border-box !important;
      background: #FFFFFF !important;
      border: 1px solid #EDEDED !important;
      border-radius: 2px !important;
  }

  &:hover {
    & > label:before {
      border-color: #E1E2E6 !important;
    }
  }

  &.disabled:hover {
      & > label:before {
          border-color: #EDEDED !important;
      }
  }

  // check icon
  & > label:after {
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    content: url('${checkSvg}') !important;
    top: 2px !important;
    left: 3px !important;
  }
`;

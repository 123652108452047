import React from "react";
import PropTypes from "prop-types";
import Cropper from "@configurator/components/cropper";
import { Modal, Container } from "./nodes";

const CropperModal = ({ src, open, onClose, onSave }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Container>
                <Cropper src={src} onSave={onSave}/>
            </Container>
        </Modal>
    );
};

CropperModal.propTypes = {
    src: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

CropperModal.defaultProps = {
    open: false,
};

export default CropperModal;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {withFormik} from 'formik';
import {withRouter} from 'react-router';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import {
    open as openDiscardChangesModal,
    close as closeDiscardChangesModal,
} from 'packages/redux/modules/modalConfirmDelete/actions';
import {connect} from 'react-redux';

import {MESSAGE_TYPE, URLS} from 'packages/enum';

import websiteAlbumUpdate from '@graphql/gql/website/websiteAlbumUpdate.gql';
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql';
import websitePagesGetByType from '@graphql/gql/website/websitePagesGetByType.gql';
import websiteAlbumGet from '@graphql/gql/website/websiteAlbumGet.gql';

import AlbumUpdate from '@configurator/components/modals/albumUpdate';
import { domainSelector, premiumDomainSelector } from 'packages/selectors/Website';
import { push } from 'connected-react-router';

const validationSchema = Yup.object()
    .shape({
        title: Yup.string()
            .required('album.field.error.name'),
        pageIds: Yup.array()
            .required('album.field.error.pageIds'),
        albumUrl: Yup.string().matches(/^[a-zA-Z]+$/, 'newDesign.album.customSlug.error')
    });

export const albumUpdateWrapper = Component => compose(
    withRouter,
    connect(
        state => ({
            domain: domainSelector(state),
            premiumDomain: premiumDomainSelector(state)
        }),
        dispatch => ({
            openDiscardChangesModal: ({
                subMessageId,
                yesMessageId,
                hideHeader,
                onClickYes,
                styles,
            }) =>
                dispatch(openDiscardChangesModal({
                    subMessageId,
                    yesMessageId,
                    hideHeader,
                    onClickYes,
                    styles,
                })),
            closeDiscardChangesModal: () => dispatch(closeDiscardChangesModal()),
            redirectToConfig: () => dispatch(push(URLS.website_config)),
        }),
    ),
    graphql(websitePagesGetByType, {
        options({pageType}) {
            return {
                variables: {
                    pageType,
                },
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websitePagesGetByType}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(websitePagesGetByType)) {
                return;
            }
            const {data} = websitePagesGetByType;

            return {pagesList: data};
        },
    }),
    graphql(websiteAlbumGet, {
        options({albumId}) {
            return {
                variables: {
                    albumId,
                },
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websiteAlbumGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(websiteAlbumGet) || !websiteAlbumGet.data) {
                return {initErrors: websiteAlbumGet.errors};
            }
            const {data} = websiteAlbumGet;

            return {
                initialValues: {
                    ...data,
                    hide: !data.visible,
                    hideHeader: !data.showHeader,
                },
            };
        },
    }),
    graphql(websiteAlbumUpdate, {
        props({mutate}) {
            return {
                async updateAlbum({
                    title,
                    description,
                    albumId,
                    pageIds,
                    visible,
                    showHeader,
                    albumUrl,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                title,
                                description,
                                albumId,
                                pageIds,
                                visible,
                                showHeader,
                                albumUrl,
                            },
                            refetchQueries: [
                                {
                                    query: websitePagesGet,
                                },
                            ],
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => {
            return {
                pageIds: [],
                ...initialValues,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
        handleSubmit: async (
            {
                title,
                description,
                pageIds,
                visible,
                hideHeader,
                albumUrl,
            }, {
                setSubmitting,
                setStatus,
                props: {
                    updateAlbum,
                    onClose,
                    albumId,
                    redirectToConfig,
                    initialValues
                },
            }) => {

            let defaultError = 'page.update.error.header';
            try {
                const res = await updateAlbum({
                    title,
                    description,
                    albumId,
                    pageIds,
                    visible,
                    showHeader: !hideHeader,
                    albumUrl,
                });
                setSubmitting(false);
                let {
                    data: {
                        websiteAlbumUpdate: {errors, success} = {},
                    },
                } = res;
                if (success) {
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_PAGE,
                        }),
                        '*',
                    );
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_META,
                        }),
                        '*',
                    );
                    if ((initialValues.albumUrl || {}).albumUrl !== albumUrl) {
                        redirectToConfig();
                    }
                    return onClose();
                }
                setStatus({error: errors._error || defaultError});
            } catch (err) {
                setSubmitting(false);
                setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
            }
        },
    }),
)(Component);

export default albumUpdateWrapper(AlbumUpdate);

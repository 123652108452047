import {connect} from 'react-redux';
import {Map} from 'immutable';
import ModalYesNo from '../components/modalYesNo/ModalYesNo';
import {close} from '../redux/modules/modalYesNo/actions';


const modalButtonsDefault = ['yes'];

const mapStateToProps = state => {
    let {
        open = false,
        buttons = modalButtonsDefault,
        headerMessageId,
        subMessageId,
        yesMessageId,
        cancelMessageId,
        onClickYes,
        onClickCancel,
        hideHeader,
        hideSubHeader,
        size,
    } = (state.get('errorModal') || new Map())
        .toJS() || {};
    return {
        open,
        buttons,
        headerMessageId,
        subMessageId,
        yesMessageId,
        cancelMessageId,
        onClickYes,
        onClickCancel,
        hideHeader,
        hideSubHeader,
        size,
    };
};

const mapDispatchToProps = {
    close,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalYesNo);

import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';
import {Container, Label} from './nodes';
import {FormToggle} from 'packages/components/inputs';

const Component = ({intl: {formatMessage}, label, ...props}) => {
    return (
        <Container>
            <Label>{formatMessage({ id: label })}</Label>
            <FormToggle {...props}/>
        </Container>
    );
};

Component.propTypes = {
    intl: intlShape.isRequired,
    label: PropTypes.string.isRequired,
};

export const StylesToggle = injectIntl(Component);

const {SERVICE} = require('../enum');

/* eslint-disable global-require */
const messagesPaths = {
    common: {
        en: './messages/common/messages.en.js',
        de: './messages/common/messages.de.js',
        fr: './messages/common/messages.fr.js',
        ja: './messages/common/messages.ja.js',
    },
    [SERVICE.configurator]: {
        en: './messages/configurator/messages.en.js',
        de: './messages/configurator/messages.de.js',
        fr: './messages/configurator/messages.fr.js',
        ja: './messages/configurator/messages.ja.js',
    },
    [SERVICE.landing]: {
        en: './messages/landing/messages.en.js',
        de: './messages/landing/messages.de.js',
        fr: './messages/landing/messages.fr.js',
        ja: './messages/landing/messages.ja.js',
    },
};

export const defaultLocale = 'en';
export const defaultType = SERVICE.configurator;

async function importMessages(path) {


    return new Promise((resolve, reject) => {
        let importDictionary;
        if (path === messagesPaths[SERVICE.configurator].en) {
            importDictionary = import('./messages/configurator/messages.en.js');
        }
        if (path === messagesPaths[SERVICE.configurator].de) {
            importDictionary = import('./messages/configurator/messages.de.js');
        }
        if (path === messagesPaths[SERVICE.configurator].fr) {
            importDictionary = import('./messages/configurator/messages.fr.js');
        }
        if (path === messagesPaths[SERVICE.configurator].ja) {
            importDictionary = import('./messages/configurator/messages.ja.js');
        }
        if (path === messagesPaths.common.en) {
            importDictionary = import('./messages/common/messages.en.js');
        }
        if (path === messagesPaths.common.de) {
            importDictionary = import('./messages/common/messages.de.js');
        }
        if (path === messagesPaths.common.fr) {
            importDictionary = import('./messages/common/messages.fr.js');
        }
        if (path === messagesPaths.common.ja) {
            importDictionary = import('./messages/common/messages.ja.js');
        }

        if (!importDictionary) {
            console.error(`${path} не найден`);
            return reject(`${path} не найден`);
        }

        importDictionary
            .then((module) => {
                resolve(module.default);
            })
            .catch(err => reject(err));
    });
}

/**
 * Returns translated messages for locale and type
 * @param {string} locale One of 'en', 'de'.
 * @param {string} type One of 'landing', 'configurator'
 * @return {{locale: string, messages: {}}}
 */
export async function fetchMessages({locale = defaultLocale, type = defaultType}) {
    type = type in messagesPaths ? type : defaultType;
    locale = locale in messagesPaths[type] ? locale : defaultLocale;

    const messages = await importMessages(messagesPaths[type][locale]);
    const messagesCommon = await importMessages(messagesPaths.common[locale]);
    return {
        locale,
        type,
        messages: {
            ...messagesCommon,
            ...messages,
        },
    };
}

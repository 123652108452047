import {InMemoryCache} from 'apollo-cache-inmemory';
import {setContext} from 'apollo-link-context';
import {createHttpLink} from 'apollo-link-http';
import {createUploadLink} from 'apollo-upload-client';
import {ApolloClient} from 'apollo-client';
import {concat, split} from 'apollo-link';
import {COOKIE, HEADER} from 'packages/enum';
import {updateStatus, closeBar} from 'packages/redux/modules/modalProgress/actions.js';
import customFetch from './customFetch';

/**
 * Create Apollo client for server and client usage.
 * @param {Cookies} cookies Instance of Cookies from react-cookie.
 * @param {Map} [apolloState] Used on client to hydrate Apollo state.
 * @param dispatch
 * @return {ApolloClient}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default ({cookies, apolloState, dispatch}) => {

    let uri = __GRAPHQL_API__;

    const authLink = cookies.get(COOKIE.token)
        ? setContext(() => ({
            headers: {
                [HEADER.authorization]: cookies.get(COOKIE.token),
            },
        }))
        : setContext(() => ({
            headers: {},
        }));
    const httpLink = createHttpLink({uri, credentials: 'same-origin'});
    const uploadLink = createUploadLink({
        uri,
        credentials: 'same-origin',
        fetch: typeof window === 'undefined' ? global.fetch : customFetch,
        fetchOptions: {
            onLoadend: (event, fileNames) => {
                if (event.total > 5) {
                    dispatch(updateStatus({
                        fileNames,
                        label: 'progressBar.processing',
                        percent: Math.floor(event.loaded / event.total * 100),
                    }));
                }
            },
            onProgress: (event, fileNames) => {
                if (event.total > 5) {
                    dispatch(updateStatus({
                        fileNames,
                        label: 'progressBar.fileUploading',
                        percent: Math.floor(event.loaded / event.total * 100),
                    }));
                }
            },
            onRequestFinish: fileNames => {
                dispatch(closeBar(fileNames));
            },
        },
    });
    const cache = new InMemoryCache();
    const isFile = value => (
        (typeof File !== 'undefined' && value instanceof File) ||
        (typeof FileList !== 'undefined' && value instanceof FileList) ||
        (typeof Blob !== 'undefined' && value instanceof Blob) ||
        Array.isArray(value) && value.filter(el => isFile(el)).length === value.length);


    const isUpload = ({variables}) =>
        Object.values(variables)
            .some(isFile);
    const terminalLink = split(isUpload, uploadLink, httpLink);
    return new ApolloClient({
        link: concat(authLink, terminalLink),
        cache: cache.restore(apolloState),
        ssrMode: false,
        // разрешить обновление UI при изменении кэша
        assumeImmutableResults: true,
    });
};

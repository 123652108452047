import PropTypes from 'prop-types';
import React from 'react';
import {intlShape, injectIntl} from 'react-intl';

import {Divider} from 'packages/components/divider';
import {Toggle} from 'packages/components/inputs';

import {
    Paragraph,
    Field,
    VisibilityTitle,
    VisibilityDescription,
} from '../nodes';


const VisibilitySection = (
    {
        values: {
            visible,
            cookie,
            branding
        },
        onChange: {
            toggleVisibility,
            toggleCookieSolution,
            toggleRemoveBranding
        },
        intl: {
            formatMessage
        },
    }) => (
    <div>
        <Paragraph type="h1" mb={30}>
            {formatMessage({id: 'website.settings.visibility.title'})}
        </Paragraph>
        <Field>
            <div>
                <VisibilityTitle>
                    {formatMessage({id: 'website.settings.visibility.text1'})}
                </VisibilityTitle>
                <VisibilityDescription>
                    {formatMessage({id: 'website.settings.visibility.text2'})}
                </VisibilityDescription>
            </div>
            <div>
                <span>{formatMessage({id: `website.settings.visibility.${visible ? 'live' : 'offline'}`})}</span>
                <Toggle checked={visible} onChange={toggleVisibility}/>
            </div>
        </Field>
        {/*<Divider/>*/}
        {/*<Field>*/}
        {/*    <div>*/}
        {/*        <VisibilityTitle>*/}
        {/*            {formatMessage({id: 'website.settings.visibility.text3'})}*/}
        {/*        </VisibilityTitle>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*        <Toggle checked={cookie} onChange={toggleCookieSolution}/>*/}
        {/*    </div>*/}
        {/*</Field>*/}
        {/*<Divider/>*/}
        {/*<Field>*/}
        {/*    <div>*/}
        {/*        <VisibilityTitle>*/}
        {/*            {formatMessage({id: 'website.settings.visibility.text4'})}*/}
        {/*        </VisibilityTitle>*/}
        {/*        <VisibilityDescription>*/}
        {/*            {formatMessage({id: 'website.settings.visibility.text5'})}*/}
        {/*        </VisibilityDescription>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*        <Toggle checked={branding} onChange={toggleRemoveBranding}/>*/}
        {/*    </div>*/}
        {/*</Field>*/}
        <Divider/>
    </div>
);

VisibilitySection.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
};

VisibilitySection.defaultProps = {
    value: true,
};

export default injectIntl(VisibilitySection);

import React from 'react';
import PropTypes from 'prop-types';
import Image from 'packages/components/gatsby-image';

const ImageComponent = ({
    alt,
    srcSetWebpMap,
    src,
    aspectRatio,
    imgStyle,
    draggable,
    backgroundColor,
    durationFadeIn,
    children,
}) => {
    return (
        <Image
            fluid={{
                srcSetWebpMap,
                src,
                aspectRatio,
            }}
            alt={alt}
            fadeIn
            durationFadeIn={durationFadeIn}
            loading="lazy"
            imgStyle={imgStyle}
            backgroundColor={backgroundColor}
            draggable={draggable}
        >
            {children}
        </Image>
    );
};

ImageComponent.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    srcSetWebpMap: PropTypes.array,
    aspectRatio: PropTypes.number,
    durationFadeIn: PropTypes.number,
    imgStyle: PropTypes.string,
    backgroundColor: PropTypes.string,
    draggable: PropTypes.bool,
    children: PropTypes.node,
};

ImageComponent.defaultProps = {
    aspectRatio: 1,
    durationFadeIn: 500,
    imgStyle: undefined,
    backgroundColor: undefined,
    srcSetWebpMap: [],
    children: null,
    draggable: false,
};

export default ImageComponent;

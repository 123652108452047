import { compose } from "redux";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import get from "lodash/get";
import billingAccountDelete from "@graphql/gql/website/billingAccountDelete.gql";
import DeleteAccount from "@configurator/components/billing/deleteAccount";
import { emailSelector } from "packages/selectors/User";
import {
    start as loaderStart,
    stop as loaderStop,
} from "packages/redux/modules/pageLoader/actions";
import { open as openErrorModal } from "packages/redux/modules/modalYesNo/actions";
import { URLS } from "packages/enum";
import { ANALYTICS } from "packages/mixpanel/Mixpanel";

export default compose(
    connect(
        // mapStateToProps
        (state) => ({
            email: emailSelector(state),
        }),
        // mapDispatchToProps
        (dispatch) => ({
            openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
                dispatch(
                    openErrorModal({
                        headerMessageId,
                        yesMessageId,
                        subMessageId,
                    })
                ),
            loaderStart: () => dispatch(loaderStart()),
            loaderStop: () => dispatch(loaderStop()),
        })
    ),
    graphql(billingAccountDelete, {
        props({
            mutate,
            ownProps: { openErrorModal, loaderStart, loaderStop },
        }) {
            return {
                async billingAccountDelete() {
                    try {
                        loaderStart();
                        const res = mutate();
                        loaderStop();
                        if (
                            get(res, "data.billingAccountDelete.errors._error")
                        ) {
                            openErrorModal({
                                headerMessageId: "error.header",
                                yesMessageId: "OK",
                                subMessageId: get(
                                    res,
                                    "data.billingAccountDelete.errors._error"
                                ),
                            });
                        }
                        ANALYTICS.deleteAccount();
                        return (window.location = URLS.root);
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    })
)(DeleteAccount);

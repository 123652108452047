import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {Semantic} from 'packages/components';
import Message from 'packages/components/message/Message';
import {Modal} from 'packages/components/modal';
import {FormInput} from 'packages/components/inputs';
import {formProptypes} from 'packages/utils/formikPropTypes';
import {
    Content,
    Header,
    MobileSubmitContainer,
    SocialInput,
    SubmitButton,
} from './nodes';
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay';
import {useSubscription} from '@configurator/providers/subscription';


const SocialLinksModal = ({
    open,
    onClose,
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    setFieldValue,
    values,
}, {intl: {formatMessage}}) => {
    const error = Object.keys(errors).length || status && !!status.error;
    const {permissions} = useSubscription();

    const handleInstagramChange = useCallback(e => {
        setFieldValue('instagramUrl', e.target.value
            .replace(/\//g, '')
            .replace(/www\.instagram\.com/g, '')
            .replace(/instagram\.com/g, '')
            .replace(/https:/g, ''));
    }, [setFieldValue]);

    const handleFacebookChange = useCallback(e => {
        setFieldValue('facebookUrl', e.target.value
            .replace(/\//g, '')
            .replace(/www\.facebook\.com/g, '')
            .replace(/facebook\.com/g, '')
            .replace(/https:/g, ''));
    }, [setFieldValue]);

    const modalStyle = {
        width: '580px',
        height: 'initial',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            styles={modalStyle}
        >
            <Content>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting || loading}
                    onSubmit={handleSubmit}
                >
                    <Header>
                        <span>
                            {formatMessage({id: 'newDesign.socialLinks.update.header'})}
                        </span>
                        <SubmitButton
                            type="submit"
                            content={formatMessage({ id: 'newDesign.socialLinks.update.button.save' })}
                            disabled={isSubmitting}
                        />
                    </Header>
                    <Message
                        error={error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <SocialInput styles={{paddingLeft: '138px'}}>
                        <span>{formatMessage({id: 'newDesign.socialLinks.update.label.facebook'})}</span>
                        <PermissionsOverlay isAllowed={permissions.SOCIAL_FB}>
                            <FormInput
                                type="text"
                                name="facebookUrl"
                                onChange={handleFacebookChange}
                                value={values.facebookUrl}
                                label={formatMessage({id: 'newDesign.socialLinks.update.placeholder.facebook'})}
                            />
                        </PermissionsOverlay>
                    </SocialInput>
                    <SocialInput styles={{paddingLeft: '141px'}}>
                        <span>{formatMessage({id: 'newDesign.socialLinks.update.label.instagram'})}</span>
                        <PermissionsOverlay isAllowed={permissions.SOCIAL_INSTA}>
                            <FormInput
                                type="text"
                                name="instagramUrl"
                                onChange={handleInstagramChange}
                                value={values.instagramUrl}
                                label={formatMessage({id: 'newDesign.socialLinks.update.placeholder.instagram'})}
                            />
                        </PermissionsOverlay>
                    </SocialInput>
                    <MobileSubmitContainer>
                        <SubmitButton
                            type="submit"
                            content={formatMessage({ id: 'newDesign.socialLinks.update.button.save' })}
                            disabled={isSubmitting}
                        />
                    </MobileSubmitContainer>
                </Semantic.Form>
            </Content>
        </Modal>
    );
};

SocialLinksModal.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    ...formProptypes,
};

SocialLinksModal.defaultProps = {
    open: false,
};

SocialLinksModal.contextTypes = {
    intl: PropTypes.object.isRequired,
};
export default SocialLinksModal;

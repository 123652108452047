import styled from 'styled-components';
import {FormDropdown} from 'packages/components/inputs/formElements';

export const Select = styled(FormDropdown)`
   .ui.selection.dropdown .menu>.item {
       padding: 0 !important;
       > div {
           padding: .78571429rem 1.14285714rem !important;
       }
       &:first-child {
          display: none !important;
       }
       &:last-child {
           pointer-events: all;
           border-top: none;
           font-weight: bold;
           color: grey;
           height: max-content !important;
           &:hover {
               background: none !important;
               cursor: initial !important;
           }
       }
   }
`;
export const Pagination = styled.div`
   display: ${props => !props.show ? 'none' : 'flex'};
`;
export const PaginationItem = styled.div`
   cursor: pointer;
   &:last-child {
      margin-left: auto;
      i {
        margin: 0  0 0 .25rem !important;
      }
   }
`;

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.label`
    font-family: Sailec, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    color: #343840;

    margin-bottom: 5px;
    display: block;
`;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import {changeTemplatesColor} from '@configurator/redux/modules/templatesColor/actions';

import previewPageGet from '@graphql/gql/website/previewPageGet.gql';

import Root from "../components/root/Root";

export default compose(
    connect(
        // mapStateToProps
        state => ({}),
        // mapDispatchToProps
        dispatch => ({
            changeTemplatesColor: (payload) => dispatch(changeTemplatesColor(payload)),
        }),
    ),
    graphql(previewPageGet, {
        options() {
            return {
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, previewPageGet}, ownProps: {changeTemplatesColor}}) {
            if (loading) {
                return {loading};
            }

            if (isEmpty(previewPageGet)) {
                return {loading};
            }

            const {data} = previewPageGet;

            changeTemplatesColor(get(data, 'meta.inverted') ? 'black' : 'white');

            return {};
        },
    }),
)(Root);

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { renderRoutes } from "react-router-config";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import ModalYesNo from "packages/containers/ModalYesNo";
import PageLoader from "packages/containers/PageLoader";
import ModalProgress from "packages/containers/ModalProgress";

import { StyledToastContainer } from "./nodes";

const Section = styled.section`
    background: #f6f6f6;
    height: 100%;
    box-sizing: border-box;
`;

const Root = ({ Header, route }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            if (window.HelpWidget) {
                let config = {
                    position: 'bottom-right',
                    show_logo: true,
                };

                window.HelpWidget('init', config);
            }
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Helmet>
                <script>{`
                    WebFont.load({custom: {
                    families: ['Sailec-Medium', 'Sailec-Light', 'Sailec'],
                }})
                `}</script>
                {/*Render ProductStash*/}
                <script>{`
                    var ps_config = {
                    productId: "2c0550e3-a1cf-4f57-951f-2bd5971e7172"
                };
                `}</script>
                <script
                    type="text/javascript"
                    src="https://app.productstash.io/js/productstash-embed.js"
                    defer="defer"
                />
            </Helmet>
            <Section>
                <StyledToastContainer
                    position="bottom-left"
                    autoClose={2000}
                    closeButton={false}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    pauseOnVisibilityChange
                    pauseOnHover
                />
                <ModalYesNo />
                <PageLoader />
                <ModalProgress />
                {renderRoutes(route.routes, {
                    Header,
                })}
            </Section>
        </>
    );
};

Root.propTypes = {
    Header: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
};

export default Root;

import styled from 'styled-components';
import {Modal} from 'packages/components/modal';

export const ModalContainer = styled(Modal)`
    position: initial !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    text-align: center;
    color: #343840;

    .title {
        font-size: 24px;
        line-height: 150%;
        font-weight: bold;
        text-align: center;
        padding: 0;
        border: none;
    }

    .body {
        margin-top: 10px;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
    }

    .footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .button {
            width: 180px;
            margin: 0 5px !important;
        }
    }
`;

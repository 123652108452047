import {compose} from 'redux';
import {connect} from 'react-redux';
import {graphql} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import statisticMapDataGet from '@graphql/gql/website/statisticMapDataGet.gql';

import StatisticMap from '@configurator/components/statistics/charts/statisticMap/StatisticMap';

export const statisticMapWrapper = Component =>  compose(
    connect(
        state => ({ matomoOptions: state.get('periodPicker').toJS() })
    ),
    graphql(statisticMapDataGet, {
        skip: ({ matomoOptions }) => !matomoOptions.idSite,
        options: ({ matomoOptions }) => ({
            variables: matomoOptions,
            ssr: false,
        }),
        props({data: {loading, statisticMapDataGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(statisticMapDataGet)) {
                return {loading};
            }
            const { data: countries } = statisticMapDataGet;
            return {
                countries: isArray(countries) ? countries : undefined,
                loading,
            };
        },
    })
)(Component);


export default statisticMapWrapper(StatisticMap);

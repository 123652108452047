import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { Semantic } from "packages/components";
import { formProptypes } from "packages/utils/formikPropTypes";
import Loader from "packages/components/pageLoader/PageLoader";
import { Button } from "packages/components/buttons";

import {
    Header,
    Content,
    Container,
    Stack,
    StyledFormInput,
    StyledFormInputPassword,
    StyledMessage
} from "@landing/components/common/nodes";
import WaitingScreen from "@landing/components/createAccount/WaitingScreen";

import { Notes, NotesRow, Description, LinkToFree } from "./nodes";
import { Helmet } from "react-helmet";
import { seoTags } from "packages/intl/messages/seo";
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Link, useLocation } from "react-router-dom";
import { URLS } from "packages/enum";

const CreateAccountFormWithReCaptcha = (props) => {
    if (__RECAPTCHA_KEY__) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={__RECAPTCHA_KEY__}>
                <CreateAccountForm {...props} />
            </GoogleReCaptchaProvider>
        );
    }

    return (
        <CreateAccountForm {...props} />
    );
};

const CreateAccountForm = (
    {
        handleSubmit,
        errors,
        status,
        isSubmitting,
        loading,
        values,
        setFieldValue
    },
    { intl: { formatMessage } }
) => {
    let location = useLocation();

    console.log(location);
    let freePlan = location.pathname.replace(/\/+$/, "") === URLS.website_create_free_account;

    const error = Object.keys(errors).length || (status && !!status.error);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const getReCaptchaToken = useCallback(async () => {
        try {
            return await executeRecaptcha("CreateAccount");
        } catch (err) {
            throw new Error("Token error");
        }
    }, [executeRecaptcha]);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        setFieldValue("freePlan", freePlan);
        if (__RECAPTCHA_KEY__) {
            getReCaptchaToken().then(token => {
                setFieldValue("recaptcha", token);
                handleSubmit();
            });
        } else {
            handleSubmit();
        }
    };

    const renderCreateAccountForm = () => (
        <Container verticalAlign="initial">
            <Helmet>
                <title>{freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}</title>
                <meta name="title"
                      content={freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title} />
                <meta
                    name="description"
                    content={freePlan ? seoTags.createAccountFree.description : seoTags.createAccountPro.description}
                />
                <meta
                    property="og:title"
                    content={freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}
                />
                <meta
                    property="og:description"
                    content={freePlan ? seoTags.createAccountFree.description : seoTags.createAccountPro.description}
                />
                <meta
                    property="twitter:title"
                    content={freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}
                />
                <meta
                    property="twitter:description"
                    content={freePlan ? seoTags.createAccountFree.description : seoTags.createAccountPro.description}
                />
            </Helmet>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
                src="https://app.prospect.io/companies/efa4d352-4543-4d08-b954-d5e6ea29f14b/tracker"
                width="0"
                height="0"
                style={{ display: "none" }} // eslint-disable-line react/style-prop-object
            />
            <Header>
                {formatMessage({ id: "landing.account.create.header" })}{freePlan ? ' Vsble Free' : ''}
            </Header>
            <Description>
                <span
                    dangerouslySetInnerHTML={{
                        __html: formatMessage({
                            id: freePlan ? "landing.account.free.description" : "landing.account.pro.description"
                        })
                    }}
                />
                {
                    freePlan ? null : (
                        <LinkToFree>
                            <Link to={URLS.website_create_free_account}>
                                {formatMessage({
                                    id: "landing.account.create.pro.link"
                                })}
                            </Link>
                        </LinkToFree>
                    )
                }
                {
                    freePlan ? null : (
                        <NotesRow>
                            <Notes>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: formatMessage({
                                        id: "landing.account.pro.note"
                                    })
                                }}
                            />
                            </Notes>
                        </NotesRow>
                    )
                }

            </Description>
            <Content>
                <Semantic.Form noValidate error={!!error} onSubmit={handleSubmit}>
                    <StyledMessage
                        error={!!error}
                        messages={errors}
                        content={status && status.error}
                    />
                    <Stack>
                        <StyledFormInput
                            name="name"
                            label={formatMessage({
                                id: "landing.account.create.label.name"
                            })}
                            width="100%"
                            type="text"
                        />
                        <StyledFormInput
                            name="email"
                            label={formatMessage({
                                id: "landing.account.create.label.email"
                            })}
                            width="100%"
                            type="email"
                        />
                        <StyledFormInputPassword
                            name="password"
                            label={formatMessage({
                                id: "landing.account.create.label.password"
                            })}
                            width="100%"
                            type="password"
                        />
                        <StyledFormInputPassword
                            name="passwordConfirm"
                            label={formatMessage({
                                id: "landing.account.create.label.passwordConfirm"
                            })}
                            width="100%"
                            type="password"
                        />
                    </Stack>
                    <NotesRow>
                        <Notes>
                            <span>
                                {formatMessage({ id: "landing.agree.text.by" })}
                            </span>{" "}
                            <a
                                rel="noreferrer"
                                href={"https://www.vsble.me/terms-conditions"}
                                target="_blank"
                            >
                                {formatMessage({ id: "legal.nav.terms" })}
                            </a>{" "}
                            {formatMessage({ id: "landing.agree.text.and" })}{" "}
                            <a
                                rel="noreferrer"
                                href={"https://www.vsble.me/privacy"}
                                target="_blank"
                            >
                                {formatMessage({ id: "legal.nav.privacy" })}
                            </a>{" "}
                            {formatMessage({ id: "landing.agree.text.last" })}
                        </Notes>
                    </NotesRow>
                    <Button
                        fluid
                        onClick={formSubmitHandler}
                        disabled={isSubmitting}
                        content={formatMessage({
                            id: "landing.account.create.button.label"
                        })}
                    />
                </Semantic.Form>
            </Content>
            {isSubmitting || loading ? (
                <Loader
                    open
                    inverted
                    content={formatMessage({
                        id: "landing.account.create.loader"
                    })}
                />
            ) : null}
        </Container>
    );

    return isSubmitting ? (
        <WaitingScreen formatMessage={formatMessage} />
    ) : (
        renderCreateAccountForm()
    );
};

CreateAccountForm.propTypes = {
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    loading: PropTypes.bool.isRequired,
    ...formProptypes
};
CreateAccountForm.defaultProps = {
    loading: false
};

CreateAccountForm.contextTypes = {
    intl: PropTypes.object.isRequired
};
export default CreateAccountFormWithReCaptcha;

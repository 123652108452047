import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { renderRoutes } from "react-router-config";
import ModalYesNo from "packages/containers/ModalYesNo";
import ModalConfirmDelete from "packages/containers/ModalConfirmDelete";
import { useSubscription } from "@configurator/providers/subscription";
import { ANALYTICS } from "packages/mixpanel/Mixpanel";

function Home({ route, email, name }) {
    const {
        info: { subscriptionType, planType },
        loading,
    } = useSubscription();

    useEffect(() => {
        if (!loading && email) {
            ANALYTICS.identify({
                email,
                subscriptionType,
                planType,
            });

            if (window.LOU) {
                window.LOU.identify(email, {
                    isAuthenticated: true,
                    name,
                    email,
                    planType,
                    subscriptionType,
                });
                console.log("LOU initialized");
            } else {
                console.log("LOU script not initialized");
            }
        }
    }, [email, name, subscriptionType, planType, loading]);

    return (
        <>
            {renderRoutes(route.routes)}
            <ModalYesNo />
            <ModalConfirmDelete />
        </>
    );
}

Home.propTypes = {
    route: PropTypes.object.isRequired,
};

export default Home;

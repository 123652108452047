import {createActions} from 'redux-actions';


export const {
    navigation: {setDragCurrentOffset},
} = createActions(
    {
        NAVIGATION: {
            SET_DRAG_CURRENT_OFFSET: currentOffset => currentOffset,
        },
    },
);

import React from 'react';
import PropTypes from 'prop-types';
import MostViewed from './MostViewed';

const MostViewedImage = ({item, loading, list}) => (
    <MostViewed
        title="mostViewed.image.title"
        item={item}
        loading={loading}
        list={list}
        image
    />
);

MostViewedImage.propTypes = {
    item: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    list: PropTypes.array,
};

MostViewedImage.defaultProps = {
    loading: false,
    list: [],
};

export default MostViewedImage;

import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 20px;
    border-top: 1px solid #E1E2E6;
`;

export const Header = styled.div`
    color: #343840;
    font-weight: bold;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 5px;
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 140%;

    > span {
        color: #666A73;
    }

    > button {
        color: #C1C3C7;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const MoveHorizontalIcon = ({ color }) => (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 4V0L0 5L5 10V6H15V10L20 5L15 0V4H5Z"
            fill={iconColors[color]}
        />
    </svg>
);

MoveHorizontalIcon.propTypes = {
    ...iconColorPropTypes,
};

MoveHorizontalIcon.defaultProps = {
    color: 'default',
};

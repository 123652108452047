import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import {Semantic} from "packages/components";

import {
    SettingsContent,
    SettingsItemButton,
    Dropdown,
} from './nodes';
import {iconColors} from "../icons";

const DropdownMenu = ({
    trigger,
    items,
    wide,
    isLastItemInRow,
    menuRef,
    open,
    disabled,
    intl: {formatMessage},
}) => {

    const isIconExist = items.filter(item => !!item.icon).length > 0;
    return (
        <Dropdown
            trigger={trigger}
            isLastItemInRow={isLastItemInRow}
            direction="left"
            disabled={disabled}
            upward={false}
        >
            <Semantic.Dropdown.Menu open={open}>
                <SettingsContent wide={wide} ref={menuRef}>
                    {items.map(({text, onClick = () => null, icon, color = 'dark', header}) => (
                        <SettingsItemButton
                            key={text}
                            header={header}
                            color={iconColors[color]}
                            onClick={onClick}
                            isIconExist={isIconExist}
                        >
                            {isIconExist && <span>{icon}</span>}
                            {formatMessage({id: text})}
                        </SettingsItemButton>
                    ))}
                </SettingsContent>
            </Semantic.Dropdown.Menu>
        </Dropdown>
    );
};

DropdownMenu.propTypes = {
    intl: intlShape.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.bool,
        text: PropTypes.string.isRequired,
        icon: PropTypes.element,
        onClick: PropTypes.func,
        header: PropTypes.bool,
        disabled: PropTypes.bool,
        color: PropTypes.oneOf(Object.keys(iconColors)),
    })).isRequired,
    trigger: PropTypes.element.isRequired,
    wide: PropTypes.bool,
    isLastItemInRow: PropTypes.bool,
    menuRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.elementType })
    ]),
    open: PropTypes.bool,
    disabled: PropTypes.bool,
};

DropdownMenu.defaultProps = {
};

export default injectIntl(DropdownMenu);


import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { Button } from "packages/components/buttons";
import ImageFocusPointSelector from "packages/components/imageFocusPoint/ImageFocusPointSelector";
import { useClickOutside } from "src/packages/helpers/useClickOutside";
import { FocusPreview } from "./focusPreview";
import { Content, ButtonRow, Modal as StyledModal } from "./nodes";

const convert = (x) => Math.round((1 + x) * 50);
const convertBack = (res) => res / 50 - 1;

const FocusPointModal = ({
    open,
    onClose,
    src,
    initialFocusPoint,
    onFocusPointUpdate,
}) => {
    const [focusPoint, setFocusPoint] = useState({
        x: convertBack(initialFocusPoint.x),
        y: convertBack(100 - initialFocusPoint.y),
    });

    const ref = useRef();
    useClickOutside(ref, () => {
        open && onClose();
    });
    return (
        <>
            {ReactDOM.createPortal(
                <FocusPreview
                    src={src}
                    xFocalPoint={focusPoint.x}
                    yFocalPoint={focusPoint.y}
                />,
                document.body
            )}

            <StyledModal ref={ref} className="ui modal">
                <Content>
                    <span>
                        <FormattedMessage id="focusPoint.modal.message" />
                    </span>
                    <ImageFocusPointSelector
                        src={src}
                        xFocalPoint={focusPoint.x}
                        yFocalPoint={focusPoint.y}
                        onChange={setFocusPoint}
                    />
                    <ButtonRow>
                        <Button
                            content={
                                <FormattedMessage id="focusPoint.modal.button.ok" />
                            }
                            onClick={() => {
                                onFocusPointUpdate(
                                    convert(focusPoint.x),
                                    convert(-focusPoint.y)
                                );
                                onClose();
                            }}
                            green
                        />
                    </ButtonRow>
                </Content>
            </StyledModal>
        </>
    );
};

FocusPointModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onClickYes: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    open: PropTypes.bool,
    initialFocusPoint: PropTypes.object,
    onChange: PropTypes.func,
};

FocusPointModal.defaultProps = {
    open: false,
    initialFocusPoint: { x: 0, y: 0 },
    onChange: () => null,
};

export default FocusPointModal;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }
`;

export const Label = styled.label`
    font-family: Sailec, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #343840;
    display: block;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 45px;
  background: white;
  border-radius: 2px;
  align-items: center;
  justify-content: flex-start;
`;

export const SvgContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 45px;
  cursor: pointer;

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) ${props => props.open ? 'rotate(180deg)' : ''};
    right: 17px;
  }
`;

export const InputColorBox = styled.div`
  background-color: ${props => props.color};
  width: 35px;
  height: 35px;
  margin-left: 5px;
`;

export const InputText = styled.div`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #343840;

  margin-left: 10px;
`;

export const InputField = styled.input`
  all: unset;
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #343840;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 340px;
  border-radius: 6px;
  background: white;
  z-index: 99;
`;

export const SavedColors = styled.div`
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;

`;

export const SavedColor = styled.div`
  margin-left: 10px;
  background: ${({color = 'white'}) => color};
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

export const AddColor = styled(SavedColor)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover > svg {
    transition: all 100ms;
    opacity: 0.5;
  }
`;

export const SaturationContainer = styled.div`
  position: relative;
  height: 200px;
  & > div > div > div:nth-child(2) > div {
    box-shadow: none !important;
    border: 2px solid white !important;
    border-radius: 50% !important;
    height: 15px !important;
    width: 15px !important;
    transform: translate(-50%, -50%) !important;
  }
`;

export const HslText = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: white;
  pointer-events: none;
`;

export const HueContainer = styled.div`
  position: relative;
  height: 30px;
  & .hue-horizontal {
    border-radius: 0 0 6px 6px;
      & > div > div {
        box-shadow: none !important;
        border: 2px solid white !important;
        border-radius: 50% !important;
        height: 15px !important;
        width: 15px !important;
        background: transparent !important;
        transform: translateX(-50%) !important;
        margin-top: 7.5px !important;
      }
  }
`;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';

import {metaSelector, structureSelector} from 'packages/selectors/Website';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql';

import Preview from 'packages/components/layout/preview/Preview';

export default compose(
    connect(
        state => ({
            meta: metaSelector(state),
            structure: structureSelector(state),
        }),
    ),
    graphql(websiteMetaGet, {
        options() {
            return {
                variables: {},
                ssr: false,
            };
        },
        props({data: {loading, websiteMetaGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(websiteMetaGet)) {
                return;
            }
            const {data} = websiteMetaGet;

            return {faviconHashPath: get(data, 'favicon.hashPath')};
        },
    }),
)(Preview);

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';
import websiteOnePhotoUpload from '@graphql/gql/website/websiteOnePhotoUpload.gql';

import {uploadPhotoWrapper} from './UploadPhotoContainer';
import UploadComponent from '@configurator/components/upload/uploadPhoto/UploadPhotos';

export default compose(
    connect(
        state => ({
            uploads: state.getIn(['uploadPhotos', 'uploads']),
        }),
    ),
    graphql(websiteOnePhotoUpload, {
        name: 'websiteOnePhotoUpload',
    }),
)(uploadPhotoWrapper(UploadComponent));


import styled, { css } from 'styled-components';
import { Button } from "packages/components/buttons/button";
import {media} from "packages/components/media";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-width: 150px;
    max-width:40%;
    padding: 16px;
    padding-top: 42px;
    border-radius: 6px;
    background: white;
    border: 4px solid ${({isActive}) => isActive ? '#58C367' : 'transparent'};
    ${({isActive}) => isActive && css`
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 40px 40px 0;
            border-color: transparent #58C367 transparent transparent;
        }
    `};

    ${media.laptop`
       min-width: 20%;
       max-width: 32.5%;
    `}
    ${media.laptopLarge`
       min-width: 20%;
       max-width: 25%;
    `}
`;

export const CheckIconContainer = styled.div`
    position: absolute;
    top: 2px;
    right: 2px;
`;

export const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    * {
        text-align: center;
        font-size: 13px;
    }
`;

export const Paragraph = styled.p`
    text-align: ${props => props.textCenter ? "center" : "left"};
    color: #000000;
`;

export const TrialTitle = styled.span`
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    color: #58C367;
    font-weight: bold;
`;

export const Title = styled.span`
    color: ${({ isFree }) => isFree ? '#58C367' : 'initial'};
    font-size: 20px;
    font-weight: bold;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    height: 45px;
    padding: 0 !important;
`;

export const BottomContainer = styled.div`
    margin-top: auto;
`;

export const PaidPlanBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ManageSubButton = styled.button`
    font-size: 15px;
    color: #58C367;
    background: none;
    border: none;
    cursor: pointer;
`;

import React from 'react';
import PropTypes from 'prop-types';

export const TabletIcon = ({className, onClick}) => (
    <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle className="iconBg" cx="20" cy="20" r="20" fill="white" onClick={onClick}/>
        <path
            className="main"
            // eslint-disable-next-line max-len
            d="M27 31H13C11.8954 31 11 30.1046 11 29V11C11 9.89543 11.8954 9 13 9H27C28.1046 9 29 9.89543 29 11V29C29 30.1046 28.1046 31 27 31ZM13 11V29H27V11H13ZM20 28C19.4477 28 19 27.5523 19 27C19 26.4477 19.4477 26 20 26C20.5523 26 21 26.4477 21 27C21 27.5523 20.5523 28 20 28Z"
            onClick={onClick}
        />
    </svg>
);

TabletIcon.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

TabletIcon.defaultProps = {
    className: '',
    onClick: () => {
    },
};

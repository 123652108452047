import React from "react";
import PropTypes from "prop-types";
import {Container, Wrapper, CloseButton} from "./nodes";
import {CloseIcon} from "../../icons/basic";

const Banner = ({view, onClose, children, ...rest}) => {
    return (
        <Container {...rest} view={view}>
            <Wrapper>
                {children}
                {onClose && (
                    <CloseButton onClick={onClose}>
                        <CloseIcon color="white" />
                    </CloseButton>
                )}
            </Wrapper>
        </Container>
    );
};

Banner.propTypes = {
    view: PropTypes.oneOf(["success", "error", "default"]),
    onClose: PropTypes.func,
};

Banner.defaultProps = {
    view: "default",
};

export default Banner;

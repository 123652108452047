import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {start, stop} from './actions';


const defaultState = fromJS({
    open: false,
});

export default handleActions({
    [start]: {
        next(state, {payload}) {
            return fromJS(payload);
        },
    },

    [stop]: {
        next() {
            return defaultState;
        },
    },

}, defaultState);

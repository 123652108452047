/* eslint-disable max-len */
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";
import { injectIntl, intlShape } from "react-intl";
import { Formik, Form as FormikForm } from "formik";

import { formProptypes } from "packages/utils/formikPropTypes";

import { DOMAIN_TYPES } from "packages/constants";
import { PREMIUM_STATUSES } from "packages/enum";

import {
    FormInput
} from "packages/components/inputs";
import { Button } from "packages/components/buttons/button";
import { RemoveIcon } from "packages/components/icons/basic/remove";
import { RetryIcon } from "packages/components/icons/basic/retry";
import { CheckIcon } from "packages/components/icons/basic/check";

import {
    DomainWrapper,
    DomainWarning,
    DomainSuffix,
    DomainPreview,
    DomainStatus,
    DomainDeleteButton,
    DomainRetryButton, DomainFormContainer, DomainRightContainer
} from "../nodes";

const DomainForm = (
    {
        domain,
        type = DOMAIN_TYPES.CUSTOM_DOMAIN,
        premiumStatus,
        isRequestExist,
        deleteDomain,
        changeDomain,
        domainRetry,
        intl: { formatMessage }
    }
) => {
    const getPremiumStatusContent = () => {
        switch (premiumStatus) {
            case PREMIUM_STATUSES.DNS_CHECKING: {
                return formatMessage({ id: "website.domain.status.dnsChecking" });
            }
            case PREMIUM_STATUSES.DNS_CHECKING_FAILED: {
                return formatMessage({ id: "website.domain.status.dnsCheckingFailed" });
            }
            case PREMIUM_STATUSES.DNS_VALID: {
                return formatMessage({ id: "website.domain.status.dnsValid" });
            }
            case PREMIUM_STATUSES.CERT_REQUEST_FAILED: {
                return formatMessage({ id: "website.domain.status.sertFailed" });
            }
            case PREMIUM_STATUSES.ACTIVE: {
                return (
                    <div>
                        <span>{formatMessage({ id: "website.domain.status.connected" })}</span>
                        &nbsp;
                        <CheckIcon />
                    </div>
                );
            }
            case PREMIUM_STATUSES.FAILURE: {
                return formatMessage({ id: "website.domain.status.failed" });
            }
            case PREMIUM_STATUSES.READY_FOR_CONF: {
                return formatMessage({ id: "website.domain.status.ssl" });
            }
            case PREMIUM_STATUSES.READY_FOR_CERT: {
                return formatMessage({ id: "website.domain.status.ssl" });
            }
            case PREMIUM_STATUSES.DELETE: {
                return formatMessage({ id: "website.domain.status.configuration" });
            }
            case PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4: {
                return formatMessage({ id: "website.domain.status.dnsCheckingWrongIPV4" });
            }
            case PREMIUM_STATUSES.DNS_CHECKING_CONSTRAINT_IPV6: {
                return formatMessage({ id: "website.domain.status.dnsCheckingConstantIPV6" });
            }
            case PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6: {
                return formatMessage({ id: "website.domain.status.wrongIPV4AndConstantIPV6" });
            }
            case PREMIUM_STATUSES.DNS_CHECKING_UNREGISTERED_DOMAIN: {
                return formatMessage({ id: "website.domain.status.unregistredDomain" });
            }
            case PREMIUM_STATUSES.GENERIC_ERROR: {
                return formatMessage({ id: "website.domain.status.genericError" });
            }

            default:
                return null;
        }
    };
    const renderPremiumStatus = () => (
        <DomainStatus
            warning={
                premiumStatus === PREMIUM_STATUSES.DNS_CHECKING
                || premiumStatus === PREMIUM_STATUSES.DNS_VALID
                || premiumStatus === PREMIUM_STATUSES.READY_FOR_CONF
                || premiumStatus === PREMIUM_STATUSES.READY_FOR_CERT
                || premiumStatus === PREMIUM_STATUSES.DELETE
            }
            success={
                premiumStatus === PREMIUM_STATUSES.ACTIVE
            }
            error={
                premiumStatus === PREMIUM_STATUSES.CERT_REQUEST_FAILED
                || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_FAILED
                || premiumStatus === PREMIUM_STATUSES.FAILURE
                || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4
                || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_CONSTRAINT_IPV6
                || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6
                || premiumStatus === PREMIUM_STATUSES.GENERIC_ERROR
                || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_UNREGISTERED_DOMAIN

            }
        >
            {getPremiumStatusContent()}
        </DomainStatus>
    );

    const freeDomainValidationSchema = Yup.object()
        .shape({
            domain: Yup
                .string()
                .test("len", formatMessage({ id: "website.settings.less" }), (val = "") => {
                    return val.length < 100;
                })
                .test("latin", formatMessage({ id: "website.settings.validation.free" }), (val = "") => {
                    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/.test(`${val}`);
                })
                .nullable(false)
                .required(formatMessage({ id: "website.settings.validation.common" }))
        });

    const premiumDomainValidationSchema = Yup.object()
        .shape({
            domain: Yup
                .string()
                .test("len", formatMessage({ id: "website.settings.less" }), (val = "") => {
                    return val.length < 100;
                })
                // eslint-disable-next-line max-len
                .test("latin", formatMessage({ id: "website.settings.validation.premium" }), (val = "") => {
                    // eslint-disable-next-line max-len
                    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/.test(`${val}`)
                        && !/^www\./.test(`${val}`);
                })
                .test("has-vsble", formatMessage({ id: "website.settings.validation.premium.vsbleForbidden" }), (val = "") => {
                    return !/[a-z0-9.]*vsble.me$/i.test(`${val}`);
                })
                .nullable(false)
                .required(formatMessage({ id: "website.settings.validation.common" }))
        });

    const premiumDomainFilter = (event, setFieldValue) => {
        const value = event.target.value.replace(/^w{3}\./i, "");
        setFieldValue("domain", value);
    };

    const showRetryButton = premiumStatus === PREMIUM_STATUSES.CERT_REQUEST_FAILED
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_FAILED
        || premiumStatus === PREMIUM_STATUSES.FAILURE
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_FAILED
        || premiumStatus === PREMIUM_STATUSES.FAILURE
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_CONSTRAINT_IPV6
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6
        || premiumStatus === PREMIUM_STATUSES.GENERIC_ERROR
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_UNREGISTERED_DOMAIN;


    const errorState = premiumStatus === PREMIUM_STATUSES.CERT_REQUEST_FAILED
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_FAILED
        || premiumStatus === PREMIUM_STATUSES.FAILURE
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_FAILED
        || premiumStatus === PREMIUM_STATUSES.FAILURE
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_CONSTRAINT_IPV6
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6
        || premiumStatus === PREMIUM_STATUSES.GENERIC_ERROR
        || premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_UNREGISTERED_DOMAIN;


    return (
        <DomainFormContainer>
            <Formik
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={
                    type === DOMAIN_TYPES.PREMIUM_DOMAIN
                        ? premiumDomainValidationSchema
                        : freeDomainValidationSchema
                }
                initialValues={{
                    domain
                }}
                onSubmit={async ({ domain }, { setFieldError, setSubmitting }) => {
                    try {
                        await changeDomain({ domain });
                    }
                    catch ({ message }) {
                        setFieldError("domain", message);
                        setSubmitting(false);
                    }
                }}
                render={(props) => {
                    return (
                        <FormikForm>
                            <DomainWarning>
                                {formatMessage({ id: "website.settings.dots" })}
                            </DomainWarning>
                            <DomainRightContainer>
                                <DomainWrapper isPremiumDomain={type === DOMAIN_TYPES.PREMIUM_DOMAIN}>
                                    <div style={{}}>
                                        <FormInput
                                            name="domain"
                                            onChange={(event) => premiumDomainFilter(event, props.setFieldValue)}
                                            disabled={isRequestExist}
                                            placeholder={formatMessage({ id: "bootstrap.domain" })}
                                            error={props.errors.domain || errorState}
                                            errorText={props.errors.domain}
                                            inlineNode={
                                                type === DOMAIN_TYPES.PREMIUM_DOMAIN && isRequestExist
                                                    ? renderPremiumStatus()
                                                    : null
                                            }
                                        />
                                    </div>
                                    <DomainSuffix>
                                        {isRequestExist && (
                                            <div>
                                                {
                                                    showRetryButton && (
                                                        <DomainRetryButton onClick={domainRetry}>
                                                            <RetryIcon />
                                                            <span>
                                                                {formatMessage({ id: "website.settings.retry" })}
                                                            </span>
                                                        </DomainRetryButton>
                                                    )}
                                                <DomainDeleteButton onClick={deleteDomain}>
                                                    <RemoveIcon />
                                                    <span>
                                                        {formatMessage({ id: "website.settings.remove" })}
                                                    </span>
                                                </DomainDeleteButton>
                                            </div>
                                        )}
                                        {
                                            type === DOMAIN_TYPES.CUSTOM_DOMAIN ? (
                                                ".vsble.me"
                                            ) : null
                                        }
                                    </DomainSuffix>
                                </DomainWrapper>
                                {!isRequestExist && <Button
                                    type="submit"
                                    view="primary"
                                    disabled={props.isSubmitting || domain === props.values.domain}
                                    content={formatMessage({ id: "bootstrap.confirm.change" })}
                                />}
                            </DomainRightContainer>
                            <DomainPreview>
                                https://<span>{props.values.domain}</span>
                                {
                                    type === DOMAIN_TYPES.CUSTOM_DOMAIN ? (
                                        ".vsble.me"
                                    ) : null
                                }
                            </DomainPreview>
                        </FormikForm>
                    );
                }}
            />
        </DomainFormContainer>
    );
};
DomainForm.propTypes = {
    domain: PropTypes.string,
    type: PropTypes.string.isRequired,
    premiumStatus: PropTypes.string,
    isRequestExist: PropTypes.bool,
    deleteDomain: PropTypes.func,
    changeDomain: PropTypes.func.isRequired,
    domainRetry: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    ...formProptypes
};

DomainForm.defaultProps = {
    domain: "",
    premiumStatus: "",
    isRequestExist: false,
    deleteDomain: () => null
};

export default injectIntl(DomainForm);

import {css} from 'styled-components';

export const sizes = {
    desktopLarge: 1920,
    desktop: 1440,
    laptopLarge: 1366,
    laptop: 1024,
    tablet: 769,
    phone: 481,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label] / 16}em) {
            ${css(...args)}
        }
    `;
    acc.max[label] = (...args) => css`
        @media (max-width: ${sizes[label]}px) {
            ${css(...args)}
        }
    `;
    return acc;
}, {
    max: {},
    between: (labelMin, labelMax) => (...args) => css`
        @media (min-width: ${sizes[labelMin]}px) and (max-width: ${sizes[labelMax]}px) {
            ${css(...args)}
        }
    `
});


import {routerMiddleware} from 'connected-react-router/immutable';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();
const reduxRouterMiddleware = routerMiddleware(history);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    reduxRouterMiddleware,
    promiseMiddleware,
    thunkMiddleware,
];

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import {connect} from 'react-redux';
import get from 'lodash/get';

import mostViewedAlbumGet from '@graphql/gql/website/mostViewedAlbumGet.gql';

import Album from '@configurator/components/statistics/mostViewed/Album';

export const albumWrapper = Component => compose(
    connect(
        state => ({ matomoOptions: state.get('periodPicker').toJS() })
    ),
    graphql(mostViewedAlbumGet, {
        skip: ({ idSubtable }) => !idSubtable,
        options: ({ matomoOptions, idSubtable }) => ({
            variables: { ...matomoOptions, idSubtable },
            ssr: false,
        }),
        props({data: {loading, mostViewedAlbumGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(mostViewedAlbumGet)) {
                return {loading};
            }
            const {data} = mostViewedAlbumGet;

            return isArray(data) ? {
                item: get(
                    data.reduce(
                        (prev, current) => (prev.nb_visits > current.nb_visits) ? prev : current,
                        { label: '', nb_visits: 0 }
                    ),
                    'label',
                ),
                list: data.map(i => ({ name: i.label, value: i.nb_visits })),
            } : {};
        },
    })
)(Component);

export default albumWrapper(Album);

import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const SearchPlusIcon = ({ color }) => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5766 15L7.80956 9.234C5.65338 10.6564 2.77057 10.2164 1.13689 8.21555C-0.496801 6.21469 -0.351348 3.30213 1.47356 1.474C3.30137 -0.351607 6.21426 -0.49769 8.21555 1.13588C10.2168 2.76946 10.6571 5.6526 9.23456 7.809L14.9996 13.576L13.5766 15ZM5.03356 2.01399C3.59281 2.01308 2.35204 3.03002 2.07004 4.4429C1.78805 5.85579 2.54337 7.27112 3.87408 7.82335C5.2048 8.37558 6.7403 7.91091 7.54154 6.71351C8.34279 5.51611 8.18662 3.91946 7.16856 2.9C6.60381 2.33134 5.835 2.0123 5.03356 2.01399ZM15.9996 6H13.9996V4H11.9996V2H13.9996V0H15.9996V2H17.9996V4H15.9996V6Z"
            fill={iconColors[color]}
        />
    </svg>
);

SearchPlusIcon.propTypes = {
    ...iconColorPropTypes,
};

SearchPlusIcon.defaultProps = {
    color: 'default',
};

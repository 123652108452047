import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {openModal, closeModal} from './actions';


const defaultState = fromJS({
    openModal: false,
});

export default handleActions({

    [openModal]: {
        next(state) {
            return state.set('openModal', true);
        },
    },

    [closeModal]: {
        next() {
            return defaultState;
        },
    },

}, defaultState);

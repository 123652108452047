import PropTypes from 'prop-types';
import {iconColorPropTypes, TabletIcon} from '../../icons';
import {StyledSVG} from '../svgStyles';

export const TabletButton = StyledSVG(TabletIcon);

TabletButton.propTypes = {
    ...iconColorPropTypes,
    className: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};

TabletButton.defaultProps = {
    color: 'darkGray',
    active: false,
    className: '',
    onClick: () => {
    },
};

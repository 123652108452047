/* eslint-disable max-len */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { useQuery, useMutation } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { MAX_IMAGE_SIZE } from "@configurator/constants/upload";

// graphql
import websiteMetaGetQuery from "@graphql/gql/website/websiteMetaGet.gql";
import websiteMetaUpdateMutation from "@graphql/gql/website/websiteMetaUpdate.gql";
import websitePagesGetByTypeQuery from "@graphql/gql/website/websitePagesGetByType.gql";
import websitePageUpdateMutation from "@graphql/gql/website/websitePageUpdate.gql";
import websiteSeoImageUpdateMutation from "@graphql/gql/website/websiteSeoImageUpdate.gql";
import featureGetQuery from "@graphql/gql/website/featureGet.gql";
import featureUpdateWithPhotoMutation from "@graphql/gql/website/featureUpdateWithPhoto.gql";
import featureUpdateMutation from "@graphql/gql/website/featureUpdate.gql";

import Image from "packages/components/gatsby-image";
import { FormInput, FormTextArea, Dropdown } from "packages/components/inputs";
import ModalConfirmDelete from "packages/components/modalConfirmDelete";
import { Button } from "packages/components/buttons";
import {
    CloseIcon,
    RefreshIcon,
    LeftIcon,
    RightIcon,
    LockIcon,
    ImageIcon,
    FileUploadIcon,
} from "packages/components/icons";
import VerificationForm from "./verificationForm";
import { HeaderStyled } from "packages/components/layout/nodes";
import { FEATURE_PROPERTY_KEYS } from "packages/enum";
import { getDomain } from "packages/helpers/Helper";

import Loader from "../loader/Loader";

import {
    Container,
    Header,
    Field,
    Paragraph,
    SearchPreview,
    SearchPreviewDomain,
    SearchPreviewDescription,
    SearchPreviewTitle,
    BrowserTab,
    BrowserTabHeader,
    BrowserTabBubble,
    BrowserTabName,
    BrowserTabCloseIcon,
    BrowserTabHelper,
    BrowserTabBottomIcon,
    BrowserTabDomain,
    BrowserTabDomainName,
    ImagePreview,
    UploadPicture,
    Title,
    CenteredDropModal,
    UploadWrapper, RightPanelBlock,
} from './nodes';
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import { useSubscription } from "@configurator/providers/subscription";
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

const getFeatureProperty = (source, obj, property) => {
    return (
        (
            ((source || []).properties || []).find(
                (el) => el.key === property
            ) || {}
        ).value || null
    );
};

const WebsiteSEO = ({
    intl: { formatMessage },
    lockModalProgress,
    unLockModalProgress,
    loaderStart,
    loaderStop,
    openErrorModal,
}) => {
    const [selectedPage, setSelectedPage] = useState("all");
    const [isFileReady, setIsFileReady] = useState(false);
    const [preview, setPreview] = useState();
    const [showModalDeleteImage, setShowModalDeleteImage] = useState(false);
    const [dropModalOpen, setDropModalOpen] = useState(false);
    const { permissions } = useSubscription();

    useEffect(() => {
        USERLIST_SERVICE.trackSeoPageVisited();
    }, []);

    const metaGetOptions = {
        variables: {},
        fetchPolicy: "network-only",
    };
    const { loading, data: { websiteMetaGet } = {} } = useQuery(
        websiteMetaGetQuery,
        metaGetOptions
    );

    const pageGetByTypeOptions = {
        variables: {
            pageType: "",
        },
        fetchPolicy: "network-only",
    };
    const { loading: pagesLoading, data: { websitePagesGetByType } = {} } =
        useQuery(websitePagesGetByTypeQuery, pageGetByTypeOptions);

    const featureGetOptions = {
        variables: {
            websiteId: websiteMetaGet?.data?.id,
            name: FEATURE_PROPERTY_KEYS.openGraph,
        },
        skip: isEmpty(websiteMetaGet),
        fetchPolicy: "network-only",
    };
    const { loading: featureLoading, data: { featureGet } = {} } = useQuery(
        featureGetQuery,
        featureGetOptions
    );

    const [websiteMetaUpdate] = useMutation(websiteMetaUpdateMutation);
    const [websitePageUpdate] = useMutation(websitePageUpdateMutation);
    const [websiteSeoImageUpdate] = useMutation(websiteSeoImageUpdateMutation);
    const [featureUpdateWithPhoto] = useMutation(
        featureUpdateWithPhotoMutation
    );
    const [featureUpdate] = useMutation(featureUpdateMutation);

    if (loading || pagesLoading || featureLoading) {
        return <Loader />;
    }

    const isMetaGetEmpty =
        isEmpty(websiteMetaGet) || !isEmpty(websiteMetaGet.errors);
    const isPagesGetEmpty =
        isEmpty(websitePagesGetByType) ||
        !isEmpty(websitePagesGetByType.errors);

    if (isMetaGetEmpty || isPagesGetEmpty) {
        return <div>Error</div>;
    }

    const { data: metaData } = websiteMetaGet;
    const { data: pagesData } = websitePagesGetByType;
    const pagesDataById = keyBy(pagesData, "id");
    const selectedPageData = pagesDataById[selectedPage];
    const data = {
        ...metaData,
        seoTitle: selectedPageData?.seoTitle ?? metaData.seoTitle,
        seoDescription:
            selectedPageData?.seoDescription ?? metaData.seoDescription,
        seoKeywords: selectedPageData?.seoKeywords ?? metaData.seoKeywords,
    };

    const defaultValues = {
        seoTitle: data.seoTitle,
        seoDescription: data.seoDescription,
        seoKeywords: data.seoKeywords,
    };

    const validationSchema = Yup.object().shape({
        seoTitle: Yup.string().nullable(),
        seoDescription: Yup.string().nullable(),
        seoKeywords: Yup.string().nullable(),
    });

    const defaultError = "error.desc";

    const domain = `https://${data.premiumDomain || `${data.domain}.vsble.me`}`;

    const openGraphEnabled =
        !!selectedPageData || get(featureGet, "data.enabled", false);
    const openGraphImage = selectedPageData
        ? selectedPageData.seoPhotoUrl
        : getFeatureProperty(
              featureGet.data,
              FEATURE_PROPERTY_KEYS.openGraph,
              FEATURE_PROPERTY_KEYS.openGraphImageMediaUrl
          );

    const updateMeta = (variables) =>
        websiteMetaUpdate({
            variables: {
                websiteId: data.id,
                ...variables,
            },
            refetchQueries: [
                {
                    query: websiteMetaGetQuery,
                    ...metaGetOptions,
                },
            ],
        });

    const updatePage = (variables) =>
        websitePageUpdate({
            variables: {
                pageId: selectedPageData.id,
                ...variables,
            },
            refetchQueries: [
                {
                    query: websitePagesGetByTypeQuery,
                    ...pageGetByTypeOptions,
                },
            ],
        });

    const updateFeature = (variables) =>
        featureUpdate({
            variables: {
                featureId: featureGet.data.id,
                ...variables,
            },
            refetchQueries: [
                {
                    query: featureGetQuery,
                    ...featureGetOptions,
                },
            ],
        });

    const updateFeatureWithPhoto = (variables) =>
        featureUpdateWithPhoto({
            variables: {
                featureId: featureGet.data.id,
                ...variables,
            },
        });

    const updateSeoImage = (variables) =>
        websiteSeoImageUpdate({
            variables: {
                pageId: selectedPageData.id,
                ...variables,
            },
        });

    const handleDropFile = (files) => {
        if (files && files.length) {
            const file = files[0];
            const preview = {
                index: 0,
                file,
                name: file.name,
                preview: window.URL.createObjectURL(file),
            };
            setIsFileReady(true);
            setPreview(preview);
            setDropModalOpen(false);
        }
    };

    const onDropRejected = (files, accept) => {
        if (files.find((el) => el.size > MAX_IMAGE_SIZE)) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileSize.description",
                subMessageValues: { size: MAX_IMAGE_SIZE / 1024 / 1024 },
            });
        }
        const acceptArr = accept.split(", ");
        if (files.find((el) => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileType.description",
                subMessageValues: { types: accept.replace(/image\//g, "") },
            });
        }
    };

    const handleSubmitForm = async (values, { setSubmitting }) => {
        loaderStart();
        try {
            USERLIST_SERVICE.trackSeoSettingsEdited()

            if (preview && preview.file) {
                if (selectedPageData) {
                    lockModalProgress();
                    const photoUpdateRes = await updateSeoImage({
                        file: preview.file,
                    });
                    unLockModalProgress();

                    if (
                        !get(
                            photoUpdateRes,
                            "data.websiteSeoImageUpdate.success"
                        )
                    ) {
                        loaderStop();
                        setSubmitting(false);
                        return openErrorModal({
                            headerMessageId: "error.header",
                            subMessageId:
                                !get(
                                    photoUpdateRes,
                                    "data.websiteSeoImageUpdate.errors._error"
                                ) || defaultError,
                            yesMessageId: "OK",
                        });
                    }
                } else {
                    lockModalProgress();
                    const photoUpdateRes = await updateFeatureWithPhoto({
                        file: preview.file,
                        propertyName: FEATURE_PROPERTY_KEYS.openGraphImage,
                    });
                    unLockModalProgress();

                    if (
                        !get(
                            photoUpdateRes,
                            "data.featureUpdateWithPhoto.success"
                        )
                    ) {
                        loaderStop();
                        setSubmitting(false);
                        return openErrorModal({
                            headerMessageId: "error.header",
                            subMessageId:
                                !get(
                                    photoUpdateRes,
                                    "data.featureUpdateWithPhoto.errors._error"
                                ) || defaultError,
                            yesMessageId: "OK",
                        });
                    }

                    const featureUpdateRes = await updateFeature({
                        enabled: true,
                    });

                    if (!get(featureUpdateRes, "data.featureUpdate.success")) {
                        loaderStop();
                        setSubmitting(false);
                        return openErrorModal({
                            headerMessageId: "error.header",
                            subMessageId:
                                !get(
                                    featureUpdateRes,
                                    "data.featureUpdate.errors._error"
                                ) || defaultError,
                            yesMessageId: "OK",
                        });
                    }
                }

                setPreview(undefined);
                setIsFileReady(false);
            }

            if (selectedPageData) {
                const websitePageUpdateRes = await updatePage({
                    seoTitle: values.seoTitle,
                    seoDescription: values.seoDescription,
                    seoKeywords: values.seoKeywords,
                });

                if (
                    !get(websitePageUpdateRes, "data.websitePageUpdate.success")
                ) {
                    loaderStop();
                    setSubmitting(false);
                    return openErrorModal({
                        headerMessageId: "error.header",
                        subMessageId:
                            !get(
                                websitePageUpdateRes,
                                "data.websitePageUpdate.errors._error"
                            ) || defaultError,
                        yesMessageId: "OK",
                    });
                }
            } else {
                const websiteMetaUpdateRes = await updateMeta({
                    seoTitle: values.seoTitle,
                    seoDescription: values.seoDescription,
                    seoKeywords: values.seoKeywords,
                });

                if (
                    !get(websiteMetaUpdateRes, "data.websiteMetaUpdate.success")
                ) {
                    loaderStop();
                    setSubmitting(false);
                    return openErrorModal({
                        headerMessageId: "error.header",
                        subMessageId:
                            !get(
                                websiteMetaUpdateRes,
                                "data.websiteMetaUpdate.errors._error"
                            ) || defaultError,
                        yesMessageId: "OK",
                    });
                }
            }

            loaderStop();
            setSubmitting(false);
        } catch (err) {
            loaderStop();
            setSubmitting(false);
            return openErrorModal({
                headerMessageId: "error.header",
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                yesMessageId: "OK",
            });
        }
    };

    const handleImageDelete = async () => {
        loaderStart();
        try {
            if (selectedPageData) {
                const websitePageUpdateRes = await updatePage({
                    seoPhotoUrl: "",
                });

                if (
                    !get(websitePageUpdateRes, "data.websitePageUpdate.success")
                ) {
                    loaderStop();
                    setShowModalDeleteImage(false);
                    return openErrorModal({
                        headerMessageId: "error.header",
                        subMessageId:
                            !get(
                                websitePageUpdateRes,
                                "data.websitePageUpdate.errors._error"
                            ) || defaultError,
                        yesMessageId: "OK",
                    });
                }
            } else {
                const featureUpdateRes = await updateFeature({
                    enabled: false,
                });

                if (!get(featureUpdateRes, "data.featureUpdate.success")) {
                    loaderStop();
                    setShowModalDeleteImage(false);
                    return openErrorModal({
                        headerMessageId: "error.header",
                        subMessageId:
                            !get(
                                featureUpdateRes,
                                "data.featureUpdate.errors._error"
                            ) || defaultError,
                        yesMessageId: "OK",
                    });
                }
            }

            loaderStop();
            setShowModalDeleteImage(false);
        } catch (err) {
            loaderStop();
            setShowModalDeleteImage(false);
            return openErrorModal({
                headerMessageId: "error.header",
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                yesMessageId: "OK",
            });
        }
    };

    const dropModalStyle = {
        height: "540px",
        width: "670px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
    };

    const renderDropModal = () => {
        const accept = "image/jpeg, image/gif, image/png";
        return (
            <CenteredDropModal
                open
                onClose={() => setDropModalOpen(false)}
                styles={dropModalStyle}
                centered
            >
                <UploadWrapper>
                    <Dropzone
                        accept={accept}
                        onDrop={handleDropFile}
                        multiple={false}
                        maxSize={MAX_IMAGE_SIZE}
                        onDropRejected={(files) =>
                            onDropRejected(files, accept)
                        }
                    >
                        {({ getInputProps, getRootProps }) => (
                            <div {...getRootProps()} className="input">
                                <input {...getInputProps()} />
                                <FileUploadIcon color="lightGray" />
                                <span className="dropzone-header">
                                    {formatMessage({
                                        id: "upload.video.text.title",
                                    })}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: formatMessage({
                                                id: "upload.video.text.browse",
                                            }),
                                        }}
                                    />
                                </span>
                                <span
                                    className="dropzone-footer"
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({
                                            id: "upload.video.text.info",
                                        }),
                                    }}
                                />
                            </div>
                        )}
                    </Dropzone>
                </UploadWrapper>
            </CenteredDropModal>
        );
    };

    const hasOpenGraphImage = openGraphImage && openGraphEnabled;
    const isFileExist = isFileReady || hasOpenGraphImage;

    const renderUploadImage = ({ isSubmitting }) => {
        const view = isFileExist ? "primaryRed" : "secondaryBlack";
        const message = isFileExist
            ? "seo.button.deleteImage"
            : "seo.button.uploadImage";

        return (
            <PermissionsOverlay isAllowed={permissions.OPEN_GRAPH}>
                <UploadPicture>
                    <Title>{formatMessage({ id: "seo.uploadImage" })}</Title>
                    <Button
                        type="button"
                        disabled={isSubmitting}
                        view={view}
                        icon={!isFileExist && <ImageIcon />}
                        onClick={() => {
                            if (hasOpenGraphImage) {
                                setShowModalDeleteImage(true);
                            } else if (preview) {
                                setPreview(undefined);
                                setIsFileReady(false);
                            } else {
                                setDropModalOpen(true);
                            }
                        }}
                    >
                        {formatMessage({ id: message })}
                    </Button>
                </UploadPicture>
            </PermissionsOverlay>
        );
    };

    const renderLeftPanel = ({ values, isSubmitting, resetForm }) => {
        const submitDisabled =
            (isEqual(defaultValues, {
                seoTitle: values.seoTitle,
                seoDescription: values.seoDescription,
                seoKeywords: values.seoKeywords,
            }) &&
                !preview) ||
            isSubmitting;

        const selectPageOptions = [
            {
                key: "all",
                value: "all",
                text: formatMessage({ id: "seo.pagesDropdown.defaultValue" }),
            },
            ...pagesData.map((page) => ({
                key: page.id,
                value: page.id,
                text: page.title,
            })),
        ];

        const handlePageSelect = (e, { value }) => {
            setSelectedPage(value);
            setPreview(undefined);
            setIsFileReady(false);
            resetForm();
        };

        const form = (
            <>
                <Field>
                    <FormInput
                        label={formatMessage({ id: "seo.title.label" })}
                        name="seoTitle"
                        placeholder={formatMessage({
                            id: "seo.title.placeholder",
                        })}
                    />
                </Field>
                <Field>
                    <FormTextArea
                        label={formatMessage({ id: "seo.description.label" })}
                        name="seoDescription"
                        placeholder={formatMessage({
                            id: "seo.description.placeholder",
                        })}
                    />
                </Field>
                <Field>
                    <FormTextArea
                        label={formatMessage({ id: "seo.keywords.label" })}
                        name="seoKeywords"
                        placeholder={formatMessage({
                            id: "seo.keywords.placeholder",
                        })}
                    />
                </Field>
                <Field>{renderUploadImage({ isSubmitting })}</Field>
                <Button
                    type="submit"
                    view="primary"
                    disabled={submitDisabled}
                    content={formatMessage({ id: "seo.save" })}
                />
            </>
        );
        return (
            <FormikForm>
                <Field>
                    <Dropdown
                        label={formatMessage({ id: "seo.pagesDropdown.label" })}
                        options={selectPageOptions}
                        value={selectedPage}
                        onChange={handlePageSelect}
                    />
                </Field>
                {selectedPage === "all" ? (
                    form
                ) : (
                    <PermissionsOverlay
                        proMax={true}
                        isAllowed={permissions.SEO}
                    >
                        {form}
                    </PermissionsOverlay>
                )}
            </FormikForm>
        );
    };

    const renderRightPanel = ({ values }) => (
        <>
            <RightPanelBlock>
                <Paragraph mb="20" type="h1">
                    {formatMessage({ id: "seo.search" })}
                </Paragraph>
                <SearchPreview>
                    <div>
                        <SearchPreviewDomain>{domain}</SearchPreviewDomain>
                        <SearchPreviewTitle>{values.seoTitle}</SearchPreviewTitle>
                        <SearchPreviewDescription>
                            {values.seoDescription}
                        </SearchPreviewDescription>
                    </div>
                </SearchPreview>
                <Paragraph mt="30" mb="20" type="h1">
                    {formatMessage({ id: "seo.tab" })}
                </Paragraph>
                <div>
                    <BrowserTab>
                        <div>
                            <BrowserTabHelper left>
                                <div />
                            </BrowserTabHelper>
                            <BrowserTabHeader>
                                <BrowserTabBubble />
                                <BrowserTabName>{values.seoTitle}</BrowserTabName>
                                <BrowserTabCloseIcon>
                                    <CloseIcon color="darkGray" />
                                </BrowserTabCloseIcon>
                            </BrowserTabHeader>
                            <BrowserTabHelper>
                                <div />
                            </BrowserTabHelper>
                        </div>
                        <div>
                            <BrowserTabBottomIcon>
                                <LeftIcon />
                            </BrowserTabBottomIcon>
                            <BrowserTabBottomIcon>
                                <RightIcon />
                            </BrowserTabBottomIcon>
                            <BrowserTabBottomIcon>
                                <RefreshIcon />
                            </BrowserTabBottomIcon>
                            <BrowserTabDomain>
                                <LockIcon />
                                <BrowserTabDomainName>
                                    {domain}
                                </BrowserTabDomainName>
                            </BrowserTabDomain>
                        </div>
                    </BrowserTab>
                </div>
            </RightPanelBlock>
            <RightPanelBlock>
                <Paragraph mt="30" mb="20" type="h1">
                    {formatMessage({ id: "seo.social" })}
                </Paragraph>
                <ImagePreview>
                    {preview || hasOpenGraphImage ? (
                        <Image
                            fluid={{
                                src: preview
                                    ? preview.preview
                                    : openGraphEnabled &&
                                      getDomain(`/${openGraphImage}`),
                                aspectRatio: 450 / 243,
                            }}
                            alt=""
                            fadeIn
                            loading="lazy"
                            backgroundColor="#EDEDED"
                        />
                    ) : (
                        <img alt="" />
                    )}
                </ImagePreview>
                <SearchPreview>
                    <div>
                        <SearchPreviewDomain>{domain}</SearchPreviewDomain>
                        <SearchPreviewTitle>{values.seoTitle}</SearchPreviewTitle>
                        <SearchPreviewDescription>
                            {values.seoDescription}
                        </SearchPreviewDescription>
                    </div>
                </SearchPreview>
            </RightPanelBlock>
        </>
    );
    return (
        <>
            <HeaderStyled backArrow />
            <Header>{formatMessage({ id: "seo.google" })}</Header>
            <Formik
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                initialValues={defaultValues}
                onSubmit={handleSubmitForm}
                render={({ values, isSubmitting, resetForm }) => {
                    return (
                        <Container>
                            <div>
                                {renderLeftPanel({
                                    isSubmitting,
                                    values,
                                    resetForm,
                                })}
                                <PermissionsOverlay
                                    proMax={true}
                                    isAllowed={permissions.SEO}
                                >
                                    <VerificationForm
                                        data={data}
                                        loaderStart={loaderStart}
                                        loaderStop={loaderStop}
                                        openErrorModal={openErrorModal}
                                    />
                                </PermissionsOverlay>
                            </div>
                            <div>{renderRightPanel({ values })}</div>
                            {dropModalOpen && renderDropModal()}
                            <ModalConfirmDelete
                                open={showModalDeleteImage}
                                onClickYes={handleImageDelete}
                                onClickCancel={() =>
                                    setShowModalDeleteImage(false)
                                }
                                onClose={() => setShowModalDeleteImage(false)}
                            />
                        </Container>
                    );
                }}
            />
        </>
    );
};

WebsiteSEO.propTypes = {
    intl: intlShape.isRequired,
    values: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    loaderStart: PropTypes.func.isRequired,
    loaderStop: PropTypes.func.isRequired,
    lockModalProgress: PropTypes.func.isRequired,
    unLockModalProgress: PropTypes.func.isRequired,
    openErrorModal: PropTypes.func.isRequired,
};

WebsiteSEO.defaultProps = {};

export default injectIntl(WebsiteSEO);

import styled from 'styled-components';

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #E1E2E6;
`;

export const Wrapper = styled.div`
    padding-top: ${props => props.padding};
    padding-bottom: ${props => props.padding};
`;

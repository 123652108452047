import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { PermissionModal } from "./permissionModal";
import { push } from "connected-react-router";
import { URLS } from "packages/enum";

import { PermissionsOverlayContainer } from "./nodes";

const PermissionsOverlay = ({ isAllowed, children, proMax }) => {
    const handleClickCapture = (e) => {
        if (!isAllowed) {
            e.stopPropagation();
            setModalOpen(true);
        }
    };

    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <PermissionsOverlayContainer
                isAllowed={isAllowed}
                onClickCapture={handleClickCapture}
            >
                {children}
            </PermissionsOverlayContainer>
            <PermissionModal
                headerMessageId={
                    proMax
                        ? "permission.modal.pro.max.header"
                        : "permission.modal.pro.header"
                }
                subMessageId={
                    proMax
                        ? "permission.modal.pro.max.subMessage"
                        : "permission.modal.pro.subMessage"
                }
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onButtonClick={() => {
                    setModalOpen(false);
                    dispatch(push(URLS.website_billing));
                }}
            />
        </>
    );
};

PermissionsOverlay.propTypes = {
    children: PropTypes.element.isRequired,
    openSubscriptionModal: PropTypes.func,
    goToSubscription: PropTypes.func,
    isAllowed: PropTypes.bool,
    proMax: PropTypes.bool,
};

PermissionsOverlay.defaultProps = {
    openSubscriptionModal: () => null,
    goToSubscription: () => null,
    isAllowed: false,
    proMax: false,
};

export default PermissionsOverlay;

/* eslint-disable */
import React from 'react';

export const RefreshIcon = () => (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.79295 0.908331C3.62101 0.904014 0.974735 3.31679 0.704999 6.4591C0.435263 9.60142 2.63204 12.4252 5.75884 12.9553C8.88565 13.4855 11.8995 11.5453 12.6925 8.49166H11.1053C10.3295 10.6735 8.03009 11.9287 5.76213 11.4086C3.49416 10.8884 1.98017 8.7585 2.244 6.45925C2.50783 4.16 4.46542 2.4239 6.79295 2.425C8.00532 2.42676 9.16605 2.9132 10.0139 3.77484L7.55948 6.21666H12.8985V0.908331L11.1053 2.69042C9.9641 1.54884 8.41181 0.907359 6.79295 0.908331Z"
            fill="#666A73"/>
    </svg>

);

RefreshIcon.propTypes = {};

RefreshIcon.defaultProps = {};

import PropTypes from 'prop-types';
import React from 'react';
import {useDrop} from 'react-dnd';
import {PAGE_TYPES} from 'packages/enum';
import {
    Container,
} from './nodes.js';

const PagePlaceholder = ({pageType, onDropPage, lastId}) => {

    const [{isOver}, drop] = useDrop({
        accept: [PAGE_TYPES.MAIN, PAGE_TYPES.FOOTER],
        drop: item => onDropPage({
            itemId: item.id,
            changeNavigation: pageType !== item.type,
            leftId: lastId,
            rightId: null,
        }),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <Container ref={drop} isOver={isOver}/>
    );
};

PagePlaceholder.propTypes = {
    pageType: PropTypes.string,
    onDropPage: PropTypes.func.isRequired,
    lastId: PropTypes.string,
};

PagePlaceholder.defaultProps = {
    pageType: PAGE_TYPES.MAIN,
    lastId: null,
};

export default PagePlaceholder;


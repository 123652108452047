import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import moment from 'moment';
import {
    PLAN_TYPE,
    SUBSCRIPTION_TYPE,
    SUBSCRIPTION_CARD_TYPE,
    CURRENCY,
    EU_COUNTRIES_CODE,
} from "packages/enum";
import { getMessagesList } from "packages/helpers/Helper";
import { currencyConversion, getPlansByInterval } from "../helpers";
import Loader from "@configurator/components/loader/Loader";
import SubscriptionCard from "../subscriptionCard";
import { Container, Notice, TrialTitle } from './nodes';
import has from "lodash/has";
import filter from "lodash/filter";
import {useLazyQuery} from "react-apollo";
import billingExchangeRateGet from "@graphql/gql/website/billingExchangeRateGet.gql";

const Subscriptions = ({
    subscriptions,
    plans,
    loading,
    billingCreateCustomerPortal,
    countryId,
    countriesList,
    intl: {formatMessage},
}) => {
    const [loadExchangeRates, { data }] = useLazyQuery(billingExchangeRateGet);
    const [subscription] = subscriptions;
    const currentCountryData = useMemo(() =>
            filter(countriesList, country => country.id === countryId)[0],[countriesList, countryId]);

    const plansByInterval = useMemo(() => getPlansByInterval(plans), [plans]);
    const [exchangeRates, setExchangeRates] = useState();
    const [currency, setCurrency] = useState();
    const activeType = subscription?.planType ?? subscription?.subscriptionType;
    const userLanguage = window?.navigator.language.toUpperCase();
    const isEUCountry = has(EU_COUNTRIES_CODE, currentCountryData ? currentCountryData?.shortName : userLanguage);
    const readyToPaymentUSD = exchangeRates && !isEUCountry && exchangeRates[CURRENCY.USD];

    const withConvertedPrice = useCallback((plan) => {
            return {
                ...plan, price: currencyConversion(plan?.price, exchangeRates)
            }
    },[exchangeRates]);

    const cardsData = {
        ...subscription,
        currency,
        billingCreateCustomerPortal,
    };

    useEffect(() => {
        if (!isEUCountry) {
            if (!exchangeRates) {
                loadExchangeRates();
            }
        }
    }, [exchangeRates, loadExchangeRates, isEUCountry]);

    useEffect(() => {
        if (readyToPaymentUSD) {
            setCurrency(CURRENCY.USD);
        }
        else {
            setCurrency(CURRENCY.EUR);
        }
    }, [readyToPaymentUSD]);

    useEffect(()=> {
        if (data) {
            const { billingExchangeRateGet: { data : { rates } } } = data;
            setExchangeRates(rates);
        }
    },[data]);

    const monthPlan = useCallback((plan) => {
        const currentPlan = readyToPaymentUSD ? withConvertedPrice(plan) : plan

        return {
            ...currentPlan,
            price: readyToPaymentUSD ? currentPlan?.price : null,
            basePrice: plan?.price,
        }
    }, [readyToPaymentUSD, withConvertedPrice])

    const subscriptionCards =  activeType === SUBSCRIPTION_TYPE.LIFETIME_PRO || activeType === PLAN_TYPE.LIFETIMEPRO ? [
        {
            cardsData,
            activeType,
            type: SUBSCRIPTION_CARD_TYPE.LIFETIME_PRO,
            title: formatMessage({ id: "subscription.plan.lifetimepro.header" }),
            isActive: true,
            bodyItems: getMessagesList(formatMessage({ id: "subscription.capabilities.lifetime" })),
        }
    ] : [
        {
            cardsData,
            activeType,
            type: SUBSCRIPTION_CARD_TYPE.FREE,
            title: formatMessage({ id: "subscription.plan.free.header" }),
            isActive: activeType === SUBSCRIPTION_TYPE.FREE,
            bodyItems: getMessagesList(formatMessage({ id: "subscription.capabilities.free" })),
        },
        {
            cardsData,
            activeType,
            type: SUBSCRIPTION_CARD_TYPE.PROLITE,
            title: formatMessage({ id: "subscription.plan.light.header" }),
            isActive: activeType === PLAN_TYPE.PROLITE,
            monthPlan: monthPlan(plansByInterval[PLAN_TYPE.PROLITE]?.month),
            bodyItems: getMessagesList(formatMessage({ id: "subscription.capabilities.light" })),
        },
        {
            cardsData,
            activeType,
            type: SUBSCRIPTION_CARD_TYPE.PRO,
            title: formatMessage({ id: "subscription.plan.pro.header" }),
            isActive: activeType === PLAN_TYPE.PRO,
            monthPlan: monthPlan(plansByInterval[PLAN_TYPE.PRO]?.month),
            yearPlan: readyToPaymentUSD ?
                withConvertedPrice(plansByInterval[PLAN_TYPE.PRO]?.year) : plansByInterval[PLAN_TYPE.PRO]?.year,
            bodyItems: getMessagesList(formatMessage({ id: "subscription.capabilities.pro" })),
        },
        {
            cardsData,
            activeType,
            type: SUBSCRIPTION_CARD_TYPE.PROMAX,
            title: formatMessage({ id: "subscription.plan.promax.header" }),
            isActive: activeType === PLAN_TYPE.PROMAX || activeType === SUBSCRIPTION_TYPE.TRIAL,
            monthPlan: monthPlan(plansByInterval[PLAN_TYPE.PROMAX]?.month),
            yearPlan: readyToPaymentUSD ?
                withConvertedPrice(plansByInterval[PLAN_TYPE.PROMAX]?.year) : plansByInterval[PLAN_TYPE.PROMAX]?.year,
            bodyItems: getMessagesList(formatMessage({ id: "subscription.capabilities.promax" })),
        },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            {activeType === SUBSCRIPTION_TYPE.TRIAL && (
                data && data.currentPeriodEnd ? (<TrialTitle>
                    {formatMessage(
                        {id: 'subscription.plan.trial.title'},
                        {days: moment(data?.currentPeriodEnd).diff(moment(), 'days')},
                    )}
                </TrialTitle>) : null
            )}
            <div>
                <Container>
                    {
                        subscriptionCards.map((props, id) => {
                            const isPaidType = props.activeType !== SUBSCRIPTION_TYPE.FREE &&
                                               props.activeType !== SUBSCRIPTION_TYPE.UNPAID &&
                                               props.activeType !== SUBSCRIPTION_TYPE.TRIAL;
                            if (props.type === SUBSCRIPTION_CARD_TYPE.FREE && isPaidType) {
                                return null;
                            } else {
                                return <SubscriptionCard key={id} {...props} />
                            }
                        })
                    }
                </Container>
                {!isEUCountry && readyToPaymentUSD && (<Notice>{formatMessage({ id: 'subscription.plan.pro.notice' })}</Notice>)}
            </div>
        </>
    );
};

Subscriptions.propTypes = {
    intl: intlShape.isRequired,
    billingCreateCustomerPortal: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    subscriptions: PropTypes.array,
    plans: PropTypes.array,
    loading: PropTypes.bool,
    billingExchangeRate: PropTypes.func.isRequired,
    countryId: PropTypes.number,
    countriesList: PropTypes.array,
};

Subscriptions.defaultProps = {
    subscriptions: [],
    plans: [],
};

export default injectIntl(Subscriptions);

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import isEqual from "lodash/isEqual";

import { Semantic } from "packages/components";
import { formProptypes } from "packages/utils/formikPropTypes";
import Message from "packages/components/message/Message";
import ModalYesNo from "packages/components/modalYesNo/ModalYesNo";
import { getDomain } from "packages/helpers/Helper";
import {
    UPLOAD_STEPS,
    FEATURE_PROPERTY_KEYS,
    MESSAGE_TYPE
} from "packages/enum";
import { MAX_IMAGE_SIZE } from "@configurator/constants/upload";
import {
    FormTextArea,
    FormToggle,
    FormSlider
} from "packages/components/inputs";
import { Button, TrashButton } from "packages/components/buttons";
import { Modal } from "packages/components/modal";
import Image from "packages/components/image/Image";
import { FileUploadIcon } from "packages/components/icons";

import {
    Container,
    Label,
    UploadWrapper,
    ImageComponent,
    Logo,
    StyledTextareaContainer,
    FormRow
} from "./nodes";
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const PdfTool = (
    {
        open,
        onClose,
        handleSubmit,
        initialValues,
        errors,
        status,
        isSubmitting,
        loading,
        values,
        unLockModalProgress,
        uploads,
        setFieldValue,
        featurePropertyDelete,
        refetchData,
        setStatus,
        submitForm,
        openErrorModal
    },
    { intl: { formatMessage } }
) => {
    const prevValues = usePrevious(values);

    const [showModalDeleteImage, setShowModalDeleteImage] = useState(false);
    const [isFileReady, setIsFileReady] = useState(false);
    const [preview, setPreview] = useState();

    useEffect(() => () => unLockModalProgress(), [unLockModalProgress]);

    useEffect(() => {
        if (preview) {
            let uploadStep = preview.uploadStep;
            if (isSubmitting) {
                if (uploads.get(preview.name)) {
                    uploadStep =
                        uploads.get(preview.name) === 100
                            ? UPLOAD_STEPS.WAITING
                            : UPLOAD_STEPS.UPLOADING;
                } else if (preview.progress === 100) {
                    // если в сторе нет фото с таким именем и progress 100%, то запрос на сервер завершен
                    uploadStep = UPLOAD_STEPS.READY;
                }
            }
            if (
                preview.progress !== uploads.get(preview.name) ||
                preview.loading !== isSubmitting ||
                preview.uploadStep !== uploadStep
            ) {
                setPreview({
                    ...preview,
                    progress: uploads.get(preview.name),
                    loading: isSubmitting,
                    uploadStep
                });
            }
        }
    }, [uploads, isSubmitting, preview]);

    const error = Object.keys(errors).length || (status && !!status.error);

    useEffect(() => {
        if (prevValues && prevValues.id !== values.id) {
            const { properties = {} } = values;
            const footer =
                properties.find(
                    (el) => el.key === FEATURE_PROPERTY_KEYS.footer
                ) || {};
            setFieldValue("footer", footer.value);
        }
    }, [prevValues, setFieldValue, values]);

    useEffect(() => {
        USERLIST_SERVICE.trackPdfSettingOpened();
    }, []);

    const handleDropFile = (files) => {
        if (files && files.length) {
            const file = files[0];
            const preview = {
                index: 0,
                file,
                name: file.name,
                preview: window.URL.createObjectURL(file)
            };
            setPreview(preview);
            setIsFileReady(true);
            setFieldValue("file", file);
        }
    };

    const onDropRejected = (files, accept) => {
        if (files.find((el) => el.size > MAX_IMAGE_SIZE)) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileSize.description",
                subMessageValues: { size: MAX_IMAGE_SIZE / 1024 / 1024 }
            });
        }
        const acceptArr = accept.split(", ");
        if (files.find((el) => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileType.description",
                subMessageValues: { types: accept.replace(/image\//g, "") }
            });
        }
    };

    const handleDeletePreview = () => {
        setFieldValue("file", undefined);
        setPreview();
        setIsFileReady(false);
    };

    const renderChangeImage = () => {
        const accept = "image/jpeg, image/png";
        return (
            <Dropzone
                accept={accept}
                onDrop={handleDropFile}
                multiple={false}
                maxSize={MAX_IMAGE_SIZE}
                onDropRejected={(files) => onDropRejected(files, accept)}
            >
                {({ getInputProps, getRootProps }) => (
                    <div {...getRootProps()} className="input">
                        <input {...getInputProps()} />
                        {formatMessage({ id: "editLogo.button.change" })}
                    </div>
                )}
            </Dropzone>
        );
    };

    const renderImage = (src) => {
        return (
            <Logo>
                <ImageComponent>
                    <Image
                        aspectRatio={1}
                        src={getDomain(src)}
                        imgStyle={{ objectFit: "contain" }}
                        alt=""
                    />
                </ImageComponent>
                {renderChangeImage()}
                <TrashButton onClick={() => setShowModalDeleteImage(true)} />
            </Logo>
        );
    };

    const renderUpload = () => {
        const accept = "image/jpeg, image/png";
        return (
            <UploadWrapper>
                {isFileReady ? (
                    <div className="image-preview">
                        <img src={preview.preview} alt="" />
                        <span>{`/...${preview.file.name.slice(-10)}`}</span>
                        {renderChangeImage()}
                        <TrashButton onClick={handleDeletePreview} />
                    </div>
                ) : (
                    <Dropzone
                        accept={accept}
                        onDrop={handleDropFile}
                        multiple={false}
                        maxSize={MAX_IMAGE_SIZE}
                        onDropRejected={(files) =>
                            onDropRejected(files, accept)
                        }
                    >
                        {({ getInputProps, getRootProps }) => (
                            <div {...getRootProps()} className="input">
                                <input {...getInputProps()} />
                                <FileUploadIcon color="lightGray" />
                                <span className="dropzone-header">
                                    {formatMessage({
                                        id: "pdfTool.upload.logo"
                                    })}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: formatMessage({
                                                id: "upload.video.text.browse"
                                            })
                                        }}
                                    />
                                </span>
                                <span
                                    className="dropzone-footer"
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({
                                            id: "pdfTool.upload.text.info"
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </Dropzone>
                )}
            </UploadWrapper>
        );
    };

    const renderPhotosBlock = () => {
        // eslint-disable-next-line react/prop-types
        const logo = (values.properties || []).find(
            (el) => el.key === FEATURE_PROPERTY_KEYS.logoMediaUrl
        );
        return (
            <FormRow>
                {logo && !values.file
                    ? renderImage(logo.value)
                    : renderUpload()}
            </FormRow>
        );
    };
    const isLogoExist =
        values.properties &&
        values.properties.find(
            (prop) => prop.key === FEATURE_PROPERTY_KEYS.logoMediaUrl
        );

    return (
        <Modal
            open={open}
            closeOnDimmerClick
            onClose={onClose}
            header={{
                title: formatMessage({ id: "pdfTool.header" }),
                buttons: (
                    <Button
                        disabled={
                            isSubmitting || isEqual(values, initialValues)
                        }
                        content={formatMessage({ id: "pdfTool.button.save" })}
                        onClick={submitForm}
                    />
                )
            }}
        >
            <Label compact={values.file || isLogoExist}>
                {formatMessage({ id: "pdfTool.modal.header" })}
            </Label>
            <Container>
                <Semantic.Form
                    noValidate
                    error={error}
                    loading={isSubmitting || loading}
                    onSubmit={handleSubmit}
                >
                    <Message
                        error={error}
                        errors={errors}
                        content={status && status.error}
                    />
                    {renderPhotosBlock()}
                    {(values.file || isLogoExist) && (
                        <FormRow>
                            <FormSlider
                                name="logoSizePercent"
                                label={formatMessage({
                                    id: "slideshow.label.logoSize"
                                })}
                                min={0.2}
                                max={1}
                                step={0.05}
                                bottomMessages={[
                                    formatMessage({
                                        id: "styles.inputs.fontSize.value.small"
                                    }),
                                    formatMessage({
                                        id: "inputs.slider.value.original"
                                    }),
                                    formatMessage({
                                        id: "styles.inputs.fontSize.value.large"
                                    })
                                ]}
                            />
                        </FormRow>
                    )}
                    <FormRow>
                        <StyledTextareaContainer>
                            <FormTextArea
                                label="pdfTool.modal.footer"
                                name="footer"
                            />
                        </StyledTextareaContainer>
                    </FormRow>
                    <FormRow marginBottom="0">
                        <span>{formatMessage({ id: "pdfTool.enable" })}</span>
                        <FormToggle name="enabled" />
                    </FormRow>
                </Semantic.Form>
            </Container>
            <ModalYesNo
                open={showModalDeleteImage}
                onClickCancel={() => setShowModalDeleteImage(false)}
                onClickYes={() => {
                    const logo = (values.properties || []).find(
                        (el) => el.key === FEATURE_PROPERTY_KEYS.logo
                    );
                    featurePropertyDelete({ propertyId: logo.id })
                        .then(refetchData)
                        .then(() =>
                            window.frames["preview-frame"]?.postMessage(
                                JSON.stringify({
                                    name: MESSAGE_TYPE.UPDATE_META
                                }),
                                "*"
                            )
                        );
                    setShowModalDeleteImage(false);
                }}
                headerMessageId="siteName.delete.header"
                subMessageId="siteName.delete.subHeader"
                yesMessageId="siteName.delete.button.delete"
                cancelMessageId="siteName.delete.button.cancel"
            />
        </Modal>
    );
};

PdfTool.propTypes = {
    pageId: PropTypes.string.isRequired, // eslint-disable-line
    initialValues: PropTypes.object.isRequired, // eslint-disable-line
    onClose: PropTypes.func.isRequired,
    featurePropertyDelete: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    uploads: PropTypes.object.isRequired,
    open: PropTypes.bool,
    openErrorModal: PropTypes.func,
    ...formProptypes
};

PdfTool.defaultProps = {
    open: false,
    openErrorModal: () => null
};

PdfTool.contextTypes = {
    intl: PropTypes.object.isRequired
};

export default PdfTool;

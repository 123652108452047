import styled from 'styled-components';
import {Semantic} from 'packages/components';
import {media} from 'packages/components/media';

export const Container = styled.div`
    height: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: auto;

    ${media.max.tablet`
        margin-left: 0;
    `}
`;

export const Styles = styled.div`
    display: flex;
    flex-direction: column;
    color: #343840;
    background: #fff;
    padding: 11px 20px;
    min-width: 368px;
    justify-content: center;
    height: 40px;
    align-self: end;

    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #cdffb9 !important;
        color: #58C367;
    }

    .Selectable .DayPicker-Day {
        border-radius: 0 !important;
    }

    .Selectable .DayPicker-Day--start {
        background-color: #58C367 !important;
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }

    .Selectable .DayPicker-Day--end {
        background-color: #58C367 !important;
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }

    .Selectable .DayPicker-Day--outside {
        background-color: transparent !important;
    }

    ${media.max.tablet`
        width: 100%;
    `}
`;

export const Form = styled(Semantic.Form)`
    display: ${props => props.open ? 'block' : 'none'};
    margin-top: 10px;
    background-color: #fff;
    position: absolute !important;
    min-width: max-content;
    right: 0;
    top: 100%;
    padding: 11px 20px;
    z-index: 1;
    box-shadow: -2px 2px 4px #f7f7f7;

    ${media.max.phone`
        min-width: 100%;
    `}
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;

    div ~ div {
        margin-left: 1em;
    }

    ${media.max.phone`
        .DayPicker-Months {
            margin-left: 0;
        }
    `}
`;

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UseDemoCheckbox = styled(Semantic.Form.Checkbox)`
    margin-top: auto !important;
`;

export const FilterPanel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
        font-family: inherit;
    }
`;

export const FilterButton = styled.button`
    background: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: auto;
    font-size: 13px;
    color: #343840;
    text-transform: capitalize;
    width: 202px;
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-self: flex-end;

    &:focus {
        border: none;
        outline: none;
    }

    > div {
        display: flex;
        align-items: center;
        margin-top: 1px;

        svg {
            margin-bottom: 2px;
            margin-left: 17px;
        }
    }
`;

export const SubmitButton = styled.button`
    background-color: #58C367;
    color: white;
    border: none;
    outline: none;
    padding: 0.5em 0;
    width: 100%;
    cursor: pointer;
    margin-top: auto;

    &:focus {
        border: none;
        outline: none;
    }
`;

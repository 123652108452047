/* eslint-disable */
import React from 'react';

export const RetryIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.005 4C14.2151 4 16.2128 4.89397 17.6585 6.34L20 4V11H12.9956L16.2176 7.78C15.1379 6.6909 13.6585 6.00114 11.9991 6C9.39003 6.00251 7.17137 7.67175 6.35147 10H6.33378C6.21976 10.3217 6.13245 10.6559 6.07452 11H4.0617C4.55243 7.05239 7.91112 4 11.995 4H12.005Z"
            fill="#58C367"/>
        <path
            d="M4 13V20L6.34146 17.66C7.78717 19.106 9.78489 20 11.995 20L12.005 20C16.0889 20 19.4476 16.9476 19.9383 13H17.9255C17.8676 13.3441 17.7802 13.6783 17.6662 14H17.6485C16.8287 16.3282 14.6101 17.9974 12.0012 18C10.3417 17.9989 8.86221 17.3092 7.78236 16.22L11.0044 13H4Z"
            fill="#58C367"/>
    </svg>

);

RetryIcon.propTypes = {};

RetryIcon.defaultProps = {};

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const CaretUpIcon = ({ color }) => (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M-2.18557e-07 5L5 2.18557e-07L10 5L-2.18557e-07 5Z"
            fill={iconColors[color]}
        />
    </svg>
);

CaretUpIcon.propTypes = {
    ...iconColorPropTypes,
};

CaretUpIcon.defaultProps = {
    color: 'dark',
};

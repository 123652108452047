import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    background: rgba(246, 246, 246, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 54px);
`;

export const Container = styled.div`
    max-width: 400px;
    width: 100%;
    padding: 18px;
    margin: 0 auto;
    background: white;
`;

export const Logo = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 15px;
    padding-right: 15px;
    height: 54px;
    z-index: 2;

    > img {
        width: 103px;
        height: 33px;
    }
`;

import {useCallback, useEffect} from 'react';

export function useClickOutside(ref, callback) {
    const handler = useCallback((e: MouseEvent) => {
        if (!ref.current.contains(e.target)) {
            callback();
        }
    }, [ref, callback]);

    useEffect(() => {
        document.addEventListener('mousedown', handler);
        return () =>
            document.removeEventListener('mousedown', handler);
    }, [ref, callback, handler]);
}

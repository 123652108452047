import {List} from 'immutable';
import {handleActions} from 'redux-actions';
import {remIdx, pushIdx, setIdx} from "./actions";

const defaultState = List()

export default handleActions({
    [pushIdx]: {
        next(state, {payload}) {
            return state.push(payload)
        }
    },
    [setIdx]: {
        next(state, {payload}) {
            return state.set(0, payload)
        }
    },
    [remIdx]: {
        next(state, {payload}) {
            return state.delete(payload)
        }
    },
}, defaultState)

/* eslint-disable max-len */
import {formatMessagesList} from "packages/helpers/Helper";

const messages = {
    "legal.nav.terms": "Terms of service",
    "legal.nav.privacy": "Privacy",
    "landing.account.create.label.name": "Name",
    "landing.account.create.label.email": "Email",
    "landing.account.create.label.password": "Password",
    "landing.account.create.label.passwordConfirm": "Confirm Password",
    "landing.account.create.label.country": "Country",
    "landing.account.create.button.label": "Create Account",
    "landing.account.create.recaptcha": "Validation error",
    "landing.account.about.header": "We’re preparing your account. Meanwhile tell us a little about yourself",
    "landing.account.about.select.header": "I am",
    "landing.account.about.select.options": formatMessagesList([
        "Photographer",
        "Videographer",
        "Illustrator / Designer",
        "Fashion stylist",
        "Painter",
        "Architect",
        "Art / Creative Director",
        "Hair / Makeup artist",
        "Fashion stylist",
        "Model / Actor",
        "Other",
    ]),
    "landing.account.about.select.placeholder": "Please choose one",
    "landing.account.about.select.name": "i am",
    "landing.account.about.checkbox.header": "Choose your interest",
    "landing.account.about.interest": formatMessagesList([
        "Showcase my work",
        "Improve my Google ranking",
        "Market better my work",
        "Sell digital products",
        "Sell online courses & tutorials",
        "Sell physical products",
        "Sell prints on demand",
    ]),
    "landing.account.about.button": "Ready, take me to the website",
    "landing.login.title": "Log in to your account",
    "landing.login.label.login": "Login",
    "landing.login.label.password": "Password",
    "landing.login.forgot": "Forgot your password? Reset it",
    "landing.login.here": "here",
    "landing.login.button.label": "Login",
    "landing.resetPassword.title": "Reset password",
    "landing.resetPassword.label.password": "New password",
    "landing.resetPassword.placeholder.password": "Enter your new password",
    "landing.resetPassword.label.login": "Login",
    "landing.resetPassword.label.here": "here",
    "landing.resetPassword.button.label": "Reset password",
    "landing.sendEmail.title": "Reset password",
    "landing.sendEmail.label.email": "Email",
    "landing.sendEmail.button.label": "Send magic link",
    "landing.account.field.error.name": "Name cannot be empty",
    "landing.account.field.error.email": "Email cannot be empty",
    "landing.account.field.error.country": "Country cannot be empty",
    "landing.account.field.error.interest": "'I am' field cannot be empty",
    "landing.account.field.error.password": "Password is required",
    "landing.account.field.confirm.password": "Password confirm is required",
    "landing.account.field.match.password": "Passwords must match",
    "landing.login.error": "Login cannot be empty",
    "landing.password.error": "Password cannot be empty",
    "landing.email.error": "Email cannot be empty",
    "landing.agree.text.by": "By signing up, i have read and agreed to",
    "landing.agree.text.and": "and",
    "landing.agree.text.last": " ",
    "landing.resetPassword.success":
        "Now you can login with the new credentials.",
    "landing.account.pro": "Pro/Pro MAX",
    "landing.account.pro.description":
        "Try Vsble PRO MAX 14-Days For Free. No credit card, no strings.",
    "landing.account.pro.note":
        "Try our best plan for 14 days and decide then which plan you pick.",
    "landing.account.free": "Free",
    "landing.account.free.description":
        "You can upgrade to advanced plans anytime",
    "landing.account.free.description.strongText": "Forever FREE.",
    'landing.account.create.pro.link': 'Skip the trial, Sign up for Vsble FREE',
    'landing.account.create.free.link': 'Try Vsble PRO MAX 14-Days For Free',
    "landing.account.create.loader":
        "Your account is being created. Please do not leave this page or close your browser.",
    "landing.login.forgot.back": "Back",
    "landing.login.forgot.text":
        "Enter your account's email and we'll send you<br/>a magic link to reset the password",
    "landing.account.create.header": "Sign Up",
    "landing.resetPassword.success.message":
        "Email with the reset link has been sent.<br/>Check your inbox.",
    "landing.login.defaultError":
        "The e-mail or password you entered is incorrect. Please try again.",
    "landing.singUp.label": "Don't have an account?",
    "landing.singUp.button.label": "Sign Up Free",
    "landing.singIn.label": "Already have an account?",
    "landing.singIn.button.label": "Sign In",
    "landing.account.create.error.recaptcha": "You have not passed Google reCAPTCHA v3 verification.",
    "landing.account.create.loading.header": "Just a few second...",
    "landing.account.create.loading.description":
        "We are preparing your account. Please don't close the browser or leave this page.",
    "landing.account.create.loading.stage1": "Preparing website structure...",
    "landing.account.create.loading.stage2": "Applying styles...",
    "landing.account.create.loading.stage3": "Adding custom code...",
    "landing.account.create.loading.stage4": "Preparing navigation...",
    "landing.account.create.loading.stage5":
        "Some additional magical touches...",
};

export default messages;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {MESSAGE_TYPE} from 'packages/enum';
import {start as loaderStart, stop as loaderStop} from 'packages/redux/modules/pageLoader/actions';
import {open, close} from 'packages/redux/modules/modalYesNo/actions';

import websitePhotoReplace from '@graphql/gql/website/websitePhotoReplace.gql';
import websitePhotoDelete from '@graphql/gql/website/websitePhotoDelete.gql';
import moveImageToWebsite from '@graphql/gql/website/moveImageToWebsite.gql';
import websiteAlbumUpdate from '@graphql/gql/website/websiteAlbumUpdate.gql';
import websitePhotosCount from '@graphql/gql/website/websitePhotosCount.gql';
import websiteAlbumItemUpdate from '@graphql/gql/website/websiteAlbumItemUpdate.gql';
import albumItemsOrderUpdate from '@graphql/gql/website/albumItemsOrderUpdate.gql';
import websiteMoveToAlbum from '@graphql/gql/website/websiteMoveToAlbum.gql';
import websiteOnePhotoUpload from '@graphql/gql/website/websiteOnePhotoUpload.gql';

import Album from '@configurator/components/page/album';

const updatePreviewPage = () => {
    window.frames['preview-frame']?.postMessage(
        JSON.stringify({
            name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*',
    );
};

export default compose(
    injectIntl,
    connect(
        // mapStateToProps
        state => ({
            expandedView: state.getIn(['albumView', 'expandedView']),
        }),
        // mapDispatchToProps
        dispatch => ({
            loaderStart: ({content} = {}) => dispatch(loaderStart({content})),
            loaderStop: () => dispatch(loaderStop()),
            closeInfoModal: () => dispatch(close()),
            openInfoModal: ({
                hideHeader,
                headerMessageId,
                subMessageId,
                yesMessageId,
                cancelMessageId,
                onClickCancel,
                onClickYes,
                buttons,
            }) =>
                dispatch(open({
                    headerMessageId,
                    hideHeader,
                    subMessageId,
                    yesMessageId,
                    cancelMessageId,
                    close,
                    onClickYes,
                    onClickCancel,
                    buttons,
                })),
        }),
    ),
    graphql(websiteMoveToAlbum, {
        props({mutate, ownProps: {loaderStart, loaderStop, refetchPagePreview}}) {
            return {
                async moveAlbumItemToAlbum({itemId, albumId}) {
                    try {
                        loaderStart();
                        const res = await mutate({
                            variables: {
                                itemId,
                                albumId,
                            },
                        });

                        if (get(res, 'data.websiteMoveToAlbum.success')) {
                            updatePreviewPage();
                            await refetchPagePreview();
                        }

                        loaderStop();

                        return get(res, 'data.websiteMoveToAlbum');
                    } catch (err) {
                        loaderStop();
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websitePhotoReplace, {
        props({mutate, ownProps: {loaderStart, loaderStop, refetchPagePreview}}) {
            return {
                async onImageReplace({id, file}) {
                    try {
                        loaderStart();
                        const res = await mutate({
                            variables: {
                                id,
                                file,
                            },
                        });

                        if (get(res, 'data.websitePhotoReplace.success')) {
                            updatePreviewPage();
                            await refetchPagePreview();
                        }

                        loaderStop();

                        return get(res, 'data.websitePhotoReplace');
                    } catch (err) {
                        loaderStop();
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websitePhotoDelete, {
        props({mutate, ownProps}) {
            return {
                async onImageDelete({
                    photoId,
                }, forceRefetchPagePreview = false) {
                    try {
                        const res = await mutate({
                            variables: {
                                photoId,
                            },
                            refetchQueries: [{query: websitePhotosCount}],
                        });

                        if (( forceRefetchPagePreview || ownProps.forceRefetchPagePreview) && ownProps.refetchPagePreview) {
                            ownProps.refetchPagePreview();
                        }

                        return get(res, 'data.websitePhotoDelete');
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteAlbumUpdate, {
        props({mutate, ownProps: {refetchPagePreview}}) {
            return {
                async onAlbumUpdate({mainPhotoId, albumId}) {
                    try {
                        const res = await mutate({
                            variables: {
                                mainPhotoId,
                                albumId,
                                // idk, something with apollo, pageId is not undefined by default.
                                pageId: undefined,
                            },
                        });

                        if (get(res, 'data.websiteAlbumUpdate.success')) {
                            updatePreviewPage();
                            refetchPagePreview();
                        }

                        return get(res, 'data.websiteAlbumUpdate');
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websitePhotosCount, {
        options() {
            return {
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websitePhotosCount, refetch}}) {
            if (loading) {
                return {loading, data: {}};
            }
            if (isEmpty(websitePhotosCount)) {
                return {loading, data: {}};
            }
            return {refetchWebsitePhotosCount: refetch};
        },
    }),
    graphql(albumItemsOrderUpdate, {
        props({mutate}) {
            return {
                async onAlbumOrderUpdate({
                    photoIds,
                    albumId,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                photoIds,
                                albumId,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteOnePhotoUpload, {
        props({mutate, ownProps: {loaderStart, loaderStop, refetchPagePreview}}) {
            return {
                async onImageResize({albumId, file, index}) {
                    try {
                        loaderStart();
                        const res = await mutate({
                            variables: {
                                albumId,
                                file,
                                index
                            },
                            refetchQueries: [{query: websitePhotosCount}],
                        });

                        if (get(res, 'data.websiteOnePhotoUpload.success')) {
                            updatePreviewPage();
                            await refetchPagePreview();
                        }

                        loaderStop();

                        return get(res, 'data.websiteOnePhotoUpload');
                    } catch (err) {
                        loaderStop();
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteAlbumItemUpdate, {
        props({mutate, ownProps: {refetchPagePreview}}) {
            return {
                async albumItemUpdate({
                    hidden,
                    albumItemId,
                    description,
                    xFocalPoint,
                    yFocalPoint,
                    altText,
                    altTextDescriptive
                }) {
                    try {
                        const res = await mutate({
                            variables: {
                                albumItemId,
                                hidden,
                                description,
                                xFocalPoint,
                                yFocalPoint,
                                altText,
                                altTextDescriptive
                            },
                        });

                        if (get(res, 'data.websiteAlbumItemUpdate.success')) {
                            updatePreviewPage();
                            refetchPagePreview();
                        }

                        return get(res, 'data.websiteAlbumItemUpdate');
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(moveImageToWebsite, {
        props({mutate, ownProps: {refetchPagePreview}}) {
            return {
                async moveImageToWebsite({
                    leftId,
                    rightId,
                    photoId,
                    albumId,
                }) {
                    try {
                        const res = await mutate({
                            variables: {
                                leftId,
                                rightId,
                                photoId,
                                albumId,
                            },
                        });

                        if (get(res, 'data.moveImageToWebsite.success')) {
                            updatePreviewPage();
                            refetchPagePreview();
                        }

                        return get(res, 'data.moveImageToWebsite');
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
)(Album);


import { compose } from "redux";
import { graphql } from "react-apollo";
import { withFormik } from "formik";
import { withRouter } from "react-router";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import {
    open as openDiscardChangesModal,
    close as closeDiscardChangesModal
} from "packages/redux/modules/modalConfirmDelete/actions";
import { connect } from "react-redux";

import { MESSAGE_TYPE } from "packages/enum";

import websiteScriptUpdate from "@graphql/gql/website/websiteScriptUpdate.gql";
import websiteScriptGet from "@graphql/gql/website/websiteScriptGet.gql";
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

const validationSchema = Yup.object().shape({
    content: Yup.string().nullable()
});

export const wrapper = (Component, integrationName) =>
    compose(
        withRouter,
        connect(
            (state) => ({
                websiteId: state.getIn(["preview", "meta", "id"])
            }),
            (dispatch) => ({
                openDiscardChangesModal: ({
                                              subMessageId,
                                              yesMessageId,
                                              hideHeader,
                                              onClickYes,
                                              styles
                                          }) =>
                    dispatch(
                        openDiscardChangesModal({
                            subMessageId,
                            yesMessageId,
                            hideHeader,
                            onClickYes,
                            styles
                        })
                    ),
                closeDiscardChangesModal: () =>
                    dispatch(closeDiscardChangesModal())
            })
        ),
        graphql(websiteScriptGet, {
            options({ match }) {
                return {
                    variables: {
                        type: integrationName
                    },
                    fetchPolicy: "network-only"
                };
            },
            props({ data: { loading, websiteScriptGet } }) {
                if (loading) {
                    return { loading };
                }
                if (isEmpty(websiteScriptGet)) {
                    return;
                }
                const { data } = websiteScriptGet;

                USERLIST_SERVICE.trackIntegrationPageVisited();

                return {
                    initialValues: {
                        content: data.content
                    }
                };
            }
        }),
        graphql(websiteScriptUpdate, {
            props({ mutate, ownProps }) {
                return {
                    async scriptUpdate({
                                           type,
                                           content,
                                           scriptPosition,
                                           websiteId
                                       }) {
                        try {
                            return await mutate({
                                variables: {
                                    type,
                                    content,
                                    scriptPosition,
                                    websiteId
                                },
                                refetchQueries: [
                                    {
                                        query: websiteScriptGet,
                                        variables: {
                                            type: integrationName
                                        }
                                    }
                                ]
                            });
                        } catch (err) {
                            return {
                                success: false
                            };
                        }
                    }
                };
            }
        }),
        withFormik({
            mapPropsToValues: ({ initialValues }) => {
                return {
                    ...initialValues
                };
            },
            enableReinitialize: true,
            validateOnBlur: false,
            validateOnChange: false,
            validationSchema,
            handleSubmit: async (
                { content },
                {
                    setSubmitting,
                    setStatus,
                    props: { match, scriptUpdate, websiteId }
                }
            ) => {
                let defaultError = "";
                try {
                    const res = await scriptUpdate({
                        type: integrationName,
                        content,
                        scriptPosition: "BODY",
                        websiteId
                    });
                    setSubmitting(false);
                    let {
                        data: { websiteScriptUpdate: { errors, success } = {} }
                    } = res;
                    if (success) {
                        window.frames["preview-frame"]?.postMessage(
                            JSON.stringify({
                                name: MESSAGE_TYPE.UPDATE_META
                            }),
                            "*"
                        );
                        return;
                    }
                    setStatus({ error: errors._error || defaultError });
                } catch (err) {
                    setSubmitting(false);
                    setStatus({
                        error: __PRODUCTION__ ? defaultError : err.toString()
                    });
                }
            }
        })
    )(Component);

import React from "react";
import { injectIntl, intlShape } from "react-intl";

import { HeaderStyled } from "packages/components/layout/nodes";
import {
    Container,
    Header,
    Teaser,
    TeaserText,
    Logo,
    FormWrapper,
    Grid,
    Notes,
    NoteHeader,
    Step,
    StepContent,
    StepNumber,
    StepWrapper,
    Paragraph,
} from "../nodes";
import Image from "packages/components/gatsby-image";
import dropinblog from "../../assets/dropinblog.png";
import dropinblog_instructions from "../../assets/dropoinblog_instructions.jpg";

import { Button } from "packages/components/buttons";

import { wrapper } from "../../../../containers/integrations/IntegrationsDetails";

const DropInBlog = ({ intl: { formatMessage } }) => {
    return (
        <>
            <HeaderStyled backArrow />
            <Container>
                <Header>
                    {formatMessage({
                        id: "integrations.dropinblog.header",
                    })}
                </Header>
                <Teaser>
                    <Logo>
                        <Image
                            fluid={{
                                src: dropinblog,
                                aspectRatio: 38 / 15,
                            }}
                            alt=""
                            fadeIn
                            imgStyle={{ objectFit: "contain" }}
                            loading="lazy"
                            backgroundColor="white"
                        />
                    </Logo>
                    <TeaserText small>
                        {formatMessage({
                            id: "integrations.dropinblog.teaser",
                        })}
                    </TeaserText>
                    <div>
                        <Button
                            as="a"
                            href={"https://dropinblog.com/"}
                            target="_blank"
                            content={formatMessage({
                                id: "integration.start.free",
                            })}
                        />
                    </div>
                </Teaser>
                <Grid>
                    <div>
                        <FormWrapper>
                            <Image
                                fluid={{
                                    src: dropinblog_instructions,
                                    aspectRatio: 3 / 2,
                                }}
                                alt=""
                                fadeIn
                                imgStyle={{ objectFit: "contain" }}
                                loading="lazy"
                                backgroundColor="white"
                            />
                        </FormWrapper>
                    </div>
                    <div>
                        <Notes>
                            <NoteHeader>
                                {formatMessage({
                                    id: "integrations.dropinblog.note.header",
                                })}
                            </NoteHeader>
                            <StepWrapper>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>{formatMessage({
                                                id: "integrations.dropinblog.step.1",
                                            })}</span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: formatMessage({
                                                        id: "integrations.dropinblog.note.1",
                                                    }),
                                                }}
                                            />
                                        </Paragraph>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>{formatMessage({
                                                id: "integrations.dropinblog.step.2",
                                            })}</span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            {formatMessage({
                                                id: "integrations.dropinblog.note.2",
                                            })}
                                        </Paragraph>
                                    </StepContent>
                                </Step>
                            </StepWrapper>
                        </Notes>
                    </div>
                </Grid>
            </Container>
        </>
    );
};

DropInBlog.propTypes = {
    intl: intlShape.isRequired,
};

DropInBlog.defaultProps = {};

export default wrapper(injectIntl(DropInBlog), "dropinblog");

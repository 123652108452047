import {connect} from 'react-redux';
import {compose} from 'redux';
import {withFormik} from 'formik';
import moment from 'moment';

import PeriodPicker from '@configurator/components/statistics/periodPicker/PeriodPicker';
import { DATE_FORMAT, PERIOD_OPTIONS } from '@configurator/constants/statistics';
import { applyFilter } from '@configurator/redux/modules/periodPicker/actions';

const mapStateToProps = state => ({ matomoOptions: state.get('periodPicker').toJS() });

const mapDispatchToProps = { applyFilter };

export const periodPickerWrapper = Component => compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: ({ matomoOptions }) => ({
            date: {
                from: moment(matomoOptions.date, DATE_FORMAT).toDate(),
                to: moment(matomoOptions.date, DATE_FORMAT).toDate(),
            },
            period: matomoOptions.period,
            useDemo: matomoOptions.useDemo,
        }),
        enableReinitialize: false,
        validateOnBlur: false,
        validateOnChange: false,
        handleSubmit: (values, { props: { applyFilter }}) => {
            if (values.period === PERIOD_OPTIONS.range) {
                const dateFrom = moment(values.date.from).format(DATE_FORMAT);
                const dateTo = values.date.to
                    ? moment(values.date.to).format(DATE_FORMAT)
                    : moment(values.date.from).format(DATE_FORMAT);
                const date = `${dateFrom},${dateTo}`;
                applyFilter({ ...values, date });
            } else {
                const date = moment(values.date.from).format(DATE_FORMAT);
                applyFilter({ ...values, date });
            }
        },
    }),
)(Component);

export default periodPickerWrapper(PeriodPicker);

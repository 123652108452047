import PropTypes from 'prop-types';
import {iconColorPropTypes, ExternalLinkIcon} from '../../icons';
import {StyledSVG} from '../svgStyles';

export const ExternalLinkButton = StyledSVG(ExternalLinkIcon);

ExternalLinkButton.propTypes = {
    ...iconColorPropTypes,
    className: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};

ExternalLinkButton.defaultProps = {
    color: 'darkGray',
    active: false,
    className: '',
    onClick: () => {
    },
};

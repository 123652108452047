import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper, Line} from './nodes';

export const Divider = ({padding}) => (
    <Wrapper padding={padding}>
        <Line/>
    </Wrapper>
);

Divider.propTypes = {
    padding: PropTypes.string,
};

Divider.defaultProps = {
    padding: '20px',
};


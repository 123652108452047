class UserlistService {
    identify = ({ token, email, name }) => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist("init", token, {
            email: email,
            properties: {
                name: name
            }
        });
    };

    trackDashboardOpened = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'dashboard_opened');
    };

    trackPageCreated = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'page_created');
    };

    trackAlbumCreated = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'album_created');
    };

    trackTemplateChanged = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'template_changed');
    };

    trackExpandedModeUsed = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'expanded_mode_used');
    };

    trackPhotoUploaded = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'photo_uploaded');
    };

    trackVideoSaved = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'video_saved');
    };

    trackCustomPreviewSaved = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'custom_preview_saved');
    };

    trackContactPageCreated = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'contact_page_created');
    };

    trackFooterPageCreated = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'footer_page_created');
    };

    trackSlideshowSettingsOpened = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'slideshow_settings_opened');
    };

    trackCustomLogoUploaded = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'custom_logo_uploaded');
    };

    trackFaviconUploaded = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'favicon_uploaded');
    };

    trackSubdomainChanged = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'subdomain_changed');
    };

    trackPremiumDomainChanged = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'premium_domain_changed');
    };

    trackSeoSettingsEdited = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'seo_settings_edited');
    };

    trackStatisticPageVisited = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'statistic_page_visited');
    };

    trackPdfSettingOpened = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'pdf_settings_opened');
    };

    trackPdfSettingUpdated = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'pdf_settings_updated');
    };

    trackIntegrationPageVisited = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'integration_page_visited');
    };

    trackSeoPageVisited = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'seo_page_visited');
    };

    trackWebsiteSettingsPageVisited = () => {
        __USERLIST_ENABLED__ && window.userlist && window.userlist('track', 'website_settings_page_opened');
    };
}

export const USERLIST_SERVICE = new UserlistService();

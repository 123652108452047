import styled from 'styled-components';
import {media} from 'packages/components/media';

export const Container = styled.div`
    background-color: white;
    position: relative;
    padding: 20px 17px 20px 20px;
    height: 100%;
    width: 100%;

    ${media.max.phone`
        padding: 20px 16px;
    `}
`;

export const DateComponent = styled.div`
    display: flex;

    i {
        margin: 10px;
    }

    > div .field [tabindex='0'] {
        z-index: 999;
        background-color: #ffffff;
        border: 1px solid #d0d1d2;
    }
`;

export const ChartComponent = styled.div`
    width: 100%;
    background-color: white;
`;

import React, {memo} from 'react';
import Image from 'packages/components/image/Image';
import PropTypes from 'prop-types';


const ImageDragPreview = memo(({aspectRatio, src, srcSetWebpMap}) => (
    <Image
        alt=""
        aspectRatio={aspectRatio}
        src={src}
        srcSetWebpMap={srcSetWebpMap}
    />
));

ImageDragPreview.propTypes = {
    src: PropTypes.string.isRequired,
    srcSetWebpMap: PropTypes.array,
    aspectRatio: PropTypes.number,
};

ImageDragPreview.defaultProps = {
    aspectRatio: 1,
    srcSetWebpMap: [],
};

export default ImageDragPreview;

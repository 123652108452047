import styled, {css} from 'styled-components';
import {Modal} from 'packages/components/modal';
import {media} from 'packages/components/media';

export const RightPanelBlock = styled.div`
    ${media.max.tablet`
        width: 50%;

        &:first-child {
            margin-right: 30px;
        }

        &:last-child {
            > div:first-child {
                margin-top: 0;
            }
        }
    `}

    ${media.max.phone`
        width: 100%;

        &:last-child {
            > div:first-child {
                margin-top: 30px;
            }
        }
    `}
`;

export const Container = styled.div`
    max-width: 1220px;
    margin: 30px auto;
    padding-bottom: 30px;
    display: flex;

    > div {
        &:first-child {
            flex-grow: 1;
            padding-right: 100px;
            max-width: 600px;
        }

        &:last-child {
            width: 100%;
            max-width: 450px;
            padding-left: 30px;
        }
    }
     form > button {
         min-width: 180px !important;
         height: 45px !important;
     }

    ${media.max.tablet`
        flex-direction: column;
        padding: 0 20px 60px;

        > div {
            &:first-child {
                padding-right: 0;
                max-width: none;
            }
            &:last-child {
                display: flex;
                padding-left: 0;
                max-width: none;
            }
        }
    `}

    ${media.max.phone`
        padding: 0 16px 80px;

        > div {
            &:last-child {
                display: block;
            }
        }

        form > button {
            width: 100%;
        }
    `}
`;

export const Header = styled.div`
    max-width: 1220px;
    margin: 30px auto 0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    /* Dark */
    color: #343840;

    ${media.max.tablet`
        padding: 0 20px;
    `}

    ${media.max.phone`
        font-size: 24px;
        line-height: 150%;
        padding: 0 16px;
    `}
`;

export const Paragraph = styled.div`
    ${props => props.type === 'h1' && css`
        font-style: normal;
        font-weight: bold;
        font-size: ${props => props.fz || '17px'};
        line-height: 150%;
    `}
    margin-top: ${props => props.mt || 0}px;
    margin-bottom: ${props => props.mb || 0}px;

    color: #343840;
`;

export const Field = styled.div`
    .dropdown, .input, textarea {
        width: 100%;
        //max-width: 500px;
    }
    margin-bottom: 20px;
`;
export const ImagePreview = styled.div`
    height: 243px;
    background: #EDEDED;
    > div {
        height: 100%;
    }
`;
export const SearchPreview = styled.div`
    > div {
        padding: 19px 26px;
        background: white;
        border-radius: 3.81356px;
    }
`;
export const SearchPreviewDomain = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 9.91525px;
    line-height: 140%;
    /* Dark */
    color: #343840;

    margin-bottom: 7px;
`;
export const SearchPreviewTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 15.2542px;
    line-height: 150%;
    /* Dark */
    color: #343840;

    margin-bottom: 3px;
`;
export const SearchPreviewDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 11.4407px;
    line-height: 16px;
    /* Dark */
    color: #343840;
`;

export const BrowserTab = styled.div`
    background: #e1e2e5;
    height: 82px;

    > div {
        display: flex;
        height: 44px;

        &:last-child {
            display: flex;
            align-items: center;
            background: white;
        }
    }
`;
export const BrowserTabHeader = styled.div`
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: white;
    height: 44px;
    align-items: center;
    display: flex;

    width: max-content;
    max-width: 100%;
    min-width: 50%;
`;
export const BrowserTabBubble = styled.div`
    margin-left: 15px;
    margin-right: 15px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #e1e2e5;
`;
export const BrowserTabName = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12.9661px;
    line-height: 150%;
    /* Dark */
    color: #343840;

    flex-grow: 1;
    overflow: hidden;
    max-width: 274px;
    height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const BrowserTabCloseIcon = styled.div`
    margin-right: 16px;
    margin-left: 16px;
    display: flex;
    align-items: center;

    svg {
        max-width: 10px;
    }
`;
export const BrowserTabHelper = styled.div`
    height: 44px;
    width: 24px;
    background: white;

    > div {
        height: 44px;
        width: 24px;
        ${props => props.left ? css`
            border-bottom-right-radius: 12px;
        ` : css`
            border-bottom-left-radius: 12px;
        `}
        background: #e1e2e5;
    }
`;
export const BrowserTabBottomIcon = styled.div`
    margin-right: 9px;
    margin-left: 9px;
    display: flex;
    align-items: center;
`;
export const BrowserTabDomain = styled.div`
    height: 30px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    margin-left: 9px;
    width: 100%;

    > svg {
        height: 8px;
        width: 8px;

        margin-left: 15px;
        margin-right: 11px;
    }
`;

export const BrowserTabDomainName = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 11.4px;
    line-height: 16px;
    /* Dark */
    color: #343840;

    max-width: 282px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const UploadPicture = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .ui.button {
       font-size: 13px;
       height: 45px;
       width: 240px;
    }

    ${media.max.phone`
        flex-direction: column;
        align-items: flex-start;

        .ui.button {
            width: 100%;
            margin-top: 15px !important;
        }
    `}
`;

export const Title = styled.div`
    position: relative;
    font-size: 15px;
    line-height: 21px;
    ${props => props.bold ? 'font-weight: bold;' : ''}
`;

export const CenteredDropModal = styled(Modal)`
    margin: auto !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const UploadWrapper = styled.div`
   position: relative;
   overflow-y: auto;height: 100%;

   .input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        color: #666A73;
        font-size: 13px;
        line-height: 140%;
        padding: 15px;

        background: #F6F6F6;
        border: 1px dashed #E1E2E6;
        border-radius: 4px;

        .dropzone-header {
            color: #343840;
            font-size: 24px;
            font-weight: bold;
            line-height: 150%;
            margin: 18px 0 0;
            text-align: center;

            > span {
                color: #666A73;
                font-size: 13px;
                line-height: 140%;
                display: block;
                font-weight: normal;
            }
        }

        .dropzone-footer {
            text-align: center;
            position: absolute;
            bottom: 15px;
        }

        &:focus {
            outline-color: rgba(0, 0, 0, 0) !important;
        }
   }
`;

import styled from 'styled-components';
import {iconColors} from '../icons';

export const StyledSVG = (Component) => styled(Component)`
    .iconBg {
        display: none;
    }

    .main {
        fill: ${({color, active}) => active ? iconColors[color] : iconColors.default};
    }

    .stroke {
        stroke: ${({color, active}) => active ? iconColors[color] : iconColors.default};
    }

    :hover {
        cursor: pointer;

        .main {
            fill: ${({color}) => iconColors[color]};

        }

        .stroke {
            stroke: ${({color}) => iconColors[color]};
        }

        .iconBg {
            display: initial;
        }
    }
`;

import {createActions} from 'redux-actions';


export const {fileUpload: {updateStatus, closeBar, lockModal}} = createActions({
    FILE_UPLOAD: {
        UPDATE_STATUS: status => status,
        LOCK_MODAL: lock => lock,
        CLOSE_BAR: fileNames => fileNames,
    },
});

import styled, { css } from "styled-components";
import {media} from 'packages/components/media';

export const Container = styled.div`
    max-width: 1220px;
    margin: 30px auto;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;

    > div {
        &:first-child {
            flex-grow: 1;
            > div {
            }
        }

        &:last-child {
            width: 100%;
            //max-width: 660px;
            //padding-left: 60px;

            > div {
                //padding: 30px;
                //background: white;
                border-radius: 6px;
            }
        }
    }

    ${media.max.tablet`
        margin: 0;
        padding: 40px 20px 70px;
    `}

    ${media.max.phone`
        padding: 30px 16px 70px;
    `}
`;

export const Header = styled.div`
    max-width: 1220px;
    margin: 30px auto 0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    /* Dark */
    color: #343840;

    ${media.max.tablet`
        padding: 0 20px;
    `}

    ${media.max.phone`
        font-size: 24px;
        line-height: 150%;
        padding: 0 16px;
    `}
`;

export const Paragraph = styled.div`
    ${(props) =>
        props.type === "h1" &&
        css`
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 150%;
        `}

    ${(props) =>
        props.type === "h2" &&
        css`
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
        `}

    > a {
        color: #58c367;
        text-decoration: none;
    }

    margin-top: ${(props) => props.mt || 0}px;
    margin-bottom: ${(props) => props.mb || 0}px;
    max-width: ${(props) => `${props.mw}px` || "auto"};

    color: #343840;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > div {
        width: 47%;
        &:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            > span {
                margin-right: 15px;
                color: #58c367;
                font-size: 13px;
            }
        }

        ${media.max.tablet`
            width: auto;

            &:nth-child(2) {
                > span {
                    margin-left: 16px;
                    white-space: nowrap;
                }
            }
        `}

        ${media.max.phone`
            &:nth-child(2) {
                flex-direction: column-reverse;
                align-items: flex-end;
                > span {
                    margin: 8px 0 0 16px;
                }
            }
        `}
    }
`;

export const VisibilityTitle = styled.div`
    font-style: normal;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 5px;
    /* Dark */
    color: #343840;
    font-weight: bold;
`;

export const VisibilityDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    /* dark gray */
    color: #666a73;
`;

export const DomainWarning = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    /* Dark */

    color: #343840;
`;

export const DomainWrapper = styled.div`
    display: flex;
    width: 100%;
    > div {
        &:first-child {
            flex-grow: 1;

            .input {
                width: 100%;
            }
        }

        &:last-child {
            padding-left: 10px;
        }
    }

    ${({isPremiumDomain}) => isPremiumDomain && css`
        ${media.max.phone`
            flex-direction: column;

            > div {
                &:last-child {
                    padding-left: 0;
                }
            }
        `}
    `}
`;

export const DomainPreview = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 150%;
    margin-top: 20px;
    margin-bottom: 30px;
    /* Dark */
    color: #343840;

    > span {
        color: #58c367;
    }
`;

export const DomainSuffix = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 150%;
    margin-top: 12px;
    margin-right: 25px;
    /* Dark */
    color: #343840;

    > div {
        display: flex;
    }

    ${media.max.phone`
        margin-right: 0;

        > div {
            justify-content: flex-start;

            > div {
                width: auto;
            }
        }
    `}
`;

export const DomainStatus = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 0.28571429rem;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    padding-left: 0.5rem;
    padding-right: 13px;

    ${(props) => (props.success ? "color: #58C367;" : "")}
    ${(props) => (props.warning ? "color: #ec9435;" : "")}
    ${(props) => (props.error ? "color: #FF002E;" : "")}
    > i.green.icon {
        color: #00bd24 !important;
    }
`;

export const DomainDeleteButton = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    svg {
        margin-right: 7px;
    }

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 13px;
        /* Red */
        color: #ff002e;
    }
`;

export const DomainRetryButton = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 22px;

    svg {
        margin-right: 7px;
    }

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 13px;
        /* Red */
        color: #58c367;
    }
`;

export const StepWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    ${media.max.tablet`
        flex-direction: column;
    `}
`;

export const StepNumber = styled.div`
    min-width: 44px;

    > div > span {
        line-height: 27px;
        font-weight: bold;
        font-size: 15px;
        color: #1f1f1f;
    }
`;

export const StepContent = styled.div`
    flex-grow: 1;
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    width: 47%;
    &:nth-child(1) {
        margin-right: 40px;
    }

    ${media.max.tablet`
        width: 100%;
    `}
`;

export const DomainFormContainer = styled.div``;

export const DomainContainer = styled.div`
    display: flex;
    justify-content: space-between;

    > ${Paragraph},${DomainFormContainer} {
        width: 47%;
    }

    ${media.max.tablet`
        flex-direction: column;

        > ${Paragraph}, ${DomainFormContainer} {
            width: 100%;
        }
    `}
`;

export const DomainRightContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    > button {
        align-self: flex-start;
        min-width: 155px;
    }

    ${media.max.phone`
        flex-direction: column;

        > button {
            width: 100%;
            margin-top: 20px !important;
        }
    `}
`;

export const PremiumHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: 30px;

    ${media.max.tablet`
        margin-top: 20px;

        ${Paragraph} {
            width: 45%;
        }
    `}

    ${media.max.phone`
        align-items: center;

        ${Paragraph} {
            width: 100%;
        }
    `}
`;

export const StepTable = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 80%;

    > div {
        display: flex;
        justify-content: space-between;
        > div {
            width: 40%;
        }
    }

    ${media.max.phone`
        grid-row-gap: 8px;

        > div {
            flex-wrap: wrap;

            > div {
                width: 50%;

                span {
                    white-space: nowrap;
                }

                &:last-child {
                    margin-top: 8px;
                }
            }
        }
    `}
`;

export const CustomDomain = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 150%;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #343840;
    > span {
        color: #58c367;
    }

    ${media.max.tablet`
        margin-bottom: 0;
    `}
`;

export const CustomDomainPreview = styled.div`
    background-color: #d8dfe3;
    width: 445px;
    height: 60px;
    display: flex;
    border-radius: 5.57214px 0 0 0;
    align-items: center;
    > div {
        display: flex;

        width: 100px;
        flex-shrink: 0;
        &:nth-child(1) {
            justify-content: space-between;
            padding: 0 21px;
            span {
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background-color: #fff;
            }
        }
        &:nth-child(2) {
            width: 100%;
            flex-shrink: 1;

            > div {
                &:nth-child(1) {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    flex-shrink: 0;
                    background-color: #024af3;
                    color: #fff;
                    border-radius: 3px;
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    margin-right: 7px;
                }

                &:nth-child(2) {
                    display: flex;
                    width: 100%;
                    border-radius: 3.48259px 0 0 3.48259px;
                    background-color: #fff;
                    position: relative;

                    > div {
                        line-height: 30px;
                        text-align: center;
                        font-size: 15px;

                        &:nth-child(1) {
                            width: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
`;

export const CustomDomainPreviewContainer = styled.div`
    &:nth-child(1) {
        box-shadow: -12px 20px 19px #a9a9a95c;
        margin-left: auto;

        > div:last-child {
            width: 100%;
            background-color: #fff;
            height: 23px;
        }
    }

    &:nth-child(2) {
        ${CustomDomainPreview} {
            width: 500px;
        }
    }
`;

export const CustomDomainPreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    ${media.max.tablet`
        top: 0;
        ${({width}) => width && css`
            right: ${(width - 40 - 1000) / 4}px;
            transform: scale(${(width - 40) / 1000});
        `}
    `}

    ${media.max.phone`
        position: static;
        ${({width}) => width && css`
            transform: scale(${(width - 32) / 500});
        `}
    `}
`;

export const ChangeIconWrapper = styled.div`
    position: absolute;
    right: 6px;
    top: 21px;
    z-index: 1;
`;

export const PrivacyContainer = styled.div`

`

export const PrivacyColumn = styled.div`

`

export const PrivacyItem = styled.div`
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
`

export const ItemTitle = styled(Paragraph)`
    font-size: 17px;
`

export const ItemDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    color: #666a73;
`;

export const ItemContent = styled.div`
    display: flex;
    gap: 50px;

    & > div {
        width: 47%;
    }

    ${media.max.tablet`
        > div {
                width: auto;
            }
        }
    `}
`

export const Toggler = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;

    ${media.max.tablet`
        justify-content: flex-end;
    `}
`

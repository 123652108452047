import PropTypes from 'prop-types';
import React, {useCallback, useState, useRef, useEffect} from 'react';
import {useDrop, useDrag} from 'react-dnd';
import {intlShape} from 'react-intl';
import {ALBUM_TYPES, MESSAGE_TYPE, WEBSITE_PAGE_TYPES} from 'packages/enum';
import AlbumUpdateModal from '@configurator/containers/AlbumUpdateModalContainer';
import ModalConfirmDelete from 'packages/components/modalConfirmDelete';

import {getEmptyImage} from 'react-dnd-html5-backend';
import {
    GridIcon,
    BlankIcon,
    TrashIcon, SettingsIcon,
    LockIcon,
} from 'packages/components/icons';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import {dndDropYHelper} from 'packages/utils/dndDropHelper';
import {
    Container,
    Item,
    IconWrapper,
    StructureBlock,
    RightIconsBlock,
    DropZone,
} from './nodes';
    
function pageOnHover(pageId) {
    window.frames['preview-frame']?.postMessage(
        JSON.stringify({
            name: MESSAGE_TYPE.HOVER_ALBUM,
            pageId,
        }),
        '*',
    );
}

const debouncedPageOnHover = debounce(pageOnHover, 100);

const AlbumNodeItem = (
    {
        pageId,
        id,
        name,
        link,
        itemType,
        onDelete,
        onDrop,
        onClick,
        redirectToPage,
        password,
        showHeader,
        visible,
        last,
        draggable,
        deletable,
        customizable,
        albumUrl,
        pageUrl,
    },
    {intl: {formatMessage}},
) => {
    const ref = useRef();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
    const [overPosition, setOverPosition] = useState(0);
    const [justDrag, setJustDrag] = useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dndDropHelperWithThrottle = useCallback(
        throttle((ref, monitor) => setOverPosition(dndDropYHelper(ref, monitor), 16, {trailing: true, leading: false}))
        , [],
    );

    const [{isDragging}, drag, preview] = useDrag({
        item: {type: itemType},
        begin: () => ({type: itemType, id, pageId, name}),
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{isOver}, drop] = useDrop({
        accept: [ALBUM_TYPES.MAIN, ALBUM_TYPES.FOOTER],
        hover(item, monitor) {
            dndDropHelperWithThrottle(ref, monitor);
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
        drop: item => onDrop({
            itemId: item.id,
            type: itemType,
            droppedId: id,
            newPageId: item.pageId !== pageId ? pageId : null,
            sourcePageId: item.pageId,
            sourceType: item.type,
            position: overPosition,
        }),
    });

    const [{isOverUp}, dropUp] = useDrop({
        accept: [ALBUM_TYPES.MAIN, ALBUM_TYPES.FOOTER],
        drop: item => onDrop({
            itemId: item.id,
            type: itemType,
            droppedId: id,
            newPageId: item.pageId !== pageId ? pageId : null,
            sourcePageId: item.pageId,
            sourceType: item.type,
            position: -1,
        }),
        collect: monitor => ({
            isOverUp: !!monitor.isOver(),
        }),
    });

    const [{isOverDown}, dropDown] = useDrop({
        accept: [ALBUM_TYPES.MAIN, ALBUM_TYPES.FOOTER],
        drop: item => onDrop({
            itemId: item.id,
            type: itemType,
            droppedId: id,
            newPageId: item.pageId !== pageId ? pageId : null,
            sourcePageId: item.pageId,
            sourceType: item.type,
            position: 1,
        }),
        collect: monitor => ({
            isOverDown: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, [preview]);

    const handleClick = () => onClick ? onClick({id, name}) : redirectToPage(albumUrl ? `${pageUrl}/${albumUrl}` : link);

    const handleDelete = () => {
        setDeleteDialogVisible(false);
        onDelete({albumId: id});
    };

    // Если убрали со своей позиции, то больше не раскрываем ее
    useEffect(() => {
        if (isDragging && !isOver) {
            setJustDrag(false);
        } else if (!isDragging) {
            setJustDrag(true);
        }
    }, [isDragging, isOver]);

    drag(drop(ref));

    return (
        <>
            <DropZone ref={dropUp} show={!isDragging && (isOver || isOverUp) && overPosition === -1}/>
            <Container
                ref={draggable ? ref : null}
                onMouseOver={() => debouncedPageOnHover(id)}
                onMouseLeave={() => debouncedPageOnHover()}
            >
                <Item
                    isDragging={isDragging}
                    isOver={isOver}
                    justDrag={justDrag}
                    isLocked={password}
                    isShowHeader={showHeader}
                    isVisible={visible}
                >
                    <IconWrapper paddingL="18" paddingR="23">
                        <GridIcon color={draggable ? 'gray' : 'transparent'}/>
                    </IconWrapper>
                    <StructureBlock last={last}/>
                    <IconWrapper paddingR="11">
                        <BlankIcon/>
                    </IconWrapper>
                    <span onClick={handleClick}>
                        {name && name.length ? name : formatMessage({id: 'album.name.noTitle'})}
                    </span>
                    <RightIconsBlock>
                        {deletable && (
                            <span onClick={() => setDeleteDialogVisible(true)}>
                                <TrashIcon/>
                            </span>
                        )}
                        {customizable && (
                            <span onClick={() => setUpdateDialogVisible(true)}>
                                <SettingsIcon/>
                            </span>
                        )}
                        <LockIcon color="gray"/>
                    </RightIconsBlock>
                </Item>
            </Container>
            <DropZone ref={dropDown} show={!isDragging && (isOver || isOverDown) && overPosition === 1}/>
            {deleteDialogVisible && (
                <ModalConfirmDelete
                    open={deleteDialogVisible}
                    headerMessageId="album.delete.header"
                    subMessageId="album.delete.subHeader"
                    onClickYes={handleDelete}
                    onClickCancel={() => setDeleteDialogVisible(false)}
                    onClose={() => setDeleteDialogVisible(false)}
                />
            )}
            {updateDialogVisible && (
                <AlbumUpdateModal
                    open
                    pageUrl={pageId}
                    albumId={id}
                    pageType={WEBSITE_PAGE_TYPES.PHOTO}
                    onClose={() => setUpdateDialogVisible(false)}
                    onDelete={() => setDeleteDialogVisible(true)}
                />
            )}
        </>
    );
};

AlbumNodeItem.propTypes = {
    id: PropTypes.number.isRequired,
    pageId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    itemType: PropTypes.string,
    password: PropTypes.string,
    showHeader: PropTypes.bool,
    visible: PropTypes.bool,
    last: PropTypes.bool,
    draggable: PropTypes.bool,
    deletable: PropTypes.bool,
    customizable: PropTypes.bool,
    albumUrl: PropTypes.string,
    pageUrl:PropTypes.string,

    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    onDrop: PropTypes.func,
    redirectToPage: PropTypes.func,
};

AlbumNodeItem.defaultProps = {
    itemType: ALBUM_TYPES.MAIN,
    password: null,
    showHeader: true,
    visible: true,
    last: false,
    draggable: true,
    deletable: true,
    customizable: true,

    onDelete: () => null,
    onDrop: () => null,
    redirectToPage: () => null,
};

AlbumNodeItem.contextTypes = {
    intl: intlShape,
};

export default AlbumNodeItem;

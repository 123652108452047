import styled from 'styled-components';
import {Modal} from 'packages/components/modal';
import {media} from 'packages/components/media';

export const ModalContainer = styled(Modal)`
    position: initial !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    text-align: left;
    color: #343840;

    .title {
        font-size: 20px;
        line-height: 150%;
        font-weight: bold;
        padding: 0;
        border: none;
    }

    .body {
        color: #FF002E;
        margin-top: 15px;
        font-size: 15px;
        line-height: 21px;
    }

   .formField {
        margin-top: 20px;

        .ui.input {
            width: 100%;
        }
   }

    .footer {
        display: flex;
        margin-top: 20px;

        .button {
            width: 180px;
            height: 45px;
            margin-right: 10px !important;
        }
    }

    ${media.max.phone`
        .footer {
            flex-direction: column;

            .button {
                width: 100%;
            }

            .button + .button {
                margin-top: 10px !important;
            }
        }
    `}
`;

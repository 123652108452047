/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const ImageIcon = ({ color }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H16C17.103 18 18 17.103 18 16V2C18 0.897 17.103 0 16 0ZM2 16V2H16L16.002 16H2Z"
            fill={iconColors[color]}
        />
        <path
            d="M7 11L6 10L3 14H15L10 7L7 11Z"
            fill={iconColors[color]}
        />
        <path
            d="M5.5 8C6.32843 8 7 7.32843 7 6.5C7 5.67157 6.32843 5 5.5 5C4.67157 5 4 5.67157 4 6.5C4 7.32843 4.67157 8 5.5 8Z"
            fill={iconColors[color]}
        />
    </svg>
);

ImageIcon.propTypes = {
    ...iconColorPropTypes,
};

ImageIcon.defaultProps = {
    color: 'white',
};

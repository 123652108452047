import styled from "styled-components";
import { Modal } from "packages/components/modal";
import { media } from "packages/components/media";

export const CenteredDropModal = styled(Modal)`
    margin: auto !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 540px !important;
`;

export const StyledTextareaContainer = styled.div`
    margin-top: 40px;

    textarea {
        min-height: 150px !important;
    }
`;

export const AlignContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;

    ${media.max.phone`
        justify-content: flex-end;
    `}
`;

export const StyledDropdown = styled.div`
    ${(props) => (props.hide ? "display: none;" : "")}
    margin-top: 20px;

    .ui.dropdown {
        width: 100% !important;
    }
`;

export const UploadPicture = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .ui.button {
        ${(props) => (props.hideButton ? "display: none !important;" : "")}
        font-size: 13px;
        height: 45px;
        width: 240px;

        ${media.max.phone`
            width: 190px;
        `}
    }
`;

export const PreviewBlock = styled.div`
    ${(props) => (props.hide ? "display: none;" : "display: flex;")}
    margin-top: 20px;

    > div {
        &:last-child {
            margin-left: 20px;
            font-size: 13px;
            line-height: 140%;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            height: max-content;

            > span {
                color: #666a73;
            }

            > div {
                display: flex;
                align-items: center;

                span {
                    &:first-child {
                        color: #58c367;
                        cursor: pointer;
                    }

                    &:last-child {
                        margin-left: 7px;
                        line-height: 0;
                        cursor: pointer;

                        &:hover svg path {
                            fill: #ff002e;
                        }
                    }
                }
            }

            ${media.max.phone`
                height: inherit;
                flex-direction: column;
                align-items: flex-start;
            `}
        }
    }
`;

export const PreviewContainer = styled.div`
    width: 100%;
    max-width: 150px;
    height: 200px;
`;

export const PreviewWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
        max-width: 100%;
    }
`;

export const UploadWrapper = styled.div`
    position: relative;
    overflow-y: auto;
    height: 100%;

    .input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        color: #666a73;
        font-size: 13px;
        line-height: 140%;
        padding: 15px;

        background: #f6f6f6;
        border: 1px dashed #e1e2e6;
        border-radius: 4px;

        .dropzone-header {
            color: #343840;
            font-size: 24px;
            font-weight: bold;
            line-height: 150%;
            margin: 18px 0 0;
            text-align: center;

            > span {
                color: #666a73;
                font-size: 13px;
                line-height: 140%;
                display: block;
                font-weight: normal;
            }
        }

        .dropzone-footer {
            text-align: center;
            position: absolute;
            bottom: 15px;
        }

        &:focus {
            outline-color: rgba(0, 0, 0, 0) !important;
        }
    }
`;

export const StyledSlugPreview = styled.div`
    margin-top: 10px;
    color: #c1c1c1;
    font-size: 14px;
`;

export const StyledSlug = styled.span`
    color: #343840;
`;

import styled from 'styled-components';

export const Content = styled.div`
    margin-bottom: 40px;
    color: #343840;

    > div:first-child {
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
    }

    .ui.button {
        width: 100%;
        max-width: 120px;
        height: 45px;
        margin: 25px 0 0 10px !important;
    }
`;

export const Field = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    @media (max-width: 375px) {
        flex-direction: column;
        .ui.button {
            max-width: initial;
            margin: 10px 0 0 0 !important;
        }
    }
`;

export const StyledInput = styled.div`
    width: 100%;
    .ui.input {
        width: 100% !important;
    }
`;

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Progress} from 'semantic-ui-react';
import {Modal} from 'packages/elements';
import styles from './modalProgress.less';


class ModalProgress extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        percent: PropTypes.number.isRequired,
        label: PropTypes.string,
    };
    static defaultProps = {
        label: '',
    };
    static contextTypes = {
        intl: PropTypes.object,
    };

    render() {
        const {
            open, percent, label,
        } = this.props;
        const {intl: {formatMessage}} = this.context;
        return (
            <Modal open={open} dimmer="blurring">
                <Modal.Content className={styles.content}>
                    <Modal.Description>
                        {label
                            ? <Progress active indicating percent={percent}>{formatMessage({id: label})}</Progress>
                            : <Progress active indicating percent={percent}/>
                        }
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default ModalProgress;

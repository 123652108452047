import { compose } from "redux";
import { connect } from "react-redux";

import Home from "../components/home/Home";

export default compose(
    connect(
        // mapStateToProps
        (state) => ({
            name: state.getIn(["user", "name"]),
            email: state.getIn(["user", "email"]),
        }),
        (dispatch) => ({})
    )
)(Home);

import React, {Component} from 'react';
import styled from 'styled-components';
import {Semantic} from 'packages/components';

const Container = styled.div`
    > div {
        position: initial !important;
        display: flex !important;
        margin: 50px auto !important;
    }
`;

export default class Loader extends Component {

    render() {
        return (
            <Container>
                <Semantic.Loader active size="tiny"/>
            </Container>
        );
    }
}

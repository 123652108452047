import { compose } from "redux";
import { graphql } from "react-apollo";
import { withFormik } from "formik";
import { Cookies } from "react-cookie";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import billingAccountUpdate from "@graphql/gql/website/billingAccountUpdate.gql";
import billingAccountGet from "@graphql/gql/website/billingAccountGet.gql";

import {COOKIE, PAYMENT_TYPE, PERIOD} from 'packages/enum';

import Address from "@configurator/components/billing/address";

const validationSchema = Yup.object()
    .shape({
        name: Yup.string()
            .required('account.field.error.name')
            .nullable(),
        address1: Yup.string()
            .required('account.field.error.address1')
            .nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
        countryId: Yup.string()
            .required('account.field.error.countryId')
            .nullable(),
        vatId: Yup.string().nullable(),
    });

const checkMarketplaceSettings = (marketplaceSettings) => {
    const marketplaceFields = [
        "name",
        "address1",
        "countryId",
    ];

    return marketplaceFields.every((item) => {
        return !isEmpty((marketplaceSettings[item] || "").toString());
    });
};

export const accountWrapper = (Component) =>
    compose(
        graphql(billingAccountGet, {
            props({ data: { loading, billingAccountGet, refetch } }) {
                if (loading) {
                    return { loading };
                }
                if (isEmpty(billingAccountGet)) {
                    return { loading };
                }
                const { data } = billingAccountGet;

            return {
                data,
                refetch,
            };
        },
    }),
    graphql(billingAccountUpdate, {
        props({mutate}) {
            return {
                async billingAccountUpdateMutation({
                    name,
                    address1,
                    address2,
                    countryId,
                    city,
                    zipCode,
                    vatId,
                }) {
                    try {
                        const data = await mutate({
                            variables: {
                                name,
                                address1,
                                address2,
                                countryId,
                                city,
                                zipCode,
                                vatId,
                            },
                            refetchQueries: [
                                {
                                    query: billingAccountGet,
                                },
                            ],
                        });
                        if (get(data, 'data.billingAccountUpdate.data.token')) {
                            new Cookies().set(
                                COOKIE.token,
                                `bearer ${get(data, 'data.billingAccountUpdate.data.token')}`,
                                {
                                    expires: PERIOD.neverExpires,
                                    path: '/',
                                });
                        }
                        return data;
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({data}) => {
            return {
                ...data,
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
        handleSubmit: async (
            {
                name,
                address1,
                address2,
                countryId,
                city,
                zipCode,
                vatId,
            }, {
                setSubmitting,
                setStatus,
                props: {
                    billingAccountUpdateMutation,
                    refetch,
                    isUpgrade,
                    redirectToPayment,
                    redirectToInvoice,
                    paymentType,
                },
            }) => {
            let defaultError = 'error.desc';
            try {
                const res = await billingAccountUpdateMutation({
                    name,
                    address1,
                    address2,
                    countryId,
                    city,
                    zipCode,
                    vatId,
                });

                    let {
                        data: {
                            billingAccountUpdate: { errors, success } = {},
                        },
                    } = res;
                    if (success) {
                        await refetch();
                        // payment stepper
                        if (isUpgrade) {
                            if (
                                checkMarketplaceSettings({
                                    name,
                                    address1,
                                    countryId,
                                })
                            ) {
                                if (paymentType === PAYMENT_TYPE.INVOICE) {
                                    redirectToInvoice();
                                } else {
                                    redirectToPayment();
                                }
                            } else {
                                setStatus({
                                    error: "accountBilling.error.requiredFields",
                                });
                            }
                        }
                        setSubmitting(false);
                        return;
                    } else {
                        setSubmitting(false);
                    }
                    setStatus({ error: errors._error || defaultError });
                } catch (err) {
                    setSubmitting(false);
                    setStatus({
                        error: __PRODUCTION__ ? defaultError : err.toString(),
                    });
                }
            },
        })
    )(Component);

export default accountWrapper(Address);

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

import {
    Rotate45Icon,
    RotateMinus45Icon,
    SearchPlusIcon,
    SearchMinusIcon,
    MoveHorizontalIcon,
    MoveVerticalIcon,
} from "packages/components/icons/basic";
import {
    Container,
    Settings,
    SettingsGroup,
    SettingsItem,
    SettingsDivider,
    Buttons,
    Button,
    Img,
} from "./nodes";

const CropperComp = ({ src, onSave, intl: { formatMessage } }) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const cropperRef = useRef();
    const imgRef = useRef();
    const resetCropBox = () =>
        cropperRef.current.setCropBoxData({ left: 0, top: 0 });

    useEffect(() => {
        cropperRef.current = new Cropper(imgRef.current, {
            viewMode: 2,
            responsive: true,
            checkCrossOrigin: false,
            ready: () => resetCropBox(),
        });
    }, [src]);

    const rotate = (v) => cropperRef.current.rotate(v);

    const zoom = (v) => cropperRef.current.zoom(v);

    const toggleScale = (x, y) => {
        const { scaleX, scaleY } = cropperRef.current.getData();
        cropperRef.current.scale(x * scaleX, y * scaleY);
    };

    const disable = () => {
        cropperRef.current.disable();
        setIsDisabled(true);
    };

    const enable = () => {
        cropperRef.current.enable();
        setIsDisabled(false);
    };

    const cancel = () => {
        enable();
        cropperRef.current.reset();
        resetCropBox();
    };

    const save = () => {
        // Upload cropped image to server if the browser supports `HTMLCanvasElement.toBlob`.
        // The default value for the second parameter of `toBlob` is 'image/png', change it if necessary.
        cropperRef.current.getCroppedCanvas().toBlob(
            (blob) => {
                onSave(new File([blob], "name.png"));
            } /*, 'image/png' */
        );
    };

    return (
        <Container>
            <Settings>
                <SettingsGroup>
                    <SettingsItem
                        isDisabled={isDisabled}
                        onClick={() => rotate(-45)}
                    >
                        <RotateMinus45Icon />
                    </SettingsItem>
                    <SettingsItem
                        isDisabled={isDisabled}
                        onClick={() => rotate(45)}
                    >
                        <Rotate45Icon />
                    </SettingsItem>
                    <SettingsDivider />
                    <SettingsItem
                        isDisabled={isDisabled}
                        onClick={() => zoom(0.1)}
                    >
                        <SearchPlusIcon />
                    </SettingsItem>
                    <SettingsItem
                        isDisabled={isDisabled}
                        onClick={() => zoom(-0.1)}
                    >
                        <SearchMinusIcon />
                    </SettingsItem>
                    <SettingsDivider />
                    <SettingsItem
                        isDisabled={isDisabled}
                        onClick={() => toggleScale(-1, 1)}
                    >
                        <MoveHorizontalIcon />
                    </SettingsItem>
                    <SettingsItem
                        isDisabled={isDisabled}
                        onClick={() => toggleScale(1, -1)}
                    >
                        <MoveVerticalIcon />
                    </SettingsItem>
                    <SettingsDivider />
                    <SettingsItem isActive={!isDisabled} onClick={enable}>
                        {formatMessage({ id: "cropper.settings.free" })}
                    </SettingsItem>
                    <SettingsItem isActive={isDisabled} onClick={disable}>
                        {formatMessage({ id: "cropper.settings.fixed" })}
                    </SettingsItem>
                </SettingsGroup>
                <Buttons>
                    <Button view="secondaryWhite" onClick={cancel}>
                        {formatMessage({ id: "cropper.button.cancel" })}
                    </Button>
                    <Button onClick={save}>
                        {formatMessage({ id: "cropper.button.save" })}
                    </Button>
                </Buttons>
            </Settings>
            <Img>
                <img crossOrigin="anonymous" ref={imgRef} src={src} alt={"vsble"} />
            </Img>
        </Container>
    );
};

CropperComp.propTypes = {
    intl: intlShape.isRequired,
    src: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
};

CropperComp.defaultProps = {};

export default injectIntl(CropperComp);

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

// в макете называется edit2
export const AlignLeftIcon = ({ color }) => (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 9.66634H0V8.33301H8V9.66634ZM12 6.99967H0V5.66634H12V6.99967ZM8 4.33301H0V2.99967H8V4.33301ZM12 1.66634H0V0.333008H12V1.66634Z"
            fill={iconColors[color]}
        />
    </svg>
);

AlignLeftIcon.propTypes = {
    ...iconColorPropTypes,
};

AlignLeftIcon.defaultProps = {
    color: 'dark',
};

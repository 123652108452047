import styled from 'styled-components';

export const Header = styled.div`
    width: 100%;
    height: 100%;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 30px;
    text-align: center;
`;

export const Content = styled.div`
    justify-content: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > span {
        margin-bottom: 25px;
        text-align: center;
        line-height: 1.7em;
    }
`;

export const ButtonRow = styled.div`
    display: flex;

    > button {
        width: 140px;
        max-height: 45px;
    }

    .button + .button {
        margin-left: 25px !important;
    }
`;

import {compose} from 'redux';
import {graphql} from 'react-apollo';
import {withFormik} from 'formik';
import * as Yup from 'yup';

import {MESSAGE_TYPE} from 'packages/enum';

import websiteAlbumCreate from '@graphql/gql/website/websiteAlbumCreate.gql';
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql';
import previewPageGet from '@graphql/gql/website/previewPageGet.gql';

import AlbumCreate from '@configurator/components/modals/albumCreate';
import { USERLIST_SERVICE } from "packages/userlist/Userlist";

const validationSchema = Yup.object()
    .shape({
        title: Yup.string()
            .required('album.field.error.name'),
        description: Yup.string(),
    });

export const albumCreateWrapper = Component => compose(
    graphql(websiteAlbumCreate, {
        props({mutate}) {
            return {
                async createAlbum({
                    title,
                    pageId,
                    description,
                    visible,
                    showHeader,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                title,
                                pageId,
                                description,
                                visible,
                                showHeader,
                            },
                            refetchQueries: [
                                {
                                    query: websitePagesGet,
                                },
                                {
                                    query: previewPageGet,
                                },
                            ],
                            awaitRefetchQueries: true,
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: () => ({
            title: '',
            description: '',
            visible: true,
        }),
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
        handleSubmit: async (
            {
                title,
                description,
                visible,
                hideHeader,
            }, {
                setSubmitting,
                setStatus,
                props: {
                    pageId,
                    createAlbum,
                    onClose,
                },
            }) => {

            let defaultError = 'page.create.error.header';
            try {
                const res = await createAlbum({
                    title,
                    description,
                    pageId,
                    visible,
                    showHeader: !hideHeader,
                });
                setSubmitting(false);
                let {
                    data: {
                        websiteAlbumCreate: {errors, success} = {},
                    },
                } = res;
                if (success) {
                    USERLIST_SERVICE.trackAlbumCreated();

                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_PAGE,
                        }),
                        '*'
                    );
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_META,
                        }),
                        '*'
                    );
                    return onClose();
                }
                setStatus({error: errors._error || defaultError});
            } catch (err) {
                setSubmitting(false);
                setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
            }
        },
    }),
)(Component);

export default albumCreateWrapper(AlbumCreate);

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { metaSelector } from "packages/selectors/Website";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { URLS } from "packages/enum";
import { ShortLeftIcon } from "packages/components/icons";
import PermissionsOverlay from "packages/components/permissionsOverlay/permissionsOverlay";
import {
    DevicesBlock,
    LeftBlock,
    ReturnArrowContainer,
    RightBlock,
    UserBlock,
    WhatsNewBlock,
} from "./nodes";
import mainLogo from "./img/logo-light.svg";
import { useSubscription } from "@configurator/providers/subscription";
import {
    BarChartButton,
    ExternalLinkButton,
    MobileButton,
    PdfButton,
    TabletButton,
    WindowButton,
} from "../buttons";
import { UserDropdown } from "./UserDropdown";
import { useIsMobile } from "../../helpers/useWindowSize";

const viewTypes = {
    desktop: "desktop",
    tablet: "tablet",
    mobile: "mobile",
};

export function Header({
    className,
    viewType,
    onTypeClick,
    pageUrl,
    onPdfToolsClick,
    onStatisticsClick,
    backArrow,
    isAlbumExpanded,
}) {
    const { permissions } = useSubscription();
    const meta = useSelector(metaSelector);
    const previewHref = useMemo(
        () => `https://${meta.domain}.${__DOMAIN__}${pageUrl}`,
        [meta.domain, pageUrl]
    );
    const isMobile = useIsMobile();
    const location = useLocation();

    const isIntegrationItemPage =
        location.pathname.includes(URLS.website_integrations) &&
        location.pathname !== URLS.website_integrations;

    return (
        <div className={className}>
            <LeftBlock>
                <Link to={URLS.website_config}>
                    <img id="vsble-logo" src={mainLogo} alt="" />
                </Link>
                {backArrow && (
                    <Link
                        to={
                            isIntegrationItemPage
                                ? URLS.website_integrations
                                : URLS.website_config
                        }
                    >
                        <ReturnArrowContainer>
                            <ShortLeftIcon />
                            <FormattedMessage
                                id={
                                    isIntegrationItemPage
                                        ? "header.arrowBackToIntegrations.text"
                                        : "header.arrowBack.text"
                                }
                            />
                        </ReturnArrowContainer>
                    </Link>
                )}
            </LeftBlock>
            <RightBlock>
                {onTypeClick && viewTypes && !isAlbumExpanded && (
                    <DevicesBlock>
                        <WindowButton
                            active={viewType === viewTypes.desktop}
                            onClick={() => onTypeClick(viewTypes.desktop)}
                            color="dark"
                        />
                        {!isMobile && (
                            <TabletButton
                                active={viewType === viewTypes.tablet}
                                onClick={() => onTypeClick(viewTypes.tablet)}
                                color="dark"
                            />
                        )}
                        <MobileButton
                            active={viewType === viewTypes.mobile}
                            onClick={() => onTypeClick(viewTypes.mobile)}
                            color="dark"
                        />
                        <a href={previewHref} target="_blank" rel="noreferrer">
                            <ExternalLinkButton color="dark" />
                        </a>
                    </DevicesBlock>
                )}
                <UserBlock>
                    <WhatsNewBlock id={"whats-new"}>
                        <FormattedMessage id="header.whats.new" />
                    </WhatsNewBlock>
                    {do {
                        if (onPdfToolsClick) {
                            <>
                                <PermissionsOverlay isAllowed={permissions.PDF}>
                                    <PdfButton
                                        onClick={onPdfToolsClick}
                                        color="dark"
                                    />
                                </PermissionsOverlay>
                                <PermissionsOverlay
                                    isAllowed={permissions.STATISTICS}
                                >
                                    <BarChartButton
                                        onClick={onStatisticsClick}
                                        color="dark"
                                    />
                                </PermissionsOverlay>
                            </>;
                        }
                    }}
                    <UserDropdown />
                </UserBlock>
            </RightBlock>
        </div>
    );
}

Header.propTypes = {
    className: PropTypes.string.isRequired,
    viewType: PropTypes.oneOf([
        viewTypes.desktop,
        viewTypes.mobile,
        viewTypes.tablet,
    ]),
    onTypeClick: PropTypes.func,
    onPdfToolsClick: PropTypes.func,
    onStatisticsClick: PropTypes.func,
    pageUrl: PropTypes.string,
    backArrow: PropTypes.bool,
    isAlbumExpanded: PropTypes.bool,
};

Header.defaultProps = {
    pageUrl: "",
    onTypeClick: undefined,
    onPdfToolsClick: undefined,
    onStatisticsClick: undefined,
    viewType: undefined,
    backArrow: false,
    isAlbumExpanded: false,
};

import styled from "styled-components";
import get from "lodash/get";

export const Header = styled.div`
    width: 100%;
    height: 100%;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 30px;
    text-align: center;
`;

export const Content = styled.div`
    justify-content: center;
    display: flex !important;
    flex-direction: column;
    width: 100%;

    > span {
        margin-bottom: 20px;
        line-height: 140%;
        font-size: 13px;
        color: #666a73;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    margin-top: 10px;

    > button {
        width: 95px;
        max-height: 35px;
    }
`;

// copied from /src/packages/components/modal/nodes.js for delete Semantic modal dimmer
export const Modal = styled.div`
    position: absolute;
    left: 110px;
    top: 32px;
    will-change: initial !important;
    border-radius: 6px !important;
    width: 320px !important;
    height: ${(props) =>
        get(props, "styles.height")
            ? `${props.styles.height}`
            : "max-content"} !important;
    padding: 20px;
    max-width: 92vw !important;
    margin: ${(props) => (props.mobileWide ? 0 : "1em")} !important;
    color: #343840;
    display: flex !important;
    flex-direction: column;

    .dimmer {
        opacity: 0;
    }

    &.ui.active.modal.visible.transition {
        display: flex !important;
        flex-direction: column;
    }

    > .close {
        display: none;
    }

    > .closeIcon {
        cursor: pointer;
        position: fixed;
        top: 25px;
        right: 25px;
        height: 24px;
        padding: 5px;

        > svg path {
            fill: #ededed;
        }
    }

    @media (max-width: 480px) {
        padding: 30px 20px !important;
        min-width: ${(props) =>
            props.mobileWide ? "100vw" : "initial"} !important;
        min-height: ${(props) =>
            props.mobileWide ? "100%" : "initial"} !important;
        max-width: ${(props) =>
            props.mobileWide ? "100vw" : "92vw"} !important;
        border-radius: ${(props) => (props.mobileWide ? 0 : 6)}px !important;
        height: min-content !important;
        .closeIcon {
            position: ${(props) => (props.mobileWide ? "absolute" : "fixed")};
            > svg path {
                top: 20px;
                right: 20px;
                fill: ${(props) => (props.mobileWide ? "#666A73" : "#EDEDED")};
            }
        }
    }
`;

export const FocusedImagesContainer = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    transition: background-color 0.5s linear;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(2px);
    z-index: 2;
`;

export const GridImages = styled.div`
    display: grid;
    grid-template-columns: 6fr 3fr 2fr;
    grid-template-rows: 6fr 3fr 2fr;
    grid-gap: 5px;

    position: absolute;
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;

    /* needed for safari */
    height: calc(100% - 160px);
`;

export const FocusedImageNode = styled.img`
    transition: top 0.25s ease-in-out, left 0.25s ease-in-out;
`;

export const CloseIconNode = styled.div`
    top: 20px;
    right: 20px;
    position: absolute;
    z-index: 100;

    svg {
        width: 15px;
        height: 15px;
    }
`;

import {handleActions} from 'redux-actions';
import {changeTemplatesColor} from "./actions";

const defaultState = 'black';

export default handleActions({
    [changeTemplatesColor]: {
        next(state, {payload}) {
            return payload;
        },
    },
}, defaultState);

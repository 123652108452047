import styled from 'styled-components';
import {Button} from 'packages/components/buttons';
import {media} from 'packages/components/media';

export const Header = styled.div`
    color: #242426;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    margin-bottom: 10px;
`;

export const Container = styled.div`
    display: flex;

    .ui.input {
        width: 195px !important;

        > input {
            ${props => props.iconInput ? 'padding-right: 40px !important;' : ''}
        }
    }

    > .ui.button {
        height: 45px;
        width: 90px;
        margin-left: 10px !important;
        background-color: #242426 !important;
    }
`;

export const SubmitButton = styled(Button)`
    ${media.max.phone`
        width: 100% !important;
    `}
`;

// Проверяет нужно ли вызывать событие, в зависимости от позиции компонентов
export function dndDropHelper(hoverComponentRef, monitor, dragIndex, hoverIndex, cb, ignorePosition = false) {
    if (!hoverComponentRef) {
        return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = hoverComponentRef.current?.getBoundingClientRect();
    // Get vertical middle
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // Если мы перетаскиваем слева направо и вверх, но недостаточно
    if (!ignorePosition && dragIndex < hoverIndex
        // и перетащили пока еще меньше, чем на половину правого итема
        && (hoverClientX < hoverMiddleX && hoverClientY < hoverMiddleY)) {
        console.log('слева направо');
        return;
    }
    // Если мы перетаскиваем справа налево и вверх, но недостаточно
    if (!ignorePosition && dragIndex > hoverIndex && (hoverClientX > hoverMiddleX && hoverClientY > hoverMiddleY)) {
        console.log('слева направо 2');
        return;
    }

    cb(hoverClientX < hoverMiddleX, hoverClientY > hoverMiddleY);
}


// Смотрим выше или ниже мы на плитке, что бы понять с какой стороны дропнуть
export function dndDropYHelper(hoverComponentRef, monitor) {
    if (!hoverComponentRef) {
        return;
    }
    const hoverBoundingRect = hoverComponentRef.current?.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    if (!hoverBoundingRect || !clientOffset) {
        return;
    }
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    return hoverClientY < hoverMiddleY ? -1 : 1;
}


export const getItemStyles = (initialOffset, currentOffset) => {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let {x, y} = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};

import PropTypes from 'prop-types';
import React, {useState, useCallback, useEffect} from 'react';
import {Formik} from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import get from 'lodash/get';
import isString from 'lodash/isString';
import fromPairs from 'lodash/fromPairs';
import {STYLE_TYPES, FEATURE_PROPERTY_KEYS, STYLE_FEATURE_PROPERTIES, MESSAGE_TYPE} from 'packages/enum';
import {Semantic} from "packages/components";
import ChangeTemplateModal from "@configurator/components/modals/changeTemplateModal";
import {
    Container,
    StyleItem,
    ResetButton,
    StylesTemplatesColorToggle,
    StylesTitle,
} from '@configurator/components/pages/pageStyles/components';
import {TabPropTypes} from 'packages/components/tabs';
import throttle from 'lodash/throttle'

import StyleForm from './styleForm';
import {LoaderContainer} from './nodes';
import {getLoaderShortProperty, getPropertyMediaUrl} from 'packages/helpers/Helper';

const stylesList = [
    {
        type: STYLE_TYPES.header,
        title: 'styles.title.header',
    },
    {
        type: STYLE_TYPES.navigation,
        title: 'styles.title.navigation',
    },
    {
        type: STYLE_TYPES.footer,
        title: 'styles.title.footer',
    },
    {
        type: STYLE_TYPES.album,
        title: 'styles.title.albumPage',
    },
    {
        type: STYLE_TYPES.contacts,
        title: 'styles.title.contacts',
    },
    {
        type: STYLE_TYPES.text,
        title: 'styles.title.textPage',
        formTitle: 'styles.title.textPage.text',
    },
    {
        type: STYLE_TYPES.burgerColor,
        title: 'styles.title.burger',
    },
    {
        type: STYLE_TYPES.hoverColor,
        title: 'styles.title.hover',
    },
    {
        type: STYLE_TYPES.pageBackground,
        title: 'styles.title.backgroundColor',
    },
    {
        type: STYLE_TYPES.loaderAnimation,
        title: 'styles.title.loaderAnimation',
    },
];

const boolProperties = [
  STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_YEAR,
  STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_DOMAIN_TITLE,
  STYLE_FEATURE_PROPERTIES.FOOTER_OVER_SLIDESHOW,
];

const PageStyle = ({
    featureData = {},
    loaderData = [],
    featureUpdate,
    featureUpdateWithPhoto,
    openErrorModal,
    closeErrorModal,
    // history,
    refetchPagePreview,
    updateFeatureProperties,
    featureResetTemplate,
    featureResetFeaturesProp,
    websiteId,
    domain,
    loading,
    loaderStart,
    loaderStop,
    templatesColor,
    changeTemplatesColor,
    updateTemplate,
    templateParams,
    footerOverSlideshow,
    scrollableHeader,
    tab: {setBackArrowState, clearBackArrowState},
    intl: {formatMessage},
}) => {
    const [selectedStyle, setSelectedStyle] = useState(null);
    const [newTemplatesColor, setNewTemplatesColor] = useState(templatesColor);
    const [isChangeTemplateDialogOpen, setIsChangeTemplateDialogOpen] = useState(false);
    const [isScrollableHeader, setIsScrollableHeader]=useState(false);
    const [isFooterOverSlideshowEnabled, setIsFooterOverSlideshowEnabled] = useState(false)

    useEffect(()=>{
        if(footerOverSlideshow) {
            setIsFooterOverSlideshowEnabled(footerOverSlideshow.enabled);
            styles.FOOTER_OVER_SLIDESHOW = footerOverSlideshow.enabled;
        };
    }, [footerOverSlideshow])


    const styles = {...fromPairs(get(featureData, 'properties', []).map(({key, value}) => {
        if(boolProperties.includes(key)){
            value = isString(value) ? value === "true" : !!value;
        }
        return ([key, value]);
    })), SCROLLABLE_HEADER: scrollableHeader?.enabled }


    useEffect(()=>{
        if(scrollableHeader) {
            setIsScrollableHeader(scrollableHeader.enabled);
        };
    }, [scrollableHeader])

    const loaderStyles = loaderData.map((loaderField) =>
        fromPairs(get(loaderField, 'properties', [])
            .filter( item =>
            {
                if (loaderField.featureName === STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM) {
                    return (item.key === "customMediaUrl") && item.value !== null
                }
                return item;
            })
            .map(({value}) => ([loaderField.featureName, value]))))
            .reduce((acc,item) =>  ({...acc,...item}),{});

    const handleChangeTemplatesColor = (value) => {
        if (value === templatesColor) return;
        setNewTemplatesColor(value);
        setIsChangeTemplateDialogOpen(true);
    };

    const handleTemplateUpdateConfirm = async () => {
        const { success } = await updateTemplate({
            ...templateParams,
            inverted: newTemplatesColor === 'black',
        });
        success && changeTemplatesColor(newTemplatesColor);
        setIsChangeTemplateDialogOpen(false);
    };

    const handleTemplateUpdateCancel = () => {
        setNewTemplatesColor(templatesColor);
        setIsChangeTemplateDialogOpen(false);
    };

    const handleSubmitForm = async ({FOOTER_OVER_SLIDESHOW, ...values}, {setSubmitting}) => {
        let defaultError = 'fonts.update.error.text';
        loaderStart();
        let res;
        try {
            if (selectedStyle.type === STYLE_TYPES.loaderAnimation) {
                const result = (await Promise.all(loaderData.map(async ({featureName, id}) => {
                    const fieldName = getLoaderShortProperty(featureName);
                    if (featureName === STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM) {
                        if (values[STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM] instanceof File) {
                            const res = await featureUpdateWithPhoto({
                                featureId: id,
                                file: values[STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM],
                                propertyName: fieldName,
                            });
                            delete values[STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM];
                            delete values[getPropertyMediaUrl(STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM)];
                            return res;
                        }
                        else {
                            return await featureUpdate({
                                featureId: id,
                                properties: {
                                    [getPropertyMediaUrl(fieldName)]: values[featureName] ?? null,
                                },
                            });

                        }
                    }
                     else {
                        return await featureUpdate({
                            featureId: id,
                            properties: {
                                [fieldName]: values[featureName] ?? null,
                            },
                        });
                    }
                })));
                const responseArray = result.filter( response => response !== undefined ).map( response => {
                    const [featureUpdate] = Object.keys(response.data);
                    return response.data[featureUpdate];
                })
                const hasErrors = responseArray.some(response => response.errors);
                const allSuccessed = responseArray.every(response => response.success);
                if(hasErrors) {
                    res = { data: { featureUpdate: { errors: null, success: false} } };
                } else if(allSuccessed) {
                    res = { data: { featureUpdate: { errors: null, success: true} } };
                }
            } else {
                if(!!footerOverSlideshow && selectedStyle.type === STYLE_TYPES.footer) {
                    await featureUpdate({
                        featureId: footerOverSlideshow.id,
                        properties: {},
                        enabled: isFooterOverSlideshowEnabled,
                    });
                }
                if(selectedStyle.type === STYLE_TYPES.navigation && !!scrollableHeader){
                   await featureUpdate({
                        featureId: scrollableHeader.id,
                        properties:{},
                        enabled: isScrollableHeader,
                    });
                }
                    res = await featureUpdate({
                        featureId: get(featureData, 'id'),
                        properties: values,
                     });
            }

            let {
                data: {
                    featureUpdate: { errors, success } = {},
                },
            } = res;
            if (!success) {
                setSubmitting(false);
                loaderStop();
                return openErrorModal({
                    headerMessageId: 'error.header',
                    subMessageId: errors?._error || defaultError,
                    hideCancelButton: true,
                    yesMessageId: 'upload.error.ok',
                    styles: {width: '450px'},
                });
            } else {
                window.frames['preview-frame']?.postMessage(
                    JSON.stringify({
                        name: 'update_features',
                    }),
                    '*'
                );
                refetchPagePreview().then(loaderStop);
            }
        } catch (err) {
            setSubmitting(false);
            loaderStop();
            return openErrorModal({
                headerMessageId: 'error.header',
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                hideCancelButton: true,
                yesMessageId: 'upload.error.ok',
                styles: {width: '450px'},
            });
        }
    };

    const handleResetStyles = () => {
        let defaultError = 'fonts.update.error.text';
        loaderStart();
        closeErrorModal();
        featureResetTemplate({
            websiteId,
        }).then(res => {
            let {
                data: {
                    featureResetTemplate: { errors, success } = {},
                },
            } = res;
            if (!success) {
                loaderStop();
                return openErrorModal({
                    headerMessageId: 'error.header',
                    subMessageId: errors._error || defaultError,
                    hideCancelButton: true,
                    yesMessageId: 'upload.error.ok',
                    styles: {width: '450px'},
                });
            } else {
                refetchPagePreview().then(loaderStop);
                window.frames['preview-frame']?.postMessage(
                    JSON.stringify({
                        name: 'update_features',
                    }),
                    '*'
                );
            }
        }).catch(err => {
            openErrorModal({
                headerMessageId: 'error.header',
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                hideCancelButton: true,
                yesMessageId: 'upload.error.ok',
                styles: {width: '450px'},
            });
            loaderStop();
        });
    };

    const handleResetFeatureProperties = ({featurePropertyNames}) => {
        let defaultError = 'fonts.update.error.text';
        loaderStart();
        closeErrorModal();
        featureResetFeaturesProp({
            featurePropertyNames,
            featureId: get(featureData, 'id'),
            domain,
        }).then(res => {
            let {
                data: {
                    featureResetFeaturesProp: { errors, success } = {},
                },
            } = res;
            if (!success) {
                loaderStop();
                return openErrorModal({
                    headerMessageId: 'error.header',
                    subMessageId: errors._error || defaultError,
                    hideCancelButton: true,
                    yesMessageId: 'upload.error.ok',
                    styles: {width: '450px'},
                });
            } else {
                window.frames['preview-frame']?.postMessage(
                    JSON.stringify({
                        name: 'update_features',
                    }),
                    '*'
                );
                refetchPagePreview().then(loaderStop);
            }
        }).catch(err => {
            openErrorModal({
                headerMessageId: 'error.header',
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                hideCancelButton: true,
                yesMessageId: 'upload.error.ok',
                styles: {width: '450px'},
            });
            loaderStop();
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUpdateFeatureProp = useCallback(throttle(({key, values}) => {
        const {FOOTER_OVER_SLIDESHOW, SCROLLABLE_HEADER, ...styleValues} = values;
        if(FOOTER_OVER_SLIDESHOW !== null && FOOTER_OVER_SLIDESHOW !==undefined && selectedStyle?.type === STYLE_TYPES.footer) {
            window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                    name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
                    key: FEATURE_PROPERTY_KEYS.FOOTER_OVER_SLIDESHOW,
                    value: isFooterOverSlideshowEnabled
                }),
                '*'
            );
        }

        if (SCROLLABLE_HEADER !== null || SCROLLABLE_HEADER !== undefined) {
            window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                    name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
                    key:FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER,
                    value: isScrollableHeader
                }),
                '*'
            );
        }

        window.frames['preview-frame']?.postMessage(
            JSON.stringify({
                name: 'update_feature',
                key,
                values: styleValues,
            }),
            '*'
        );
        updateFeatureProperties({
            key: FEATURE_PROPERTY_KEYS.style,
            values: styleValues,
        });
    },32), [updateFeatureProperties, selectedStyle?.type, isFooterOverSlideshowEnabled, isScrollableHeader]);

    const handleToggleScrolableHeader = () => {
        setIsScrollableHeader(!isScrollableHeader)
    }

    const handleDiscard = useCallback(() => {
        if(!!footerOverSlideshow && selectedStyle.type === STYLE_TYPES.footer) {
            setIsFooterOverSlideshowEnabled(footerOverSlideshow.enabled);
            window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                    name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
                    key: FEATURE_PROPERTY_KEYS.FOOTER_OVER_SLIDESHOW,
                    value: footerOverSlideshow.enabled
                }),
                '*'
            );
        }
        if(!!scrollableHeader && selectedStyle.type === STYLE_TYPES.navigation) {
            setIsScrollableHeader(scrollableHeader.enabled);
            window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                    name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
                    key: FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER,
                    value: scrollableHeader.enabled
                }),
                '*'
            );
        }
    },[footerOverSlideshow, selectedStyle?.type, scrollableHeader])

    const handleToggleFooterOverSlideshow = () => {
        setIsFooterOverSlideshowEnabled(!isFooterOverSlideshowEnabled)
    }

    return (
        <Container>
            {loading && (
                <LoaderContainer>
                    <Semantic.Loader active size="large" />
                </LoaderContainer>
            )}
            {!selectedStyle && (
                <>
                    <StylesTemplatesColorToggle
                        value={templatesColor}
                        onChange={handleChangeTemplatesColor}
                    />
                    <StylesTitle>{formatMessage({id: 'styles.title.visiblepro'})}</StylesTitle>
                    {stylesList.map(({type, title, ...rest}) => (
                        <StyleItem
                            key={type}
                            onClick={() => {
                                if (selectedStyle && selectedStyle.type === type) {
                                    setSelectedStyle(null);
                                    clearBackArrowState();
                                } else {
                                    setSelectedStyle({type, title, ...rest});
                                }
                            }}
                        >
                            {formatMessage({id: title})}
                        </StyleItem>
                    ))}
                    <ResetButton
                        view="secondaryGray"
                        content={formatMessage({id: 'styles.button.resetAllSettingsToDefault'})}
                        color="black"
                        onClick={() => {
                            return openErrorModal({
                                onClickYes: handleResetStyles,
                                headerMessageId: 'styles.reset.header',
                                subMessageId: 'styles.reset.subHeader',
                                yesMessageId: 'modalYesNo.yes',
                            });
                        }}
                    />
                </>
            )}
            {selectedStyle && (
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    validate={() => ({})}
                    initialValues={selectedStyle.type === STYLE_TYPES.loaderAnimation ? loaderStyles : styles}
                    onSubmit={handleSubmitForm}
                    render={(props) => {
                        console.log(props)
                        return (
                            <StyleForm
                                templateName={templateParams?.template}
                                handleToggleFooterOverSlideshow={handleToggleFooterOverSlideshow}
                                handleDiscard={handleDiscard}
                                footerOverSlideshow={footerOverSlideshow}
                                isFooterOverSlideshowEnabled={isFooterOverSlideshowEnabled}
                                slideshowEnabled={templateParams?.slideshowEnabled}
                                toggleScollableHeader={handleToggleScrolableHeader}
                                scrollableHeader={!!scrollableHeader ? {...scrollableHeader,enabled: isScrollableHeader} : null}
                                selectedStyle={selectedStyle}
                                setSelectedStyle={setSelectedStyle}
                                initialValues={selectedStyle.type === STYLE_TYPES.loaderAnimation ? loaderStyles : styles}
                                updateFeatureState={handleUpdateFeatureProp}
                                resetFeaturesProp={({ featurePropertyNames }) => openErrorModal({
                                    onClickYes: () => handleResetFeatureProperties({ featurePropertyNames }),
                                    headerMessageId: 'styles.reset.header',
                                    subMessageId: 'styles.reset.subHeader',
                                    yesMessageId: 'modalYesNo.yes',
                                })}
                                openErrorModal={openErrorModal}
                                closeErrorModal={closeErrorModal}
                                clearBackArrowState={clearBackArrowState}
                                setBackArrowState={setBackArrowState}
                                {...props}
                            />
                        );
                    }}
                />
            )}
            {isChangeTemplateDialogOpen && (
                <ChangeTemplateModal
                    open
                    onClickYes={handleTemplateUpdateConfirm}
                    onClose={handleTemplateUpdateCancel}
                />
            )}
        </Container>
    );
};

PageStyle.propTypes = {
    intl: intlShape.isRequired,
    websiteId: PropTypes.number.isRequired,
    domain: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    featureData: PropTypes.object.isRequired,
    featureUpdate: PropTypes.func.isRequired,
    featureUpdateWithPhoto: PropTypes.func.isRequired,
    openErrorModal: PropTypes.func.isRequired,
    closeErrorModal: PropTypes.func.isRequired,
    updateFeatureProperties: PropTypes.func.isRequired,
    featureResetTemplate: PropTypes.func.isRequired,
    featureResetFeaturesProp: PropTypes.func.isRequired,
    loaderStart: PropTypes.func.isRequired,
    loaderStop: PropTypes.func.isRequired,
    refetchPagePreview: PropTypes.func,
    loading: PropTypes.bool,
    footerOverSlideshow: PropTypes.shape(),
    scrollableHeader:PropTypes.shape(),
    ...TabPropTypes,
};

PageStyle.defaultProps = {
    loading: false,
    refetchPagePreview: () => null,
};
PageStyle.contextTypes = {
    intl: PropTypes.object.isRequired,
    refetchPagePreview: () => null,
};
export default injectIntl(PageStyle);

import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import moment from 'moment';
import { DATE_FORMAT, PERIOD_OPTIONS } from '@configurator/constants/statistics';
import {applyFilter} from './actions.js';

const defaultState = Map({
    idSite: null,
    date: moment().format(DATE_FORMAT),
    period: PERIOD_OPTIONS.week,
    useDemo: false,
});

export default handleActions({
    [applyFilter]: {
        next(state, { payload }) {
            return state.merge(payload);
        },
    },
}, defaultState);

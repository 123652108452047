import React from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';

import {ClockIcon} from 'packages/components/icons';

import TotalInfo from './TotalInfo';
import {AverageTimeContainer} from './nodes';

const toHHMMSS = function (secNumber) {
    let hours = Math.floor(secNumber / 3600);
    let minutes = Math.floor((secNumber - (hours * 3600)) / 60);
    let seconds = secNumber - (hours * 3600) - (minutes * 60);

    if (hours < 10 && hours !== 0) {
        hours = `0${hours}`;
    }
    if (minutes < 10 && minutes !== 0) {
        minutes = `0${minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    if (hours) {
        return `${hours}hrs ${minutes}min ${seconds}s`;
    }
    if (minutes) {
        return `${minutes}min ${seconds}s`;
    }
    return `${seconds}s`;
};

const AverageTimeOnSite = ({count, loading}, {intl: {formatMessage, formatNumber}}) => (
    <AverageTimeContainer>
        <TotalInfo
            icon={<ClockIcon color="white"/>}
            count={toHHMMSS(count)}
            text={formatMessage({id: 'statistics.total.averageTime'})}
            loading={loading}
        />
    </AverageTimeContainer>
);

AverageTimeOnSite.propTypes = {
    count: PropTypes.number,
    loading: PropTypes.bool,
};

AverageTimeOnSite.defaultProps = {
    count: 0,
    loading: false,
};

AverageTimeOnSite.contextTypes = {
    intl: intlShape,
};

export default AverageTimeOnSite;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {iconColors, iconColorPropTypes} from '../const';

export const TrashIcon = ({ color, showBg, onClick }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        {showBg && <circle className="iconBg" cx="16" cy="16" r="16" fill="white"/>}
        <path
            d="M18 7H14C12.897 7 12 7.897 12 9V10H8V12H10V23C10 24.103 10.897 25 12 25H20C21.103 25 22 24.103 22 23V12H24V10H20V9C20 7.897 19.103 7 18 7ZM14 9H18V10H14V9ZM20 23H12V12H20V23Z"
            fill={iconColors[color]}
        />
    </svg>
);

TrashIcon.propTypes = {
    ...iconColorPropTypes,
    showBg: PropTypes.bool,
    onClick: PropTypes.func,
};

TrashIcon.defaultProps = {
    color: 'dark',
    showBg: false,
    onClick: () => null
};

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Progress} from 'semantic-ui-react';
import {useInterval} from 'packages/helpers/useInterval';

import {
    WaitingScreenContainer,
    WaitingScreenContent,
    WaitingScreenDescription,
    WaitingScreenHeader,
    WaitingScreenProgress,
} from './nodes';

const WaitingScreen = ({formatMessage}) => {
    const [stage, setStage] = useState(1);
    useInterval(() => {
        setStage(stage > 4 ? 1 : stage + 1);
    }, 2000);
    const getStageText = () => {
        switch (stage) {
            case 1:
                return formatMessage({id: 'landing.account.create.loading.stage1'});
            case 2:
                return formatMessage({id: 'landing.account.create.loading.stage2'});
            case 3:
                return formatMessage({id: 'landing.account.create.loading.stage3'});
            case 4:
                return formatMessage({id: 'landing.account.create.loading.stage4'});
            case 5:
                return formatMessage({id: 'landing.account.create.loading.stage5'});
            default:
                return formatMessage({id: 'landing.account.create.loading.stage1'});
        }
    };
    return (
        <WaitingScreenContainer>
            <WaitingScreenHeader>
                {formatMessage({id: 'landing.account.create.loading.header'})}
            </WaitingScreenHeader>
            <WaitingScreenDescription>
                {formatMessage({id: 'landing.account.create.loading.description'})}
            </WaitingScreenDescription>
            <WaitingScreenContent>
                {getStageText()}
            </WaitingScreenContent>
            <WaitingScreenProgress>
                <Progress
                    percent={18 * stage}
                    size="tiny"
                />
            </WaitingScreenProgress>
        </WaitingScreenContainer>
    );
};

WaitingScreen.propTypes = {
    formatMessage: PropTypes.func.isRequired,
};

export default WaitingScreen;

import styled from 'styled-components';

export const PagesList = styled.div`
    margin-top: 30px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;

    > span {
        margin-left: 10px;
        font-size: 13px;
        line-height: 140%;
    }
`;
export const StyledSlugPreview = styled.div`
    margin-top: 10px;
    color: #c1c1c1;
    font-size: 14px;
`;

export const StyledSlug = styled.span`
    color: #343840;
`;

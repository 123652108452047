import React, {useEffect, useMemo} from 'react';
import {
    FeaturesColumn,
    FeaturesContainer,
    FeaturesTitle,
    RadioContainer,
    RadioField,
    Text,
    Title,
} from './nodes';
import {getMessagesList} from 'packages/helpers/Helper';
import {Radio} from 'packages/components/inputs';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';

export const FeaturesStep = ({values, setFieldValue}, {intl: {formatMessage}}) => {
    const reasonsList = useMemo(
        () => getMessagesList(formatMessage({id: 'downgradeToFree.modal.reasons'})),
        [formatMessage],
    );

    useEffect(() => {
        setFieldValue('reasonQuestion', formatMessage({id: 'downgradeToFree.modal.reasons.header'}));
        setFieldValue('reasonAnswer', reasonsList[values.reasonIndex]);
    }, [formatMessage, reasonsList, setFieldValue, values.reasonIndex]);

    return (
        <>
            <Title>
                {formatMessage({id: 'downgradeToFree.modal.features.header'})}
            </Title>
            <Text>
                {formatMessage({id: 'downgradeToFree.modal.features.subheader'})}
            </Text>
            <FeaturesContainer>
                <FeaturesColumn>
                    <FeaturesTitle>
                        {formatMessage({id: 'downgradeToFree.modal.features.loose.header'})}
                    </FeaturesTitle>
                    {getMessagesList(formatMessage({id: 'downgradeToFree.modal.features.loose'}))
                        .map((item, id) => (
                            <Text key={id}>{item}</Text>
                        ))}
                </FeaturesColumn>
                <FeaturesColumn>
                    <FeaturesTitle>
                        {formatMessage({id: 'downgradeToFree.modal.features.keep.header'})}
                    </FeaturesTitle>
                    {getMessagesList(formatMessage({id: 'downgradeToFree.modal.features.keep'}))
                        .map((item, id) => (
                            <Text key={id}>{item}</Text>
                        ))}
                </FeaturesColumn>
            </FeaturesContainer>
            <Title>
                {values['reasonQuestion']}
            </Title>
            <RadioContainer>
                {reasonsList
                    .map((item, id) => (
                        <RadioField key={id}>
                            <Radio
                                radio
                                name="reasonIndex"
                                checked={values['reasonIndex'] === id}
                                onChange={() => setFieldValue('reasonIndex', id)}
                            />
                            <span>{item}</span>
                        </RadioField>
                    ))}
            </RadioContainer>
        </>
    );
};

FeaturesStep.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};

FeaturesStep.contextTypes = {
    intl: intlShape.isRequired,
};

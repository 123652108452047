import { compose } from "redux";
import { graphql } from "react-apollo";
import { withFormik } from "formik";
import * as Yup from "yup";

import { URLS } from "packages/enum";

import AboutForm from "@landing/components/createAccount/AboutForm";
import surveySend from "@graphql/gql/website/surveySend.gql";

const validationSchema = Yup.object().shape({
    "i am": Yup.string().required("landing.account.field.error.interest"),
});

export default compose(
    graphql(surveySend, {
        props({mutate}) {
            return {
                async surveySend({
                                     answers,
                                     surveyType,
                                 }) {
                    try {
                        return await mutate({
                            variables: {
                                answers,
                                surveyType,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => ({
                ...initialValues,
            }),
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
        handleSubmit: async ( values,
                              {
                                  setSubmitting,
                                  setStatus,
                                  props: { surveySend },
                              }) => {
            const answers = Object
                .entries(values)
                .filter( item => item[1])
                .map(item => {
                    if(typeof item[1] !== "boolean") {
                        return ({question: "i am", answer: item[1]})
                    }
                    return ({question: "interest", answer: item[0]})
                });

            let defaultError = "error.desc";
            try {
               let res = await surveySend({
                    surveyType: 'SIGNUP',
                    answers
                });
                let {
                    data: {
                        surveySend: { errors, success } = {},
                    },
                } = res;
                if (success) {
                    return (window.location = URLS.website_config);
                } else {
                    setSubmitting(false);
                }
                setStatus({ error: errors._error || defaultError });
            } catch (err) {
                setSubmitting(false);
                setStatus({
                    error: __PRODUCTION__ ? defaultError : err.toString(),
                });
            }
        },
    })
)(AboutForm);

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #E1E2E6;
  padding-top: 8px;
  background-color: #F6F6F6;
  z-index: 10;
  & > * {
    display: block !important;
    width: 100% !important;
    &:first-child {
      margin-right: 8px !important;
    }
  }
`;

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const HappyIcon = ({color}) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18Z"
            fill={iconColors[color]}/>
        <path
            d="M6.5 10C7.32843 10 8 9.32843 8 8.5C8 7.67157 7.32843 7 6.5 7C5.67157 7 5 7.67157 5 8.5C5 9.32843 5.67157 10 6.5 10Z"
            fill={iconColors[color]}/>
        <path
            d="M13.493 9.986C14.3176 9.986 14.986 9.31756 14.986 8.493C14.986 7.66844 14.3176 7 13.493 7C12.6684 7 12 7.66844 12 8.493C12 9.31756 12.6684 9.986 13.493 9.986Z"
            fill={iconColors[color]}/>
        <path d="M10 16C14 16 15 12 15 12H5C5 12 6 16 10 16Z" fill={iconColors[color]}/>
    </svg>
);

HappyIcon.propTypes = {
    ...iconColorPropTypes,
};

HappyIcon.defaultProps = {
    color: 'dark',
};

/* eslint-disable */
import React from 'react';

export const LeftIcon = () => (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.8816 4.21669L3.59941 4.21668L6.32992 1.49427L5.25449 0.425018L0.678223 4.97502L5.25449 9.52502L6.32992 8.45577L3.59941 5.73335L12.8816 5.73335V4.21669Z"
            fill="#343840"/>
    </svg>
);

LeftIcon.propTypes = {};

LeftIcon.defaultProps = {};

import PropTypes from 'prop-types';

const fieldProp = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
};

const formProp = {
    touched: PropTypes.shape({
        [PropTypes.string]: PropTypes.bool,
    }).isRequired,
    errors: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
    }).isRequired,
};

export const fieldProptypes = {
    field: PropTypes.exact(fieldProp).isRequired,
    form: PropTypes.shape(formProp).isRequired,
};

export const formProptypes = {
    values: PropTypes.shape(PropTypes.shape({
        [PropTypes.string]: PropTypes.bool,
    })).isRequired,
};

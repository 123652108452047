import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import {Semantic} from 'packages/components';
import {CloseIcon} from 'packages/components/icons';

import {
    Modal as StyledModal,
    ModalHeader as StyledModalHeader,
    ButtonRow,
} from './nodes';

export const ModalHeader = ({title, buttons}) => (
    <StyledModalHeader>
        {title}
        <ButtonRow
        >
            {buttons}
        </ButtonRow>
    </StyledModalHeader>
);

ModalHeader.propTypes = {
    title: PropTypes.string,
    buttons: PropTypes.element,
};

ModalHeader.defaultProps = {
    title: '',
    buttons: undefined,
};

export const Modal = ({
    onClose,
    open,
    styles,
    children,
    closeOnDimmerClick,
    header,
    mobileWide,
    showCloseIcon,
    ...props
}) => (
    <StyledModal
        open={open}
        onClose={onClose}
        dimmer="blurring"
        size="small"
        closeOnDimmerClick={closeOnDimmerClick}
        closeIcon
        styles={styles}
        mobileWide={mobileWide}

        {...props}
    >
        {showCloseIcon &&
        <div
            className="closeIcon"
            onClick={onClose}
        >
            <CloseIcon/>
        </div>}
        {header && <ModalHeader {...header}/>}
        {children}
        {get(header, 'buttons') &&
        <ButtonRow>
            {header.buttons}
        </ButtonRow>}
    </StyledModal>
);


Modal.propTypes = {
    open: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
    onClose: PropTypes.func,
    styles: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
        paddingLeft: PropTypes.string,
        paddingRight: PropTypes.string,
        paddingTop: PropTypes.string,
        paddingBottom: PropTypes.string,
    }),
    header: PropTypes.shape( {
        title: PropTypes.string,
        buttons: PropTypes.element,
    }),
    closeOnDimmerClick: PropTypes.bool,
    mobileWide: PropTypes.bool,
    children: PropTypes.element,
    ...Semantic.Modal.propTypes,
};

Modal.defaultProps = {
    open: false,
    showCloseIcon: true,
    onClose: () => null,
    styles: undefined,
    closeOnDimmerClick: true,
    mobileWide: false,
    header: undefined,
    children: undefined,
};

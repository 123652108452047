import PropTypes from 'prop-types';
import React, {useCallback, useState, useRef, useEffect} from 'react';
import {intlShape} from 'react-intl';
import {useDrop, useDrag} from 'react-dnd';
import {PAGE_TYPES, ALBUM_TYPES, WEBSITE_NAVIGATION_PAGE_TYPES} from 'packages/enum';
import PageUpdateModal from '@configurator/containers/PageUpdateModalContainer';
import ModalConfirmDelete from 'packages/components/modalConfirmDelete';
import {
    HomeIcon, GridIcon,
    FolderIcon, BlankIcon, LinkIcon,
    TrashIcon, SettingsIcon, LockIcon,
} from 'packages/components/icons';
import {getEmptyImage} from 'react-dnd-html5-backend';
import throttle from 'lodash/throttle';

import {dndDropYHelper} from 'packages/utils/dndDropHelper';
import {
    Container,
    ContainerDropAlbum,
    Title,
    IconWrapper,
    RightIconsBlock,
    DropZone,
} from './nodes';

export const renderTypeIcon = (type) => {
    switch (type) {
        case WEBSITE_NAVIGATION_PAGE_TYPES.EXTERNAL_LINK:
            return <IconWrapper><LinkIcon/></IconWrapper>;
        case WEBSITE_NAVIGATION_PAGE_TYPES.TEXT:
        case WEBSITE_NAVIGATION_PAGE_TYPES.CONTACTS:
            return <IconWrapper paddingL="2" paddingR="2"><BlankIcon/></IconWrapper>;
        default:
            return <IconWrapper><FolderIcon/></IconWrapper>;
    }
};

const PageLinkNode = ({
    title, url, id, pageType, onClickPage, onDeletePage, onDropPage,
    onDropAlbum, isAlbumListExist, startPage, redirectToPage,
    password, showHeader, visible, type, slideshowEnabled,
    draggable, deletable, customizable,
}) => {
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [updateDialogVisible, setUpdateDialogVisible] = useState(false);

    const [{isDragging}, drag, preview] = useDrag({
        item: {type: pageType},
        begin: () => ({type: pageType, id, title, startPage, itemType: type}),
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [overPosition, setOverPosition] = useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dndDropHelperWithThrottle = useCallback(
        throttle((ref, monitor) => setOverPosition(dndDropYHelper(ref, monitor), 16, {trailing: true, leading: false}))
        , [],
    );

    const ref = useRef();
    const [{isOver}, drop] = useDrop({
        accept: [PAGE_TYPES.MAIN, PAGE_TYPES.FOOTER],
        drop: item => onDropPage({
            type: pageType,
            itemId: item.id,
            droppedId: id,
            changeNavigation: pageType !== item.type,
            position: overPosition,
        }),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
        hover(item, monitor) {
            dndDropHelperWithThrottle(ref, monitor);
        },
    });

    const [{isOverPageUp}, dropPageUp] = useDrop({
        accept: [PAGE_TYPES.MAIN, PAGE_TYPES.FOOTER],
        drop: item => onDropPage({
            type: pageType,
            itemId: item.id,
            droppedId: id,
            changeNavigation: pageType !== item.type,
            position: -1,
        }),
        collect: monitor => ({
            isOverPageUp: !!monitor.isOver(),
        }),
    });

    const [{isOverPageDown}, dropPageDown] = useDrop({
        accept: [PAGE_TYPES.MAIN, PAGE_TYPES.FOOTER],
        drop: item => onDropPage({
            type: pageType,
            itemId: item.id,
            droppedId: id,
            changeNavigation: pageType !== item.type,
            position: 1,
        }),
        collect: monitor => ({
            isOverPageDown: !!monitor.isOver(),
        }),
    });

    const [{isOverAlbum}, dropAlbum] = useDrop({
        accept: [ALBUM_TYPES.MAIN, ALBUM_TYPES.FOOTER],
        drop: item => onDropAlbum({
            sourcePageId: item.pageId,
            newPageId: id,
            itemId: item.id,
            type: pageType === PAGE_TYPES.MAIN ? ALBUM_TYPES.MAIN : ALBUM_TYPES.FOOTER,
            sourceType: item.type,
        }),
        canDrop: item => item.pageId !== id && isAlbumListExist,
        collect: monitor => ({
            isOverAlbum: !!monitor.isOver() && isAlbumListExist && !!monitor.canDrop(),
        }),
    });

    const redirectToUrl = useCallback((url) => {
        if (type === WEBSITE_NAVIGATION_PAGE_TYPES.EXTERNAL_LINK) {
            return window.open(url, '_blank');
        }
        return redirectToPage(url);
    }, [redirectToPage, type]);

    const handleClick = () => onClickPage ? onClickPage({pageId: id}) : redirectToUrl(url);

    const handleDelete = () => {
        setDeleteDialogVisible(false);
        onDeletePage({pageId: id});
    };
    
    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, [preview]);

    // Если убрали со своей позиции, то больше не раскрываем ее
    const [justDrag, setJustDrag] = useState(true);

    useEffect(() => {
        if (isDragging && !isOver) {
            setJustDrag(false);
        } else if (!isDragging) {
            setJustDrag(true);
        }
    }, [isDragging, isOver]);

    drop(ref);

    return (
        <>
            <DropZone ref={dropPageUp} show={!isDragging && (isOver || isOverPageUp) && overPosition === -1}/>
            <Container ref={draggable ? ref : null} isDragging={isDragging}>
                <ContainerDropAlbum ref={draggable ? dropAlbum : null}>
                    <Title
                        ref={draggable ? drag : null}
                        justDrag={justDrag}
                        isDragging={isDragging}
                        isOverAlbum={isOverAlbum}
                        isLocked={password}
                        isShowHeader={showHeader}
                        isVisible={visible}
                    >
                        {startPage && !slideshowEnabled
                            ? (
                                <IconWrapper paddingL="17" paddingR="17">
                                    <HomeIcon/>
                                </IconWrapper>
                            ) : (
                                <IconWrapper paddingL="18" paddingR="18">
                                    <GridIcon color={draggable ? 'gray' : 'transparent'} />
                                </IconWrapper>
                            )
                        }
                        {renderTypeIcon(type)}
                        <span className="navTitle" onClick={handleClick}>
                            {title}
                        </span>
                        <RightIconsBlock>
                            {deletable && (
                                <span onClick={() => setDeleteDialogVisible(true)}>
                                    <TrashIcon/>
                                </span>
                            )}
                            {customizable && (
                                <span onClick={() => setUpdateDialogVisible(true)}>
                                    <SettingsIcon/>
                                </span>
                            )}
                            <LockIcon color="gray"/>
                        </RightIconsBlock>
                    </Title>
                </ContainerDropAlbum>
            </Container>
            <DropZone ref={dropPageDown} show={!isDragging && (isOver || isOverPageDown) && overPosition === 1}/>
            {deleteDialogVisible && (
                <ModalConfirmDelete
                    open
                    headerMessageId="page.confirmDelete.header"
                    headerMessageValues={{name: title}}
                    subMessageId="page.confirmDelete.subMessage"
                    subMessageValues={{name: title}}
                    onClickYes={handleDelete}
                    yesMessageId="page.confirmDelete.yesMessage"
                    onClickCancel={() => setDeleteDialogVisible(false)}
                    onClose={() => setDeleteDialogVisible(false)}
                />
            )}
            {updateDialogVisible && (
                <PageUpdateModal
                    pageId={id}
                    open
                    onClose={() => setUpdateDialogVisible(false)}
                    onDelete={() => setDeleteDialogVisible(true)}
                    slideshowEnabled={slideshowEnabled}
                />
            )}
        </>
    );
};

PageLinkNode.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    startPage: PropTypes.bool.isRequired,
    pageType: PropTypes.string,
    isAlbumListExist: PropTypes.bool,
    password: PropTypes.string,
    showHeader: PropTypes.bool,
    visible: PropTypes.bool,
    type: PropTypes.string,
    slideshowEnabled: PropTypes.bool,
    draggable: PropTypes.bool,
    deletable: PropTypes.bool,
    customizable: PropTypes.bool,

    // methods
    onClickPage: PropTypes.func,
    onDeletePage: PropTypes.func,
    onDropPage: PropTypes.func,
    onDropAlbum: PropTypes.func,
    redirectToPage: PropTypes.func,
};
PageLinkNode.defaultProps = {
    pageType: PAGE_TYPES.MAIN,
    isAlbumListExist: false,
    password: null,
    showHeader: true,
    visible: true,
    type: undefined,
    slideshowEnabled: false,
    draggable: true,
    deletable: true,
    customizable: true,

    onDeletePage: () => null,
    onDropPage: () => null,
    onDropAlbum: () => null,
    redirectToPage: () => null,
};

PageLinkNode.contextTypes = {
    intl: intlShape,
};

export default PageLinkNode;

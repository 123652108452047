import {compose} from 'redux';
import {graphql} from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {withFormik} from 'formik';
import {MESSAGE_TYPE, SOCIAL_LINK_NAMES, SOCIAL_LINK_URLS} from 'packages/enum';

import websiteMetaUpdate from '@graphql/gql/website/websiteMetaUpdate.gql';
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql';

import SocialLinks from '@configurator/components/modals/socialLinks';

export const socialLinksWrapper = Component => compose(
    graphql(websiteMetaUpdate, {
        props({mutate}) {
            return {
                async updateMeta({
                    socialLinks,
                    websiteId,
                }) {
                    try {
                        return await mutate({
                            variables: {
                                socialLinks,
                                websiteId,
                            },
                        });
                    } catch (err) {
                        return {
                            success: false,
                        };
                    }
                },
            };
        },
    }),
    graphql(websiteMetaGet, {
        options() {
            return {
                variables: {},
                ssr: false,
                fetchPolicy: 'network-only',
            };
        },
        props({data: {loading, websiteMetaGet}}) {
            if (loading) {
                return {loading};
            }
            if (isEmpty(websiteMetaGet)) {
                return;
            }
            const {data: {socialLinks, id}} = websiteMetaGet;

            const instagramData = socialLinks.find(({name, url}) => name === SOCIAL_LINK_NAMES.instagram
                || ~url.indexOf(SOCIAL_LINK_URLS.instagram)) || {};
            const facebookData = socialLinks.find(({name, url}) => name === SOCIAL_LINK_NAMES.facebook
                || ~url.indexOf(SOCIAL_LINK_URLS.facebook)) || {};

            const initialValues = {
                instagramUrl: get(instagramData, 'url', '').replace(SOCIAL_LINK_URLS.instagram, ''),
                facebookUrl: get(facebookData, 'url', '').replace(SOCIAL_LINK_URLS.facebook, ''),
                id,
            };
            return {initialValues};
        },
    }),
    withFormik({
        mapPropsToValues: ({initialValues}) => {
            return {
                ...initialValues,
                instagramUrl: get(initialValues, 'instagramUrl') || '',
                facebookUrl: get(initialValues, 'facebookUrl') || '',
            };
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        handleSubmit: async (
            {
                instagramUrl,
                facebookUrl,
                id,
            }, {
                setSubmitting,
                setStatus,
                props: {
                    updateMeta,
                    onClose,
                },
            }) => {

            let defaultError = 'socialLinks.update.error.header';

            try {
                const socialLinks = [
                    {
                        name: SOCIAL_LINK_NAMES.instagram,
                        url: isEmpty(instagramUrl) ? instagramUrl : SOCIAL_LINK_URLS.instagram + instagramUrl,
                    },
                    {
                        name: SOCIAL_LINK_NAMES.facebook,
                        url: isEmpty(facebookUrl) ? facebookUrl : SOCIAL_LINK_URLS.facebook + facebookUrl,
                    },
                ];
                const res = await updateMeta({
                    socialLinks,
                    websiteId: id,
                });
                setSubmitting(false);
                let {
                    data: {
                        websiteMetaUpdate: {errors, success} = {},
                    },
                } = res;
                if (success) {
                    window.frames['preview-frame']?.postMessage(
                        JSON.stringify({
                            name: MESSAGE_TYPE.UPDATE_META,
                        }),
                        '*'
                    );
                    return onClose();
                }
                setStatus({error: errors._error || defaultError});
            } catch (err) {
                setSubmitting(false);
                setStatus({error: __PRODUCTION__ ? defaultError : err.toString()});
            }
        },
    }),
)(Component);

export default socialLinksWrapper(SocialLinks);

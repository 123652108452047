import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {changeView} from './actions';


const defaultState = fromJS({
    expandedView: false,
});

export default handleActions({

    [changeView]: {
        next(state, {payload}) {
            return state.set('expandedView', payload?.expandedView ?? !state.get('expandedView'));
        },
    },

}, defaultState);

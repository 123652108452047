import {createActions} from 'redux-actions';

export const {
    periodPicker: {
        applyFilter,
    },
} = createActions({
    PERIOD_PICKER: {
        APPLY_FILTER: payload => payload,
    },
});

import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Ref, Input as SemanticInput} from 'semantic-ui-react';
import {Field} from 'formik';
import {intlShape} from 'react-intl';
import styled from 'styled-components';
import {HideIcon, ShowIcon} from 'packages/components/icons/basic';
import {fieldProptypes} from './formikPropTypes';
import {Input} from '../input';

const InputContainer = styled(Input)`
    .ui.input {
        ${props => props.$hideText ? '-webkit-text-security: disc;' : ''}
    }
`;

const IconContainer = styled.div`
    position: absolute;
    right: 6px;
    top: 6px;
    height: 32px;
    cursor: pointer;

    &:hover svg path{
        fill: #666A73;
    }
`;

const SemanticFormInput = (
    {
        field, // { name, value, onChange, onBlur }
        form: {touched = {}, errors = {}, setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid,
        type,
        ...props
    },
) => {
    const [fieldRef, setFieldRef] = useState(null);
    // fix Firefox autofill form input
    useEffect(() => {
        if (fieldRef && fieldRef.value && !field.value) {
            setFieldValue(field.name, fieldRef.value);
        }
    }, [field.name, field.value, fieldRef, setFieldValue]);
    const [showPassword, setShowPassword] = useState(false);

    const inputType = useMemo(() => (showPassword && type !== 'text') ? 'text' : type, [showPassword, type]);

    return (
        <Ref innerRef={(node) => setFieldRef(node.querySelector('input'))}>
            <InputContainer
                error={errors[field.name] && touched[field.name]} // eslint-disable-line react/prop-types
                maxLength={255}
                icon={
                    <IconContainer
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword
                            ? <ShowIcon color="gray"/>
                            : <HideIcon color="gray"/>
                        }
                    </IconContainer>
                }
                {...field}
                {...props}
                autoComplete={type === 'text' ? 'new-password' : 'off'}
                $hideText={!showPassword}
                type={inputType}
            />
        </Ref>
    );
};

SemanticFormInput.propTypes = {
    ...fieldProptypes,
    ...SemanticInput.propTypes,
};

SemanticFormInput.defaultProps = {
    ...SemanticInput.defaultProps,
};

SemanticFormInput.contextTypes = {
    intl: intlShape,
};

export const FormInputPassword = ({name, ...props}) => (
    <Field name={name} {...props} component={SemanticFormInput}/>
);

FormInputPassword.propTypes = {
    name: PropTypes.string.isRequired,
    ...SemanticInput.propTypes,
};

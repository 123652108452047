import styled, {css} from 'styled-components';
import {media} from 'packages/components/media';

export const Content = styled.div`
    color: #242426;

    & > form {
        display: flex;
        flex-direction: column;
    }

    ${({isUpgrade}) => isUpgrade && css`
        height: 100%;

        & > form {
            height: calc(100% - 120px);
        }

        @media (min-width: 1025px) {
            ${VatWrapper} {
                margin-top: 16px;
            }
        }
    `}
`;

export const Header = styled.div`
    ${props => props.hide ? 'display: none;' : ''}
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 10px;
`;

export const StyledGroupInput = styled.div`
    flex: ${props => props.flex ? props.flex : 1};

    .ui.input {
        width: 100% !important;
        input{
            border-radius: 4px !important;
            color: #242426 !important;
        }
    }
    label{
        color: #242426;
    }
`;

export const StyledButton = styled.div`
    flex: ${props => props.flex ? props.flex : '1'};
    margin-top: 20px !important;
    .ui.button {
        width: 195px;
        height: 45px;

        ${media.max.phone`
            width: 100%;
        `}
    }
`;

export const StyledGroup = styled.div`
    display: flex;
    margin: 0 0 0 -10px;

    ${({flexDirection}) => flexDirection && css`
        flex-direction: ${flexDirection};
    `}

    & > * {
        margin: 16px 0 0 10px;
    }

    ${media.max.phone`
        flex-direction: column;
    `}
`;

export const StyledDropdown = styled.div`
    width: 100%;
    .ui.dropdown {
        width: 100% !important;
    }

    ${media.max.phone`
        width: auto;
    `}
`;

export const VatWrapper = styled.div`
    margin-top: 16px;
`;

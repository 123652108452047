import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { fieldProptypes } from "../formElements/formikPropTypes";
import { Slider } from "../slider";
import { useMemo } from "react";

const SliderInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid,
    initialMargin,
    min,
    max,
    step,
    ...props
}) => {
    // const marginRef = useRef(initialMargin);
    // const initMargin = useMemo(() => marginRef.current, []);
    const hasMargin = initialMargin > 0;
    
    const handleChange = (value) => {
        if (!props.disabled) {
            if (!hasMargin) {
                setFieldValue(field.name, +value);
            } else {
                setFieldValue(field.name, initialMargin * value);
            }
        }
    };
    return (
        <Slider
            {...field}
            {...props}
            onChange={handleChange}
            value={hasMargin ? field.value / initialMargin : field.value}
            min={hasMargin ? min : 0}
            max={hasMargin ? max : 60}
            step={hasMargin ? step : 3}
        />
    );
};

SliderInput.propTypes = {
    ...fieldProptypes,
};

SliderInput.defaultProps = {};

export const MarginSlider = ({ name, ...props }) => (
    <Field name={name} {...props} component={SliderInput} />
);

MarginSlider.propTypes = {
    name: PropTypes.string.isRequired,
};

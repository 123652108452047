import React from 'react';
import PropTypes from 'prop-types';
import {Button as SemanticButton} from 'semantic-ui-react';
import {StyledButton, viewPropType} from './nodes';

export const Button = ({ view, icon, content, children, ...props }) => (
    <StyledButton view={view} {...props}>
        {content || children}
        {icon || null}
    </StyledButton>
);

Button.propTypes = {
    icon: PropTypes.element,
    view: viewPropType,
    ...SemanticButton.propTypes,
};

Button.defaultProps = {
    view: 'primary',
    icon: undefined,
};

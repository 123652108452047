import React, {useCallback, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {ChevronDownIcon} from 'packages/components/icons/arrows/chevronDown';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import get from 'lodash/get';
import {metaSelector} from 'packages/selectors/Website';
import {makeTemplatesArray} from '@configurator/components/templates/const';
import {URLS} from 'packages/enum';
import {TemplatesPanel} from './nodes';

export function ChooseTemplate() {
    const dispatch = useDispatch();
    const meta = useSelector(metaSelector);
    const templateParams = useMemo(() => (
        {
            templateName: get(meta, 'templateName'),
            align: get(meta, 'align'),
            inverted: get(meta, 'inverted'),
            slideshowEnabled: get(meta, 'slideshowEnabled'),
        }
    ), [meta]);
    const selectedTemplate = useMemo(() => {
        return makeTemplatesArray({
            updateTemplate: () => {},
            ...templateParams,
        }).find(el => el.selected);
    }, [templateParams]);

    const goToTemplates = useCallback(() => dispatch(push(URLS.website_edit_template)), [dispatch]);

    if (!selectedTemplate) {
        return null;
    }

    return (
        <TemplatesPanel
            role="button"
            onClick={goToTemplates}
        >
            <img src={get(selectedTemplate, 'imageUrl', '')} alt="template"/>
            <div>
                <FormattedMessage id="current.theme"/>
                <span>
                    <b>
                        {selectedTemplate &&
                            <FormattedMessage id={get(selectedTemplate, 'header')}>
                                {msg => msg.toLowerCase()}
                            </FormattedMessage>
                        }
                    </b>
                </span>
            </div>
            <div>
                <ChevronDownIcon/>
            </div>
        </TemplatesPanel>
    );
}

import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { useQuery, useMutation } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { Formik } from "formik";
import * as Yup from "yup";
import {toast} from 'react-toastify';

// graphql
import websiteMetaGetQuery from "@graphql/gql/website/websiteMetaGet.gql";
import websiteMetaUpdateMutation from "@graphql/gql/website/websiteMetaUpdate.gql";
import websiteScriptGetQuery from "@graphql/gql/website/websiteScriptGet.gql";
import websiteScriptUpdateMutation from "@graphql/gql/website/websiteScriptUpdate.gql";

import { SCRIPT_TYPE } from "packages/enum";
import { Toggle, FormInput } from "packages/components/inputs";
import { Button } from "packages/components/buttons";

import Loader from "../../loader/Loader";

import CopyToClipboardButton from "../../copyToClipboardButton";
import { Field, Paragraph } from "../nodes";
import {
    Form,
    ToggleField,
    CopyToClipboardButtons,
    StylizedText,
} from "./nodes";

const VerificationForm = ({
    intl: { formatMessage },
    data,
    loaderStart,
    loaderStop,
    openErrorModal,
}) => {
    const { loading: scriptLoading, data: { websiteScriptGet } = {} } =
        useQuery(websiteScriptGetQuery, {
            variables: {
                type: SCRIPT_TYPE.GOOGLE_VERIFICATION_TAG,
            },
            fetchPolicy: "network-only",
        });

    const [websiteMetaUpdate] = useMutation(websiteMetaUpdateMutation);

    const [websiteScriptUpdate] = useMutation(websiteScriptUpdateMutation);

    if (scriptLoading) {
        return <Loader />;
    }

    if (isEmpty(websiteScriptGet) || !isEmpty(websiteScriptGet.errors)) {
        return <div>Error</div>;
    }

    const websiteScriptData = websiteScriptGet.data;
    const websiteScriptContent = websiteScriptData.content || "";

    const initValues = {
        siteVerificationMetaTag: websiteScriptContent,
    };
    const validationSchema = Yup.object().shape({
        siteVerificationMetaTag: Yup.string().nullable(),
    });
    const defaultError = "error.desc";

    const toggleSeoGenerateFiles = async () => {
        try {
            loaderStart();

            const websiteMetaUpdateRes = await websiteMetaUpdate({
                variables: {
                    websiteId: data.id,
                    seoGenerateFiles: !data.seoGenerateFiles,
                },
                refetchQueries: [
                    {
                        query: websiteMetaGetQuery,
                        variables: {},
                    },
                ],
            });

            if (!get(websiteMetaUpdateRes, "data.websiteMetaUpdate.success")) {
                loaderStop();
                return openErrorModal({
                    headerMessageId: "error.header",
                    subMessageId:
                        !get(
                            websiteMetaUpdateRes,
                            "data.websiteMetaUpdate.errors._error"
                        ) || defaultError,
                    yesMessageId: "OK",
                });
            }

            loaderStop();
        } catch (err) {
            loaderStop();
            return openErrorModal({
                headerMessageId: "error.header",
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                yesMessageId: "OK",
            });
        }
    };

    const handleSubmitForm = async (values, { setSubmitting }) => {
        try {
            loaderStart();

            const websiteScriptUpdateRes = await websiteScriptUpdate({
                variables: {
                    websiteId: data.id,
                    type: SCRIPT_TYPE.GOOGLE_VERIFICATION_TAG,
                    content: values.siteVerificationMetaTag,
                    scriptPosition: "HEADER",
                },
                refetchQueries: [
                    {
                        query: websiteScriptGetQuery,
                        variables: {
                            type: SCRIPT_TYPE.GOOGLE_VERIFICATION_TAG,
                        },
                        fetchPolicy: "network-only",
                    },
                ],
            });

            if (
                !get(websiteScriptUpdateRes, "data.websiteScriptUpdate.success")
            ) {
                loaderStop();
                setSubmitting(false);
                return openErrorModal({
                    headerMessageId: "error.header",
                    subMessageId:
                        !get(
                            websiteScriptUpdateRes,
                            "data.websiteScriptUpdate.errors._error"
                        ) || defaultError,
                    yesMessageId: "OK",
                });
            }

            loaderStop();
            setSubmitting(false);
            toast.success(formatMessage({ id: "seo.toast.success" }), {position: 'top-center'});
        } catch (err) {
            loaderStop();
            setSubmitting(false);
            return openErrorModal({
                headerMessageId: "error.header",
                subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                yesMessageId: "OK",
            });
        }
    };

    const renderForm = ({ values, isSubmitting }) => {
        const submitDisabled =
            isEqual(initValues, {
                siteVerificationMetaTag: values.siteVerificationMetaTag,
            }) || isSubmitting;

        const Link = ({ uri }) => (
            <b>
                https://
                {
                    <StylizedText>
                        {data.premiumDomain || data.domain}
                    </StylizedText>
                }
                {!data.premiumDomain && ".vsble.me"}
                {uri}
            </b>
        );

        return (
            <Form>
                <ToggleField>
                    <Paragraph type="h1">
                        {formatMessage({ id: "seo.generateFiles.title" })}
                    </Paragraph>
                    <Toggle
                        checked={data.seoGenerateFiles}
                        onChange={toggleSeoGenerateFiles}
                    />
                </ToggleField>
                <CopyToClipboardButtons>
                    <Link uri={"/robots.txt"} />
                    <CopyToClipboardButton textToCopy={`sitemap.xml`} successMessage={formatMessage({ id: "seo.toast.success" })}>
                        <Link uri={"/sitemap.xml"} />
                    </CopyToClipboardButton>
                </CopyToClipboardButtons>
                <Field>
                    <Paragraph mb="10" type="h1" fz="20px">
                        {formatMessage({ id: "seo.googleVerification.title" })}
                    </Paragraph>
                    <p>
                        {formatMessage({ id: "seo.googleVerification.desc" })}
                    </p>
                    <FormInput
                        label={formatMessage({
                            id: "seo.googleVerification.input.label",
                        })}
                        name="siteVerificationMetaTag"
                    />
                </Field>
                <Button
                    type="submit"
                    view="primary"
                    disabled={submitDisabled}
                    content={formatMessage({ id: "seo.save" })}
                />
            </Form>
        );
    };

    return (
        <Formik
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            initialValues={initValues}
            onSubmit={handleSubmitForm}
            render={({ values, isSubmitting }) =>
                renderForm({ values, isSubmitting })
            }
        />
    );
};

VerificationForm.propTypes = {
    intl: intlShape.isRequired,
    data: PropTypes.object.isRequired,
    loaderStart: PropTypes.func.isRequired,
    loaderStop: PropTypes.func.isRequired,
    openErrorModal: PropTypes.func.isRequired,
};

VerificationForm.defaultProps = {};

export default injectIntl(VerificationForm);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { formProptypes } from "packages/utils/formikPropTypes";
import { Semantic } from "packages/components";
import { FormInput } from "packages/components/inputs";
import { Button } from "packages/components/buttons";
import ChangePassword from "@configurator/containers/billing/ChangePasswordModalContainer";
import { Content, Field, StyledInput } from "./nodes";
import Message from "packages/components/message/Message";

const LoginInfo = ({
                       status,
                       loading,
                       errors,
                       isSubmitting,
                       handleSubmit,
                       intl: { formatMessage },
                   }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const error = Object.keys(errors).length || (status && !!status.error);
    return (
        <Semantic.Form
            error={error}
            loading={isSubmitting || loading}
            onSubmit={handleSubmit}
        >
            <Content>
                <div>
                    {formatMessage({ id: "accountBilling.login.header" })}
                </div>
                <Message
                    error={error}
                    messages={errors}
                    content={status && status.error}
                />
                <Field>
                    <StyledInput>
                        <FormInput
                            input={{ autocomplete: 'off' }}
                            name="email"
                            label={formatMessage({
                                id: "accountBilling.login.field.email",
                            })}
                            type="email"
                            required
                        />
                    </StyledInput>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        content={formatMessage({
                            id: "accountBilling.login.button.update",
                        })}
                    />
                </Field>
                <Field>
                    <StyledInput>
                        <FormInput
                            input={{ autocomplete: 'off' }}
                            label={formatMessage({
                                id: "accountBilling.login.field.changePassword",
                            })}
                            type="text"
                            value="•••••••••••••••"
                            disabled
                        />
                    </StyledInput>
                    <Button
                        type="button"
                        content={formatMessage({
                            id: "accountBilling.login.button.changePassword",
                        })}
                        view="secondaryBlack"
                        onClick={() => setModalOpen(true)}
                    />
                </Field>
                {modalOpen && (
                    <ChangePassword open onClose={() => setModalOpen(false)} />
                )}
            </Content>
        </Semantic.Form>
    );
};

LoginInfo.propTypes = {
    intl: intlShape.isRequired,
    loading: PropTypes.bool,
    ...formProptypes,
};

LoginInfo.defaultProps = {};

export default injectIntl(LoginInfo);

import React from "react";
import { injectIntl, intlShape } from "react-intl";

import { HeaderStyled } from "packages/components/layout/nodes";
import {
    Container,
    Header,
    Teaser,
    TeaserText,
    Logo,
    FormWrapper,
    Grid,
    Notes,
    StyledTextareaContainerLabel,
    StyledTextareaContainer,
    NoteHeader,
    Step,
    StepContent,
    StepNumber,
    StepWrapper,
    Paragraph,
    VideoLink,
} from "../nodes";
import Image from "packages/components/gatsby-image";
import helpspace from "../../assets/helpspace.png";

import { Button } from "packages/components/buttons";
import { Semantic } from "packages/components";
import { FormTextArea } from "packages/components/inputs";

import { wrapper } from "../../../../containers/integrations/IntegrationsDetails";

const Helpspace = ({
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    intl: { formatMessage },
}) => {
    const error = Object.keys(errors).length || (status && !!status.error);

    return (
        <>
            <HeaderStyled backArrow />
            <Container>
                <Header>
                    {formatMessage({
                        id: "integrations.helpspace.header",
                    })}
                </Header>
                <Teaser>
                    <Logo>
                        <Image
                            fluid={{
                                src: helpspace,
                                aspectRatio: 38 / 15,
                            }}
                            alt=""
                            fadeIn
                            imgStyle={{ objectFit: "contain" }}
                            loading="lazy"
                            backgroundColor="white"
                        />
                    </Logo>
                    <TeaserText small>
                        {formatMessage({
                            id: "integrations.helpspace.teaser",
                        })}
                    </TeaserText>
                    <div>
                        <Button
                            as="a"
                            href={
                                "https://a.paddle.com/v2/click/37698/135603?link=1870"
                            }
                            target="_blank"
                            content={formatMessage({
                                id: "integration.start.free",
                            })}
                        />
                    </div>
                </Teaser>
                <Grid>
                    <div>
                        <FormWrapper>
                            <Semantic.Form
                                noValidate
                                error={error}
                                loading={isSubmitting || loading}
                                onSubmit={handleSubmit}
                            >
                                <StyledTextareaContainerLabel>
                                    {formatMessage({
                                        id: "integration.footer.code",
                                    })}
                                </StyledTextareaContainerLabel>

                                <StyledTextareaContainer>
                                    <FormTextArea
                                        name="content"
                                        placeholder={formatMessage({
                                            id: "integrations.helpspace.code.here",
                                        })}
                                    />
                                </StyledTextareaContainer>
                                <Button
                                    type="submit"
                                    content={formatMessage({
                                        id: "integration.save",
                                    })}
                                    disabled={isSubmitting}
                                />
                            </Semantic.Form>
                        </FormWrapper>
                    </div>
                    <div>
                        <Notes>
                            <NoteHeader>
                                {formatMessage({
                                    id: "integrations.helpspace.note.header",
                                })}
                            </NoteHeader>
                            <StepWrapper>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>1</span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: formatMessage({
                                                        id: "integrations.helpspace.note.1",
                                                    }),
                                                }}
                                            />
                                        </Paragraph>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>2</span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            {formatMessage({
                                                id: "integrations.helpspace.note.2",
                                            })}
                                        </Paragraph>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>
                                        <div>
                                            <span>3</span>
                                        </div>
                                    </StepNumber>
                                    <StepContent>
                                        <Paragraph type="h2" mt={3} mb={10}>
                                            {formatMessage({
                                                id: "integrations.helpspace.note.3",
                                            })}
                                        </Paragraph>

                                        <VideoLink
                                            href={"https://vimeo.com/605105510"}
                                            target={"_blank"}
                                        >
                                            {formatMessage({
                                                id: "integration.watch.tutorial",
                                            })}
                                        </VideoLink>
                                    </StepContent>
                                </Step>
                            </StepWrapper>
                        </Notes>
                    </div>
                </Grid>
            </Container>
        </>
    );
};

Helpspace.propTypes = {
    intl: intlShape.isRequired,
};

Helpspace.defaultProps = {};

export default wrapper(injectIntl(Helpspace), "helpspace");

import styled from 'styled-components';
import Image from 'packages/components/image/Image';
import { Button } from 'packages/components/buttons';
import {media} from 'packages/components/media';

export const Container = styled.div`
    border: ${props => props.cover || props.isDragging ? '1px solid #58C367' : 'none'};
    width: ${props => props.isDragging && (!props.isOver || !props.justDrag) ? '0' : `calc((100% - ${props.columnCount * 2 * 4}px) / ${props.columnCount})` /*24margin * 4px = 96px*/};
    min-width: ${props => props.isDragging && (!props.isOver || !props.justDrag) ? '0' : `calc((100% - ${props.columnCount * 2 * 4}px) / ${props.columnCount})`};
    margin: ${props => props.isDragging ? '0' : '4px'};
    transition: width 0.2s cubic-bezier(0.2, 0, 0, 1) 0s;
    opacity: ${props => props.isDragging ? 0 : 1};
    cursor: move;
    text-align: center;

    height: 140px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #EDEDED;

    position: relative;

    img {
        object-fit: contain !important;
    }
`;

export const AlbumCoverText = styled.div`
    background: #58C367;
    color: white;
    position: absolute;
    left: auto;
    right: auto;
    bottom: 0;
    width: 100%;
    font-weight: 500;
    height: 20px !important;
    font-size: 13px;
    line-height: 1.8em;
    align-self: center;
`;

export const ItemInfo = styled.div`
    color: white;
    position: absolute;
    left: 5px;
    top: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > svg {
        margin-right: 5px;
    }
`;

export const ImageComponent = styled(Image)`
    padding: 4px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-height: 140px;
`;

export const SettingsButton = styled.span`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: white;
    border-radius: 16px;
`;

export const DropdownContainer = styled.div`
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
`;

export const ModalContent = styled.div`
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    textarea {
        width: 100% !important;
        height: 135px !important;
    }
`;

export const SubmitButton = styled(Button)`
    width: 165px !important;
    height: 45px !important;
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #343840;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 20px;

    ${media.max.phone`
        ${SubmitButton} {
            display: none !important;
        }
    `}
`;

export const DropZone = styled.div`
    width: ${props => props.show ? `calc((100% - ${props.columnCount * 2 * 4}px) / ${props.columnCount})` : '0'};
    transition: ${props => !props.fast ? 'width 0.2s cubic-bezier(0.2, 0, 0, 1) 0s' : ''};
    opacity: 0.1;
    z-index: 10;
    background: ${props => props.lastItem && !props.isOver ? 'inherit' : 'green'};
    display: ${props => props.isDragging ? 'none' : 'flex'};
    height: 140px;
    //display: flex;
`;

export const LoaderContainer = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.3);
    position: fixed;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MobileSubmitContainer = styled.div`
    display: none;

    ${media.max.phone`
        display: block;
        margin-top: 10px;
    `}
`;

export const ImageLimitBannerWrapper = styled.div`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ImageLimitBanner = styled.div`
    width: 62px;
    min-height: 30px;
    background-color: #0076b6;
    color: #FFF;
    border-radius: 7px;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

import React from 'react';
import * as Semantic from 'semantic-ui-react';
import {intlShape} from 'react-intl';
import {getTitle, intlDuck} from '../../utils';
import {StyledInputContainer, StyledInputWithIcon, StyledLabel, StyledInput, StyledInputError} from './nodes';

export const Input = (
    {
        placeholder,
        label,
        className,
        inlineNode,
        errorText,
        ...props
    },
    {
        intl: {formatMessage} = intlDuck,
    },
) => {
    return (
        <StyledInputContainer className={className}>
            {label ? <StyledLabel>{getTitle(label, formatMessage)}</StyledLabel> : null}
            <StyledInputWithIcon>
                <StyledInput
                    maxLength={255}
                    {...props}
                    placeholder={getTitle(placeholder, formatMessage)}
                />
                {inlineNode || null}
            </StyledInputWithIcon>
            {errorText ? <StyledInputError>{errorText}</StyledInputError> : null}
        </StyledInputContainer>
    );
};

Input.propTypes = {
    ...Semantic.Input.propTypes,
};

Input.defaultProps = {
    ...Semantic.Input.defaultProps,
};

Input.contextTypes = {
    intl: intlShape,
};

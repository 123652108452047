import PropTypes from "prop-types";
import React from "react";
import merge from "lodash/merge";
import { Formik, Form as FormikForm } from "formik";

import { Button } from "packages/components/buttons";
import {
    ModalContainer,
    StAltBlock,
    StFooter,
    StHint,
    StImg,
    StImgInfoBlock,
    StImgInfoParams,
    StImgInfoParamsRow,
    StImgParam,
    StModalBody,
    StTabBtn,
    StTabsContainer,
    StTextarea,
    StTitle,
} from "./nodes";
import { useState } from "react";
import { SizeSmallIcon } from "../icons/basic/sizeSmall";
import { WeightIcon } from "../icons/basic/weight";
import { ColorsIcon } from "../icons/basic/colors";

const AddInfoAndAltModal = (
    { open, onClose, hideHeader, imgWidth, imgHeight, src, onAlbumItemUpdate,altText,altTextDescriptive },
    { intl: { formatMessage } }
) => {
    const [isDecorative, setIsDecorative] = useState(!altTextDescriptive);

    const initialValues = {
        altText: altText || "",
    };

    return (
        <ModalContainer
            open={open}
            onClose={onClose || close}
            styles={merge({
                height: "initial",
                width: "initial",
            })}
        >
            {!hideHeader && (
                <StTitle className="title">
                    {formatMessage({ id: `addInfoAndAlt.modal.title` })}
                </StTitle>
            )}
            <StModalBody>
                <StImgInfoBlock>
                    <StImgInfoParams>
                        <div className="">img_name.png</div>
                        <StImgInfoParamsRow>
                            <StImgParam>
                                <SizeSmallIcon />
                                {`${imgWidth} x ${imgHeight}`}
                            </StImgParam>
                            <StImgParam>
                                <WeightIcon />
                                640kb
                            </StImgParam>
                            <StImgParam>
                                <ColorsIcon />
                                Adobe-RGB
                            </StImgParam>
                        </StImgInfoParamsRow>
                    </StImgInfoParams>
                    <StImg>
                        <img src={src} alt={altText || ''} />
                    </StImg>
                </StImgInfoBlock>
                <StAltBlock>
                    <StTitle small>
                        {formatMessage({
                            id: "addInfoAndAlt.modal.alt.subtitle",
                        })}
                    </StTitle>
                    <StTabsContainer>
                        <StTabBtn
                            view={
                                isDecorative
                                    ? `secondaryWhite`
                                    : `secondaryBlack`
                            }
                            onClick={() => setIsDecorative(false)}
                        >
                            {formatMessage({
                                id: "addInfoAndAlt.modal.button.descriptive",
                            })}
                        </StTabBtn>
                        <StTabBtn
                            view={
                                isDecorative
                                    ? `secondaryBlack`
                                    : `secondaryWhite`
                            }
                            onClick={() => setIsDecorative(true)}
                        >
                            {formatMessage({
                                id: "addInfoAndAlt.modal.button.decorative",
                            })}
                        </StTabBtn>
                    </StTabsContainer>
                    <Formik
                        enableReinitialize
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={{}}
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            onAlbumItemUpdate({...values, altTextDescriptive: !isDecorative})
                            onClose();
                        }}
                        render={() => {
                            return (
                                <FormikForm>
                                    {!isDecorative ? (
                                        <>
                                            <StTextarea 
                                                name="altText"
                                                placeholder={formatMessage({
                                                    id: "addInfoAndAlt.modal.textarea.placeholder",
                                                })}
                                            />
                                            <StHint>
                                                <p>
                                                    {formatMessage({
                                                        id: "addInfoAndAlt.modal.descriptive.text",
                                                    })}
                                                </p>
                                            </StHint>
                                        </>
                                    ) : (
                                        <p>
                                            {formatMessage({
                                                id: "addInfoAndAlt.modal.decorative.text",
                                            })}
                                        </p>
                                    )}
                                    <StFooter>
                                        <Button
                                            content={formatMessage({
                                                id: "addInfoAndAlt.modal.button.save",
                                            })}
                                            type="submit"
                                        />
                                    </StFooter>
                                </FormikForm>
                            );
                        }}
                    />
                </StAltBlock>
            </StModalBody>
        </ModalContainer>
    );
};

AddInfoAndAltModal.propTypes = {
    src: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    hideCancelButton: PropTypes.bool,
    hideHeader: PropTypes.bool,
    imgWidth: PropTypes.number,
    imgHeight: PropTypes.number,
    onAlbumItemUpdate: PropTypes.func,
    altText: PropTypes.string,
};

AddInfoAndAltModal.contextTypes = {
    intl: PropTypes.object,
};

export default AddInfoAndAltModal;

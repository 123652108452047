import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import {withFormik} from 'formik';
import get from "lodash/get";

import { start as loaderStart, stop as loaderStop } from "packages/redux/modules/pageLoader/actions";
import { open as openErrorModal } from "packages/redux/modules/modalYesNo/actions";
import downgradeToFree from "@graphql/gql/website/downgradeToFree.gql";
import billingSubscriptionsGet from "@graphql/gql/website/billingSubscriptionsGet.gql";
import billingHistoryGet from "@graphql/gql/website/billingHistoryGet.gql";
import billingCreateOneTimeCheckoutSession from '@graphql/gql/website/billingCreateOneTimeCheckoutSession.gql';
import surveySend from '@graphql/gql/website/surveySend.gql';

import DowngradeToFreeModal from "@configurator/components/billing/modals/downgradeToFree";
import { ANALYTICS } from "packages/mixpanel/Mixpanel";

export const downgradeToFreeModalWrapper = (Component) =>
    compose(
        connect(
            // mapStateToProps
            (state) => ({}),
            // mapDispatchToProps
            (dispatch) => ({
                openErrorModal: ({
                    headerMessageId,
                    yesMessageId,
                    subMessageId,
                }) =>
                    dispatch(
                        openErrorModal({
                            headerMessageId,
                            yesMessageId,
                            subMessageId,
                        })
                    ),
                loaderStart: () => dispatch(loaderStart()),
                loaderStop: () => dispatch(loaderStop()),
            })
        ),
        graphql(downgradeToFree, {
            props({
                mutate,
                ownProps: { openErrorModal, loaderStart, loaderStop },
            }) {
                return {
                    async downgradeToFree() {
                        try {
                            loaderStart();
                            const res = await mutate({
                                refetchQueries: [
                                    {
                                        query: billingSubscriptionsGet,
                                    },
                                    {
                                        query: billingHistoryGet,
                                    },
                                ],
                            });
                            loaderStop();
                            if (
                                get(res, "data.downgradeToFree.errors._error")
                            ) {
                                openErrorModal({
                                    headerMessageId: "error.header",
                                    yesMessageId: "OK",
                                    subMessageId: get(
                                        res,
                                        "data.downgradeToFree.errors._error"
                                    ),
                                });
                            }
                            ANALYTICS.downgradeToFree();
                            return res;
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        graphql(billingCreateOneTimeCheckoutSession, {
            props({mutate, ownProps: {openErrorModal, loaderStart, loaderStop}}) {
                return {
                    async billingCreateOneTimeCheckoutSession() {
                        try {
                            loaderStart();
                            const res = await mutate();
                            loaderStop();
                            if (get(res, 'data.billingCreateOneTimeCheckoutSession.errors._error')) {
                                openErrorModal({
                                    headerMessageId: 'error.header',
                                    yesMessageId: 'OK',
                                    subMessageId: get(res, 'data.billingCreateOneTimeCheckoutSession.errors._error'),
                                });
                            }
                            return res;
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        graphql(surveySend, {
            props({mutate}) {
                return {
                    async surveySend({
                        answers,
                        surveyType,
                    }) {
                        try {
                            return await mutate({
                                variables: {
                                    answers,
                                    surveyType,
                                },
                            });
                        } catch (err) {
                            return {
                                success: false,
                            };
                        }
                    },
                };
            },
        }),
        withFormik({
            mapPropsToValues: ({ initialValues }) => ({
                reasonIndex: 0,
                messageAnswer: '',
                ...initialValues,
            }),
            enableReinitialize: true,
            validateOnBlur: false,
            validateOnChange: false,
            handleSubmit: async (
                { reasonQuestion, reasonAnswer, messageQuestion, messageAnswer },
                {
                    setSubmitting,
                    setStatus,
                    props: {
                        downgradeToFree,
                        surveySend,
                        onClose,
                    },
                }
            ) => {
                let defaultError = "error.desc";
                try {
                    surveySend({
                        surveyType: 'DOWNGRADE',
                        answers: [
                            {question: reasonQuestion, answer: reasonAnswer},
                            ...(messageAnswer ? [{question: messageQuestion, answer: messageAnswer}] : []),
                        ],
                    });
                    const res = await downgradeToFree();
                    let {
                        data: {
                            downgradeToFree: {errors, success} = {},
                        },
                    } = res;
                    if (!success) {
                        setSubmitting(false);
                        setStatus({error: errors._error || defaultError});
                    } else {
                        onClose();
                    }
                } catch (err) {
                    setSubmitting(false);
                    setStatus({
                        error: __PRODUCTION__ ? defaultError : err.toString(),
                    });
                }
            },
        })
    )(Component);

export default downgradeToFreeModalWrapper(DowngradeToFreeModal);

import { useState, useLayoutEffect } from 'react';

export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useLayoutEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);

    return matches;
};

export const useMediaBreakpoints = () => {
    const xs = useMediaQuery('(max-width: 320px)');
    const sm = useMediaQuery('(max-width: 375px)');
    const md = useMediaQuery('(max-width: 768px)');
    const lg = useMediaQuery('(max-width: 1024px)');
    const xl = useMediaQuery('(max-width: 1200px)');
    const xxl = useMediaQuery('(min-width: 1201px)');

    return {xs, sm, md, lg, xl, xxl};
};

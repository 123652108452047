import React from 'react';
import PropTypes from 'prop-types';
import {intlShape} from 'react-intl';

import {ShowIcon} from 'packages/components/icons';

import TotalInfo from './TotalInfo';
import {VisitorsNowContainer} from './nodes';

const TotalVisitorsNow = ({count, loading}, {intl: {formatMessage, formatNumber}}) => (
    <VisitorsNowContainer>
        <TotalInfo
            icon={<ShowIcon color="white"/>}
            count={formatNumber(count, {style: 'unit'})}
            text={formatMessage({id: 'statistics.newDesign.total.visitors'})}
            loading={loading}
        />
    </VisitorsNowContainer>
);

TotalVisitorsNow.propTypes = {
    count: PropTypes.number,
    loading: PropTypes.bool,
};

TotalVisitorsNow.defaultProps = {
    count: 0,
    loading: false,
};

TotalVisitorsNow.contextTypes = {
    intl: intlShape,
};

export default TotalVisitorsNow;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {Input} from 'semantic-ui-react';
import {CustomPicker} from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import {CaretDownIcon, PlusIcon} from '../../icons';
import {
    hslObjToHex,
    hslObjToRgbaString,
    hexToHslObj,
    hslObjToHsvObj,
    hsvObjToHslObj,
    rgbaStringToHslObj,
    makeHslString,
} from './utils';
import {
    Wrapper,
    SvgContainer,
    InputContainer,
    InputColorBox,
    InputText,
    InputField,
    DropdownContainer,
    HueContainer,
    SaturationContainer,
    HslText,
    SavedColors,
    SavedColor,
    AddColor,
} from './nodes';

const ColorPickerComponent = (
    {
        value,
        disabled,
        onColorChange,
        savedColors,
        onColorAdd,
        showSavedColors,
        // onColorRemove,
    },
) => {
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState(hexToHslObj(value));
    const [inputValue, setInputValue] = useState(hslObjToHex(hexToHslObj(value)));
    const wrapperRef = useRef(null);

    useEffect(() => {
        const hslObj = hexToHslObj(value);
        setColor(hslObj);
        setInputValue(hslObjToHex(hslObj));
    }, [value]);

    const handleColorPick = useCallback((hslObj) => {
        setColor(hslObj);
        setInputValue(hslObjToHex(hslObj));
        onColorChange(hslObjToHex(hslObj, true));
    }, [onColorChange]);

    const handleInputChange = useCallback((e) => {
        const eventValue = e.target.value.replace('#', '').slice(0, 6);
        setInputValue(eventValue);
        const isHex = /^[0-9A-F]{6}$/i.test(eventValue);
        if (isHex) {
            const hslObj = hexToHslObj(eventValue, color.a);
            setColor(hslObj);
            onColorChange(hslObjToHex(hslObj, true));
        }
    }, [color.a, onColorChange]);

    const handleSavedColorClick = useCallback((rgbaString) => {
        setColor(rgbaStringToHslObj(rgbaString));
        setInputValue(hslObjToHex(rgbaStringToHslObj(rgbaString)));
        onColorChange(hslObjToHex(rgbaStringToHslObj(rgbaString), true));
    }, [onColorChange]);

    const handleInputFocus = useCallback(() => {
        if (!disabled) {
            setOpen(true);
        }
    }, [disabled]);

    const handleIconClick = useCallback(() => {
        if (!disabled) {
            setOpen(!open);
        }
    }, [disabled, open]);

    const handleAddColor = useCallback(() => {
        onColorAdd(hslObjToHex(color, true));
    }, [onColorAdd, color]);

    const handleClickOutside = useCallback((e) => {
        if (wrapperRef && !wrapperRef.current.contains(e.target)) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside); };
    }, [handleClickOutside]);

    return (
        <Wrapper ref={wrapperRef}>
            <InputContainer>
                <InputColorBox color={hslObjToRgbaString(color)}/>
                <InputText>#</InputText>
                <InputField
                    onFocus={handleInputFocus}
                    value={inputValue}
                    onChange={handleInputChange}
                />
                <SvgContainer open={open} onClick={handleIconClick}>
                    <CaretDownIcon/>
                </SvgContainer>
            </InputContainer>
            {open && (
                <DropdownContainer>
                    {savedColors && showSavedColors ? (
                        <SavedColors>
                            {savedColors.map(color => (
                                <SavedColor
                                    color={color}
                                    onClick={() => handleSavedColorClick(color)}
                                />
                            ))}
                            <AddColor onClick={handleAddColor}>
                                <PlusIcon/>
                            </AddColor>
                        </SavedColors>
                    ) : null}
                    <SaturationContainer>
                        <Saturation
                            hsl={color}
                            hsv={hslObjToHsvObj(color)}
                            onChange={(hsvObj) => handleColorPick(hsvObjToHslObj(hsvObj))}
                        />
                        <HslText>
                            {makeHslString(color)}
                        </HslText>
                    </SaturationContainer>
                    <HueContainer>
                        <Hue
                            hsl={color}
                            onChange={handleColorPick}
                        />
                    </HueContainer>
                </DropdownContainer>
            )}
        </Wrapper>
    );
};

ColorPickerComponent.propTypes = {
    ...Input.propTypes,
    value: PropTypes.string,
    onColorChange: PropTypes.func,
    savedColors: PropTypes.array,
    onColorAdd: PropTypes.func,
    showSavedColors: PropTypes.bool,
    // onColorRemove: PropTypes.func,
};

ColorPickerComponent.defaultProps = {
    ...Input.defaultProps,
    value: '#FF0000',
    onColorChange: () => {},
    savedColors: ['rgba(0, 255, 0, 1)', 'rgba(0, 0, 0, 1)', 'rgba(255, 0, 0, 1)'],
    onColorAdd: () => {},
    showSavedColors: false,
    // onColorRemove: () => {},
};

export const ColorPicker = CustomPicker(ColorPickerComponent);

import {combineReducers} from 'redux-immutable';
import {connectRouter} from 'connected-react-router/immutable';
import intlReducer from 'packages/intl/redux';
import {routingReducer, userAgentReducer} from 'packages/redux';
import {history} from './initialMiddlewares';
import adminReducer from '@admin/redux/modules/admin/reducers';
import errorModalReducer from 'packages/redux/modules/modalYesNo/reducers';
import modalConfirmDeleteReducer from 'packages/redux/modules/modalConfirmDelete/reducers';
import pageLoaderReducer from 'packages/redux/modules/pageLoader/reducers';
import userReducer from 'packages/redux/modules/user/reducers';
import modalProgressReducer from 'packages/redux/modules/modalProgress/reducers';
import selectedImageReducer from './modules/selectedImages/reducers';
import previewReducer from './modules/previewReducer';
import uploadPhotoReducer from './modules/uploadPhotoReducer';
import subscriptionReducer from './modules/subscription/reducers';
import periodPickerReducer from './modules/periodPicker/reducers';
import accordionReducer from './modules/accordion/reducers';
import downgradeReducer from './modules/downgrade/reducers';
import navigationReducer from './modules/navigation';
import albumViewReducer from './modules/albumView/reducers';
import templatesColorReducer from './modules/templatesColor/reducers';


export default combineReducers({
    intl: intlReducer,
    router: connectRouter(history),
    // для логирования
    routerHook: routingReducer,
    userAgent: userAgentReducer,
    errorModal: errorModalReducer,
    modalConfirmDelete: modalConfirmDeleteReducer,
    pageLoader: pageLoaderReducer,
    user: userReducer,
    modalProgress: modalProgressReducer,
    preview: previewReducer,
    uploadPhotos: uploadPhotoReducer,
    selectedImages: selectedImageReducer,
    subscription: subscriptionReducer,
    periodPicker: periodPickerReducer,
    accordion: accordionReducer,
    downgrade: downgradeReducer,
    admin: adminReducer,
    navigation: navigationReducer,
    albumView: albumViewReducer,
    templatesColor: templatesColorReducer,
});

import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape } from "react-intl";
import {toast} from 'react-toastify';
import { CooliconIcon } from "packages/components/icons";
import { Button, Title } from "./nodes";

const CopyToClipboardButton = ({
    intl: { formatMessage },
    textToCopy,
    children,
    successMessage,
}) => {
    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        successMessage && toast.success(successMessage, {position: 'top-center'});
    }
    return (
        <Button type="button" onClick={handleClick}>
            <CooliconIcon />
            <Title>{formatMessage({ id: "copyToClipboard.title" })}</Title>
            {children}
        </Button>
    );
};

CopyToClipboardButton.propTypes = {
    intl: intlShape.isRequired,
    textToCopy: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
};

CopyToClipboardButton.defaultProps = {
    successMessage: '',
};

export default injectIntl(CopyToClipboardButton);

import styled from 'styled-components';
import {media} from 'packages/components/media';

export const Container = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-content: flex-start;
   min-height: ${props => props.minHeight}px;

   > div {
        //width: 30.3%;
        //min-width: 100px;
   }

    ${media.max.tablet`
        margin-right: -10px;
    `};

    @media (pointer: coarse) {
        user-select: none;
    }
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.expandedView ? 'row' : 'column'};
  width: ${props => props.expandedView ? '50%' : '100%'};
  margin: 20px 0;
  .ui.button {
    height: 45px !important;
    &:active {
      transform: none !important;
    }
  }

    div {
        width: 100%;

        + .ui.button {
            ${props => props.expandedView ? 'margin-left' : 'margin-top'}: 10px !important;
        }
    }

    ${media.between('phone', 'tablet')`
        flex-direction: row;

        .ui.button + .ui.button {
            margin-top: 0 !important;
            margin-left: 8px !important;
        }
    `}
`;

import PropTypes from 'prop-types';
import React from 'react';

import {Modal} from 'packages/components/modal';
import {Button} from 'packages/components/buttons';

import {
    Content,
    Header,
    ButtonRow,
} from './nodes';


const ChangeTemplateModal = (
    {
        open,
        onClose,
        onClickYes,
    },
    {intl: {formatMessage}},
) => {

    return (
        <Modal
            open={open}
            closeOnDocumentClick
            onClose={onClose}
            size="mini"
            closeIcon
            styles={{
                width: '380px',
                paddingLeft: '30px',
                paddingRight: '30px',
                paddingTop: '30px',
                paddingBottom: '30px',
            }}
        >
            <Content>
                <Header>
                    {formatMessage({id: 'template.change.header'})}
                </Header>
                <span>{formatMessage({id: 'template.change.message'})}</span>
                <span>{formatMessage({id: 'template.change.question'})}</span>
                <ButtonRow>
                    <Button
                        content={formatMessage({id: 'template.change.button.cancel'})}
                        onClick={onClose}
                        view="primaryGray"
                    />
                    <Button
                        content={formatMessage({id: 'template.change.button.ok'})}
                        onClick={onClickYes}
                        green
                    />
                </ButtonRow>
            </Content>
        </Modal>
    );
};

ChangeTemplateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onClickYes: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

ChangeTemplateModal.defaultProps = {
    open: false,
};

ChangeTemplateModal.contextTypes = {
    intl: PropTypes.object.isRequired,
};

export default ChangeTemplateModal;

/* eslint-disable */
import React from 'react';
import {iconColors, iconColorPropTypes} from '../const';

export const CaretDownIcon = ({ color }) => (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M-2.18557e-07 -2.38419e-06L5 5L10 -2.8213e-06L-2.18557e-07 -2.38419e-06Z"
            fill={iconColors[color]}
        />
    </svg>
);

CaretDownIcon.propTypes = {
    ...iconColorPropTypes,
};

CaretDownIcon.defaultProps = {
    color: 'dark',
};

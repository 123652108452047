import styled from 'styled-components';
import {media} from 'packages/components/media';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 13px;
    padding: 30px 110px;
    margin: 0 auto;

    ${media.max.tablet`
        padding: 30px 20px;
    `}

    ${media.max.phone`
        padding: 0 0 50px;
    `}
`;

export const HeaderContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    ${media.max.tablet`
        flex-direction: column;
    `}
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${props => props.width ? props.width * 6.25 : 6.25}%;
    padding: 5px;

    > div + div {
        padding-top: 10px;
    }

    ${media.max.tablet`
        width: 100%;
        padding: 5px 0;

        > div {
            border-radius: 0;
        }
    `}
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;

    ${media.between('phone', 'tablet')`
        &:last-child {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            ${Column} {
                width: 49%;

                &:last-child {
                    width: 100%;
                }

                > div {
                    height: 100%;
                }
            }
        }
    `}

    ${media.max.tablet`
        flex-direction: column;
    `}
`;

export const Header = styled.div`
    color: #343840;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 150%;
    margin-bottom: 5px;
    padding-bottom: 10px;
    width: 100%;
`;

export const Loader = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
`;

export const GroupWrapper = styled.div`
    width: 100%;
    height: max-content;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    ${media.max.tablet`
        flex-direction: row;
        flex-wrap: wrap;

        > div {
            width: 50%;
        }
    `}

    ${media.max.phone`
        padding: 10px 0;
    `}
`;

import { createSelector } from "reselect";

export const metaSelector = createSelector(
    (state) => state.getIn(["preview", "meta"]),
    (meta) => meta.toJS()
);

export const templateLogoMarginsSelector = createSelector(
    (state) => state.getIn(["preview",'meta',"templateLogoMargins"]),
    (data) => data ? data.toJS() : {top:0, bottom:0}
);

export const structureSelector = createSelector(
    (state) => state.getIn(["preview", "structure"]),
    (structure) => structure.toJS()
);

const configuratorDomainSelector = (state) =>
    state.getIn(["preview", "meta", "domain"]);
const portfolioDomainSelector = (state) => state.getIn(["meta", "domain"]);

export const domainSelector = createSelector(
    configuratorDomainSelector,
    portfolioDomainSelector,
    (configuratorDomain, portfolioDomain) =>
        configuratorDomain || portfolioDomain
);

const premDomain = state => state.getIn(["preview", 'meta', 'premiumDomain']);

export const premiumDomainSelector = createSelector(
    premDomain, (premDomain) => premDomain,
);
import {handleActions} from 'redux-actions';
import {Map, fromJS} from 'immutable';
import {setDragCurrentOffset} from '@configurator/components/navigation/action';


export default handleActions(
    {
        [setDragCurrentOffset]: (state, {payload}) => {
            return state.set('dragCurrentOffset',
                fromJS(payload || {}),
            );
        },
    },
    Map({dragCurrentOffset: {}}),
);

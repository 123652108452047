import styled from 'styled-components';

const BACKGROUND_HOVER_COLOR = '#E1E2E6';
const TRASH_HOVER_COLOR = '#FF002E';
const SETTINGS_HOVER_COLOR = '#666A73';
const STRUCTURE_LINES_COLOR = '#666A73';
const TEXT_COLOR = '#343840';
const VISIBILITY_OFF_TEXT_COLOR = '#C1C3C7';

export const Container = styled.div`
    position: relative;

    :hover {
        background: ${props => props.isDragging ? undefined : BACKGROUND_HOVER_COLOR};
    }
`;

export const IconWrapper = styled.div`
    ${props => props.paddingL ? `padding-left: ${props.paddingL}px;` : ''}
    ${props => props.paddingR ? `padding-right: ${props.paddingR}px;` : ''}
    line-height: 0;
`;

export const RightIconsBlock = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;

    span:first-child {
        &:hover svg path {
            fill: ${TRASH_HOVER_COLOR};
        }
    }

    span:nth-child(2) {
        padding: 6px;

        &:hover svg path {
            fill: ${SETTINGS_HOVER_COLOR};
        }
    }

    > span {
        line-height: 0;
        margin-left: 4px;

        svg {
            visibility: hidden;
        }
    }

    > svg {
        margin-left: 15px;
    }
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${props => props.isDragging && (!props.isOver || !props.justDrag) ? '0' : '40px'};
    opacity: ${props => props.isDragging ? '0' : '1'};
    transition: height 0.2s cubic-bezier(0.2, 0, 0, 1) 0.1s;
    z-index: 2;

    > span {
        z-index: 1;
        color: ${props => props.isVisible ? TEXT_COLOR : VISIBILITY_OFF_TEXT_COLOR};
        text-decoration: ${props => props.isShowHeader ? 'none' : 'line-through'};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 170px;
    }

    &:hover ${RightIconsBlock} > span svg {
        visibility: visible;
    }

    ${RightIconsBlock} > svg {
        visibility: ${props => props.isLocked ? 'visible' : 'hidden'};
    }
`;

export const StructureBlock = styled.div`
    position: relative;
    width: 35px;
    height: inherit;

    ::before {
        opacity: 1 !important;
        content: '';
        display: block;
        position: absolute;
        right: 30px;
        top: 0;
        height: ${props => props.last ? '21px' : '100%'};
        border-left: 1px solid ${STRUCTURE_LINES_COLOR};
    }

    ::after {
        opacity: 1 !important;
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 10px;
        border-top: 1px solid ${STRUCTURE_LINES_COLOR};
    }
`;

export const LayerContainer = styled.div`
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    opacity: 1;
    width: max-content !important;
`;


export const DropZone = styled.div`
    height: ${props => props.show ? '40px' : '0px'};
    transition: height 0.2s cubic-bezier(0.2, 0, 0, 1) 0.1s;
    background: ${props => props.show ? 'green' : ''};
    opacity: ${props => props.show ? '0.1' : ''};
`;

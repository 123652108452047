export const SERVICE = {
    configurator: "configurator",
    landing: "landing",
    admin: "admin",
};

export const mobileBreakpoint = {
    thin: 768,
    veryThin: 414, // iPhone 6 Plus vertical
};

export const COOKIE = {
    token: "wbt",
    locale: "l",
    page: "page",
    pageProtection: "page-protection",
};

export const HEADER = {
    authorization: "Authorization",
    pageProtection: "x-page-protection",
    userAgent: "user-agent",
    forwarded: "x-forwarded-for",
};

export const PERIOD = {
    oneYear: 31536e3,
    neverExpires: new Date(2147483647000),
};

export const URLS = {
    root: "/",
    website_account: "/account",
    website_login: "/account/login",
    website_create_free_account: "/account/free/create-account",
    website_create_account: "/account/create-account",
    website_reset_password: "/account/reset-password",
    website_new_password: "/account/new-password/:token",
    website_stripe_redirect: "/account/stripe/redirect",
    website_about: "/account/about",

    website_root: "/website",
    website_config: "/website/config",

    website_config_style: "/website/config/style",
    website_edit_template: "/website/template",
    website_settings: `/website/settings`,
    website_seo: `/website/seo`,
    website_billing: "/website/billing",
    website_bootstrap: "/website/bootstrap",

    legal: "/legal",
    legal_terms: "/terms-conditions",
    legal_privacy: "/privacy",
    legal_imprint: "/imprint",
    legal_cookies: "/cookie-policy",

    website_statistics: "/website/statistics",
    website_integrations: "/website/integrations",
    website_integrations_details_ecwid: "/website/integrations/ecwid",
    website_integrations_details_tawk: "/website/integrations/tawk",
    website_integrations_details_livechat: "/website/integrations/livechat",
    website_integrations_details_calendly: "/website/integrations/calendly",
    website_integrations_details_helpspace: "/website/integrations/helpspace",
    website_integrations_details_chatra: "/website/integrations/chatra",
    website_integrations_details_tidio: "/website/integrations/tidio",
    website_integrations_details_optinly: "/website/integrations/optinly",
    website_integrations_details_blogger: "/website/integrations/blogger",
    website_integrations_details_weglot: "/website/integrations/weglot",
    website_integrations_details_typeform: "/website/integrations/typeform",
    website_integrations_details_jotform: "/website/integrations/jotform",
    website_integrations_details_dropinblog: "/website/integrations/dropinblog",
    website_statistics_subscription: `/website/statisticsSubscription`,
    landing_why: "/why-vsble",
    landing_features: "/features",
    landing_pricing: "/pricing",

    admin: "/admin",
    admin_users: "/admin/users-list",
};

export const CURRENCY = {
    EUR: "EUR",
    USD: "USD",
};

export const EU_COUNTRIES_CODE = {
    AT: "AT",
    BE: "BE",
    BG: "BG",
    HU: "HU",
    DE: "DE",
    GR: "GR",
    DK: "DK",
    IE: "IE",
    ES: "ES",
    IT: "IT",
    CY: "CY",
    LV: "LV",
    LT: "LT",
    LU: "LU",
    MT: "MT",
    NL: "NL",
    PL: "PL",
    PT: "PT",
    RO: "RO",
    SK: "SK",
    SI: "SI",
    FI: "FI",
    FR: "FR",
    HR: "HR",
    CZ: "CZ",
    SE: "SE",
    EE: "EE",
    AX: "AX",
    AL: "AL",
    AD: "AD",
    BY: "BY",
    BA: "BA",
    VA: "VA",
    GB: "GB",
    IS: "IS",
    XK: "XK",
    LI: "LI",
    MK: "MK",
    MD: "MD",
    MC: "MC",
    NO: "NO",
    RU: "RU",
    SM: "SM",
    RS: "RS",
    UA: "UA",
    ME: "ME",
    CH: "CH",
    SJ: "SJ",
}

export const WEBSITE_BLOCK = {
    SLIDER: "SLIDER",
    ALBUM_LIST: "ALBUM_LIST",
    ALBUM_DETAIL: "ALBUM_DETAIL",
    MAIN: "MAIN",
    CONTACTS: "CONTACTS",
    HTML: "HTML",
    TEXT: "TEXT",
    PASSWORD_FORM: "PASSWORD_FORM",
    COOKIE_PAGE: "COOKIE_PAGE",
};

export const PORTFOLIO_TEMPLATES = {
    ANTON: "anton",
    SOPHIE: "sophie",
    NIKOLAI: "nikolai",
    CATHERINE: "catherine",
    PARIS: "paris",
    BROOKLYN: "brooklyn",
    NEW_YORK: "newyork",
    SYDNEY: "sydney",
    BERLIN: "berlin",
    ANTWERPEN: "antwerpen",
    BARCELONA: "barcelona",
    PAVEL: "pavel",
    MADRID: "madrid",
    RIGA: "riga",
    STOCKHOLM: "stockholm",
    VIEN: "vien",
    OSLO: "oslo",
    ROTTERDAM: "rotterdam",
    LOS_ANGELES: "losangeles",
    MARSEILLE: "marseille",
    SEVILLA: "sevilla",
    MEMPHIS: "memphis",
    SACRAMENTO: "sacramento",
    EDMONTON: "edmonton",
    GOTHENBURG: "gothenburg",
};

export const PERMISSIONS_ALL_PORTFOLIO_TEMPLATES = "ALL";

export const CONTACT_FORM_ALIGN = {
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right",
};

export const PORTFOLIO_TEMPLATES_ALIGN = {
    LEFT: "LEFT",
    CENTER: "CENTER",
};

export const WEBSITE_ALBUM_ITEM_TYPE = {
    PHOTO: "PHOTO",
    VIDEO: "VIDEO",
};

export const VIDEO_HOSTING = {
    VIMEO: "VIMEO",
    YOUTUBE: "YOUTUBE",
};

export const WEBSITE_MODE = {
    PUBLIC: "PUBLIC",
    PRIVATE: "PRIVATE",
};

export const PAGE_TYPES = {
    MAIN: "MAIN_PAGE",
    FOOTER: "FOOTER_PAGE",
};

export const ALBUM_TYPES = {
    MAIN: "MAIN_ALBUM",
    FOOTER: "FOOTER_ALBUM",
};

export const WEBSITE_PAGE_TYPES = {
    PHOTO: "PHOTO",
    VIDEO: "VIDEO",
    TEXT: "TEXT",
    HTML: "HTML",
    CONTACTS: "CONTACTS",
    EXTERNAL_LINK: "EXTERNAL_LINK",
};

export const WEBSITE_PAGE_BLOCK_TYPES = {
    ECWID: "ecwid",
    SCRIPT: "script",
};

export const WEBSITE_NAVIGATION_PAGE_TYPES = {
    ALBUM_LIST: "ALBUM_LIST",
    CONTACTS: "CONTACTS",
    HTML: "HTML",
    TEXT: "TEXT",
    EXTERNAL_LINK: "EXTERNAL_LINK",
};

export const SOCIAL_LINK_NAMES = {
    facebook: "facebook",
    instagram: "instagram",
};

export const SOCIAL_LINK_URLS = {
    facebook: "www.facebook.com/",
    instagram: "www.instagram.com/",
};

export const HOSTING_URLS = {
    VIMEO: "https://vimeo.com/",
    YOUTUBE: "https://www.youtube.com/watch?v=",
};

export const LOCALE = {
    en: "en",
    de: "de",
    fr: "fr",
    ja: "ja",
};

export const UPLOAD_STEPS = {
    UPLOADING: "uploading",
    WAITING: "waiting",
    READY: "ready",
    GENERATING_PREVIEWS: "generating_previews",
    GENERATING_THUMBNAILS: "generating_thumbnails",
    COMPRESSING: "compressing",
};

export const VAT_STATES = {
    VALID: "valid",
    INVALID: "invalid",
    UNKNOWN: "unknown",
};

export const PAYMENT_STEPPER_TYPES = {
    BILLING_UPGRADE: "BILLING_UPGRADE",
    CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
    RENEW_SUBSCRIPTION: "RENEW_SUBSCRIPTION",
};

export const PAYMENT_TYPE = {
    CREDIT_CARD: "CREDIT_CARD",
    INVOICE: "INVOICE",
}

export const SUBSCRIPTION_TYPE = {
    FREE: "FREE",
    UNPAID: "UNPAID",
    PAID: "PAID",
    TRIAL: "TRIAL",
    LIFETIME_PRO: "LIFETIME_PRO",
};

export const SUBSCRIPTION_CARD_TYPE = {
    FREE: "FREE",
    PROLITE: "PROLITE",
    PRO: "PRO",
    PROMAX: "PROMAX",
    LIFETIME_PRO: "LIFETIME_PRO",
};

export const PREMIUM_STATUSES = {
    DNS_CHECKING: "DNS_CHECKING",
    DNS_CHECKING_FAILED: "DNS_CHECKING_FAILED",
    DNS_VALID: "DNS_VALID",
    CERT_REQUEST_FAILED: "CERT_REQUEST_FAILED",
    READY: "READY",
    ACTIVE: "ACTIVE",
    FAILURE: "FAILURE",
    DELETE: "DELETE",
    READY_FOR_CONF: "READY_FOR_CONF",
    READY_FOR_CERT: "READY_FOR_CERT",
    DNS_CHECKING_WRONG_IPV4:"DNS_CHECKING_WRONG_IPV4",
    DNS_CHECKING_CONSTRAINT_IPV6:'DNS_CHECKING_CONSTRAINT_IPV6',
    DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6:"DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6",
    DNS_CHECKING_UNREGISTERED_DOMAIN:"DNS_CHECKING_UNREGISTERED_DOMAIN",
    GENERIC_ERROR:"GENERIC_ERROR"
};

export const BLOCK_PROPERTY_KEYS = {
    userPhoto: "userPhoto",
    userPhotoMediaUrl: "userPhotoMediaUrl",
    userPhotoText: "userPhotoText",
    contactFormAlign: "contactFormAlign",
    contactFormLang: "contactFormLang",
    content: "content",
};

export const FEATURE_PROPERTY_KEYS = {
    pdf: "pdf",
    pdfLogoSize: "pdf_logo_size",
    slider: "slider",
    font: "font",
    footer: "footer",
    logo: "logo",
    logoMediaUrl: "logoMediaUrl",
    header: "header",
    subheader: "subheader",
    transitionSpeed: "transitionSpeed",
    album: "album",
    objectFit: "objectFit",
    sliderType: "sliderType",
    sliderVideoUrl: "sliderVideoUrl",
    logoColor: "logoColor",
    navigationColor: "navigationColor",
    albumTitleColor: "albumTitleColor",
    albumDescriptionColor: "albumDescriptionColor",
    style: "style",
    contactForm: "contactForm",
    contactFormLang: "contactFormLang",
    contactFormAlign: "contactFormAlign",
    openGraph: "open_graph",
    openGraphImageMediaUrl: "open_graph_imageMediaUrl",
    openGraphImage: "open_graph_image",
    LOADER_ANIMATION_CUSTOM: "LOADER_ANIMATION_CUSTOM",
    LOADER_ANIMATION_VARIANT: "LOADER_ANIMATION_VARIANT",
    LOADER_ANIMATION_COLOR: "LOADER_ANIMATION_COLOR",
    FOOTER_OVER_SLIDESHOW: "FOOTER_OVER_SLIDESHOW",
    SCROLLABLE_HEADER: "SCROLLABLE_HEADER"
};

export const OBJECT_FIT_KEYS = {
    contains: "contains",
    cover: "cover",
};

export const SLIDER_TYPE_KEYS = {
    photo: "photo",
    video: "video",
};

export const LOCATION_TYPE = {
    MAIN_PAGE: "MAIN_PAGE",
    DETAILS_PAGE: "DETAILS_PAGE",
    UNKNOWN_PAGE: "UNKNOWN_PAGE",
};

export const CONTACT_FORM_LOCALE = {
    en: "en",
    de: "de",
    fr: "fr",
    ja: "ja",
    sp: "sp",
    ru: "ru",
};

export const STYLE_FEATURE_PROPERTIES = {
    HEADER_LINKS_COLOR: "HEADER_LINKS_COLOR",
    HEADER_LINKS_SIZE: "HEADER_LINKS_SIZE",
    HEADER_LINKS_FONT_NAME: "HEADER_LINKS_FONT_NAME",
    HEADER_LINKS_FONT_ID: "HEADER_LINKS_FONT_ID",
    HEADER_LINKS_FONT_SOURCE: "HEADER_LINKS_FONT_SOURCE",
    HEADER_LOGO_COLOR: "HEADER_LOGO_COLOR",
    HEADER_LOGO_SIZE: "HEADER_LOGO_SIZE",
    HEADER_LOGO_MARGIN_TOP:"HEADER_LOGO_MARGIN_TOP",
    HEADER_LOGO_MARGIN_BOTTOM:"HEADER_LOGO_MARGIN_BOTTOM",
    HEADER_LOGO_FONT_NAME: "HEADER_LOGO_FONT_NAME",
    HEADER_LOGO_FONT_ID: "HEADER_LOGO_FONT_ID",
    HEADER_LOGO_FONT_SOURCE: "HEADER_LOGO_FONT_SOURCE",
    HEADER_SUBLOGO_COLOR: "HEADER_SUBLOGO_COLOR",
    HEADER_SUBLOGO_SIZE: "HEADER_SUBLOGO_SIZE",
    HEADER_SUBLOGO_FONT_NAME: "HEADER_SUBLOGO_FONT_NAME",
    HEADER_SUBLOGO_FONT_ID: "HEADER_SUBLOGO_FONT_ID",
    HEADER_SUBLOGO_FONT_SOURCE: "HEADER_SUBLOGO_FONT_SOURCE",
    SCROLLABLE_HEADER: "SCROLLABLE_HEADER",
    FOOTER_COLOR: "FOOTER_COLOR",
    FOOTER_SIZE: "FOOTER_SIZE",
    FOOTER_FONT_NAME: "FOOTER_FONT_NAME",
    FOOTER_FONT_ID: "FOOTER_FONT_ID",
    FOOTER_FONT_SOURCE: "FOOTER_FONT_SOURCE",
    FOOTER_LINK_STYLE: "FOOTER_LINK_STYLE",
    FOOTER_SHOW_YEAR: "FOOTER_SHOW_YEAR",
    FOOTER_SHOW_DOMAIN_TITLE: "FOOTER_SHOW_DOMAIN_TITLE",
    FOOTER_OVER_SLIDESHOW: "FOOTER_OVER_SLIDESHOW",
    CONTACTS_COLOR: "CONTACTS_COLOR",
    CONTACTS_SIZE: "CONTACTS_SIZE",
    CONTACTS_FONT_NAME: "CONTACTS_FONT_NAME",
    CONTACTS_FONT_ID: "CONTACTS_FONT_ID",
    CONTACTS_FONT_SOURCE: "CONTACTS_FONT_SOURCE",
    ALBUM_TITLE_COLOR: "ALBUM_TITLE_COLOR",
    ALBUM_TITLE_SIZE: "ALBUM_TITLE_SIZE",
    ALBUM_TITLE_FONT_NAME: "ALBUM_TITLE_FONT_NAME",
    ALBUM_TITLE_FONT_ID: "ALBUM_TITLE_FONT_ID",
    ALBUM_TITLE_FONT_SOURCE: "ALBUM_TITLE_FONT_SOURCE",
    PAGE_NAVIGATION_COLOR: "PAGE_NAVIGATION_COLOR",
    PAGE_NAVIGATION_SIZE: "PAGE_NAVIGATION_SIZE",
    PAGE_NAVIGATION_FONT_NAME: "PAGE_NAVIGATION_FONT_NAME",
    PAGE_NAVIGATION_FONT_ID: "PAGE_NAVIGATION_FONT_ID",
    PAGE_NAVIGATION_FONT_SOURCE: "PAGE_NAVIGATION_FONT_SOURCE",
    ALBUM_DESCRIPTION_COLOR: "ALBUM_DESCRIPTION_COLOR",
    ALBUM_DESCRIPTION_SIZE: "ALBUM_DESCRIPTION_SIZE",
    ALBUM_DESCRIPTION_FONT_NAME: "ALBUM_DESCRIPTION_FONT_NAME",
    ALBUM_DESCRIPTION_FONT_ID: "ALBUM_DESCRIPTION_FONT_ID",
    ALBUM_DESCRIPTION_FONT_SOURCE: "ALBUM_DESCRIPTION_FONT_SOURCE",
    TEXT_BLOCK_COLOR: "TEXT_BLOCK_COLOR",
    TEXT_BLOCK_SIZE: "TEXT_BLOCK_SIZE",
    TEXT_BLOCK_FONT_NAME: "TEXT_BLOCK_FONT_NAME",
    TEXT_BLOCK_FONT_ID: "TEXT_BLOCK_FONT_ID",
    TEXT_BLOCK_FONT_SOURCE: "TEXT_BLOCK_FONT_SOURCE",
    BACKGROUND_COLOR: "BACKGROUND_COLOR",
    HOVER_COLOR: "HOVER_COLOR",
    BURGER_COLOR: "BURGER_COLOR",
    ALBUM_PHOTO_NOTE_COLOR: "ALBUM_PHOTO_NOTE_COLOR",
    ALBUM_PHOTO_NOTE_SIZE: "ALBUM_PHOTO_NOTE_SIZE",
    ALBUM_PHOTO_NOTE_FONT_NAME: "ALBUM_PHOTO_NOTE_FONT_NAME",
    ALBUM_PHOTO_NOTE_FONT_SOURCE: "ALBUM_PHOTO_NOTE_FONT_SOURCE",
    ALBUM_PHOTO_NOTE_FONT_ID: "ALBUM_PHOTO_NOTE_FONT_ID",
    LOADER_ANIMATION_COLOR: "LOADER_ANIMATION_COLOR",
    LOADER_ANIMATION_VARIANT: "LOADER_ANIMATION_VARIANT",
    LOADER_ANIMATION_CUSTOM: "LOADER_ANIMATION_CUSTOM",
    LOADER_ANIMATION_CUSTOMMediaUrl: "LOADER_ANIMATION_CUSTOMMediaUrl",
};

export const STYLE_TYPES = {
    header: "header",
    footer: "footer",
    navigation: "navigation",
    album: "album",
    contacts: "contacts",
    text: "text",
    burgerColor: "burgerColor",
    hoverColor: "hoverColor",
    pageBackground: "pageBackground",
    loaderAnimation: "loaderAnimation",
};

export const MESSAGE_TYPE = {
    UPDATE_PAGE: "update_page",
    UPDATE_APOLLO_STATE: "update_apollo_state",
    UPDATE_META: "update_meta",
    UPDATE_FEATURE: "update_feature",
    UPDATE_FEATURES: "update_features",
    CHANGE_ROUTE: "change_route",
    OPEN_EDIT_PAGE: "open_edit_page",
    SET_LOGO_PREVIEW: "set_logo_preview",
    SET_FAVICON_PREVIEW: "set_favicon_preview",
    HOVER_ALBUM: "hover_album",
    FRAME_LOADED: "frame_loaded",
    CHECK_PATH: "check_path",
    CURRENT_TEMPLATE_MARGINS:"CURRENT_TEMPLATE_MARGINS",
    SET_FEATURE_ENABLED: "set_feature_enabled",
};
export const UPDATE_APOLLO_TYPES = {
    album: "album",
    page: "page",
};

export const PLAN_TYPE = {
    PRO: "PRO",
    PROMAX: "PROMAX",
    PROLITE: "PROLITE",
    UNPAID: "UNPAID",
    LIFETIMEPRO: "LIFETIMEPRO"
};

export const WEBSITE_NAVIGATION_VIEW_MODE = {
    MAIN: "MAIN",
    ALBUM_ITEM_MOVING: "ALBUM_ITEM_MOVING",
};

export const SCRIPT_TYPE = {
    GOOGLE_VERIFICATION_TAG: "google-verification-tag",
};

export const SCRIPT_POSITION = {
    BODY: "BODY",
    FOOTER: "FOOTER",
    HEADER: "HEADER",
};


export const MESSAGES_SPLIT_KEY = "6bfcdb609ac7";


export const FOOTER_STYLE_ITEM_TYPE = {
    allCaps: "uppercase",
    firstCaps: "capitalize",
    lowerCase: "lowercase",
}

import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown as SemanticDropdown} from 'semantic-ui-react';
import {Field} from 'formik';
import {intlShape} from 'react-intl';
import {fieldProptypes} from './formikPropTypes';
import {Dropdown} from '../dropdown';

const SemanticFormDropdown = (
    {
        field: {onChange, onBlur, ...field}, // { name, value, onChange, onBlur }
        form, // : {touched = {}, errors = {}}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        placeholder,
        ...props
    },
) => {
    const onChangeValue = (e, {value}) => onChange(field.name)(value);
    return (
        <Dropdown
            {...field}
            {...props}
            placeholder={placeholder}
            onChange={onChangeValue}
        />
    );
};

SemanticFormDropdown.propTypes = {
    ...fieldProptypes,
    ...SemanticDropdown.propTypes,
};

SemanticFormDropdown.defaultProps = {
    ...SemanticDropdown.defaultProps,
};

SemanticFormDropdown.contextTypes = {
    intl: intlShape,
};

export const FormDropdown = ({name, ...props}) => (
    <Field name={name} {...props} component={SemanticFormDropdown}/>
);

FormDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    ...SemanticDropdown.propTypes,
};

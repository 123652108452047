import PropTypes from 'prop-types';
import React from 'react';
import {FormDropdown} from 'packages/components/inputs';

const CountryDropdown = (props) => {
    return (
        <FormDropdown
            {...props}
            upward={false}
            options={props.options.map(itm => ({
                text: itm.englishName,
                value: itm.id,
            }))}
        />
    );
};


CountryDropdown.propTypes = {
    options: PropTypes.array.isRequired,
};

export default CountryDropdown;

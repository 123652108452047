import styled, {css} from 'styled-components';
import {Modal} from 'packages/components/modal';
import {ModalHeader} from 'packages/components/modal/nodes';

export const StyledModal = styled(Modal)`
    width: 680px !important;
    height: 545px !important;
    padding: 20px 30px !important;

    ${ModalHeader} {
        margin-bottom: 30px;
    }
`;

export const Container = styled.div`
    position: relative;
`;

export const AvailableLoaderContainer = styled.div`
    display: flex;
    height: 120px;
`;

export const LoaderPreviewContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    min-width: 120px;
    background: white;
`;

export const ColorPickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 20px;

    .ui.button {
        height: 45px;
        margin-top: 7px !important;
    }
`;

export const LoadersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    height: 410px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    background: #F6F6F6;
    cursor: pointer;
    box-sizing: border-box;

    ${({isActive}) => isActive && css`
        border: 1px solid #58C367;
    `}
`;

export const Separator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 20px;
    hr {
        width: 100%;
        border: 1px solid #E1E2E6;
    }
    > span {
        text-align: center;
        margin-top: -0.5em;
        line-height: 1em;
        padding: 0 8px;
        font-size: 13px;
        color: #666A73;
        white-space: nowrap;
    }
`;

export const ImageComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    min-width: 120px;
    background-color: white;

    > div {
        width: 65px;
        height: 65px;
    }
`;

export const UploadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #343840;
    border-radius: 2px;
    height: 45px;
    width: 100%;
    color: #FFF;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid #343840;

    &:hover {
        border: 1px solid #666A73;
        background: #666A73;
    }
`;

export const ChangeButton = styled.div`
    color: #58C367;
    background: none;
    cursor: pointer;
    &:focus {
        outline-color: rgba(0, 0, 0, 0);
    }
`;

export const ImageInfoContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    margin-left: 20px;
`;

export const ChangeButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > svg {
        cursor: pointer;
    }
`;

export const UploadContainer = styled.div`
    display: flex;
`;

export const CustomLoaderDescription = styled.span`
    font-size: 13px;
    text-align: left;
    margin-bottom: 20px;
    font-family: ${(props) => props.theme.fonts.fontFamily};
    font-weight: bold;
    line-height: 140%;
    display: block;
    color: #343840;
    > span {
        margin-top: 5px;
        display: block;
        font-weight: normal;
        color: #666A73;
    }
`;

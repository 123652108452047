import React from 'react';
import PropTypes from 'prop-types';

export const MobileIcon = ({className, onClick}) => (
    <svg
        className={className}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle className="iconBg" cx="20" cy="20" r="20" fill="white" onClick={onClick}/>
        <path
            onClick={onClick}
            className="main"
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M13 11C13 9.89543 13.8954 9 15 9H25C26.1046 9 27 9.89543 27 11V29C27 30.1046 26.1046 31 25 31H15C13.8954 31 13 30.1046 13 29V11ZM23 11H25V29H15L15 11H17C17 12.1046 17.8954 13 19 13H21C22.1046 13 23 12.1046 23 11Z"
        />
    </svg>
);

MobileIcon.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

MobileIcon.defaultProps = {
    className: '',
    onClick: () => {
    },
};

import styled from 'styled-components';
import {Checkbox} from 'semantic-ui-react';

export const StyledRadio = styled(Checkbox)`
  width: 28px !important;
  height: 28px !important;

  // box
  & > label:before {
      width: 28px !important;
      height: 28px !important;
      box-sizing: border-box !important;
      background: #FFFFFF !important;
      border: 1px solid #EDEDED !important;
      border-radius: 50% !important;
  }

  &:hover {
    & > label:before {
      border-color: #E1E2E6 !important;
    }
  }
  
  &.disabled:hover {
      & > label:before {
          border-color: #EDEDED !important;
      }
  }

  // check icon
  & > label:after {
    content: '' !important;
    width: 20px !important;
    height: 20px !important;
    border: none !important;
    background: #58C367 !important;
    border-radius: 50% !important;
    position: absolute !important;
    top: 5px !important;
    left: 4px !important;
  }
`;

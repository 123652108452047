import styled, {css} from 'styled-components';
import {media} from 'packages/components/media';
import {StyledConfigPanel} from '@configurator/components/configPanel/nodes';
import {ReturnArrowContainer} from '../header/nodes';
import {Header} from '../header/Header';

const BACKGROUND_COLOR = '#F6F6F6';
const MAIN_COLOR = '#343840';
const LIGHT_GRAY = '#C1C3C7';
const PADDING_TOP = 0;
const PADDING_RIGHT = 20;
const PADDING_BOTTOM = 20;
const PADDING_LEFT = 0;
const HEADER_HEIGHT = 80;
const LIFT_SIDE_MIN_WIDTH = 400;
const RIGHT_SIDE_MIN_WIDTH = 800;

export const Wrapper = styled.div`
    font-size: 13px;
    color: ${MAIN_COLOR};
    background: ${BACKGROUND_COLOR};
    padding: ${PADDING_TOP} 0 ${PADDING_BOTTOM}px ${PADDING_LEFT}px;
`;

export const HeaderStyled = styled(Header)`
    height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    ${media.phone`
        padding-right: 30px;
    `};
    ${media.tablet`
        padding-right: ${PADDING_RIGHT}px;
    `};
`;

export const EllipseWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    margin-left: ${props => props.inverted ? '0' : '10px'};
    width: ${props => props.inverted ? '50px' : '40px'};
    height: ${props => props.inverted ? '50px' : '40px'};
    border-radius: ${props => props.inverted ? '25px' : '20px'};
    background: ${props => props.inverted ? '#EDEDED' : '#343840'};
    color: ${props => props.inverted ? 'inherit' : '#fff'};

    > div {
        font-weight: bold;
        font-size: 15px;
        text-transform: uppercase;
        display: inline-block;
        line-height: 0;
        margin: 0 auto;
        &::after {
            content: '';
            display: inline-block;
            height: 0.7em;
        }
    }
`;

export const UserDropdownHeader = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    text-transform: none;

    .dropdown-header-text div {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 188px;
        :first-child {
            font-size: 15px;
        }
        :last-child {
            font-size: 13px;
            color: ${LIGHT_GRAY}
        }
    }
`;

export const SplitContent = styled.div`
    height: calc(100vh - ${HEADER_HEIGHT + PADDING_TOP + PADDING_BOTTOM}px);
    display: flex;
    padding-right: ${PADDING_RIGHT}px;
    margin-top: 13px;
    ${media.phone`
       margin-top: 0;
    `}

    ${media.max.tablet`
        ${({view}) => view ? css`
            margin-top: 0;
            padding-right: 0;
            ${StyledConfigPanel} {
                display: none;
            }
        ` : css`
            ${MobilePreviewContainer} {
                display: none;
            }
        `}
    `}
`;

export const LeftPanel = styled.div`
    width: 100%;
    height: auto;

    ${media.tablet`
        width: ${props => props.isAlbumExpanded ? '100%' : `${LIFT_SIDE_MIN_WIDTH}px`};
        min-width: ${LIFT_SIDE_MIN_WIDTH}px;
    `}
`;

export const RightPanel = styled.div`
    display: none;

    ${media.tablet`
        flex: auto;
        min-width: ${RIGHT_SIDE_MIN_WIDTH}px;
        display: ${(props) => props.isAlbumExpanded ? 'none' : 'initial'};
    `}
`;

export const MobilePreviewContainer = styled.div`
    ${ReturnArrowContainer} {
        height: 30px;
        padding-left: 20px;
    }
`;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    AvailableLoaderContainer,
    ColorPickerContainer,
    Container,
    LoaderContainer,
    LoaderPreviewContainer,
    LoadersContainer,
    Separator,
    StyledModal,
    ImageComponent,
    UploadContainer,
    UploadButton,
    ChangeButton, ChangeButtonContainer, ImageInfoContainer, CustomLoaderDescription,
} from './nodes';
import {StylesColorPicker} from '@configurator/components/pages/pageStyles/components';
import {loaderVariants} from 'packages/components/customLoader/const';
import {CustomLoader} from 'packages/components/customLoader';
import {injectIntl} from 'react-intl';
import {formProptypes} from 'packages/utils/formikPropTypes';
import {Button, TrashButton} from 'packages/components/buttons';
import {MAX_LOADER_SIZE} from '@configurator/constants/upload';
import Dropzone from 'react-dropzone';
import Image from 'packages/components/image/Image';
import {getDomain, getPropertyMediaUrl} from 'packages/helpers/Helper';
import {usePrevious} from 'packages/helpers/usePrevious';

const Component = ({
    colorFieldName,
    variantFieldName,
    customFieldName,
    values,
    initialValues,
    setFieldValue,
    openErrorModal,
    intl: {formatMessage},
}) => {
    const prevInitialValues = usePrevious(initialValues);
    const [customLoaderFile, setCustomLoaderFile] = useState();
    const [customLoaderUrl, setCustomLoaderUrl] = useState();
    const [changeLoaderModalOpen, setChangeLoaderModalOpen] = useState(false);
    const [variant, setVariant] = useState(initialValues[variantFieldName]);

    useEffect(() => {
        if (!values[customFieldName] || prevInitialValues?.[getPropertyMediaUrl(customFieldName)] !== initialValues?.[getPropertyMediaUrl(customFieldName)]) {
            setCustomLoaderUrl();
        }
    }, [customFieldName, initialValues, prevInitialValues, values]);

    const handleSubmit = () => {
        setFieldValue(variantFieldName, variant);
        setChangeLoaderModalOpen(false);
    };

    const handleDropFile = (files) => {
        if (files && files.length) {
            const file = files[0];
            setCustomLoaderFile(file);
            setCustomLoaderUrl(window.URL.createObjectURL(file));
            setFieldValue(customFieldName, file);
        }
    };

    const onDropRejected = (files, accept, maxSize) => {
        if (files.find((el) => el.size > maxSize)) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileSize.description",
                subMessageValues: { size: maxSize / 1024 / 1024 },
            });
        }
        const acceptArr = accept.split(", ");
        if (files.find((el) => !acceptArr.includes(el.type))) {
            return openErrorModal({
                headerMessageId: "uploadFile.error.header",
                yesMessageId: "uploadFile.error.ok",
                subMessageId: "uploadFile.error.fileType.description",
                subMessageValues: { types: accept.replace(/image\//g, "") },
            });
        }
    };

    const hashPath = values[customFieldName];

    return (
        <Container>
            <AvailableLoaderContainer>
                <LoaderPreviewContainer>
                    <CustomLoader variant={values[variantFieldName]} color={values[colorFieldName] ?? undefined}/>
                </LoaderPreviewContainer>
                <ColorPickerContainer>
                    <StylesColorPicker name={colorFieldName} />
                    <Button
                        type="button"
                        fluid
                        view="secondaryBlack"
                        onClick={() => setChangeLoaderModalOpen(true)}
                        content={formatMessage({ id: "styles.loaderAnimation.changeButton" })}
                    />
                </ColorPickerContainer>
            </AvailableLoaderContainer>
            <Separator>
                <hr />
                <span>{formatMessage({ id: "styles.loaderAnimation.modal.separator" })}</span>
                <hr />
            </Separator>
            <CustomLoaderDescription>
                {formatMessage({ id: "styles.loaderAnimation.upload.header" })}
                <span>
                    {formatMessage({ id: "styles.loaderAnimation.upload.description" })}
                </span>
            </CustomLoaderDescription>
            <UploadContainer>
                {(customLoaderUrl || hashPath) && (
                    <ImageComponent>
                        <Image
                            src={customLoaderUrl ? customLoaderUrl : getDomain(hashPath)}
                            imgStyle={{ objectFit: "contain", width: '65px', height: '65px' }}
                            alt=""
                        />
                    </ImageComponent>
                )}
                <Dropzone
                    accept="image/gif, image/svg+xml"
                    onDrop={(files) => handleDropFile(files)}
                    multiple={false}
                    maxSize={MAX_LOADER_SIZE}
                    onDropRejected={(files) => onDropRejected(files, "image/gif, image/svg", MAX_LOADER_SIZE)}
                >
                    {({ getInputProps, getRootProps }) => (
                        (customLoaderUrl || hashPath) ? (
                            <ImageInfoContainer>
                                <ChangeButtonContainer>
                                    <ChangeButton {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {formatMessage({id: "styles.loaderAnimation.upload.change"})}
                                    </ChangeButton>
                                    <TrashButton
                                        onClick={() => {
                                            setCustomLoaderFile();
                                            if (customLoaderUrl) {
                                                setCustomLoaderUrl();
                                                setFieldValue(customFieldName, initialValues[customFieldName]);
                                            } else {
                                                setFieldValue(customFieldName, null);
                                            }
                                        }}
                                    />
                                </ChangeButtonContainer>
                                {customLoaderFile && <span>{`/...${customLoaderFile.name.slice(-10)}`}</span>}
                            </ImageInfoContainer>
                        ) : (
                            <UploadButton {...getRootProps()}>
                                <input {...getInputProps()} />
                                {formatMessage({id: "styles.loaderAnimation.upload.button"})}
                            </UploadButton>
                        )
                    )}
                </Dropzone>
            </UploadContainer>
            <StyledModal
                open={changeLoaderModalOpen}
                onClose={() => setChangeLoaderModalOpen(false)}
                closeOnDimmerClick
                header={{
                    title: formatMessage({ id: "styles.loaderAnimation.modal.header" }),
                    buttons: (
                        <Button
                            disabled={variant === initialValues[variantFieldName]}
                            content={formatMessage({ id: "styles.loaderAnimation.modal.saveButton" })}
                            onClick={handleSubmit}
                        />
                    ),
                }}
            >
                <LoadersContainer>
                    {Object.values(loaderVariants).map(el => (
                        <LoaderContainer key={el} isActive={variant === el} onClick={() => setVariant(el)}>
                            <CustomLoader variant={el} color={values[colorFieldName] ?? undefined}/>
                        </LoaderContainer>
                    ))}
                </LoadersContainer>
            </StyledModal>
        </Container>
    );
};

Component.propTypes = {
    colorFieldName: PropTypes.string.isRequired,
    variantFieldName: PropTypes.string.isRequired,
    customFieldName: PropTypes.string.isRequired,
    openErrorModal: PropTypes.func,
    ...formProptypes,
};

Component.defaultProps = {
    openErrorModal: () => null,
};

export const CustomLoaderPicker = injectIntl(Component);

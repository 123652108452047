import React from 'react';
import {SubTitle, Text, Title} from './nodes';
import {intlShape} from 'react-intl';

export const ExtendTrialStep = ({}, {intl: {formatMessage}}) => (
    <>
        <Title align="center">
            {formatMessage({id: 'downgradeToFree.modal.extendTrial.header'})}
            <SubTitle>
                {formatMessage({id: 'downgradeToFree.modal.extendTrial.subHeader'})}
            </SubTitle>
        </Title>
        <Text align="center">
            {formatMessage({id: 'downgradeToFree.modal.extendTrial.description'})}
        </Text>
    </>
);

ExtendTrialStep.contextTypes = {
    intl: intlShape.isRequired,
};

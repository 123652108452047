import PropTypes from "prop-types";
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { intlShape } from "react-intl";
import { toast } from "react-toastify";
import isBrowser from "packages/utils/isBrowser";
import {
    MESSAGE_TYPE,
    URLS,
    WEBSITE_ALBUM_ITEM_TYPE,
} from "packages/enum";
import ModalConfirmDelete from "packages/components/modalConfirmDelete";
import {
    MenuIcon,
    CheckIcon,
    HideIcon,
    AlignLeftIcon,
} from "packages/components/icons";
import DropdownMenu from "packages/components/dropdownMenu";
import throttle from "lodash/throttle";
import FocusPointModal from "@configurator/components/modals/focusPointModal";
import CropperModal from "@configurator/components/modals/cropperModal";
import NavigationPopup from "@configurator/components/modals/navigationPopup";
import UploadPhotoModal from "@configurator/containers/UploadPhotoContainer";
import { AlbumItemUpdateModal } from "./AlbumItemUpdateModal";
import {
    Container,
    ImageComponent,
    AlbumCoverText,
    SettingsButton,
    ItemInfo,
    DropdownContainer,
    DropZone,
    ImageLimitBannerWrapper,
    ImageLimitBanner,
} from './nodes';
import { useSubscription } from "@configurator/providers/subscription";
import {
    close as closeSubscriptionModal,
    open as openSubscriptionModal,
} from "packages/redux/modules/modalConfirmDelete/actions";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {useIsMobile} from 'packages/helpers/useWindowSize';
import AddInfoAndAltModal from "packages/components/AddInfoAndAltModal";

// Смотрим левее или правее мы на плитке, что бы понять с какой стороны дропнуть
function dndDropHelper(hoverComponentRef, monitor) {
    if (!hoverComponentRef) {
        console.log("!not bound!!!! 1");
        return;
    }
    const hoverBoundingRect =
        hoverComponentRef.current?.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    if (!hoverBoundingRect || !clientOffset) {
        console.log("!not bound!!!! 2");
        return;
    }
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    return hoverClientX < hoverMiddleX ? -1 : 1;
}

const AlbumItem = (
    {
        src,
        cover,
        srcSetWebpMap,
        aspectRatio,
        photoId,
        id,
        itemType,
        onItemMoveToAlbum,
        onImageReplace,
        onImageDelete,
        onAlbumUpdate,
        onDropImage,
        loaderStart,
        loaderStop,
        hideCover,
        hidden,
        onAlbumItemUpdate,
        description,
        hideText,
        lastItem,
        index,
        expandedView,
        xFocalPoint,
        yFocalPoint,
        setPhotos,
        onImageResize,
        columnCount,
        disabledByLimit,
        imgWidth,
        imgHeight,
        altTextDescriptive,
        altText
    },
    { intl: { formatMessage } }
) => {
    const ref = useRef();
    const dropDownRef = useRef();
    const [addInfoModalOpen, setAddInfoModalOpen] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [itemUpdateModalOpen, setItemUpdateModalOpen] = useState(false);
    const [focusPointModalOpen, setFocusPointModalOpen] = useState(false);
    const [cropperModalOpen, setCropperModalOpen] = useState(false);
    const [itemMoveModalOpen, setItemMoveModalOpen] = useState(false);
    const [replacePhotoModalOpen, setReplacePhotoModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { permissions } = useSubscription();
    const isMobile = useIsMobile();

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: itemType, photoId, id, src, srcSetWebpMap, aspectRatio },
        begin: () => ({
            type: itemType,
            photoId,
            id,
            src,
            srcSetWebpMap,
            aspectRatio,
        }),
        canDrag: () => isBrowser,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dndDropHelperWithThrottle = useCallback(
        throttle((ref, monitor) =>
            setOverPosition(dndDropHelper(ref, monitor), 16, {
                trailing: true,
                leading: false,
            })
        ),
        []
    );

    const [{ isOver }, drop] = useDrop({
        accept: [WEBSITE_ALBUM_ITEM_TYPE.VIDEO, WEBSITE_ALBUM_ITEM_TYPE.PHOTO],
        canDrop: () => isBrowser,
        collect: (monitor) => {
            if (!ref) {
                return {
                    isOver: false,
                };
            }
            return {
                isOver: !!monitor.isOver() /* && (monitor.getItem() || {}).id !== id,*/,
            };
        },
        drop: (item) =>
            onDropImage({
                itemId: item.id,
                droppedId: id,
                position: -1,
            }),
        hover(item, monitor) {
            dndDropHelperWithThrottle(ref, monitor);
        },
    });

    const [overPosition, setOverPosition] = useState(1);

    const handleReplace = async (file) => {
        await onImageReplace(file);
        setReplacePhotoModalOpen(false);
    };

    const handleResize = async (file) => {
        await onImageResize(file);
        setCropperModalOpen(false);
    };

    const handleDelete = async () => {
        setDeleteDialogVisible(false);
        loaderStart();
        await onImageDelete();
        setPhotos(prev => prev.filter(el => el.id !== id));
        loaderStop();
        window.frames["preview-frame"]?.postMessage(
            JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_PAGE,
            }),
            "*"
        );
    };

    const handleMoveToAlbum = async (albumId, albumName) => {
        const {success} = await onItemMoveToAlbum(albumId);
        if (success) {
            setItemMoveModalOpen(false);
            toast.success(`${formatMessage({ id: "album.notification.movedToAlbum" })} ${albumName}`);
        }
    };

    const menuItems = useMemo(() => {
        let items = [];
        if(!disabledByLimit) {
            items.push(
                {
                    text: "album.item.label.show",
                    icon: !hidden && <CheckIcon color="green" />,
                    onClick: () => onAlbumItemUpdate({ hidden: !hidden }),
                });

            if (!hideCover) {
                items.push({
                    text: "album.label.makeCover",
                    icon: cover && <CheckIcon color="green" />,
                    onClick: () => onAlbumUpdate({ mainPhotoId: id }),
                });
            }

            const handleOpenSubscriptionModal = () => {
                dispatch(
                    openSubscriptionModal({
                        headerMessageId: "permission.modal.pro.header",
                        subMessageId: "permission.modal.pro.subMessage",
                        yesMessageId: "permission.modal.buttonMessage",
                        onClickYes: () => {
                            dispatch(push(URLS.website_billing));
                            dispatch(closeSubscriptionModal());
                        },
                        hideCancelButton: true,
                        styles: { width: "450px" },
                    })
                );
            };

            if (!isMobile) {
                items.push({
                    text: 'album.label.focusPoint',
                    icon: (xFocalPoint || yFocalPoint || null) && <CheckIcon color="green"/>,
                    onClick: () =>
                        permissions.FOCAL_POINTS
                            ? setFocusPointModalOpen(true)
                            : handleOpenSubscriptionModal(),
                });

                items.push({
                    text: 'album.label.cropper',
                    onClick: () =>
                        permissions.EDIT_PHOTO
                            ? setCropperModalOpen(true)
                            : handleOpenSubscriptionModal(),
                });
            }

            items.push({
                text: 'album.label.replace',
                onClick: () =>
                    permissions.REPLACE_PHOTO
                        ? setReplacePhotoModalOpen(true)
                        : handleOpenSubscriptionModal(),
            });

            if (!hideText) {
                items.push({
                    text: "album.item.label.addText",
                    icon: description && <CheckIcon color="green" />,
                    onClick: () =>
                        permissions.IMAGE_DESCRIPTION
                            ? setItemUpdateModalOpen(true)
                            : handleOpenSubscriptionModal(),
                });
            }


            if (!isMobile) {
                items.push({
                    text: "album.label.move",
                    onClick: () => setItemMoveModalOpen(true),
                });
            }

          if(itemType === "PHOTO"){
            items.push({
                text: "album.label.info",
                icon: false && <CheckIcon color="green" />,
                onClick: () =>
                    permissions.ALT_DESCRIPTION
                        ? setAddInfoModalOpen(true)
                        : handleOpenSubscriptionModal(),
            });
          }
        }

        items.push({
            text: "album.label.delete",
            onClick: () => setDeleteDialogVisible(true),
            color: "red",
        });

        return items;
    }, [
        hidden,
        hideCover,
        xFocalPoint,
        yFocalPoint,
        hideText,
        onAlbumItemUpdate,
        cover,
        onAlbumUpdate,
        id,
        description,
        disabledByLimit,
        permissions.FOCAL_POINTS,
        permissions.EDIT_PHOTO,
        permissions.REPLACE_PHOTO,
        permissions.IMAGE_DESCRIPTION,
        dispatch,
        isMobile,
    ]);
    drag(drop(ref));

    const [{ isOverExtraLeft }, dropExtraLeft] = useDrop({
        accept: [WEBSITE_ALBUM_ITEM_TYPE.VIDEO, WEBSITE_ALBUM_ITEM_TYPE.PHOTO],
        drop: (item) =>
            onDropImage({
                itemId: item.id,
                droppedId: id,
                position: -1,
            }),
        collect: (monitor) => {
            return {
                isOverExtraLeft: !!monitor.isOver(),
            };
        },
    });
    const [{ isOverExtraRight }, dropExtraRight] = useDrop({
        accept: [WEBSITE_ALBUM_ITEM_TYPE.VIDEO, WEBSITE_ALBUM_ITEM_TYPE.PHOTO],
        drop: (item) =>
            onDropImage({
                itemId: item.id,
                droppedId: id,
                position: 1,
            }),
        collect: (monitor) => {
            return {
                isOverExtraRight: !!monitor.isOver(),
            };
        },
    });

    // Если убрали со своей позиции, то больше не раскрываем ее
    const [justDrag, setJustDrag] = useState(true);
    useEffect(() => {
        if (isDragging && !isOver) {
            setJustDrag(false);
        } else if (!isDragging) {
            setJustDrag(true);
        }
    }, [isDragging, isOver]);

    const isLastItemInRow = (index + 1) % columnCount === 0;

    return (
        <>
            <DropZone
                ref={dropExtraLeft}
                isDragging={isDragging}
                show={(isOver || isOverExtraLeft) && overPosition === -1}
                fast={isLastItemInRow}
                expandedView={expandedView}
                columnCount={columnCount}
            />
            <Container
                key={photoId}
                isOver={isOver}
                justDrag={justDrag}
                isDragging={isDragging}
                ref={ref}
                cover={cover}
                expandedView={expandedView}
                columnCount={columnCount}
            >
                {disabledByLimit &&
                <ImageLimitBannerWrapper>
                    <ImageLimitBanner>Locked</ImageLimitBanner>
                </ImageLimitBannerWrapper>
                }
                <ImageComponent
                    aspectRatio={aspectRatio}
                    src={src}
                    srcSetWebpMap={srcSetWebpMap}
                    backgroundColor="#ececec"
                    imgStyle={{ pointerEvents: "none", opacity: disabledByLimit ? 0.5 : 1 }}
                    draggable 
                    alt={altText}
                />
                <DropdownContainer>
                    <DropdownMenu
                        menuRef={dropDownRef}
                        trigger={
                            <SettingsButton>
                                <MenuIcon color="darkGray" />
                            </SettingsButton>
                        }
                        open={itemMoveModalOpen}
                        items={menuItems}
                        isLastItemInRow={isLastItemInRow}
                    />
                </DropdownContainer>
                {cover ? (
                    <AlbumCoverText>
                        {formatMessage({ id: "album.label.cover" })}
                    </AlbumCoverText>
                ) : null}
                <ItemInfo cover={cover}>
                    {hidden && <HideIcon size="small" />}
                    {description && <AlignLeftIcon />}
                </ItemInfo>
            </Container>
            {lastItem ||
                ((index + 1) % columnCount !== 0 && (
                    <DropZone
                        expandedView={expandedView}
                        isOver={isOverExtraRight}
                        ref={dropExtraRight}
                        isDragging={isDragging}
                        show={
                            lastItem ||
                            ((isOver || isOverExtraRight) && overPosition === 1)
                        }
                        lastItem
                        right
                    />
                ))}

            {deleteDialogVisible && (
                <ModalConfirmDelete
                    open
                    onClickYes={handleDelete}
                    onClickCancel={() => setDeleteDialogVisible(false)}
                    onClose={() => setDeleteDialogVisible(false)}
                />
            )}
            {addInfoModalOpen && (
                <AddInfoAndAltModal
                    altTextDescriptive={altTextDescriptive}
                    altText={altText}
                    open
                    onAlbumItemUpdate={onAlbumItemUpdate}
                    onClickCancel={() => setAddInfoModalOpen(false)}
                    onClose={() => setAddInfoModalOpen(false)}
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    src={src}
                />
            )}
            {itemUpdateModalOpen && (
                <AlbumItemUpdateModal
                    open
                    onAlbumItemUpdate={onAlbumItemUpdate}
                    onClose={() => setItemUpdateModalOpen(false)}
                    initialValues={{ description }}
                />
            )}
            {focusPointModalOpen && (
                <FocusPointModal
                    open
                    onClickYes={() => setFocusPointModalOpen(false)}
                    onClose={() => setFocusPointModalOpen(false)}
                    src={src}
                    initialFocusPoint={{
                        x: xFocalPoint || 50,
                        y: yFocalPoint || 50,
                    }}
                    onFocusPointUpdate={(xFocalPoint, yFocalPoint) =>
                        onAlbumItemUpdate({
                            xFocalPoint,
                            yFocalPoint,
                        })
                    }
                />
            )}
            {cropperModalOpen && (
                <CropperModal
                    open
                    src={src}
                    onClose={() => setCropperModalOpen(false)}
                    onSave={handleResize}
                />
            )}
            {replacePhotoModalOpen && (
                <UploadPhotoModal
                    open
                    onClose={() => setReplacePhotoModalOpen(false)}
                    onUpload={handleReplace}
                />
            )}
            {itemMoveModalOpen && (
                <NavigationPopup
                    open
                    context={dropDownRef.current}
                    onClose={() => setItemMoveModalOpen(false)}
                    clickAlbum={({id, name}) => handleMoveToAlbum(id, name)}
                />
            )}
        </>
    );
};

AlbumItem.propTypes = {
    src: PropTypes.string.isRequired,
    cover: PropTypes.bool.isRequired,
    srcSetWebpMap: PropTypes.array.isRequired,
    aspectRatio: PropTypes.string.isRequired,
    photoId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    columnCount: PropTypes.number.isRequired,

    // methods
    onImageReplace: PropTypes.func.isRequired,
    onImageDelete: PropTypes.func.isRequired,
    onAlbumUpdate: PropTypes.func.isRequired,
    onDropImage: PropTypes.func.isRequired,
    onAlbumItemUpdate: PropTypes.func.isRequired,
    onItemMoveToAlbum: PropTypes.func.isRequired,
    loaderStart: PropTypes.func.isRequired,
    loaderStop: PropTypes.func.isRequired,
    onImageResize: PropTypes.func.isRequired,

    lastItem: PropTypes.bool,
    hideCover: PropTypes.bool,
    hideText: PropTypes.bool,
    hidden: PropTypes.bool,
    expandedView: PropTypes.bool,
    disabledByLimit: PropTypes.bool,
    xFocalPoint: PropTypes.string,
    yFocalPoint: PropTypes.string,
    setPhotos: PropTypes.func,
};

AlbumItem.defaultProps = {
    hideCover: false,
    hideText: false,
    hidden: false,
    lastItem: false,
    expandedView: false,
    disabledByLimit: false,
    xFocalPoint: "",
    yFocalPoint: "",
    setPhotos: () => null,
};

AlbumItem.contextTypes = {
    intl: intlShape,
};

export default AlbumItem;

import {createActions} from 'redux-actions';


export const {selectedImages: {setImages, deleteAll, setOpenDownload}} = createActions({
    SELECTED_IMAGES: {
        SET_IMAGES: (data) => (data),
        DELETE_ALL: () => null,
        SET_OPEN_DOWNLOAD: (open) => open,
    },
});

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {CustomLoaderContainer, UserCustomLoader} from './nodes';
import {loaderDefaultProps, loaderPropTypes, loaderVariants} from './const';

import {Semantic} from 'packages/components';
import {Circle} from './css/circle';
import {Ellipsis} from './css/ellipsis';
import {Facebook} from './css/facebook';
import {Grid} from './css/grid';
import {Heart} from './css/heart';
import {Hourglass} from './css/hourglass';
import {Ring} from './css/ring';
import {RingDotted} from './css/ringDotted';
import {RingDual} from './css/ringDual';
import {Ripple} from './css/ripple';
import {Roller} from './css/roller';
import {Spinner} from './css/spinner';
import {Audio} from './svg/audio';
import {BallTriangle} from './svg/ballTriangle';
import {Bars} from './svg/bars';
import {Circles} from './svg/circles';
import {Hearts} from './svg/hearts';
import {Oval} from './svg/oval';
import {Puff} from './svg/puff';
import {Rings} from './svg/rings';
import {SpinningCircles} from './svg/spinningCircles';
import {TailSpin} from './svg/tailSpin';
import {ThreeDots} from './svg/threeDots';

export const CustomLoader = React.memo(({color, variant, width, height, customLoaderUrl}) => {
    const LoaderComponent = useMemo(() => {
        switch (variant) {
            case loaderVariants.circle: return Circle;
            case loaderVariants.ellipsis: return Ellipsis;
            case loaderVariants.facebook: return Facebook;
            case loaderVariants.grid: return Grid;
            case loaderVariants.heart: return Heart;
            case loaderVariants.hourglass: return Hourglass;
            case loaderVariants.ring: return Ring;
            case loaderVariants.ringDotted: return RingDotted;
            case loaderVariants.ringDual: return RingDual;
            case loaderVariants.ripple: return Ripple;
            case loaderVariants.roller: return Roller;
            case loaderVariants.spinner: return Spinner;
            case loaderVariants.audio: return Audio;
            case loaderVariants.ballTriangle: return BallTriangle;
            case loaderVariants.bars: return Bars;
            case loaderVariants.circles: return Circles;
            case loaderVariants.hearts: return Hearts;
            case loaderVariants.oval: return Oval;
            case loaderVariants.puff: return Puff;
            case loaderVariants.rings: return Rings;
            case loaderVariants.spinningCircles: return SpinningCircles;
            case loaderVariants.tailSpin: return TailSpin;
            case loaderVariants.threeDots: return ThreeDots;
            case loaderVariants.default:
            default: return () => <Semantic.Loader active size="massive" />;
        }
    }, [variant]);

    return (
        <CustomLoaderContainer color={color} width={width} height={height}>
            {
                customLoaderUrl ?
                    <UserCustomLoader src={customLoaderUrl} width={width} height={height}/> :
                    <LoaderComponent color={color} width={width} height={height}/>
            }
        </CustomLoaderContainer>
    )
});

CustomLoader.propTypes = {
    ...loaderPropTypes,
    variant: PropTypes.oneOf(Object.keys(loaderVariants)),
}

CustomLoader.defaultProps = {
    ...loaderDefaultProps,
    variant: loaderVariants.oval,
}

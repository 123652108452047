import styled from 'styled-components';
import { Modal as ModalComp } from "packages/components/modal";
import { ModalHeader, ButtonRow } from "packages/components/modal/nodes";
import {media} from 'packages/components/media';

export const Modal = styled(ModalComp)`
    width: 670px !important;
    height: 540px !important;
    padding: 20px !important;
`;

export const UploadContainer = styled.div`
    width: 100%;
    .ui.grid {
        margin: 0 -10px;
        .column {
            padding: 10px !important;
        }

        ${media.max.phone`
            margin: 0 -5px;
            .column {
                padding: 5px !important;
            }
        `}
    }
`;

export const UploadWrapper = styled.div`
   height: ${props => props.compact ? 'calc(100% - 65px)' : '100%'};
   position: relative;
   overflow-y: auto;
   overflow-x: hidden;

   .input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        color: #666A73;
        font-size: 13px;
        line-height: 140%;
        padding: 15px;

        background: #F6F6F6;
        border: 1px dashed #E1E2E6;
        border-radius: 4px;

         .dropzone-header {
             color: #343840;
             font-size: 24px;
             font-weight: bold;
             line-height: 150%;
             margin: 18px 0 0;
             text-align: center;

             > span {
               color: #666A73;
               font-size: 13px;
               line-height: 140%;
               display: block;
               font-weight: normal;
             }
          }
         .dropzone-footer {
            text-align: center;
            position: absolute;
            bottom: 15px;
         }

         &:focus {
            outline-color: rgba(0, 0, 0, 0) !important;
         }
    }

    ${media.max.phone`
        height: ${props => props.compact ? 'calc(100% - 120px)' : '100%'};
        ${props => !props.compact && 'padding-top: 40px;'}
    `}

    @media (pointer: coarse) {
        user-select: none;
    }
`;

export const Container = styled.div`
   max-width: 100%;
   height: 100%;

    ${media.max.tablet`
        ${ModalHeader}, ${ButtonRow} {
            flex-wrap: nowrap;
        }
    `}
`;

export const MobileButtonsContainer = styled.div`
    display: none;

    ${media.max.phone`
        display: flex;
        width: 100%;
        position: absolute;
        bottom: 20px;
        left: 0;
        padding: 0 16px;

        button {
            width: 100%;
        }

        button + button {
            margin-left: 10px !important;
        }
    `}
`;
